import React from 'react';
import normalizeQuotes from '../../lib/Typographus/normalizeQuotes';
import replaceSymbols from '../../lib/Typographus/replaceSymbols';
import { REGION } from '../../const/config';

import {
  Button,
} from '/ui/molecules';

const handleTypograph = ({ value }) => {
  let typographed = value;
  try {
    typographed = replaceSymbols(typographed, REGION);
    typographed = normalizeQuotes(typographed);
  } catch (e) {
    console.error(e);
    return value;
  }

  return typographed;
};

const TypographButton = ({
  borderRadius = '0 10px 10px 0',
  value = '',
  onClick,
}) => (
  <Button
    width="38px"
    height="38px"
    backgroundColor="#E0E1E2"
    color="rgba(0, 0, 0, 0.6)"
    backgroundColorHover="#CACBCD"
    colorHover="rgba(0, 0, 0, 0.8)"
    borderRadius={borderRadius}
    transition="opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease"
    onClick={() => {
      if (onClick && typeof onClick === 'function') {
        onClick(handleTypograph({ value }));
      } else {
        console.error('TypographButton component error: onClick must be a function');
      }
    }}
    title="Типографировать"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27">
      <g fill="none" fillRule="evenodd">
        <path fill="#293043" d="M9.786 17.01V8.47H7V7h7.448v1.47h-2.786v8.54H9.786zm7.253-.86c1.033 0 1.602-.618 1.602-1.772v-.083c0-1.195-.697-1.782-1.614-1.782-.976 0-1.672.587-1.672 1.782v.083c0 1.205.662 1.772 1.684 1.772zm-3.043 2.73v-7.233h1.405v.866c.337-.515 1.092-.979 1.986-.979 1.521 0 2.694 1 2.694 2.74v.083c0 1.731-1.126 2.782-2.694 2.782-.964 0-1.66-.423-1.986-.948v2.69h-1.405z" />
      </g>
    </svg>
  </Button>
);

export default TypographButton;
