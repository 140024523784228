const E = {};

E.FETCH_POST_ORIGIN = 'FETCH_POST_ORIGIN';
E.FETCH_POST_ORIGIN_SUCCESS = 'FETCH_POST_ORIGIN_SUCCESS';
E.FETCH_POST_ORIGIN_FAILURE = 'FETCH_POST_ORIGIN_FAILURE';

E.fetchPostOrigin = ({ postId }) => ({
  type: E.FETCH_POST_ORIGIN,
  payload: { postId },
  meta: { remote: true },
});

E.fetchPostOriginSuccess = ({ post }) => ({
  type: E.FETCH_POST_ORIGIN_SUCCESS,
  payload: { post },
});

E.fetchPostOriginFailure = ({ error }) => ({
  type: E.FETCH_POST_ORIGIN_FAILURE,
  error,
});

module.exports = E;
