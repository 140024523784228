import React, {Component, PropTypes} from 'react';
import {connect} from 'react-redux';
import {setVisibilityCkToolbar, updateTransactionId} from '../../actions/actions.js';
import debounce from 'lodash/debounce';

import "./index.styl";


//const FieldInput = ({ input, data, valueField, textField }) =>
//	<input {...input}
//		value={input.value}
//		onChange={textField}
//	/>

class RichText extends Component {
	constructor(props) {
		super(props);
		this.emitChange = this.emitChange.bind(this);
		this.editor = null;
		this.updateFlage = false;
		this.releaseBounce = true;
		this.focused = false;
		this.range= null;
	}

	render() {
			const {input: {name}, formKey} = this.props;
		return <div ref={e => this.htmlEl = e} id={formKey + '_' + name} key={"richtext-"+formKey + '_' + name}></div>;
	}

	initEditor(id, visualOptions, value){
		(this.counter && this.counter++) || (this.counter = 0);

		var editor = CKEDITOR.appendTo(id, visualOptions, value);

		editor.addCommand('embedOpenDialog', {
			exec: function (editor) {
				return editor.openDialog('embedBase');
			}
		});

		// this.bindEditorEvents(editor);
		return editor;
	}

	bindEditorEvents(editor){
		let dispatch = this.props.dispatch;

		editor.setKeystroke(CKEDITOR.CTRL + CKEDITOR.SHIFT + 77, 'embedOpenDialog'); //press ctrl shift 'm'

		editor.on('dialogDefinition', function (ev) {
			var err, targetField;
			try {
				if (ev.data.name === 'link') {
					targetField = ev.data.definition.getContents('target').get('linkTargetType');
					return targetField['default'] = '_blank';
				}
			} catch (_error) {
				err = _error;
			}
		});
		
		editor.on('focus', function (ev) {
				dispatch(setVisibilityCkToolbar(true))
			});

		editor.on('blur', function (ev) {
				dispatch(setVisibilityCkToolbar(false))
			});


		// const customOnChange = this.props.onChange;
		// const notifyOnChange = this.props.notifyOnChange;
		const debounced = debounce((newData) => {
			// console.log('new data', newData, customOnChange, onChange, notifyOnChange);
			
			this.releaseBounce = true;

			if (this.props.customOnChange) {
				this.props.customOnChange({target: {name, value: newData}});
			}
			else {
				this.props.input.onChange(editor.getData());
			}

			if (this.props.notifyOnChange) {
				this.props.notifyOnChange({target: {name, value: newData}});
			}

		}, 1500, {maxWait: 20000});

		var data = editor.getData();
		var self = this;
		editor.on('change', (event) => {
			var newData = editor.getData();
			if (self.updateFlag) {
				self.updateFlag = false;
				return;
			}
			if (data != newData) {
				data = newData;
				if (this.releaseBounce) {
					dispatch(updateTransactionId('awaiting', false));
					this.releaseBounce = false;
				}
				debounced(newData);
			}
		});

		// this.currentRanges существует лишь по той причине, что мы
		// не можем узнать когда вызывается iframe.onload. Старый dom
		// редактора исчезает и позиция курсора становится неизвестной
		// debugger
		editor.on('change', _ => {
			this.currentRanges = editor.getSelection().getRanges();
		})
		editor.document.getBody().on('click', _ => {
			this.currentRanges = editor.getSelection().getRanges();
		})
		editor.document.getBody().on('keyup', (event) => {
			let keyCode = event.data.$.keyCode;
			if([0x28, 0x25, 0x27, 0x26].indexOf(keyCode) > -1)
				this.currentRanges = editor.getSelection().getRanges();
		})

		//возможно стоит делать через команду а не через keyup
		// editor.addCommand( 'sample', {
		// 	exec: function( editor ) {
		// 		alert( 'Executing a command for the editor name "' + editor.name + '"!' );
		// 	}
		// } );
	}

	componentDidMount() {
		const {input: {value, onChange, name}, dispatch, formKey} = this.props;

		var $element, changed, component, ref, ref1, ref10, ref11, ref2, ref3, ref4, ref5, ref6, ref7, ref8, ref9;

		const visualOptions = Object.assign({}, CKEDITOR.config.visualOptions, {
			autoGrow_minHeight: this.props.height || CKEDITOR.config.visualOptions.autoGrow_minHeight
		})

		this.editor = this.initEditor(formKey + '_' + name, visualOptions, value);

		this.lastUpdate = new Date(0);
		const onFocus = this.props.onFocus;
		const onBlur = this.props.onBlur;



		var data, //буфер для данных после уничтожения редактора - не используется в данный момент
			ranges, oldBody;

		CKEDITOR.on('instanceDestroyed', ({editor: editor}) => {
			if(editor !== this.editor)
				return;

			this.editor = this.initEditor(formKey + '_' + name, visualOptions, value);
			
			this.editor.once('instanceReady', (ev) => {
				//очищаем все содержимое нового редактора
				this.editor.document.getBody().$.innerHTML = '';
				//и копируем узлы со старого редактора, чтобы
				//ссылки из range для курсора были старыми
				[...oldBody.children].forEach(el => {
					this.editor.document.getBody().$.appendChild(el);
				})

				oldBody = this.editor.document.getBody().$;

				if(typeof this.currentRanges !== 'undefined'){
					var range = new CKEDITOR.dom.range( this.editor.document );

					//*this.currentRanges изменяется по событиям
					range.setStart(this.currentRanges[0].startContainer, this.currentRanges[0].startOffset);
					range.setEnd(this.currentRanges[0].endContainer, this.currentRanges[0].endOffset);

					this.editor.getSelection().selectRanges([range]);

					//берем ближайший редактируемый элемент
					//Редактируемый элемент не всегда body. Например
					//в случае с виджетами они имеют свои редактируемые и не
					//редактируемые поля. В крайнем случае, если курсор был вне
					//виджета он поднимится до body, который должен быть редактируемым
					var closestEditableElement = range.startContainer.getAscendant(el => {
						return el.getAttribute('contenteditable');
					})
					closestEditableElement.focus();
				};


				this.bindEditorEvents(this.editor);
			})
		});

		this.editor.once('instanceReady', (ev) => {
			this.bindEditorEvents(this.editor);
			// debugger;

			oldBody = this.editor.document.getBody().$;//не забывать, что это ссылка на узел

			// В случае даже если компоненты имеют одинаковые
			// ключи, но сами компоненты меняются местами,
			// то происходит чудо: ререндера нет, методы жизненного цикла
			// вызываются неожидаемо, а iframe теряет свое содержимое,
			// когда редактор остается привязан к уже отсутствующему дому
			let iframe = $(this.htmlEl).find('iframe')[0]
			iframe.onload = _ => {
				//убедимся, что разрушать старый редактор все-таки надо
				if(this.editor.document.$ === iframe.contentWindow.document)
					return false;

				data = this.editor.getData();
				this.editor.destroy(true);
			};

		});
	}



	componentWillReceiveProps(props) {
		const {input: {value}} = props;
		if (this.editor) {
			var curData = this.editor.getData();
			if (curData.trim() !== value.trim()) {
				this.updateFlag = true;
				this.editor.setData(value);
			}
		}
	}

	shouldComponentUpdate(nextProps) {
		// We need not rerender if the change of props simply reflects the user's
		// edits. Rerendering in this case would make the cursor/caret jump.
		//debugger;
		return false;
	}


	componentDidUpdate() {
		if (this.htmlEl && this.props.html !== this.htmlEl.innerHTML) {
			// Perhaps React (whose VDOM gets outdated because we often prevent
			// rerendering) did not update the DOM. So we update it manually now.
			this.htmlEl.innerHTML = this.props.html;
		}
	}

	emitChange(evt) {
		//if (!this.htmlEl) return;
		//var html = this.htmlEl.innerHTML;
		//if (this.props.onChange && html !== this.lastHtml) {
		//	evt.target = { value: html };
		//	this.props.onChange(evt);
		//}
		//this.lastHtml = html;
	}
}
// RichText.contextTypes = {
// 	_reduxForm: PropTypes.object
// }

export default connect((state, props) => ({...props}))
(RichText)
