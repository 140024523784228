import React from 'react';

export const Edit = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
    <path fill="#FFF" fillRule="evenodd" d="M14.397 13.795c.168 0 .31.058.427.175.117.118.176.26.176.427 0 .168-.059.31-.176.427a.581.581 0 0 1-.427.176H.603a.581.581 0 0 1-.427-.176.581.581 0 0 1-.176-.427c0-.167.059-.31.176-.427a.581.581 0 0 1 .427-.175h13.794zM3.884 11.819a.502.502 0 0 1-.586-.117.502.502 0 0 1-.117-.586l1.674-3.934a.47.47 0 0 1 .117-.184L11.618.352c.235-.235.52-.352.854-.352.335 0 .62.117.854.352l1.322 1.322c.235.234.352.52.352.854 0 .335-.117.62-.352.854l-6.646 6.663a.666.666 0 0 1-.167.1L3.884 11.82zm8.588-10.614l-1.038 1.038 1.323 1.323 1.038-1.038-1.323-1.323zM5.91 7.768l-.971 2.293 2.293-.97 4.67-4.671-1.322-1.323-4.67 4.67z" />
  </svg>
);

export const Delete = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15">
    <path fill="#FFF" fillRule="evenodd" d="M1.908 14.814l.013.001-.012-.001h-.001zm-.167-.096zm0 0zM15 2.758a.554.554 0 0 1-.184.412.606.606 0 0 1-.434.176h-.334v10.477c0 .325-.117.602-.35.832a1.15 1.15 0 0 1-.836.345H2.305a1.15 1.15 0 0 1-.835-.345 1.124 1.124 0 0 1-.35-.832V3.346H.6a.578.578 0 0 1-.426-.176A.576.576 0 0 1 0 2.75c0-.163.058-.303.175-.421a.578.578 0 0 1 .426-.177h3.09v-.975c0-.325.115-.602.343-.832.228-.23.51-.345.844-.345h5.244c.323 0 .602.115.836.345.234.23.35.507.35.832v.975h3.074a.598.598 0 0 1 .618.606zM4.86 1.194v.958h5.262v-.958H4.861zm8.002 12.629V3.346H2.305v10.477h10.557zM7.9 5.549a.546.546 0 0 1 .184.42v5.617a.586.586 0 0 1-.176.43.568.568 0 0 1-.417.176.568.568 0 0 1-.418-.177.586.586 0 0 1-.175-.429V5.97c0-.168.058-.311.175-.43a.568.568 0 0 1 .418-.176.53.53 0 0 1 .409.185zm-3.24 0a.546.546 0 0 1 .183.42v5.617a.586.586 0 0 1-.175.43.578.578 0 0 1-.426.176.578.578 0 0 1-.426-.177.586.586 0 0 1-.176-.429V5.97c0-.168.059-.311.176-.43a.578.578 0 0 1 .426-.176.54.54 0 0 1 .417.185zm6.464 0a.546.546 0 0 1 .183.42v5.617a.586.586 0 0 1-.175.43.578.578 0 0 1-.426.176.578.578 0 0 1-.426-.177.586.586 0 0 1-.175-.429V5.97c0-.168.058-.311.175-.43a.578.578 0 0 1 .426-.176.54.54 0 0 1 .418.185z" />
  </svg>
);

export const Upload = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="rgba(0, 0, 0, 0.6)">
    <path d="M17 12v5H3v-5H1v5a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5z"/>
    <path d="M15 7l-5-6-5 6h4v8h2V7h4z"/>
  </svg>
);
