import React from 'react';
import PropTypes from 'prop-types';
import FeedLabel from './FeedLabel';
import FeedItem from './FeedItem';

export const FeedGroupedItems = ({ title, themes }) => {

    return (
        <div className="publish-sections">

            <FeedLabel text={ title }/>

            <table className="ui very basic fixed divided table">

                <tbody>

                {Object.keys(themes).map((key) => {
                    const theme = themes[key];
                    return (
                        <FeedItem key={theme.id} {...theme} />
                    )
                })}

                </tbody>
            </table>
        </div>
    );

};

FeedGroupedItems.propTypes = {
    title: PropTypes.any,
    themes: PropTypes.any
};

export default FeedGroupedItems;