import styled from 'react-emotion';

export default styled('div')`
  text-align: center;
  margin-top: 0.5rem;

  a {
    display: inline-block;
    margin-right: 0.5rem;
  }
`;
