import React from 'react';
import {
  normalizeImgSrc,
} from '../../../../helpers';

const Quote = (props) => {
  const { children, attributes, node } = props;
  const { data } = node;

  const imgSrc = data.get('imgSrc');
  const alt = data.get('alt');

  const normalizeImg = normalizeImgSrc(imgSrc);
  return (
    <aside
      {...attributes}
      className="mz-publish-quote"
    >
      <>
        {normalizeImg ?
          <img
            className="mz-publish-quote__image"
            src={normalizeImg}
            alt={alt}
          />
          : null}
        {children}
      </>
    </aside>
  );
};

export default Quote;
