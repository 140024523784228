/* @flow */
import styled from 'react-emotion';

export const PopupWrapperContainer = styled('div')`
  position: absolute;
  left: ${props => (props.positionX ? props.positionX : 0)}px;
  top: ${props => (props.positionY ? props.positionY : 0)}px;
  z-index: 20;
  margin-top: 10px;
  ::after {
    content: "";
    position: absolute;
    top: -7px;
    left: 18px;
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 7px solid white;
  }
`;

export const PopupOverlay = styled('div')`
  display: flex;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  z-index: 19;
`;
