import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { DragSource, DropTarget } from 'react-dnd';
import ItemTypes from './ItemTypes';
import {types} from '../../utils/constants.js';
import { Link } from 'react-router';
import { formatTitlePost } from '../../lib/formatString';

const featureSource = {
	beginDrag(props) {
		return {
			id: props.id,
			post_id: props.post_id,
			sort_id: props.sort_id,
			title: props.title,
			index: props.index,
		};
	},
	endDrag: function (props, monitor, component) {
		// if (!monitor.didDrop()) {
		// 	return;
		// }
		//SyncForm
		// // When dropped on a compatible target, do something
		// const item = monitor.getItem();
		// const dropResult = monitor.getDropResult();
		// console.log(item, dropResult);
		// console.log(item.id, '____', dropResult.id);


		// CardActions.moveCardToList(item.id, dropResult.listId);
	}
};

const featureTarget = {
	hover(props, monitor, component) {
		if (monitor.getItemType() === ItemTypes.CONTEXT) {
			return;
		}
		const dragIndex = monitor.getItem().index;
		const hoverIndex = props.index;

		// Don't replace items with themselves
		if (dragIndex === hoverIndex) {
			return;
		}

		// Determine rectangle on screen
		const hoverBoundingRect = ReactDOM.findDOMNode(component).getBoundingClientRect();

		// Get vertical middle
		const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

		// Determine mouse position
		const clientOffset = monitor.getClientOffset();

		// Get pixels to the top
		const hoverClientY = clientOffset.y - hoverBoundingRect.top;

		// Only perform the move when the mouse has crossed half of the items height
		// When dragging downwards, only move when the cursor is below 50%
		// When dragging upwards, only move when the cursor is above 50%

		// Dragging downwards
		if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
			return;
		}

		// Dragging upwards
		if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
			return;
		}

		// Time to actually perform the action
		props.moveFeature(dragIndex, hoverIndex);

		// Note: we're mutating the monitor item here!
		// Generally it's better to avoid mutations,
		// but it's good here for the sake of performance
		// to avoid expensive index searches.
		monitor.getItem().index = hoverIndex;
	},
	drop(props, monitor) {
		const itemType = monitor.getItemType();
		if (itemType === ItemTypes.FEATURE) {
			props.onDragEndFeature();
		} else if (itemType === ItemTypes.CONTEXT) {
			props.onDragEndContext(monitor.getItem(), props.index);
		}
	}
};

@DropTarget([ItemTypes.FEATURE, ItemTypes.CONTEXT], featureTarget, (connect, monitor) => ({
	connectDropTarget: connect.dropTarget(),
	isOver: monitor.isOver(),
	isOverCurrent: monitor.isOver({ shallow: true }),
}))
@DragSource(ItemTypes.FEATURE, featureSource, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	isDragging: monitor.isDragging(),
}))


// function collect(connect, monitor) {
// 	return {
// 		connectDragSource: connect.dragSource(),
// 		isDragging: monitor.isDragging()
// 	};
// }
//
// const propTypes = {
// 	id: PropTypes.number.isRequired,
// 	// Injected by React DnD:
// 	isDragging: PropTypes.bool.isRequired,
// 	connectDragSource: PropTypes.func.isRequired
// };



export default  class Feature extends Component {
	static propTypes = {
		connectDragSource: PropTypes.func.isRequired,
		connectDropTarget: PropTypes.func.isRequired,
		sort_id: PropTypes.number.isRequired,
		post_id: PropTypes.number.isRequired,
		isDragging: PropTypes.bool.isRequired,
		id: PropTypes.any.isRequired,
		feature: PropTypes.object.isRequired,
		moveFeature: PropTypes.func.isRequired,
		onDragEndFeature: PropTypes.func.isRequired,
		onDragEndContext: PropTypes.func.isRequired,
		onRemove: PropTypes.func.isRequired,
		index: PropTypes.number.isRequired,
	};
	constructor(props) {
		super(props);
	}
	render(){
		const {feature} = this.props;
		const { isDragging, connectDragSource, connectDropTarget } = this.props;
		const isActive = this.props.isOver;
		return connectDragSource(connectDropTarget(
			<div className={[
				'relatedPostsItem feature',
				isDragging ? 'feature_dragging' : '',
				isActive ? 'feature_active': ''
			].join(' ')}>
				<div className="PostTitle">
					{/*<i className="PostTitle__index">{feature.sort_id}. </i>*/}
					<div className="format">{types[feature.type]}</div>
					<div className="title">
						<Link to={`/post/${feature.post_id}`}>{formatTitlePost(feature)}</Link>
					</div>
					<i
						className="unlinkPost ui grey delete icon"
					    onClick={this.props.onRemove}
					/>
				</div>
			</div>
		))
	}
}
// Feature.propTypes = propTypes;
// export default DragSource(ItemTypes.CARD, featureSource, collect)(Feature);