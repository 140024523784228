import React from 'react';

const PhotoCaption = (props) => {
  const { attributes, children } = props;

  return (
    <figcaption
      className="mz-publish-photo__comment"
      {...attributes}
    >
      {children}
    </figcaption>
  );
};

export default PhotoCaption;