// @flow
import styled from 'react-emotion';

const Comment = styled('p')`
  display: flex;
  font-family: 'Graphik LC', sans-serif;
  font-weight: 500;
  opacity: 0.75;
  font-size: 13px;
  color: rgb(101, 119, 134);
  
  b, strong {
    font-weight: 500;
  }
`;

export default Comment;