import React from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import RichText from '../RichText/RichText.js';
import SlateRichText from "../SlateRichText/SlateRichText";
import RelatedField from '../RelatedField/RelatedField.js';
import { Field as ReduxFormField, change, reduxForm } from 'redux-form';
import DateTimePicker from '../DateTimePicker';
import {
  Label
} from '/ui/atoms';
import {
  Field
} from '/ui/molecules';
import './Event.styl';

import {
  allTransactionsApplied,
  focusedSelector,
  focusedUsersSelector
} from '../../store/selectors';

import { confirmExtr } from '/utils/confirmExtr';
import { timezoneDate } from '/utils/dateFormat';

import syncInfo from '../../../common/sync-channel';

import * as actions from '../../actions/actions.js';
import {
  deleteEvent,
  saveEvent,
  publishEvent,
  changeCurrentVersionEvent,
  changeMajorEvent,
  setEventPublishedStatus,
  setEventEditMode
} from '../../actions/actions.js';
import { collectionValueSelector, collectionItemValueAccessor } from '../../lib/sync-form';

import { timzoneShort } from '/utils/dateFormat';

class Body extends React.Component {
  shouldComponentUpdate(nextProps) {
    return this.props.body != nextProps.body;
  }
  render() {
    return this.props.isSlateRichText
    ? (<ReduxFormField
          name="body"
					component={SlateRichText}
          key={`event-richtext-${this.props.id}`}
          formKey={`event-richtext-${this.props.id}`}
          notifyOnChange={() => this.props.focused({ user: this.props.my_username })}
          entityId={this.props.id}
          contextTitle={this.props.contextTitle}
          height="200"
		/> )
    : (<div className="ckeditor">
        <ReduxFormField
          name="body"
          key={`event-richtext-${this.props.id}`}
          formKey={`event-richtext-${this.props.id}`}
          component={RichText}
          notifyOnChange={() => this.props.focused({ user: this.props.my_username })}
          height="200"
        />
      </div>)
  }
}

class MajorInputComponent extends React.Component {
  render() {
    console.log('MajorInputComponent render()');
    const { input: { value, name }, formKey, changeMajor, dispatch } = this.props;
    return (
      <div
        ref={e => (this.htmlEl = e)}
        className={`importantEvent ui icon right floated small button ${value ? 'green' : ''}`}
        onClick={e => this.setChange(e)}
      >
        <i className="star icon" />
      </div>
    );
  }

  setChange(e) {
    const { input: { value, name }, formKey, changeMajor, dispatch } = this.props;
    const reduxFormChange = this.props.input.onChange;
    const propsChange = this.props.onChange;
    reduxFormChange(!value);
    // propsChange(!value);
  }
}

const isPublishedSelector = (accessor) => {
  const publishedVersion = accessor('PublishedVersion');
  const body = accessor('body');
  const is_major = accessor('is_major');
  const context_id = accessor('context_id');
  const action_date = accessor('action_date');
  const res =
    publishedVersion &&
    (publishedVersion.body || null) == (body || null) &&
    (publishedVersion.is_major || null) == (is_major || null) &&
    (publishedVersion.post_id || null) == (context_id || null) &&
    new Date(publishedVersion.action_date).getTime() == new Date(action_date).getTime();

  return res;
};

@connect(
  (state, props) => {
    // const selector = formValueget(props.form_id);
    // const selector = formValueget(syncInfo.formFunc('event', props.id));
    const collectionName = syncInfo.formFunc('events', props.post_id);
    const itemId = props.id;

    const get = collectionItemValueAccessor(collectionName, itemId)(state);

    const _id = get('id');

    const { id, type } = props;

    const formValues = {
      type, // online, chronicle
      id,

      body: get('body'),
      action_date: new Date(get('action_date')),
      createdAt: get('createdAt'),
      updatedAt: get('updatedAt'),
      is_major: get('is_major') || false,
      context_id: get('context_id')
    };

    const res = {
      syncedEntity: 'event',
      syncedId: id,

      user_id: state.user.user.id,
      my_username: state.user.user.name,
      event_id: get('id'),
      username: get('username'),
      post_id: get('post_id'),
      published_version: get('published_version'),
      publishedVersion: get('PublishedVersion'),
      current_snapshot_id: get('current_snapshot_id'),

      canPublish: allTransactionsApplied(state),
      isEditing: (state.eventsUi[props.id] || {}).isEditing || !isPublishedSelector(get),
      isPublished: isPublishedSelector(get),
      isFocused: focusedSelector(state)('event', `${props.post_id}#${props.id}`, 'all'),
      focusedUsers: focusedUsersSelector(state)('event', `${props.post_id}#${props.id}`, 'all'),

      form: syncInfo.formFunc('event', `${props.post_id}#${props.id}`),
      ...formValues,
      formValues,
      initialValues: formValues
    };

    return res;
  },
  (dispatch, ownProps) => ({
    getRelated: ({ entity, post_id }) => {},
    // deleteEvent: (event_id)=> {
    // 	// delete evemnt by event id
    // 	dispatch(deleteEvent(event_id));
    // },
    saveEvent: (eventData) => {
      // auto visual update autor field
      if (confirmExtr(eventData)) {
        dispatch(change(syncInfo.formFunc('event', ownProps.id), 'username', eventData.username));
        if (eventData.isPublished) {
          dispatch(publishEvent(eventData))
        } else {
          dispatch(saveEvent(eventData))
        }
      } else {
        window.alert('Изменения не будут опубликованы, так как вы не приняли предупреждение об экстремистской организации');
      }
    },
    changeCurrentVersion: (eventData) => {
      dispatch(changeCurrentVersionEvent(eventData));
    },
    changeMajor: (snapshotData) => {
      dispatch(changeMajorEvent(snapshotData));
    },
    setEventPublishedStatus: (status = false) => {
      dispatch(setEventPublishedStatus(ownProps.id, status));
    },
    setEventEditMode: (status) => {
      dispatch(setEventEditMode(ownProps.id, status));
    },

    focused: ({ user }) => {
      dispatch(
        actions.fieldFocused({
          entity: 'event',
          id: `${ownProps.post_id}#${ownProps.id}`,
          field: 'all',
          user
        })
      );
    },

    blured: ({ user }) => {
      dispatch(
        actions.fieldBlured({
          entity: 'event',
          id: `${ownProps.post_id}#${ownProps.id}`,
          field: 'all',
          user
        })
      );
    }
  })
)
@reduxForm({
  pristine: true
})
class Event extends React.Component {
  constructor(props) {
    super(props);
    this.switchMode = this.switchMode.bind(this);
    this.submitEvent = this.submitEvent.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clickBeforeSubmit = this.clickBeforeSubmit.bind(this);
    this.changeCurrentVersion = this.changeCurrentVersion.bind(this);
    this.changeMajor = this.changeMajor.bind(this);
    this.renderContextField = this.renderContextField.bind(this);
    this.renderDateField = this.renderDateField.bind(this);
    this.renderBodyField = this.renderBodyField.bind(this);
    this.DOMComponent = null;
    this.state = {
      isEditing: (this.props.isEditing && this.props.isPublished === '' && !this.props.body && this.props.currentUser === this.props.user_id) || false
    };
  }

  // // просто проверить поможет ли
  // componentWillReceiveProps(nextProps){
  // 	if(this.props.index != nextProps.index){
  // 		this.reinitializeCKE = true;
  // 	}
  // }

  deleteEvent(event_id) {
    const willDelete = confirm('Точно удалить событие?');
    if (willDelete) {
      this.props.remove(event_id);
    }
  }

  switchMode() {
    const futureState = !this.props.isEditing;

    if (!this.state.isEditing && this.props.isEditing) {
      this.setState(state => ({
        isEditing: true
      }));
    } else {
      this.props.setEventEditMode(futureState);

      if (futureState == true) {
        this.setState(
          state => ({
            isEditing: true
          }),
          () =>
            this.props.focused({
              user: this.props.my_username
            })
        );
      } else {
        this.setState(
          state => ({
            isEditing: false
          }),
          () =>
            this.props.blured({
              user: this.props.my_username
            })
        );
      }
    }
  }

  renderBodyField() {
    return (
      <Body
        {...this.props}
        ref={(ref) => {
          this.body = ref;
        }}
        key={`event-body-${this.props.id}`}
      />
    );

    // return (
    // 	<div className="ckeditor">
    // 		<Field 	name="body"
    // 				key={"event-richtext-"+this.props.id}
    // 				formKey={"event-richtext-"+this.props.id}
    // 			   	component={RichText}
    // 				notifyOnChange={() => this.props.focused({ user: this.props.my_username })}
    // 				height="200"/>
    // 	</div>
    // );
  }

  renderDateField() {
    const { focusToEvent } = this.props;
    return this.props.published_version ? (
      <Field>
        <Label>Время события {`(${timzoneShort})`}</Label>
        <ReduxFormField
          name="action_date"
          component={DateTimePicker}
          eventId={this.props.event_id}
          onChange={(e) => {
            focusToEvent(this, this.props.id);
          }}
        />
      </Field>
    ) : null;
  }

  renderContextField() {
    return (
      <div>
        <RelatedField
          entity="event_context"
          id={this.props.current_snapshot_id}
          key={`event_context${this.props.current_snapshot_id}`}
        />
      </div>
    );
  }

  renderSaveButton() {
    return this.props.canPublish ? (
      <div>
        <button
          className="submitEdit ui submit button"
          type="button"
          onClick={this.onSubmit}
        >
          Опубликовать
        </button>
      </div>
    ) : (
      <div>Подождите...</div>
    );
  }

  onSubmit(e) {
    this.clickBeforeSubmit('publish');
    this.props.handleSubmit(this.submitEvent)(e);
  }

  clickBeforeSubmit (actionType) {
    if (actionType == 'add') {
      this.props.setEventPublishedStatus(false);
    } else {
      // publish
      this.props.setEventPublishedStatus(true);
      this.setState(() => ({
        isEditing: false
      }));
    }
  }

  submitEvent(values) {
    this.props.setEventEditMode(false);
    
    this.props.saveEvent({
      event_id: values.id,
      body: values.body,
      action_date: values.action_date,
      is_major: values.is_major,
      isPublished: true,
      // user_id: this.props.user_id,
      post_id: this.props.post_id,
      context_id: this.props.context_id,
      username: this.props.my_username, // меняем на себя
      transactionId: syncInfo.generateTransactionId(window.windowId),
      clientId: window.windowId
    });
  }
  changeCurrentVersion(versionData) {
    console.log(versionData);
    this.props.changeCurrentVersion({
      event_id: this.props.id,
      snapshot_id: versionData.value,
      post_id: this.props.post_id
    });
  }
  changeMajor(is_major) {
    this.props.changeMajor({
      event_id: this.props.id,
      snapshot_id: this.props.current_snapshot_id,
      post_id: this.props.post_id,
      published_version: this.props.published_version,
      is_major: !is_major
    });
  }
  render() {
    const {
      event_id,
      body,
      is_major,
      username,
      type,
      id,
      action_date,
      createdAt,
      updatedAt,
      submitting,
      current_snapshot_id,
      context_id,
      handleSubmit,
      formValues,
      isFocused,
      focusedUsers,
      isPublished,
      isEditing,
      isSlateRichText,
    } = this.props;

    if (!event_id) {
      return <div className="loading-event">Loading</div>;
    }

    console.log(`EventForm#${event_id} render`);
    console.log('formValues', formValues);

    let isMajorTmpl = null;
    let context = null;
    if (type == 'chronicle') {
      isMajorTmpl = <ReduxFormField name="is_major" component={MajorInputComponent} />;

      // context field for chronicle - комментируем как то, от чего редакция хочет отказаться, но возможно захочет вернуть)) 
      // context = this.renderContextField();
    }

    const labelColor = isPublished !== '' ? (isEditing ? 'yellow' : 'green') : 'red';

    return (
      <div
        key={`event-container-${id}`}
        ref={(ref) => {
          this.DOMComponent = ref;
        }}
        className={`eventListItem ui segment${isPublished ? ' eventListItem_published' : ''}${
          isFocused ? ' eventListItem_focused' : ''
        }`}
      >
        <form>
          <Field>
            <div
              className="deleteEvent ui icon right floated red small button"
              onClick={() => {
                this.deleteEvent(this.props.id);
              }}
            >
              <i className="remove icon" />
            </div>
            <div
              className="editEvent ui icon right floated orange small button"
              onClick={this.switchMode}
            >
              <i className="edit icon" />
            </div>
            {isMajorTmpl}
            <div>
              <div className="ui blue ribbon label">
                {format(new Date(timezoneDate(action_date)), 'yyyy-MM-dd HH:mm', { locale: ruLocale })}{` (${timzoneShort})`}
              </div>
              <div className={`ui label event-status ${labelColor}`}>
                {isPublished !== '' ? (
                  isEditing ? (
                    'Есть изменения'
                  ) : (
                    <i className="icon checkmark" />
                  )
                ) : (
                  'Неопубликован'
                )}
              </div>
            </div>
            {username} &nbsp;&nbsp;
            {focusedUsers && focusedUsers.length > 0 ? (
              <div className="event_users">
                Сейчас здесь:
                {this.props.focusedUsers.join(', ')}
              </div>
            ) : null}
            <div className="event__date">
              Создано {format(new Date(createdAt), 'yyyy-MM-dd HH:mm', { locale: ruLocale })}
              &nbsp;&nbsp; Обновлено{' '}
              {format(new Date(updatedAt), 'yyyy-MM-dd HH:mm', {
                locale: ruLocale
              })}{' '}
              &nbsp;&nbsp;
            </div>
            {context}
            <div className="ui basic clearfix">
              {this.state.isEditing ? (
                this.renderBodyField()
              ) : (
                <div dangerouslySetInnerHTML={{ __html: body }} />
              )}
            </div>
          </Field>
          {this.state.isEditing && this.props.published_version ? this.renderDateField() : null}
          {this.props.isEditing ? this.renderSaveButton() : null}
        </form>
      </div>
    );
  }
}

export default Event;
