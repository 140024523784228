import syncInfo from '../../common/sync-channel';

function form(state = {}, action){
	switch(action.type){
		case "CHANGE_CURRENT_VERSION_EVENT_ON_CLIENT":
			let formEvent = state["event-formevent-"+action.event.id];
			let curEvent = action.event;
			formEvent = Object.assign({}, formEvent, {
				'values': {
					...curEvent,
					...curEvent.CurrentVersion,
					action_date: new Date(curEvent.CurrentVersion.action_date),
					createdAt: new Date(curEvent.CurrentVersion.createdAt),
					updatedAt: new Date(curEvent.CurrentVersion.updatedAt)
				}
			});
			return {
				...state,
				...{
					["event-formevent-"+action.event.id]: formEvent
				}
			}
		case "ADD_ELEMENT_TO_SYNC_COLLECTION":
			const { entity, id } = syncInfo.formDestructor(action.name);

			if (entity == 'events') {
				const formName = syncInfo.formFunc('event', `${id}#${action.item.id}`);

				return {
					...state,
					[formName]: {
						...state[formName],
						values: {
							...(state[formName] || {}).values,
							...action.item
						}
					}
				};
			}
			else {
				
				return state;
			}

		default:
			return state;
	}

}

export default form;
