export default function reports(state = {}, action){
	switch(action.type){
		case "FETCH_REPORTS_SUCCESS":
			return {
				...state,
				...action.reports.reduce((curState, item)=> {
					return {
						...curState,
						[item.id]: item
					}
				}, {})
			}
		case "ARCHIVE_REPORT_SUCCESS":
			var copyStateList = Object.assign( {}, state);
			delete copyStateList[action.id];
			return {
				...copyStateList
			}
		default:
			return state;	

	}
}