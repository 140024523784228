// @flow
import React, { PureComponent } from 'react';
import type {
  Node
} from 'react';
import {
  Draggable,
  Droppable,
  DragDropContext,
} from 'react-beautiful-dnd';
import type {
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';
import styled from 'react-emotion';

type Item = {|
  component: Node,
  id: number
|}

type Props = {|
  listId: string,
  items: Item[],
  listType?: string,
  isDropDisabled?: boolean,
  style?: Object,
  onDragStart: Function,
  onDragEnd: Function,
|};

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')};
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
`;

const InnerList = React.memo(({ items }) =>
  items.map(({ id, component }, index) => (
    <Draggable
      key={id}
      draggableId={id}
      index={index}
    >
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {component}
        </div>
      )}
    </Draggable>
  ))
);

class DnDList extends PureComponent<Props> {
  static defaultProps = {
    listType: 'DEFAULT',
    isDropDisabled: false,
    style: {}
  };

  render() {
    const {
      listId,
      items,
      listType,
      isDropDisabled,
      style,
      onDragStart,
      onDragEnd,
    } = this.props;

    return (
      <DragDropContext
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <Droppable
          droppableId={listId}
          type={listType}
          isDropDisabled={isDropDisabled}
        >
          {(
            provided: DroppableProvided,
            snapshot: DroppableStateSnapshot,
          ) => (
            <Wrapper
              style={style}
              innerRef={provided.innerRef}
              isDraggingOver={snapshot.isDraggingOver}
              isDropDisabled={isDropDisabled}
              isDraggingFrom={Boolean(snapshot.draggingFromThisWith)}
              {...provided.droppableProps}
            >
              <InnerList
                items={items}
              />
              {provided.placeholder}
            </Wrapper>
          )}
        </Droppable>
      </DragDropContext>
    );
  }
}

export default DnDList;
