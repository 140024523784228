import React, {Component} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PostForm from '../components/Post/PostForm.js'
import Events from '../components/Events/EventsList.js';
import { getPost, replaceSnapshot, archivePost, restorePost, updateRelatedSearch, changeTitle, changeSlug, changeAnons, deleteRelatedType, addRelatedType } from '../actions/actions.js';

function mapStateToProps(state, ownProps) {
    const idPost = ownProps.params.id;
    return {
        idPost,
        // post: state.post,
        ui: state.ui
    };
}

function mapDispatchToProps(dispatch){
    return {
        getPost: function (idPost) {
            return dispatch(getPost(idPost));
        }
    };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class Post extends Component{
	constructor(props) {
		super(props);
	}
	componentDidMount(){
	    // this.props.getPost(this.props.idPost);
	}
	componentWillReceiveProps(nextProps) {
		// if (nextProps.idPost !== this.props.idPost){
	    	// this.props.getPost(nextProps.idPost);
		// }
	}

	render() {
		return (
			<div>
				<PostForm id={this.props.idPost}/>
			</div>
		)
	};
	//return (<div>
	//	<ActionMenu post = {data.post} actionCreators = {data.actionCreators} dispatch={data.dispatch} />
	//	<PostForm />
	//</div>);
}
