import migrationsList from './index';
import migrationMap from './migrationMap.json';

const migrationMapKeys = Object.keys(migrationMap);
const initialMigrationId = migrationMapKeys[0];
const migrations = migrationsList.reduce((p, c) => ({ ...p, ...c }), {});

const isNotLastMigration = migrationId => !!migrationMap[migrationId];

const setMigrationId = (node, id) => {
  const updatedNode = node;
  // get custom data from migrated element
  const customFieldMigratedEl = node.dataset.slateCustom
    ? JSON.parse(node.dataset.slateCustom)
    : {};

  // save the last migrationId to the custom field
  updatedNode.dataset.slateCustom = JSON.stringify({
    ...customFieldMigratedEl,
    migrationId: id
  });

  return updatedNode;
};

const migrateElement = (element) => {
  try {
    if (!initialMigrationId || !element || !element.dataset || !element.dataset.type) {
      return element;
    }

    // get custom data from element
    const customField = element.dataset.slateCustom
      ? JSON.parse(element.dataset.slateCustom)
      : {};

    // find last applied migration
    let currentMigrationId = customField.migrationId || initialMigrationId;

    let migratedElement = element;

    while (isNotLastMigration(currentMigrationId)) {
      // go to the next migration
      currentMigrationId = migrationMap[currentMigrationId];

      if (!currentMigrationId) {
        throw Error('not consistency migration map, undefined value');
      }

      const migration = migrations[currentMigrationId];

      if (!migration) continue;

      // apply current migration
      // if type of element equal one of migration types
      if (migration.types.includes(element.dataset.type)) {
        migratedElement = migration.up(migratedElement);
      }
    }

    // save the last migrationId to the custom field in migrationElement
    migratedElement = setMigrationId(migratedElement, currentMigrationId);

    // save the last migrationId to the custom field in migrationElement children
    const childrenMigratedElement = migratedElement.childNodes;
    if (childrenMigratedElement.length) {
      for (const node of childrenMigratedElement) {
        if (!node || !node.dataset || !node.dataset.type) continue;
        migratedElement.replaceChild(setMigrationId(node, currentMigrationId), node);
      }
    }

    return migratedElement;
  } catch (e) {
    console.error(e);
  }
};

export default (html) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const all = doc.querySelectorAll('*');

  for (const item of all) {
    item.replaceWith(migrateElement(item));
  }

  return doc.body.innerHTML;
};