import * as actions from '../actions/transformPost';

const transformPost = (state = {}, action) => {
  switch (action.type) {
    case actions.GET_POST_TRANSFORM:
      return { ...state, isLoading: true };
    case actions.GET_POST_TRANSFORM_SUCCESS:
      return { ...state, value: action.payload, isLoading: false };
    case actions.GET_POST_TRANSFORM_FAILURE:
      return { ...state, error: action.error, isLoading: false };
    default: return state;
  }
};

export default transformPost;
