import Html from 'slate-html-serializer';
import Editor from '../Editor';
import markRules from './mark';
import nodeRules from './node';

const rules = [
  markRules,
  nodeRules
];

const htmlRules = new Html({
  rules,
  defaultBlock: Editor.node.Paragraph()
});

export default htmlRules;
