// @flow
import React from 'react';
import styled from 'react-emotion';

type Props = HTMLInputElement & {|
  title: string,
|};


export const CustomRadioContainer = styled('label')`
  display: block;
  position: relative;
  padding-left: 21px;
  margin-right: 20px;
  cursor: pointer;
  user-select: none;
  font-family: 'Graphik LC', sans-serif;
  font-size: 13px;
  line-height: 15px;
  
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  :hover input ~ span {
    background-color: #ccc;
  }
  
  input:checked ~ span:after {
    display: block;
  }
`;

export const CustomRadioCheckMark = styled('span')`
  position: absolute;
  top: 0;
  left: 0;
  border: solid 1px rgba(41, 48, 67, 0.41);
  width: 15px;
  height: 15px;
  :after {
    content: "";
    width: 7px;
    height: 7px;
    background-color: #fd6073;
    position: absolute;
    left: 3px;
    top: 3px;
    display: none;
  }
`;

export default (props: Props) => {
  const { title } = props;

  return (
    <CustomRadioContainer>
      { title }
      <input type="checkbox" {...props} />
      <CustomRadioCheckMark />
    </CustomRadioContainer>
  );
};