import React, { Component } from 'react';
import {
  ToolTip,
} from '/components/SlateRichText/molecules';
import {
  TopBarButton,
} from '../../atoms';
import {
  Icons,
  ModuleContainer,
} from '../../../../atoms';
import { EmbedForm } from '../../../../features/Embed';
import Editor from '../../../../Editor';

const {
  CreateEmbed,
} = Icons;

class EmbedToolModule extends Component {
  state = {
    isOpenModal: false,
  };

  onSubmit = (props) => {
    const { editor } = this.props;
    const { value } = editor;
    const { focusBlock } = value;

    if (!focusBlock) return;

    const {
      type,
      embedValue,
      url,
    } = props;

    let Embed;

    switch (type) {
      case 'youtube':
        Embed = Editor.node.Youtube({ url });
        break;
      case 'embed':
        Embed = Editor.node.Embed({ html: embedValue, url });
        break;
      case 'code':
        Embed = Editor.node.Embed({ html: embedValue });
        break;
      default:
        return;
    }

    const EmbedContainer = Editor.node.EmbedContainer({
      nodes: [
        Embed,
        Editor.node.EmbedCaption(),
      ],
    });

    if (focusBlock.text.length === 0) {
      editor.replaceNodeByKey(focusBlock.key, EmbedContainer);
    } else {
      editor.insertBlock(EmbedContainer);
    }
  };

  closeModal = () => {
    this.setState(() => ({
      isOpenModal: false,
    }));
  };

  handleModal = () => {
    this.setState(prev => ({
      isOpenModal: !prev.isOpenModal,
    }));
  };

  render() {
    const { editor, enabled } = this.props;
    const { isOpenModal } = this.state;
    const type = Editor.NODE.EMBED;
    return (
      <ModuleContainer>
        {isOpenModal ? (
          <EmbedForm
            handleModal={this.handleModal}
            closeModal={this.closeModal}
            isOpen={isOpenModal}
            onSubmit={this.onSubmit}
            submitText={'Вставка'}
            title={'Вставить медиа:'}
          />) : null
        }
        <TopBarButton
          id={Editor.EDITOR_IDS.EMBED + editor.props.editorId}
          onClick={enabled ? this.handleModal : null}
          data-for={type}
          data-tip
          enabled={enabled}
        >
          <CreateEmbed />
          <ToolTip
            id={type}
            tooltip={Editor.TOOLTIPS.EMBED}
          />
        </TopBarButton>
      </ModuleContainer>
    );
  }
}

export default EmbedToolModule;
