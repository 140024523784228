module.exports = {
  types: {
    chronicle: 'Сюжет',
    article: 'Статья',
    online: 'Онлайн',
    test: 'Тест',
    card: 'Расклад',
    number: 'Цифра',
    news: 'Новость',
    page: 'Материал',
    theme: 'Тема',
    podcast: 'Подкаст',
    translate: 'Перевод статьи',
  },
  typesEn: {
    chronicle: 'Chronicle',
    article: 'Article',
    online: 'Online',
    test: 'Test',
    card: 'Cards',
    number: 'Number',
    news: 'News',
    page: 'Page',
    theme: 'Theme',
    podcast: 'Podcast',
    translate: 'Story',
  },
  menuright: [
    {
      title: 'Статья',
      key: 'article',
      regions: ['ru', 'ca', 'by'],
    },
    {
      title: 'Онлайн',
      key: 'online',
      regions: ['ru', 'ca', 'by'],
    },
    {
      title: 'Сюжет',
      key: 'chronicle',
      regions: ['ru', 'ca', 'by'],
    },
    {
      title: 'Цифра',
      key: 'number',
      regions: ['ru', 'ca', 'by'],
    },
    {
      title: 'Тема',
      key: 'theme',
      regions: ['ru', 'ca', 'by'],
    },
    {
      title: 'Тест',
      key: 'test',
      regions: ['ru', 'by', 'ca'],
    },
    {
      title: 'Расклад',
      key: 'card',
      regions: ['ru', 'by'],
    },
    {
      title: 'Подкаст',
      key: 'podcast',
      regions: ['ru', 'by'],
    },
  ],
  os: {
    MAC: 'mac',
    WIN: 'windows',
    LINUX: 'linux',
  },
};
