import React from 'react';
import { connect } from 'react-redux';
import EventForm from './EventForm.js';
import { syncCollection } from '../../lib/sync-form';
import { eventsEditingState } from '../../store/selectors';
import InfoWrapper from '../../ui/molecules/InfoWrapper';

import get from 'lodash/get';

const getMajorCount = (values) => {
  const count = Object.values(values).reduce(
    (accumulator, {is_major, published_version, is_deleted}) => 
    (is_major && published_version && !is_deleted) ? accumulator + 1 : accumulator, 0)
  return count;
};

@connect(
  (state, props) => ({
    syncedEntity: 'events',
    syncedId: props.post_id,
    type: props.type
  }),
  (dispatch, ownProps) => ({
    getRelated: ({ entity, post_id }) => {},
  })
)
@syncCollection()
export default class EventList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showCount: 20, sugars: {} };
    this.loader = null;
    this.checker = null;
    this.focusToEvent = this.focusToEvent.bind(this);
  }

  shouldComponentUpdate(props, state) {
    console.log('events should update', this.props.formValues != props.formValues);
    return this.props.formValues != props.formValues || state.showCount != this.state.showCount ||
    this.props.is_reverse !== props.is_reverse;
  }

  componentDidMount() {
    if (this.loader) {
      this.checker = () => {
        const rect = this.loader.getBoundingClientRect();
        if (rect.bottom < 1000) {
          const count = this.props.formValues ? Object.keys(this.props.formValues).length : 0;
          if (count > this.state.showCount) {
            this.setState({ showCount: this.state.showCount + 10 });
          }
        }
      };
      window.addEventListener('scroll', this.checker);
    }

    this.add = () => { this.props.add() };
    this.props.addEventRef(this);
  }

  componentWillUnmount() {
    if (this.checker) {
      window.removeEventListener('scroll', this.checker);
    }
  }

  focusToEvent(ref, id) {
    this.setState(
      {
        showCount: Object.keys(this.props.formValues).length,
        sugars: {
          [id]: `${Math.random() * 1000}`
        }
      },
      () => {
        if (ref && ref.DOMComponent) {
          ref.DOMComponent.scrollIntoView();
        }
      }
    );
  }

  render() {
    console.log('render events lsit');
    const { events, formValues, type, eventsEditingState, is_reverse } = this.props;
    const isChronicle = type === 'chronicle';

    if (!formValues) {
      return (
        <div className="">
          <div
            className="event-loader"
            key="event-loader"
            ref={(ref) => {
              this.loader = ref;
            }}
          />
        </div>
      );
    }
    const list = [];
    for (const p in formValues) {
      list.push(formValues[p]);
    }
    const ascSort = (a, b) => new Date(get(b, 'action_date')) - new Date(get(a, 'action_date'));
    const descSort = (a, b) => new Date(get(a, 'action_date')) - new Date(get(b, 'action_date'));
    const unpublishFirstSort = (a, b) => (!!b.published_version === !!a.published_version) ? 0 : !!b.published_version ? -1 : 1;

    console.log('EVENTS PROPS', this.props);
    list
      .sort(is_reverse ? descSort : ascSort)
      .sort(unpublishFirstSort);

    const majorCounter = (isChronicle && formValues) ? getMajorCount(formValues) : 0;
    
    return (
      <div className="">
        {
          isChronicle
            ? (
              <InfoWrapper valid={majorCounter < 10}>
                Мы не рекомендуем размещать более 10 постов в оглавлении. Сейчас их {majorCounter}
              </InfoWrapper>
            )
            : null
        }

        <div className="eventList">
          {list
            .slice(0, this.state.showCount)
            .map((event, index) => (
            <EventForm
              id={event.id}
              post_id={this.props.syncedId}
              set={(value) => {
                this.props.set(event.id, value);
              }}
              key={event.id}
              type={type}
              remove={this.props.remove}
              focusToEvent={this.focusToEvent}
              sugar={this.state.sugars[event.id] || ''}
              currentUser={event.user_id}
              isSlateRichText={this.props.isSlateRichText}
            />
          ))}
        </div>
        <div
          className="event-loader"
          key="event-loader"
          ref={(ref) => {
            this.loader = ref;
          }}
        />
      </div>
    );
  }
}
