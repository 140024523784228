import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { fetchPosts, refreshFeed } from '/actions/actions';
import { bindActionCreators } from 'redux'

import postsSelector from '/utils/postsSelector';
import getFormattedDate from '/utils/feedFormat';
import feedSelectorsFactory from '/utils/feedSelectorsFactory';

import PostFeedItem from './FeedItem';
import { startOfDay } from 'date-fns';

import Feed from '/ui/organism/Feed';
import { TranslateCollection, TranslatesFeedItem } from '/features/Translate';

const fetchTimer = 30 * 1000;

const groupByFunc = ({ publish_date, published_version }) =>
  publish_date && published_version
    ? +new Date(startOfDay(publish_date))
    : 0;

@connect(
  (state, ownProps) => {
    const { groupedPostsSelector, groupedPostsDatesSelector } = feedSelectorsFactory({
      listSelector: postsSelector,
      groupByFunc,
    });

    return {
      groupedItems: groupedPostsSelector(state),
      groupedDates: groupedPostsDatesSelector(state),
      query: state.searchQuery,
    };
  },
  (dispatch) => bindActionCreators({ fetchPosts, refreshFeed}, dispatch),
)
export default class PostsFeed extends React.Component {
  static propTypes = {
    params: PropTypes.shape({
      type: PropTypes.string,
      year: PropTypes.string,
      month: PropTypes.string,
      day: PropTypes.string
    }),
    query: PropTypes.string,
    groupedItems: PropTypes.object,
    groupedDates: PropTypes.array
  }

  _fetchPosts({
    type,
    year,
    month,
    day,
    query
  }) {
    this.props.fetchPosts({
      type,
      year,
      month,
      day,
      query
    });
  }

  componentDidMount() {
    this.timer = setInterval(
      () => {
        if (this.props.query.length === 0) {
          this.props.refreshFeed(this.props)
        }
      },
      fetchTimer);
    this._fetchPosts(this.props);
  }

  componentWillUnmount() {
    if (this.timer)
      clearInterval(this.timer);
  }

  componentWillReceiveProps(nextProps) {
    const isRouteNew = (
      nextProps.type !== this.props.type ||
      nextProps.year !== this.props.year ||
      nextProps.month !== this.props.month ||
      nextProps.day !== this.props.day ||
      nextProps.query !== this.props.query
    );

    if (isRouteNew) {
      this._fetchPosts(nextProps);
    }
  }

  render() {
    const {
      groupedItems,
      groupedDates
    } = this.props;
    return (
      <Feed>
        {
          groupedDates.map((date) => {
            const groupedPosts = groupedItems[date];
            const {
              title,
              subTitle
            } = getFormattedDate(date);
            return (
              <Feed.Group 
                key={ date }
                title={  title  }
                subTitle={ subTitle }>
                {
                  groupedPosts.map((post, postIdx) => {
                    return (
                      <Feed.Item key={post.id} main>
                          <PostFeedItem key={post.id} {...post} />
                          { post.translates?.length 
                            ? (<TranslateCollection postId={post.id} component={TranslatesFeedItem}/>)
                            : null
                          }
                      </Feed.Item>
                    )
                  })
                }
            </Feed.Group>
            );
          })
        }
      </Feed>
    );
  }
};
