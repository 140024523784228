import styled from 'react-emotion';

const Link = styled('a')`
  font-family: 'GraphikLC', sans-serif !important;
  font-size: 12px;
  color: ${({ color }) => color || '#006FFF'};
  
  &:hover {
    color: ${({ hoverColor }) => hoverColor || 'linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #006FFF'};
  }
  
  b, strong {
    font-weight: 500;
  }
`;

export default Link;
