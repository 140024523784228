import config from "../const/config";

export const getTimezone = () => {
  console.log('REGION');
  console.log(config.REGION);

  switch (config.REGION) {
    case 'ru':
      return 'Europe/Moscow';

    case 'by':
      return 'Europe/Minsk';

    case 'ca':
      return 'Asia/Almaty';

    default:
      return 'Europe/Moscow';
  }
};

export default getTimezone;
