//
// Created on 19-03-2019.
//

export default {
  1552996946910: {
    types: [
      'mz-iframe-embed',
      'youtube',
      'iframely',
      'code-embed'
    ],
    up: (el) => {
      const newEl = el;

      // create embed container and set data-type
      const embedContainer = document.createElement('figure');
      embedContainer.dataset.type = 'embed-container';

      // create embed caption
      const embedCaption = document.createElement('figcaption');
      const embedCaptionParagraph = document.createElement('p');
      embedCaption.appendChild(embedCaptionParagraph);
      embedCaption.dataset.type = 'embed-caption';

      // copy and set new type
      const embedNode = newEl.cloneNode();
      // mz-iframe-embed, iframely, code-embed => embed
      if (el.dataset.type !== 'youtube') {
        embedNode.dataset.type = 'embed';
      }

      // append elements to container
      embedContainer.appendChild(embedNode);
      embedContainer.appendChild(embedCaption);

      return embedContainer;
    }
  }
};