import React, { Component } from 'react';
import { Editor as SlateEditor, getEventTransfer } from 'slate-react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import * as R from 'ramda';
import htmlRules from './rules';
import Schema from './Schema';
import { updateTransactionId } from '../../actions/actions';
import Editor from './Editor';
import hotKeys from './plugins/hotKeys';
import topToolBar from './plugins/topToolBar';
import { RichTextContainer } from './atoms';
import { richEditorStyle } from './styles';
import migrateHTML from './migrations/migrator';
import {
  createEditorId,
  createEditorPortalRootId,
  deleteLineBreak,
  replaceEmptyTextNode,
} from './helpers';

class SlateRichText extends Component {
  constructor(props) {
    super(props);
    this.releaseBounce = true;
    this.updateFlag = false;
    this.isFirstValue = true;
    this.schema = Schema.create();
    this.plugins = [
      topToolBar({ toolbarOptions: props.toolbarOptions }),
      hotKeys(),
    ];
    this.deserializeHTML = R.pipe(
      migrateHTML,
      deleteLineBreak,
      htmlRules.deserialize,
    );

    this.state = {
      value: this.deserializeHTML(props.input.value),
    };
  }

  static defaultProps = {
    autoFocus: false,
  };

  debounced = debounce((value) => {
    this.releaseBounce = true;
    const content = htmlRules.serialize(value);
    this.props.input.onChange(content);
  }, 1500, { maxWait: 30000 });


  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.value !== this.state.value) {
      return true;
    }
    return false;
  }

  componentWillReceiveProps(nextProps) {
    const content = htmlRules.serialize(this.state.value);
    if (nextProps.input.value !== content) {
      const deserializeValue = this.deserializeHTML(nextProps.input.value);
      this.updateFlag = true;
      this.setState(() => ({ value: deserializeValue }));
    }
  }

  renderMark = (props) => {
    const { attributes, children, mark } = props;
    return Editor.mark.render({
      type: mark.type,
      attributes,
      children,
    });
  };

  renderAnnotation = (props) => {
    const { attributes, children, annotation } = props;
    return Editor.annotation.render({
      type: annotation.type,
      attributes,
      children,
    });
  };

  renderNode = (props, next) => {
    const { attributes, children, node, editor } = props;

    return Editor.renderNodeByType({
      node,
      attributes,
      children,
      editor,
      next,
    });
  };

  onChange = ({ value }) => {
    const dispatch = this.props.dispatch;

    if (this.isFirstValue) {
      this.setState(() => ({
        value,
      }));
      this.isFirstValue = false;
      return;
    }

    if (value.document !== this.state.value.document) {
      if (this.releaseBounce) {
        dispatch(updateTransactionId('awaiting', false));
        this.releaseBounce = false;
      }
      if (this.updateFlag) {
        this.updateFlag = false;
        return;
      }
      this.debounced(value);
    }
    this.setState(() => ({ value }));
  };

  onPaste = (event, change) => {
    event.preventDefault();
    const transfer = getEventTransfer(event);

    switch (transfer.type) {
      case 'html': {
        const { document } = this.deserializeHTML(transfer.html);
        change.insertFragment(document);
        return true;
      }
      case 'text': {
        change.insertText(transfer.text);
        return true;
      }
      case 'fragment': {
        change.insertFragment(transfer.fragment);
        return true;
      }
      default:
        return false;
    }
  };

  render() {
    const {
      input,
      autoFocus,
      entityId,
      contextTitle,
    } = this.props;

    const editorId = createEditorId(input.name);
    const rootPortalId = createEditorPortalRootId(editorId);

    return (
      <RichTextContainer
        id={editorId}
      >
        <div id={rootPortalId} />
        {this.props.disabled
          ? (<SlateEditor
              readOnly={true}
              className={richEditorStyle}
              value={this.state.value}
              editorId={editorId}
              rootPortalId={rootPortalId}
              entityId={entityId}
              renderMark={this.renderMark}
              renderBlock={this.renderNode}
              renderInline={this.renderNode}
              renderAnnotation={this.renderAnnotation}
              schema={this.schema}
              contextTitle={contextTitle}
            />)
          : (<SlateEditor
              readOnly={false}
              autoFocus={autoFocus}
              placeholder="Текст..."
              className={richEditorStyle}
              value={this.state.value}
              editorId={editorId}
              rootPortalId={rootPortalId}
              entityId={entityId}
              onChange={this.onChange}
              onPaste={this.onPaste}
              renderMark={this.renderMark}
              renderBlock={this.renderNode}
              renderInline={this.renderNode}
              renderAnnotation={this.renderAnnotation}
              plugins={this.plugins}
              schema={this.schema}
              contextTitle={contextTitle}
              style={{
                WebkitUserModify: 'read-write',
              }}
            />)
          }
      </RichTextContainer>
    );
  }
}

export default connect((state, props) => ({ ...props }))(SlateRichText);
