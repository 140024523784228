import { settings } from '../../const/const.js';
CKEDITOR.disableAutoInline = true;
CKEDITOR.config.fillEmptyBlocks = false;
CKEDITOR.config.entities = false;
CKEDITOR.config.htmlEncodeOutput = false;
CKEDITOR.config.entities_processNumerical = false;
CKEDITOR.config.entities_greek = false;
CKEDITOR.config.entities_latin = false;
CKEDITOR.config.entities_additional = '';
CKEDITOR.config.fillEmptyBlocks = false;
CKEDITOR.config.visualOptions = {
	language: "ru",
	toolbar: [
		{
			name: 'basicstyles',
			items: ['Bold', 'Italic', 'Superscript', '-', 'RemoveFormat', 'Source', 'Typofilter', 'jQuerySpellChecker', 'BRTOP']
		}, {
			name: 'links',
			items: ['Link', 'Unlink', 'Anchor']
		}
	],
	autoGrow_bottomSpace: 20,
	autoGrow_onStartup: true,
	allowedContent: true,
	format_h2: {
		element: 'h2'
	},
	format_h3: {
		element: 'h3'
	},
	format_highlight: {
    name: 'Выделенный', element: 'p', attributes: { 'class': 'mz-publish__text__highlight-lead' }
	},
	format_tags: 'p;h2;h3;highlight',
	embed_provider: "//iframe.ly/api/oembed?api_key=" + settings.public.iframelyApiKey +"&url={url}&callback={callback}",
	removePlugins: "resize,tabletools,contextmenu,scayt,elementspath",
	contentsCss: "/ckeditor/rich.css",
	extraPlugins: "mediazonacite,mediazonadocscite,mediazonaimage,sharedspace,pastecode,wpmore,typofilter,mediazonaembed,youtube,jqueryspellchecker,brtop",
	sharedSpaces: {
		top: 'ckeditor_toolbar'
	},
	youtube_width: '100%',
	youtube_height: '100%',
	youtube_related: false,
	youtube_older:  false,
	youtube_privacy: false,
	youtube_responsive: true
};
CKEDITOR.config.visualOptions.toolbar.push({
	name: 'styles',
	items: ['Format', 'WPMore']
});
CKEDITOR.config.visualOptions.toolbar.unshift({
	name: 'actions',
	items: ['Undo', 'Redo']
});
//options.height = this.data.atts.richOptions.height;
CKEDITOR.config.visualOptions.height = 400;
CKEDITOR.config.visualOptions.autoGrow_minHeight = 400;
//options.autoGrow_minHeight = this.data.atts.richOptions.height;
//delete options.autoGrow_bottomSpace;
CKEDITOR.config.visualOptions.toolbar.push({
	name: 'insert',
	items: ['Mediazonaimage', 'Embed', 'PasteCode', 'Mediazonaembed', 'Youtube']
});
CKEDITOR.config.visualOptions.toolbar.push({
	name: 'cite',
	items: ['Mediazonacite', 'Mediazonadocscite']
});

CKEDITOR.on('dialogDefinition', function(ev) {
	var err, targetField;
	try {
		if (ev.data.name === 'link') {
			targetField = ev.data.definition.getContents('target').get('linkTargetType');
			return targetField['default'] = '_blank';
		}
	} catch (_error) {
		err = _error;
	}
});

// (function () {
// 	var _val;
// 	_val = $.fn.val;
// 	$.fn.val = function(value) {
// 		var el, richEditor;
// 		el = $(this);
// 		richEditor = el.data("richEditor");
// 		if (richEditor) {
// 			if (value === void 0) {
// 				return richEditor.getData().replace(/\&nbsp\;/g, ' ');
// 			} else {
// 				return el.setContent(arguments[0]);
// 			}
// 		} else {
// 			return _val.apply(this, arguments);
// 		}
// 	};
// })();
