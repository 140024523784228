/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, Field, change } from 'redux-form';
import syncForm from '/lib/sync-form';
import syncInfo from '/../common/sync-channel';
import { SingleFileUploader } from '/features/FileUploader';
import Label from '/ui/atoms/Label';

const FormField = ({ label, name, component = 'input', type = 'text', form, image = '', handler, validate = [] }) => (
  <div className="field">
    <Label>
      {label}
    </Label>
    {type === 'file' ?
      <SingleFileUploader
        name={name}
        form={form}
        file={image}
        handler={handler(name)}
        accepted="image/*"
      /> :
      <Field
        name={name}
        type={type}
        validate={validate}
        component={component}
      />}
  </div>);

@connect(
  (state, ownProps) => {
    const formName = syncInfo.formFunc('advertCampaign', ownProps.params.id);
    const selector = formValueSelector(formName);

    return {
      title: selector(state, 'title'),
      description: selector(state, 'description'),
      image: selector(state, 'image'),
      link: selector(state, 'link'),
      partner_name: selector(state, 'partner_name'),
      partner_logo: selector(state, 'partner_logo'),
      partner_logo_link: selector(state, 'partner_logo_link'),
      weight: selector(state, 'weight'),
      shows_limit: selector(state, 'shows_limit'),
      click_count: selector(state, 'click_count'),
      shows_count: selector(state, 'shows_count'),
      view_pixel_code: selector(state, 'view_pixel_code'),
      click_pixel_code: selector(state, 'click_pixel_code'),
      is_published: selector(state, 'is_published'),
      is_archived: selector(state, 'is_archived'),
      created_at: selector(state, 'created_at'),
      updated_at: selector(state, 'updated_at'),
      disclaimer: selector(state, 'disclaimer'),

      syncedEntity: 'advertCampaign',
      syncedId: ownProps.params.id,
    };
  },
)
@syncForm()
@reduxForm()
class AdvertCampaignForm extends React.Component {
  handleChange = field => (s3_path) => {
    const { dispatch, form } = this.props;
    dispatch(change(form, field, s3_path));
  };

  toggleArchived = (e) => {
    e.preventDefault();
    const { dispatch, form } = this.props;
    dispatch(change(form, 'is_archived', !this.props.is_archived));
  };

  toggleActive = (e) => {
    e.preventDefault();
    const { dispatch, form } = this.props;
    dispatch(change(form, 'is_published', !this.props.is_published));
  };

  disableSubmitButton = () => {
    if (this.props.is_published) {
      return false;
    }
    return !this.props.title || !this.props.description || this.props.weight < 1;
  };

  render() {
    const { formValues, form } = this.props;
    if (!formValues || !formValues.id) return null;

    return (
      <form id="EditorForm" className="ui form">
        <div className="ui container basic segment">
          <div className="ui vertically padded grid">
            <div className="ui dividing large header eleven wide column">
              Рекламная кампания
            </div>
            <div className="ui two wide column">
              <button
                type="button"
                onClick={this.toggleArchived}
                disabled={this.props.is_published}
                className="ui button secondary"
              >
                {this.props.is_archived ? 'Восстановить' : 'Архивировать'}
              </button>
            </div>
            <div className="ui two wide column">
              <button
                type="button"
                style={{ marginLeft: '10px' }}
                onClick={this.toggleActive}
                disabled={this.disableSubmitButton()}
                className="ui button primary"
              >
                {this.props.is_published ? 'Выключить' : 'Включить'}
              </button>
            </div>
          </div>
          <fieldset style={{ border: 'none' }} disabled={this.props.is_published}>
            <div className="ui vertically padded grid">
              <div className="ten wide column">
                <FormField label="Заголовок" name="title" />
                <FormField label="Описание" name="description" />
                <FormField
                  label="Загрузить изображение к материалу"
                  name="image"
                  type="file"
                  form={form}
                  image={this.props.image}
                  handler={this.handleChange}
                />
                <FormField label="Ссылка на материал" name="link" />
                <FormField label="Весы/приоритет" name="weight" type="number" />
                <FormField label="Лимит показов" name="shows_limit" type="number" />
                <FormField label="Дисклеймер (иноагент)" name="disclaimer" />
              </div>
              <div className="six wide column">
                <FormField label="Имя партнера" name="partner_name" />
                <FormField
                  label="Загрузить логотип партнера"
                  name="partner_logo"
                  type="file"
                  form={form}
                  image={this.props.partner_logo}
                  handler={this.handleChange}
                />
                <FormField label="Ссылка для логотипа" name="partner_logo_link" />
                <div className="field">
                  <Label>Кол-во показов:</Label>
                  {this.props.shows_count}
                </div>
                <div className="field">
                  <Label>Кол-во кликов:</Label>
                  {this.props.click_count}
                </div>
              </div>
            </div>
          </fieldset>
        </div>
      </form>
    );
  }
}

export default AdvertCampaignForm;
