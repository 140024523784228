/* @flow */
import React from 'react';
import 'react-quill/dist/quill.core.css';
import ReactQuill, { Quill } from 'react-quill';
import {
  Container,
  Row,
  InputTitle,
  RichText,
  RichTextTitle,
  Accept,
  Delete,
  Control,
  Error,
} from '../../atoms';
import {
  Input,
} from '/ui/atoms';
import {
  SimpleRichText,
} from '/ui/organism';

export type State = {
  text: string,
  body: string,
  isHintMaxHeight: boolean
}

export type Props = {
  text: string,
  body: string,
  isCreated: boolean,
  onSubmit: (State) => void,
  onDelete: () => void
}

class HintForm extends React.Component<Props, State> {
  static defaultProps = {
    text: '',
    body: ''
  };

  state = {
    text: this.props.text,
    body: this.props.body,
    childrens: this.props.childrens,
    isHintMaxHeight: false
  };

  availableFieldNames: Array<string> = [
    'text'
  ];

  reactQuillRef: ReactQuill = null;
  quillRef: Quill.Quill = null;

  componentDidMount() {

    // fix for hint that have more than one node (formatting) 
    if (this.state.childrens.length > 1) {
      this.setState(() => ({ text: this.state.childrens[0].props.node.text }));
    }

    if (this.quillRef) {
      const { root } = this.quillRef;
      this.setState(() => ({
        isHintMaxHeight: this.checkMaxHeightHint(root)
      }));
    }
  }

  isAvailableFieldName = (name: string) => this.availableFieldNames.includes(name);

  onSubmit = (event: SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.onSubmit(this.state);
  };

  onDelete = (event: SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.onDelete();
  };

  handleChangeRichText = (body: string) => {
    if (!this.quillRef) return;

    const { root } = this.quillRef;

    this.setState(() => ({
      body,
      isHintMaxHeight: this.checkMaxHeightHint(root)
    }));
  };

  handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.persist();

    const fieldName = event.target.name;

    if (this.isAvailableFieldName(fieldName)) {
      this.setState(() => ({
        [fieldName]: event.target.value
      }));
    }
  };

  checkMaxHeightHint = (hintEditorRoot: HTMLDivElement): boolean => {
    const { scrollHeight } = hintEditorRoot;

    return scrollHeight > 360;
  };

  setQuillRef = (ref: ReactQuill) => {
    this.reactQuillRef = ref;
    if (ref && ref.getEditor) {
      this.quillRef = ref.getEditor();
    }
  };

  render() {
    const {
      text,
      body,
      isHintMaxHeight,
    } = this.state;

    const {
      isCreated
    } = this.props;

    return (
      <Container
        onSubmit={this.onSubmit}
      >
        <Row>
          <InputTitle>Текст</InputTitle>
          <Input
            type="text"
            name="text"
            value={text}
            onChange={this.handleChange}
          />
        </Row>
        <Row>
          <RichTextTitle>Подсказка</RichTextTitle>
          <RichText>
            <SimpleRichText
              innerRef={this.setQuillRef}
              onChange={this.handleChangeRichText}
              value={body}
            />
          </RichText>
        </Row>
        {isHintMaxHeight && (
          <Row>
            <Error>
              Размер подсказки превышен
            </Error>
          </Row>
        )}
        <Control>
          <Delete
            type="button"
            disabled={isCreated}
            onClick={this.onDelete}
          >
            Удалить
          </Delete>
          <Accept
            type="submit"
          >
            Сохранить
          </Accept>
        </Control>
      </Container>
    );
  }
}

export default HintForm;
