// @ts-nocheck

import { useState, useEffect } from 'react';
// импорт сокет-менеджмента из локальной директории, не библиотеку
import socket from '/io';
import { Notification } from '/../common/notify-channel';

const MAX_QUEUE_LENGTH = 99; // максимальный размер хранилища сообщений

// eslint-disable-next-line import/prefer-default-export
export default ({
  socketEvents,   // массив строк имён серверных событий
  browserEvents,  // масстив строк имён браузерных событий
  userId: currentUserId, // для получения прямых сообщений к пользователю 
}) => {
  // Внутреннее хранилище сформированных сообщений,
  // готовых к отображению пользователем хука.
  // Последнее сообщение извлекается пользователем
  // через функцию pop
  const [
    notificationQueue,
    setNotificationQueue,
  ] = useState([]);

  // Внутренний список слушателей серверных событий
  // Только для внутреннего использования.
  // Пользователь хука не имеет доступа, но формирует
  // список слушателей через параметр socketEvents
  const [
    serverListeners, 
    setServerListeners,
  ] = useState([]);

  // Функция добавления нового сообщения в очередь.
  // Внутренне хранилище сообщений
  // Пользователь хука должен самостоятельно забирать сообщения
  // из очереди функцией pop
  const addNotificationToQueue = (
    { header, message, type, userId },
  ) => {
    if (userId && userId !== currentUserId) {
      if (typeof userId !== typeof currentUserId) {
        console.error(
          'Message from notify channel was recieved,',
          'but type of userId from message not complain to type of userId from local state:',
          typeof userId,
          'not a',
          typeof currentUserId,
        );
      }
      return notificationQueue;
    }

    setNotificationQueue((previousNotifications) => {
      let restNotifications = [...previousNotifications];
      if (previousNotifications.length >= MAX_QUEUE_LENGTH) {
        restNotifications = restNotifications.slice(0, MAX_QUEUE_LENGTH - 2);
      }
      return [
        { header, message, type },
        ...restNotifications,
      ];
    });
  };

  // Добавляет слушателей сообщений с сервера
  // Число слушателей равно длине входящего
  // массива строк имён серверных событий 
  useEffect(() => {
    if (!socketEvents) {
      return null;
    }
    if (!Array.isArray(socketEvents)) {
      console.error(
        'Error at hook useNotifications:',
        'socketEvents must be an array or undefined',
      );
      return null;
    }

    socketEvents.forEach((socketEventName) => {
      setServerListeners(
        (prevEvents) => [
          ...prevEvents,
          new Notification(socket, socketEventName),
        ],
      );
    });

    return () => {
      setServerListeners([]);
    };
  }, [socket, socketEvents]);

  // Привязывает коллбеки добавления сообщений в очередь
  // ко всем слушателям серверных сообщений
  useEffect(() => {
    serverListeners
      .forEach((instance) => {
        instance.recive(addNotificationToQueue);
      });

    return () => {
      serverListeners
        .forEach((instance) => {
          instance.stopRecive(addNotificationToQueue);
        });
    };
  }, [serverListeners]);

  // Регистирует слушателей сообщения браузера
  // Число слушателей равно длине входящего
  // массива строк имён браузерных событий 
  useEffect(() => {
    if (!browserEvents) {
      return null;
    }
    if (!Array.isArray(browserEvents)) {
      console.error(
        'Error at hook useNotifications:',
        'browserEvents must be an array or undefined',
      );
      return null;
    }

    browserEvents.forEach((browserEventName) => {
      window.addEventListener(
        browserEventName,
        addNotificationToQueue,
      );
    });

    return () => {
      browserEvents.forEach((browserEventName) => {
        window.removeEventListener(
          browserEventName,
          addNotificationToQueue,
        );
      });
    };
  }, [socket, browserEvents]);

  // Извлекает новое сообщение из хранилища
  // и отдаёт его пользователю хука.
  // Извлечённое сообщение больше не хранится
  // внутри хука
  const popMessageFromQueue = () => {
    if (notificationQueue.length <= 0) return null;

    const notificationToReturn = notificationQueue.at(0);
    let restNotifications = [];

    setNotificationQueue((previousNotifications) => {
      if (previousNotifications.length === 0) {
        return previousNotifications;
      }

      restNotifications = previousNotifications.slice(1);
      if (restNotifications.length === 0) {
        restNotifications = [];
      }
      return restNotifications;
    });
    return notificationToReturn;
  };

  // Очищает очередь сообщений в хранилище
  const pruneMessages = () => {
    setNotificationQueue([]);
  };

  // Возвращает последнее сообщение из хранилища
  // подобно функции pop,
  // но сохраняет в очередь входящее сообщение.
  // Применяется,если у пользователя хука есть
  // необходимость заменить сообщение из своего списка
  // на более новое. Например, при достижении лимита
  // демонстирруемых сообщений на экране.
  const swapMessage = ({ header, message, type }) => {
    if (notificationQueue.length <= 0) return null;
    const notificationToReturn = notificationQueue.at(0);
    let restNotifications = [];
    setNotificationQueue((previousNotifications) => {
      if (previousNotifications.length === 0) {
        return previousNotifications;
      }

      restNotifications = previousNotifications.slice(1);
      if (restNotifications.length === 0) {
        restNotifications = [];
      }
      return [{ header, message, type }, ...restNotifications];
    });
    return notificationToReturn;
  };

  /*
  Первым объектом массива возвращаем длину очереди,
  чтобы пользователь хука следил за новыми сообщениями;
  
  По изменению длины очереди пользователь хука запрашивает pop
  и забирает сообщение. Длина очереди меняется,
  и пользователь может далее забирать сообщения до достижения своего лимита;
  
  По достижению собственного лимита, пользователь может вызывать swap
  для того, чтобы заменять свои сообщения на экране более новыми из очереди,
  не удаляя старые сообщения в очереди;
  
  Когда клиенту более не нужна очередь сообщений, он может очистить её
  функцией prune.
  */
  return [
    notificationQueue.length,
    {
      pop: popMessageFromQueue,
      prune: pruneMessages,
      swap: swapMessage,
    },
  ];
};
