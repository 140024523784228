import React from 'react';

import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';

import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  PagingInfo,
  ResultsPerPage,
  Paging,
  Sorting,
  WithSearch,
} from '@elastic/react-search-ui';
import { Layout } from '@elastic/react-search-ui-views';
import '@elastic/react-search-ui-views/lib/styles/styles.css';

import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  buildSortOptionsFromConfig,
  getConfig,
  getFacetFields,
  getSiteBaseURL,
  getImageBaseURL,
} from './searchConfig/config-helper';

import Result from './components/Result';

const {
  hostIdentifier,
  searchKey,
  endpointBase,
  engineName,
} = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase,
});
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig(),
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  alwaysSearchOnInitialLoad: true,
  initialState: {
    sortDirection: 'desc',
    sortField: 'publication_date',
  },
};

export default () => (
  <SearchProvider config={config}>
    <WithSearch mapContextToProps={(context) => (context)}>
      {(context) => (
        <ErrorBoundary>
          <Layout
            className="ui container"
            header={<SearchBox autocompleteSuggestions />}
            sideContent={(
              <>
                {context.wasSearched && (
                  <Sorting
                    label="Сортировать"
                    sortOptions={buildSortOptionsFromConfig()}
                  />
                )}
                {getFacetFields().map((field) => (
                  <Facet key={field} field={field} label={field} />
                ))}
              </>
              )}
            bodyHeader={(
              <>
                {context.wasSearched && <PagingInfo />}
                {context.wasSearched && <ResultsPerPage />}
              </>
              )}
            bodyContent={(context.results).map((result) => (
              <Result result={result} siteBaseURL={getSiteBaseURL()} imageBaseURL={getImageBaseURL()} />
            ))}
            bodyFooter={<Paging />}
          />
        </ErrorBoundary>
      )}
    </WithSearch>
  </SearchProvider>
);
