import React from 'react';
import '../../../../node_modules/flag-icons/css/flag-icons.min.css';

const flagNameMapping = {
  en: 'gb', // небольшой хак, чтобы на англйском тексте показывался британский флаг
};

const Flag = ({ country }) => {
  if (!country) return null;
  const flagName = flagNameMapping[country] || country;
  return <span className={`fi fi-${flagName}`} style={{ marginRight: '5px', height: '1.3em' }} />;
};

export default Flag;
