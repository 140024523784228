/* @flow */

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { publicUrl } from '../../const/config';

import * as actions from '../../actions/actions';

import './style.styl';

type Props = {
  item: {
    pre: string,
    error: string,
    post: string,
    comment: string,
    link: string,
    admin_link: string,
    id: number,
  },
  archive: (number) => void,
};

@connect(
  (state, props) => ({
    item: props.item,
  }),
  (dispatch, ownProps) => ({
    archive: (id) => {
      dispatch(actions.archiveReport(id));
    },

  })
)

export default class UserProfileForm extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.archive = this.archive.bind(this);
  }

  archive() {
    this.props.archive(this.props.item.id);
  }
  archive: () => void;

  render() {
    const { item } = this.props;
    return (
      <tr className="report">

        <td className="report__error">
          <span>{item.pre}</span>
          <strong>{item.error}</strong>
          <span>{item.post}</span>
        </td>

        <td className="report__comment">
          {item.comment}
        </td>

        <td className="report__link">
          <a href={publicUrl + item.link} target="_blank">
            {item.link}
          </a>
        </td>

        <td className="report__link">
          <Link to={item.admin_link} target="_blank">
            {item.admin_link}
          </Link>
        </td>
        <td className="report__execute">
          <button onClick={this.archive}>
            Исправлено
          </button>
        </td>
      </tr>
    );
  }
};
