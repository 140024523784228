/* @flow */
import React from 'react';
import { Range } from 'slate';
import type { Editor as SlateEditor } from 'slate-react';
import {
  ToolTip,
} from '/components/SlateRichText/molecules';
import {
  TopBarButton,
} from '../../atoms';
import {
  Icons,
  ModuleContainer,
} from '../../../../atoms';
import Editor from '../../../../Editor';

const {
  AddHint,
} = Icons;

const ControlButton = ({ icon, onClick, enabled }) => {
  const type = Editor.NODE.HINT;

  return (
    <TopBarButton
      onMouseDown={enabled ? onClick : null}
      data-for={type}
      data-tip
      enabled={enabled}
    >
      {icon}
      <ToolTip
        id={type}
        tooltip={Editor.TOOLTIPS.HINT}
      />
    </TopBarButton>
  );
};

type Props = {
  editor: SlateEditor,
  enabled: boolean
}

class HintToolModule extends React.Component<Props> {
  addHint = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { editor } = this.props;
    const { value } = editor;
    const { selection, fragment } = value;

    if (!fragment) return;

    const isEmptySelection = selection.isBlurred || selection.isCollapsed || fragment.text === '';

    if (isEmptySelection || fragment.nodes.size > 1) return;

    const range = new Range({
      anchor: selection.anchor,
      focus: selection.focus,
    });

    editor.wrapInlineAtRange(range, Editor.node.Hint({ isCreated: true }));
  };

  render() {
    const { enabled } = this.props;
    return (
      <ModuleContainer>
        <ControlButton
          icon={<AddHint />}
          onClick={enabled ? this.addHint : null}
          enabled={enabled}
        />
      </ModuleContainer>
    );
  }
}

export default HintToolModule;
