import * as actions from '../actions/actions';

export default function themes(state = {}, action){
	switch(action.type){
		case actions.FETCH_THEMES_SUCCESS:
			return action.res.reduce((res, theme) => {
				return Object.assign(res, {
					[theme.id]: theme
				});
			}, {});
		case actions.FETCH_THEME_SUCCESS:
			return {
				...state,
				theme: action.theme
			}
		case 'GET_RELATED_FOR_THEME_SUCCESS':
			return {
				...state,
				related: action.related
			}
		default:
			return state;
	}
}