import { createStore, applyMiddleware, compose } from 'redux';
import socketIoMiddleware from 'redux-socket.io-middleware';
import persistState from 'redux-localstorage';
import thunk from 'redux-thunk';
import { hashHistory } from 'react-router';
import { syncHistoryWithStore, routerMiddleware } from 'react-router-redux';

import io from '../io';
import initialState from './initial-state';
import channelName from '../utils/channelName';
import rootReducer from '../reducers/rootReducer'
import redirectMiddleware from './redirectMiddleware';
import routerReduxFormChange from './routerReduxFormChange';

const args = [
  applyMiddleware(
    redirectMiddleware,
    routerReduxFormChange,
    socketIoMiddleware(io, channelName),
    routerMiddleware(hashHistory),
    thunk,
  ),
  persistState('user', { key: 'mediazona' }),
];

if (window.devToolsExtension) {
  args.push(window.devToolsExtension());
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = composeEnhancers(...args);

const store = createStore(
  rootReducer,
  initialState,
  middlewares,
);

const history = syncHistoryWithStore(hashHistory, store);

export {
  store,
  history,
};
