import Mark from '../Mark';

export default {
  serialize(obj, children) {
    if (!obj) return;

    if (obj.object === 'mark') {
      return Mark.render({
        type: obj.type,
        children,
        attributes: obj.data.toObject()
      });
    }
  },
  deserialize(el, next) {
    const mark = Mark.TAGS[el.tagName.toLowerCase()];

    if (!el && !next) return;

    if (mark) {
      return {
        object: 'mark',
        type: mark,
        nodes: next(el.childNodes),
      };
    }
  },
};
