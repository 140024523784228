import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

const Dropzone = ({
  onDrop, id, children, maxFiles, accepted,
}) => {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles,
    accept: accepted,
  });

  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 1,
    borderRadius: 3,
    borderColor: '#d4d4d5',
    borderStyle: 'dashed',
    backgroundColor: '#fff',
    color: '#000',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    textAlign: 'center',
    position: 'relative',
  };

  const focusedStyle = {
    borderColor: '#85B7D9',
  };

  const acceptStyle = {
    borderColor: '#21BA45',
  };

  const rejectStyle = {
    borderColor: 'red',
  };

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  }), [
    isFocused,
    isDragAccept,
    isDragReject,
  ]);

  return (
    <div {...getRootProps({ style })}>
      <input className="dropzone-input" name="file" key={id} id={id} {...getInputProps()} />
        {children}
    </div>
  );
};

export default Dropzone;
