let abc = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz';


const getChar = () => abc[Math.round(Math.random()*(abc.length-1))]

export default function(length = 8){

    let result = '';

    for (let i = 0; i < length; i++){
        result += getChar();
    }

    return result;
}