import mz_proto from '../utils/mz_protocol';

export const GET_POST_TRANSFORM = 'GET_POST_TRANSFORM ';
export const GET_POST_TRANSFORM_SUCCESS = 'GET_POST_TRANSFORM_SUCCESS';
export const GET_POST_TRANSFORM_FAILURE = 'GET_POST_TRANSFORM_FAILURE';

export const getPostTransform = (post_id) => async (dispatch) => {
  try {
    dispatch({ type: GET_POST_TRANSFORM });
    const post = await mz_proto('getPostTransform', post_id);
    dispatch({ type: GET_POST_TRANSFORM_SUCCESS, payload: post });
  } catch (e) {
    dispatch({ type: GET_POST_TRANSFORM_FAILURE, error: e.message });
    console.log(e);
  }
};
