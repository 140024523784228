import React from 'react';
import {
  ContextPullQuote,
  PullQuoteController,
} from './components';
import {
  nodeTypes,
  objectTypes,
} from '../../consts';

const ContextPullQuoteNode = {
  type: nodeTypes.CONTEXT_PULL_QUOTE,
  object: objectTypes.BLOCK,

  schema: {
    isVoid: true,
  },

  create({ viewType, context, imgSrc, alt }) {
    return {
      type: this.type,
      object: this.object,
      data: {
        viewType,
        context,
        imgSrc,
        alt,
      },
    };
  },

  htmlRender(attrs) {
    return (
      <ContextPullQuote
        {...attrs}
      />
    );
  },

  editorRender(attrs) {
    return (
      <PullQuoteController
        {...attrs}
      >
        <ContextPullQuote
          {...attrs}
        />
      </PullQuoteController>
    );
  },
};

export default ContextPullQuoteNode;

