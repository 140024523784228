import React from 'react';
import {
  normalizeImgSrc
} from '../../../../helpers';

const Photo = (props) => {
  const { node, attributes } = props;
  const { data } = node;
  const imgSrc = data.get('imgSrc');
  const alt = data.get('alt');

  const normalizeImg = normalizeImgSrc(imgSrc);

  return (
    <img
      className="mz-publish-photo__image"
      src={normalizeImg}
      alt={alt}
      {...attributes}
    />
  );
};

export default Photo;