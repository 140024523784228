import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';
import Label from '../../atoms/Label';

const COLOR_CA = '#3A59AB';
const COLOR_BY = '#296843';
const CLASS_CA = 'ca-blue';
const CLASS_BY = 'by-green';

const Description = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: #5D5D5D;
`;

const RadioWrapper = styled.div`
    position: relative;
    width: 31px;
    height: 31px;
    background: #F2F2F2;
    border: ${({ checked }) => (checked ? '2px solid #2184D0' : '1px solid #D4D4D5')};
    box-sizing: border-box;
    margin-right: 5px;

    &:last-child {
        margin-right: 0;
    }
`;

const RadioController = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 21px;
    height: 21px;
    background-color: ${({ color }) => color};
`;

const Container = styled.div`
    display: flex;
    width: 100%;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #D3D3D4;

    .original-radio {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 21px;
        height: 21px;
        opacity: 0;
    }
`;

const ColorWrapper = styled.div`
    display: flex;
`;

const getColorName = (color) => {
  switch (color) {
    case COLOR_CA: return CLASS_CA;
    case COLOR_BY: return CLASS_BY;
    default: return color;
  }
};

const RadioButton = ({ color, name, value }) => (
  <RadioWrapper checked={value === color}>
    <RadioController color={color} />
    <Field
      name={name}
      type="radio"
      component="input"
      className="original-radio"
      value={getColorName(color)}
    />
  </RadioWrapper>
);

const ColorPicker = ({ label, name, value, colorList = ['white', 'black'] }) => (
  <Container>
    <div>
      <Label> {label} </Label>
      <Description>{value}</Description>
    </div>
    <ColorWrapper>
      {colorList.map(color => (color ? <RadioButton name={name} color={color} value={value} /> : null))}
    </ColorWrapper>
  </Container>
);

export default ColorPicker;
