import React from 'react';
import { connect } from 'react-redux';

import Filter from './styles';
import DateFilterElement from './components/DateFilterElement';
import ResetFilter from './components/ResetFilter';

import {
  generateUrl,
  generateResetFilterLabel,
} from '/utils/dateFilter';

@connect(
  state => ({
    dateFilters: state.dateFilter.count,
    totalCount: state.dateFilter.totalCount
  })
)
export default class DateFilter extends React.Component {
  render() {
    const {
      dateFilters,
      totalCount
    } = this.props;
    const {
      type,
      year,
      month,
      day
    } = this.props.params;

    const resetLabel = generateResetFilterLabel(year, month, day);

    if (!dateFilters) return null;

    return (<Filter>
      <ResetFilter label={resetLabel} resetLink={ `/${type}` }/>
      {
        Object.keys(dateFilters || {}).map((label) => {
          const count=dateFilters[label];
          const url=generateUrl(label, type, year, month, day);
          return ( <DateFilterElement
            key={label}
            count={count}
            label={label}
            totalCount={totalCount}
            url={url}
            />
          );
        })
      }
    </Filter>)
  }
};
