import React, { Component } from 'react';
import {
  Select,
} from '/ui/atoms';
import ProcessUploadImage from '/utils/processUploadImage';
import {
  InputTitle,
  Loading,
  ModalCancel,
  ModalController,
  ModalLoadingSubmit,
  ModalSubmit,
  Input,
} from '/components/SlateRichText/atoms';
import {
  ImageField,
} from '/components/SlateRichText/molecules';
import {
  selectStyle,
} from '/components/SlateRichText/styles';
import {
  nodeTypes,
} from '/components/SlateRichText/consts';
import ContextField from '../ContextField';
import MultiContextField from '../MultiContextField';

const viewTypeOptions = [
  { value: 'justify', label: 'На ширину текста' },
  { value: 'side', label: 'Налево' },
  { value: 'side-right', label: 'Направо' },
];

class PullQuoteForm extends Component {
  constructor(props) {
    super(props);
    const { node, editor } = props;

    this.processUploadImage = new ProcessUploadImage();
    const dataJSON = node?.data?.toJSON() || {};
    const initialState = {
      type: dataJSON.type,
      imgSrc: dataJSON.imgSrc || '',
      imgError: dataJSON.imgError || '',
      error: '',
      alt: dataJSON.alt || '',
      context: {
        postId: null,
        title: '',
        url: '',
        type: '',
      },
      multiContext: this.props.multiContext || [],
      contextTitle: this.props.contextTitle || editor.props.contextTitle,
      title: '',
      text: '',
      viewType: dataJSON.viewType || viewTypeOptions[0].value,
      isLoading: false,
    };

    if (props.initialState) {
      this.state = { ...initialState, ...props.initialState };
    } else {
      this.state = initialState;
    }
  }

  componentDidMount() {
    // TODO: Fix close by Enter
    // window.addEventListener('keypress', (e) => {
    //   if (e.key === 'Enter') {
    //     this.onSubmit();
    //   }
    // });
  }

  componentWillUnmount() {
    // TODO: Fix close by Enter
    // window.removeEventListener('keypress');
  }

  isMultiContextPullQuote = type => type === nodeTypes.MULTICONTEXT_PULL_QUOTE;
  isContextPullQuote = type => type === nodeTypes.CONTEXT_PULL_QUOTE;

  onUploadImage = ({ isLoading, src, error }) => {
    this.onChange({
      imgSrc: src,
      error,
      isLoading,
    });
  };

  onSelect = fieldName => (value) => {
    this.setState(() => ({
      [fieldName]: value,
      type: value,
    }));
  };

  onChange = (value) => {
    this.setState(() => value);
    this.setState({ error: '' });
  };

  onChangeInput = fieldName => (event) => {
    event.persist();
    event.preventDefault();

    this.onChange({
      [fieldName]: event.target.value,
    });
  };

  onSubmit = (event) => {
    event && event.persist();
    event && event.preventDefault();

    const {
      imgError,
      imgSrc,
      viewType,
      alt,
      context,
      multiContext,
      title,
      text,
      url,
      error,
      contextTitle,
    } = this.state;

    const {
      type,
    } = this.props;

    let submitValue = {
      viewType,
      type,
      imgSrc,
      alt,
      title,
      url,
      context,
      text,
    };

    if (this.isMultiContextPullQuote(type)) {
      if (!contextTitle || contextTitle.length < 1) {
        this.setState({ error: 'Отсутствует заголовок' });
        return;
      }

      if (multiContext.length < 1) {
        this.setState({ error: 'Не выбран материал' });
        return;
      }

      submitValue = { multiContext, contextTitle, type };
    }

    if (this.isContextPullQuote(type) && !context.postId) {
      this.setState({ error: 'Не выбран материал' });
      return;
    }

    if (imgError || error) return;
    this.props.onSubmit(submitValue);
    this.props.onClose();
  };

  render() {
    const {
      viewType,
      imgSrc,
      alt,
      error,
      isLoading,
      context,
      title,
      multiContext,
      contextTitle,
      text,
    } = this.state;

    const {
      onClose,
      submitText,
      editor,
      type,
    } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        {this.isMultiContextPullQuote(type) &&
          <MultiContextField
            error={error}
            isLoading={isLoading}
            id={editor.props.entityId}
            multiContext={multiContext}
            contextTitle={contextTitle}
            onChange={this.onChange}
          />}

        {this.isContextPullQuote(type) &&
          <ContextField
            error={error}
            isLoading={isLoading}
            id={editor.props.entityId}
            context={context}
            imgSrc={imgSrc}
            onChange={this.onChange}
          />
        }
        {!this.isMultiContextPullQuote(type) &&
        !this.isContextPullQuote(type) &&
        <ImageField
          src={imgSrc}
          error={error}
          alt={alt}
          onUpload={this.onUploadImage}
          onChangeAlt={this.onChangeInput('alt')}
        />}

        {!this.isMultiContextPullQuote(type) &&
        <React.Fragment>
          <InputTitle>
            Стиль изображения
          </InputTitle>
          <Select
            clearable={false}
            className={selectStyle}
            menuContainerStyle={{ zIndex: 1000 }}
            value={viewType}
            onChange={this.onSelect('viewType')}
            options={viewTypeOptions}
          />
        </React.Fragment>}
        <ModalController>
          <ModalCancel
            onClick={onClose}
          >
            Отменить
          </ModalCancel>
          {isLoading ? (
            <ModalLoadingSubmit>
              <Loading />
            </ModalLoadingSubmit>
          ) : (
            <ModalSubmit
              type="submit"
              value={submitText}
            />
          )
          }
        </ModalController>
      </form>
    );
  }
}

export default PullQuoteForm;
