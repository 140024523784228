import React from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import FeedGroupedItems from './FeedGroupedItems';
import * as actions from '../../actions/actions';

import format from 'date-fns/format'
import ruLocale from 'date-fns/locale/ru'

@connect(
    (state) => ({
        themes: state.themes
    }),
    (dispatch) => bindActionCreators({
        fetchThemes: actions.fetchThemes,
    }, dispatch)
)

export default class Feed extends React.Component {

    static propTypes = {
        type: PropTypes.string,
        year: PropTypes.string,
        month: PropTypes.string,
        day: PropTypes.string,
    };

    _fetchThemes ({ type, year, month, day }) {

        this.props.fetchThemes({ type, year, month, day });
    }

    componentDidMount () {
        this._fetchThemes(this.props);
    }

    componentWillReceiveProps (nextProps) {
        const isRouteNew = (
            nextProps.type !== this.props.type ||
            nextProps.year !== this.props.year ||
            nextProps.month !== this.props.month ||
            nextProps.day !== this.props.day
        );

        if (isRouteNew) {
            this._fetchPosts(nextProps);
        }
    }

    render () {

        const { themes } = this.props;

        return (
            <div className="ui container">
                <div className="ui vertically padded grid">
                    <div className="column">
                        <div className="Feed">
                            <FeedGroupedItems themes={ themes } title="Темы"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}