import React, { Component} from 'react';
import { connect } from 'react-redux';
import {syncCollection, collectionSelector} from '../../lib/sync-form';
@connect(
	(state, props)=>{
		return {
			syncedEntity: 'urls',
			syncedId: props.post_id,
			post_id: props.post_id,
		}
	},
	(dispatch, ownProps)=>{
		return {

		}
	}
)
@syncCollection()

export default class Urls extends Component{
	constructor(props){
		super(props);
		this.removeUrl = this.removeUrl.bind(this);
		this.disabled = props.disabled;
	}

	removeUrl(x){
		if (this.disabled) {
			return;
		}
		if (x.canonical!==x.url){
			this.props.remove(x.id);
		}
	}

	tmpl(o){
		let template = null;
		var urls = [];
		if (Object.keys(o).length>0)
		{
			for (var prop in o)
			{
				urls.push(o[prop]);
			}
			return (<tr>
				<td className="metaUrls" colSpan="3">
					<div className="ui horizontally padded grid">
						<div className="column">
							<div className="ui relaxed list">
								{
									urls.map((x, i) => {
										return (
											<div className="item" key={i}>
												<i className={x.canonical == x.url
													? "world small icon"
													: "removeUrl link red remove small icon"
												} onClick={() => this.removeUrl(x)}></i>
												<div className="content" key={'urls' + i}><strong>{x.url}</strong></div>
											</div>
										)
									})
								}
							</div>
						</div>
					</div>
				</td>
			</tr>);
		}
		return null;
	}
	render(){
		const {formValues} = this.props;
		if (!formValues)
			return null;
		return this.tmpl(formValues);
	}
}
