/* @flow */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import config from '/const/config';

import {
  fetchPushesList,
  subscribeToWebpushes,
  unsubscribeFromWebpushes,
} from '../../actions/actions';
import './WebPushList.styl';

type Push = {
  time: ?number,
  title: ?string,
  description?: string,
  link: string,
  image: ?string,
};

type Props = {
  pushes: Array<Push>,
  dispatch: Object => void,
};

type State = {
  pushes: Array<Push>,
};

@connect(
  state => ({
    pushes: state.webPushes,
  }),
  dispatch => ({
    dispatch,
  }),
)
export default class WebPushesList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      pushes: [],
    };
    props.dispatch(fetchPushesList());
    this.timer = setInterval(() => {
      props.dispatch(fetchPushesList());
    }, 60000);
  }
  state: State;

  componentWillMount() {
    this.props.dispatch(subscribeToWebpushes());
  }

  componentWillReceiveProps(props: Props) {
    const pushes: Array<Push> = props.pushes;
    this.setState(state => ({
      pushes,
    }));
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.props.dispatch(unsubscribeFromWebpushes());
  }
  props: Props;
  timer: number;

  render() {
    const { pushes } = this.state;
    return (
      <div className="webpush">
        <div className="header">Список пушей</div>
        {pushes && pushes.length ? (
          pushes.map((item: Push) => (
            <div className="webpush-wrapper" key={item.time}>
              <img alt={item.title} src={item.image} />
              <div className="webpush-content-container">
                <span>
                  <strong>Заголовок:</strong> {item.title}
                </span>
                <span>
                  <strong>Описание:</strong> {item.description}
                </span>
                <span>
                  <strong>Дата:</strong>{' '}
                  {format(new Date((item.time + 1469203594) * 1000), 'yyyy.MM.dd HH:mm')}
                </span>
                <span>
                  <strong>Ссылка: </strong>
                  <a href={`${config.publicUrl}${item.link}`}>{item.link}</a>
                </span>
              </div>
            </div>
          ))
        ) : null}
      </div>
    );
  }
}
