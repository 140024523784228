/* @flow */
import React from 'react';
import type { Node as ReactNode } from 'react';
import { Portal } from 'react-portal';
import { findDOMNode } from 'slate-react';
import type { Editor } from 'slate-react';
import { Decoration } from 'slate';
import type { Inline } from 'slate';
import {
  PopupWrapperContainer,
  PopupOverlay,
} from './styled';
import {
  annotationTypes
} from '../../consts';

type PopupWrapperProps = {
  editor: Editor,
  node: Inline,
  isShowPopup: boolean,
  handleShowPopup: (boolean) => void,
  children: ReactNode
}

export type PopupWrapperState = {
  positionX: number,
  positionY: number,
  selectedRangeKeys: string[],
}

class TextPopupWrapper extends React.Component<PopupWrapperProps, PopupWrapperState> {
  rootPortalRef: ?HTMLElement = null;
  nodeRef: ?HTMLElement = null;
  editorRef: ?HTMLElement = null;

  state = {
    positionX: 0,
    positionY: 0,
    selectedRangeKeys: [],
  };

  componentDidMount() {
    const { editor, node } = this.props;

    const rootPortalId = editor.props.rootPortalId;
    const rootPortalRef = document && document.getElementById(rootPortalId);
    if (!rootPortalRef) {
      throw new TypeError('root-portal is not found, check editor render');
    }

    this.rootPortalRef = rootPortalRef;

    const nodeRef = findDOMNode(node);
    if (!nodeRef) {
      throw new TypeError('nodeRef is not found, check call TextPopupWrapper');
    }

    this.nodeRef = nodeRef;

    const editorId = editor.props.editorId;
    const editorRef = document && document.getElementById(editorId);
    if (!editorRef) {
      throw new TypeError('editor is not found, check editor render');
    }

    this.editorRef = editorRef;

    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentDidUpdate(prevProps: PopupWrapperProps) {
    const { isShowPopup } = this.props;
    const isShowPopupChanged = prevProps.isShowPopup !== isShowPopup;

    if (isShowPopupChanged && isShowPopup) {
      this.updatePosition();
      this.setDecoration();
    }

    if (isShowPopupChanged && !isShowPopup) {
      this.removeDecoration();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  handleKeyDown = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      this.props.handleShowPopup(false);
    }
  };

  getSelectedRangeKey = (key: string) => `text_popup_wrapper_${key}`;

  setDecoration = () => {
    const { node, editor } = this.props;
    const textNodes = node.getTextsAsArray();
    const keys = [];

    editor.withoutSaving(() => {
      textNodes.forEach((textNode) => {
        keys.push(textNode.key);
        editor.addAnnotation({
          key: this.getSelectedRangeKey(textNode.key),
          type: annotationTypes.SELECTED_RANGE,
          anchor: { key: textNode.key, offset: 0 },
          focus: { key: textNode.key, offset: textNode.text.length },
        });
      });
    });

    this.setState(() => ({
      selectedRangeKeys: keys,
    }));
  };

  removeDecoration = () => {
    const { selectedRangeKeys } = this.state;
    const { editor } = this.props;
    const { value } = editor;
    const { annotations } = value;

    editor.withoutSaving(() => {
      selectedRangeKeys.forEach((key) => {
        const ann = annotations.get(this.getSelectedRangeKey(key));
        editor.removeAnnotation(ann);
      });
    });
  };

  hideModalOverlay = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === e.currentTarget) {
      this.props.handleShowPopup(false);
    }
  };

  updatePosition = () => {
    if (this.nodeRef && this.editorRef) {
      const editorRect = this.editorRef.getBoundingClientRect();
      const nodeRect = this.nodeRef.getBoundingClientRect();

      const positionLeft = nodeRect.left - editorRect.left;
      const positionTop = (nodeRect.top - editorRect.top) + nodeRect.height;

      this.setState(() => ({
        positionX: positionLeft,
        positionY: positionTop
      }));
    }
  };

  render() {
    const {
      isShowPopup,
      children
    } = this.props;

    const {
      positionX,
      positionY
    } = this.state;

    if (!isShowPopup) return null;

    return (
      <Portal
        node={this.rootPortalRef}
      >
        <React.Fragment>
          <PopupOverlay
            onClick={this.hideModalOverlay}
          />
          <PopupWrapperContainer
            positionX={positionX}
            positionY={positionY}
          >
            {children}
          </PopupWrapperContainer>
        </React.Fragment>
      </Portal>
    );
  }
}

export default TextPopupWrapper;
