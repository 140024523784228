import React, { Component } from 'react';

export default class RenderField extends Component {
  state = {
    value: this.props.input.value,
    timer: null
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.input.value !== this.props.input.value) {
      this.setState(() => ({
        value: nextProps.input.value
      }));
    }
  }
  
  debounce = (fn, delay) => {
    return (event) => {
      event.persist();
      this.setState(() => ({
        value: event.target.value
      }));

      clearTimeout(this.state.timer);

      this.setState(({
        timer: setTimeout(() => {
          fn(event);
        }, delay)
      }));
    };
  }

  render() {
    const {  
      label, 
      type, 
      name, 
      input,
      meta: { warning } 
    } = this.props;

    const onChange = input.onChange;

    return (
      <div>
        <input value={this.state.value} name={name} onChange={this.debounce(onChange, 300)} placeholder={label} type={type} />
        {(warning && <span className="webpush__text-counter">{warning}</span>)}
      </div>
    )
  }
}