import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, Field, formValueSelector, reduxForm } from 'redux-form';
import syncInfo from '../../../../../common/sync-channel';
import syncForm from '/lib/sync-form';
import { SingleFileUploader } from '/features/FileUploader';
import SlateRichText from '/components/SlateRichText/SlateRichText';
import {
  Field as FormField,
} from '/ui/molecules';
import {
  Label,
  Comment,
  Input,
} from '/ui/atoms';

const ReduxFormInput = ({ input, label, type, meta: { warning } }) => (
  <div>
    <Input autoComplete="off" {...input} placeholder={label} type={type} />
    {(warning && <span>{warning}</span>)}
  </div>
);

@connect(
  (state, ownProps) => {
    const formName = syncInfo.formFunc('partner', ownProps.params.id);
    const selector = formValueSelector(formName);

    return {
      name: selector(state, 'name'),
      url: selector(state, 'url'),
      color: selector(state, 'color'),
      description: selector(state, 'description'),
      image: selector(state, 'image'),
      pattern: selector(state, 'pattern'),
      isArchived: selector(state, 'is_archived'),

      syncedEntity: 'partner',
      syncedId: ownProps.params.id,
    };
  },
  (dispatch, ownProps) => ({
    changeField: (field, value) => {
      dispatch(change(syncInfo.formFunc('partner', ownProps.params.id), field, value));
    },
  }),
)
@syncForm()
@reduxForm()
class PartnerForm extends Component {
  handleChangeImage = field => (s3Path) => {
    this.props.changeField(field, s3Path);
  };

  toggleArchived = () => {
    this.props.changeField('is_archived', !this.props.isArchived);
  };

  render() {
    const {
      form,
      pattern,
      image,
      formValues,
      isArchived,
    } = this.props;

    if (!formValues) return null;

    return (
      <form id="EditorForm" className="ui form">
        <div className="ui container basic segment">
          <div className="ui grid">
            <div className="floated left row">
              <div className="ui thirteen wide column dividing large header">Партнер</div>
              <div className="ui two column">
                <button
                  type="button"
                  onClick={this.toggleArchived}
                  className="ui button secondary"
                >
                  {isArchived ? 'Восстановить' : 'Архивировать'}
                </button>
              </div>
            </div>
            <div className="ten wide column">
              <FormField>
                <Label>Название</Label>
                <Field
                  name="name"
                  type="text"
                  debounceTime={1500}
                  label="Заголовок"
                  component={ReduxFormInput}
                />
              </FormField>
              <FormField>
                <Label>Ссылка</Label>
                <Field
                  name="url"
                  type="text"
                  debounceTime={1500}
                  label="Заголовок"
                  component={ReduxFormInput}
                />
              </FormField>
              <FormField>
                <Label>Цвет партнера</Label>
                <Field
                  name="color"
                  type="text"
                  debounceTime={1500}
                  height="100"
                  component={ReduxFormInput}
                />
              </FormField>
              <FormField>
                <Label>Описание</Label>
                <Field
                  name="description"
                  component={SlateRichText}
                  key="description"
                />
              </FormField>
            </div>
            <div className="six wide column">
              <Label>Паттерн</Label>
              <SingleFileUploader
                name="pattern"
                form={form}
                file={pattern}
                handler={this.handleChangeImage('pattern')}
                accepted="image/*"
              />
              <Comment>*рекомендуемое разрешение 182х158</Comment>
              <Label>Логотип</Label>
              <SingleFileUploader
                name="image"
                form={form}
                file={image}
                handler={this.handleChangeImage('image')}
                accepted="image/*"
              />
              <Comment>*рекомендуемое разрешение 250х100</Comment>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default PartnerForm;
