import React, { Component } from 'react';
import axios from 'axios';
import config from '../../const/config';
import './mentionSearch.styl';

const card = (item, idx) => {
  if (Array.isArray(item)) {
    return (
      <>
        <div className="ui item">
          <span>
            {idx}
            {' '}
          </span>
          <a
            href={`${item[0]}`}
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            {item[0]}
          </a>
          <ol>
            {item.slice(1).map((i) => (
              <li>
                <a
                  href={`${i}`}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  {i}
                </a>
              </li>
            ))}
          </ol>
        </div>
        <div className="ui divider" />
      </>
    );
  }
  return (
    <>
      <div className="ui item">
        <span>
          {idx}
          {' '}
        </span>
        <a
          href={`${item}`}
          rel="noopener noreferrer nofollow"
          target="_blank"
        >
          { item }
        </a>
      </div>
      <div className="ui divider" />
    </>
  );
};

export default class MentionSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchedWord: '',
      links: [],
      loading: false,
      success: true,
    };
  }

  onChangeStart = (e) => {
    this.setState({ searchedWord: e.target.value });
    e.persist();
  };

  onChangeEnd = (e) => {
    this.setState({ searchedWord: e.target.value });
    e.persist();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { searchedWord, loading } = this.state;
    if (loading) {
      return false;
    }

    this.setState({ loading: true });

    const url = window.location.origin;
    axios.get(`${url}/webservices/get_mentions?searchedWord=${searchedWord}`).then(({ data }) => {
      const links = data.map((m) => {
        if (m.events && m.events.length > 0) {
          const eventsLinks = m.events.map((l) => `${config.publicUrl}${l.canonical}#${l.id}`);
          return [`${url}/#/post/${m.id}`, ...eventsLinks];
        }
        return `${url}/#/post/${m.id}`;
      });

      if (links.length === 0) this.setState({ success: false });

      this.setState({ links });
    }).finally(() => {
      this.setState({ loading: false });
    });

    return true;
  }

  render() {
    const {
      searchedWord,
      loading,
      links,
      success,
    } = this.state;

    return (
      <div className="ui container mention-search__container">
        <div className="ui segment">
          <h2 className="ui large header">Найти посты по ключевому слову</h2>
          <form className="ui form">
            <div className="ui inline fields">
              <div className="ui eleven wide field">
                <label htmlFor="searchedWord">
                  Введите
                  {'\u00A0'}
                  слово:
                </label>
                <input
                  type="text"
                  name="searchedWord"
                  onChange={this.onChangeStart}
                  value={searchedWord}
                />
              </div>
              <button className="ui button" type="submit" onClick={this.handleSubmit}>
                Показать ссылки
              </button>
            </div>
          </form>

          <div className="mention-search__result">
            {loading && (
              <div className="ui active inverted dimmer">
                <div className="ui text loader">Идет поиск...</div>
              </div>
            )}
            {(!(success) && !(loading) && (links.length < 1)) && <div>Ничего не найдено</div>}
            {!(loading) && <div className="ui list">{links.map(card)}</div>}
          </div>
        </div>
      </div>
    );
  }
}
