import store from './store';

export TranslateCollection from './components/TranslateCollection';
export TranslatesFeedItem from './components/TranslatesFeedItem';
export TranslatesFeedItemSmall from './components/TranslatesFeedItemSmall';
export TranslatePostConfig from './components/TranslatePostConfig';

export const reducer = store.reducer;

export store from './store';
