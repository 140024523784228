/* eslint-disable no-nested-ternary */
// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { InputContainer, Name, Link, Delete, Container, AddButton } from './styled';

const ALLOW_TYPES = ['podcast'];
const EMPTY_LINK_ELEMENT = { idx: null, name: '', link: '' };

export default ({ type, links, onChange }) => {
  const [linkArray, setLinks] = useState(links);
  const [lastDeletion, saveDeletion] = useState(EMPTY_LINK_ELEMENT);
  const [isMiniLoader, runMiniLoader] = useState(false);
  const [isMaxiLoader, runMaxiLoader] = useState(false);

  useEffect(() => {
    let onChangeTimer;
    let timerMaxiLoader;
    if (links !== linkArray) {
      onChangeTimer = setTimeout(() => onChange(linkArray), 300);

      if (links.length !== linkArray.length) {
        runMaxiLoader(true);
      } else {
        runMiniLoader(true);
      }
    } else {
      if (isMaxiLoader) {
        timerMaxiLoader = setTimeout(() => runMaxiLoader(false), 300);
      }
      if (isMiniLoader) {
        runMiniLoader(false);
      }
    }

    return () => {
      clearTimeout(onChangeTimer);
      clearTimeout(timerMaxiLoader);
    };
  });

  const restoreDeletion = () => (<button
    className="ui negative basic button"
    onClick={() => {
      const newArray = [...linkArray];
      newArray.splice(
        lastDeletion.idx,
        0,
        { name: lastDeletion.name, link: lastDeletion.link },
      );
      setLinks(newArray);
      saveDeletion(EMPTY_LINK_ELEMENT);
    }}
  >
    Отменить удаление {lastDeletion.name}
  </button>);

  if (ALLOW_TYPES.includes(type)) {
    return (
      <Container className="ui segment">
        <div>Ссылки на подкаст
          {isMiniLoader && <div className="ui mini active inline loader" />}
          {isMaxiLoader && (<div className="ui active inverted dimmer">
            <div className="ui text loader">Применяю изменения</div>
          </div>)}
        </div>
        {lastDeletion?.idx === 0 && restoreDeletion()}
        {linkArray.map((link, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`podcast-link-${idx}`}>
            {idx > 0 && lastDeletion?.idx === idx && restoreDeletion()}
            <InputContainer>
              <Name
                type="text"
                name="name"
                placeholder="Название сервиса..."
                value={link.name}
                onChange={(e) => {
                  const newArray = [...linkArray];
                  newArray[idx].name = e.target.value;
                  setLinks(newArray);
                }}
              />
              <Link
                type="text"
                name="link"
                placeholder="Ссылка на подкаст"
                value={link.link}
                onChange={(e) => {
                  const newArray = [...linkArray];
                  newArray[idx].link = e.target.value;
                  setLinks(newArray);
                }}
              />
              <Delete
                onClick={() => {
                  const newArray = [...linkArray];
                  const [deletion] = newArray.splice(idx, 1);
                  setLinks(newArray);
                  saveDeletion({ idx, ...deletion });
                }}
              />
            </InputContainer>
          </div>),
        )}
        {linkArray?.length > 0 && lastDeletion?.idx >= linkArray.length && restoreDeletion()}
        <AddButton
          onClick={() => setLinks([
            ...linkArray,
            { link: '', name: '' },
          ])}
        >Добавить</AddButton>
      </Container>
    );
  }
  return null;
};
