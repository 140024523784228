/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';
import Feed from '/ui/organism/Feed';
import Label from '/ui/atoms/Label';
import config from '/const/config';
import { formatTitlePost } from '/lib/formatString';

const PostFeedItem = (props) => {
  const {
    id,
    uuid,
    url,
    view_count,
    publish_date,
    snapshot_last_update,
    published,
    author,
    author_id,
    is_completed,
    type,
  } = props;
  const publishDate = publish_date || snapshot_last_update;

  const isOnline = ['online', 'chronicle'].indexOf(type) >= 0 && !is_completed && published;
  const timeColor = isOnline ? 'red' : null;
  const publishTime = format(new Date(publishDate), 'HH:mm', { locale: ruLocale });

  return (
    <Feed.Row>
      <Feed.Cell width={1}>
        <Label weight={400} color={timeColor} online={isOnline}>
          {publishTime}
        </Label>
      </Feed.Cell>

      <Feed.Cell width={9}>
        <Link to={`/post/${id}`} className="post-item">
          {formatTitlePost(props)}
        </Link>
      </Feed.Cell>

      <Feed.Cell width={2}>
        {!published
          ? (<a href={`${config.publicUrl}/preview/${uuid}`} target="_blank" rel="noopener noreferrer">
            Черновик
          </a>)
          : (<div className="item viewPublicPost">
            <a href={config.publicUrl + (url || '')} target="_blank" rel="noopener noreferrer">
              <i className="icon world" />
              На сайте
            </a>
          </div>)
        }
      </Feed.Cell>

      <Feed.Cell width={3}>
        <Link to={`/users/${author_id}`}> {author} </Link>
      </Feed.Cell>

      <Feed.Cell width={1}>
        { view_count.toLocaleString() }
      </Feed.Cell>
    </Feed.Row>
  );
};

PostFeedItem.propTypes = {
  id: PropTypes.number,
  title: PropTypes.any,
  view_count: PropTypes.any,
  publish_date: PropTypes.any,
  snapshot_last_update: PropTypes.any,
  createdAt: PropTypes.any
};

export default PostFeedItem;
