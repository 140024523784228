// @flow
import Editor from '../Editor';
import {
  hasLink
} from '../helpers';

export default (str: string): string => {
  switch (true) {
    case /twitter/.test(str): {
      return Editor.EMBED.TWITTER;
    }
    case /facebook/.test(str): {
      return Editor.EMBED.FACEBOOK;
    }
    case /instagram/.test(str): {
      return Editor.EMBED.INSTAGRAM;
    }
    case /youtube/.test(str): {
      return Editor.EMBED.YOUTUBE;
    }
    default: {
      if (hasLink(str)) return Editor.EMBED.LINK;
      return Editor.EMBED.CODE;
    }
  }
}