/* @flow */
import React from 'react';
import type { Node as ReactNode } from 'react';
import type { Inline } from 'slate';
import type { Editor as SlateEditor } from 'slate-react';
import { HintInline } from '../../atoms';
import { TextPopupWrapper } from '../../../../molecules';
import { HintForm } from '../index';
import type { State as HintFormState } from '../index';
import Editor from '../../../../Editor';

export type Props = {
  editor: SlateEditor,
  node: Inline,
  attributes: {},
  children: ReactNode[]
}

export type State = {
  isShowPopup: boolean,
  isHintChanged: boolean,
  isCreated: boolean,
  isDeleted: boolean
}

export default class HintWrapper extends React.Component<Props, State> {
  getHintIsCreated: (Inline) => boolean;

  constructor(props: Props) {
    super(props);
    this.getHintIsCreated = (node: Inline): boolean => node.data.get('isCreated');
    this.state = {
      isHintChanged: false,
      isShowPopup: false,
      isCreated: this.getHintIsCreated(this.props.node),
      isDeleted: false
    };
  }

  componentDidMount() {
    if (this.state.isCreated) {
      this.handleShowPopup(true);
      this.props.editor.deselect();
    }
  }

  componentWillUpdate(nextProps: Props, nextState: State) {
    const {
      node
    } = nextProps;

    const isShowPopupUpdated = this.state.isShowPopup !== nextState.isShowPopup;
    const isNodeUpdate = this.props.node !== node;

    if (isShowPopupUpdated && !nextState.isShowPopup) {
      // check exist body(if body not existed, delete Hint)
      const body = node.data.get('body');

      if (!body || body.trim().length === 0) {
        this.handleDelete(true);
      }
    }

    if (isNodeUpdate) {
      this.setState(() => ({
        isCreated: this.getHintIsCreated(node)
      }));
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const {
      node
    } = this.props;

    const {
      isHintChanged,
      isDeleted
    } = this.state;

    const isNodeUpdate = node !== prevProps.node;
    const isDeletedUpdate = isDeleted !== prevState.isDeleted;

    // if Hint changed, close popup and set isCreated state
    if (isHintChanged && isNodeUpdate) {
      this.handleShowPopup(false);
    }

    if(isDeletedUpdate && isDeleted) {
      this.deleteHint();
    }
  }

  changeHint = ({ text, body }: HintFormState) => {
    const {
      editor,
      node
    } = this.props;
    const firstText = node.getFirstText();

    if (!body || body.trim().length === 0) {
      this.handleShowPopup(false);
      return;
    }

    if (!text || text.trim().length === 0) {
      editor.removeNodeByKey(node.key);
      return;
    }

    editor.setTextByKey(firstText.key, text);
    editor.setNodeByKey(node.key, Editor.node.Hint({ body }));

    this.setState(() => ({
      isHintChanged: true
    }));
  };

  deleteHint = () => {
    const {
      node,
      editor
    } = this.props;

    editor.unwrapInlineByKey(node.key, 'hint');
  };

  handleDelete = (isDeleted: boolean) => {
    this.setState(() => ({
      isDeleted
    }));
  };

  onDeleteHint = () => {
    this.handleShowPopup(false);
    this.handleDelete(true);
  };

  handleShowPopup = (isShowed: boolean) => {
    this.setState(() => ({
      isShowPopup: isShowed
    }));
  };

  onShowPopup = () => {
    const { editor } = this.props;

    this.setState(() => ({
      isShowPopup: true,
      isHintChanged: false
    }));

    editor.deselect();
  };

  render() {
    const {
      isShowPopup,
      isCreated
    } = this.state;

    const {
      children,
      attributes,
      editor,
      node
    } = this.props;

    const body = node.data.get('body');

    return (
      <HintInline
        onClick={this.onShowPopup}
        {...attributes}
      >
        <TextPopupWrapper
          editor={editor}
          node={node}
          isShowPopup={isShowPopup}
          handleShowPopup={() => this.handleShowPopup(false)}
        >

          <HintForm
            body={body}
            text={node.text}
            childrens={children}
            isCreated={isCreated}
            onSubmit={this.changeHint}
            onDelete={this.onDeleteHint}
          />
        </TextPopupWrapper>
        {children}
      </HintInline>
    );
  }
}
