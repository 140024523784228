import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, change } from 'redux-form';
import Card from "./Card";
import syncForm from '../../lib/sync-form';
import syncInfo from '../../../common/sync-channel';
import {editCard} from '../../actions/actions';
const generateId = () => Date.now()+'_'+Math.round(Math.random()*100000);


@connect((state, props)=> {
    const selector = formValueSelector(props.form);
    return {
        cards: selector(state, "cards"),
        form: props.form
    }
}, (dispatch) => {
    return {
        dispatch
    }
})

export default class Cards extends PureComponent {
    constructor(props){
        super(props);
    }
    add() {
        const { cards   } = this.props;
        const card = {
            id: generateId(),
            title: '',
            slug: '',
            body: '',
            order: Object.keys(cards).length
        };
        this.props.dispatch(change(this.props.form, 'cards', {...cards, [card.id]: card}));
        this.props.dispatch(editCard({cardId: card.id, isEdited: true}));
    }

    remove(id){
        const { cards } = this.props;
        let clone = Object.assign({}, cards);
        this.recalculateOrder(clone, id);
        delete clone[id];
        this.props.dispatch(change(this.props.form, 'cards', {
            ...clone
        }));
    }
    recalculateOrder(clone, id){
        for(let key in clone){
            if (clone[key].order>clone[id].order){
                clone[key].order-=1;
            }
        }
    }
    sort(id1, id2){
        let cards= {...this.props.cards};
        let temp_order = cards[id1].order;
        cards[id1].order = cards[id2].order;
        cards[id2].order = temp_order;
        return cards;
    }
    render(){
        const {cards, form} = this.props;
        const count = Object.keys(cards).length;
        const cardsArr =  Object.values(cards);
        return (<div>
            {
                cardsArr
                    .sort((card_a, cards_b) => +card_a.order - +cards_b.order)
                    .map((card, index) => <Card
                        card={card}
                        count={count}
                        key = {'card' + card.id}
                        remove = {(id) => { this.remove(id); }}
                        form={'card' + card.id}
                        sortHandler={(id_old, dir) => {
                            let id_new = cardsArr
                                .filter((card) => {
                                    return (
                                        card.order == (cards[id_old].order + dir)
                                    );
                                })[0].id;

                            this.props.dispatch(change(
                                form,
                                'cards',
                                {
                                    ...this.sort(id_old, id_new)
                                }
                            ));
                        }}
                        updateHandler={(item) => {
                            this.props.dispatch(change(
                                form,
                                'cards',
                                {
                                    ...cards,
                                    [item.id]: item
                                }
                            ));
                        }}
                    />)
            }
            <div className="ui segment">
				<span className="ui button blue addQuestion"
                      onClick={(e) => {this.add();}}>Добавить карточку
				</span>
            </div>
        </div>)
    }
}