import React, { useCallback, useState, useEffect } from 'react';
import Input from '../../ui/atoms/Input';
import Dropzone from './components/Dropzone';
import {
  FormWrapper,
} from './components/Wrappers';
import { Upload } from './components/icons';
import SingleFilePreview from './components/SingleFilePreview';
import * as uploadImgUtils from '../../lib/upload-form-image';

const generateId = () => `id_${Date.now()}${Math.round(Math.random() * 100000)}`;

const FileUploader = ({
  customDir = false,
  customDirPlaceholder = 'Указать путь',
  customPath = 'custom/e/',
  handler = () => null,
  file,
  input,
  onChange,
  multipleLoading,
  accepted,
  editable = true
}) => {
  const [files, setFiles] = useState([file]);
  const [isLoading, setIsLoading] = useState(false);
  // save id's
  const [inputId] = useState(generateId());
  const [formId] = useState(generateId());

  const onDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // props.onDelete()
    handler(null);
    setFiles([null]);
  };

  const onEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    document.getElementById(inputId).click();
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles, event) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        setIsLoading(true);
        const formElem = document.getElementById(formId);
        const formData = new FormData(formElem);

        if (event.type === 'drop') {
          acceptedFiles.forEach((f) => {
            formData.append('file', f, f.name);
          });
        }

        uploadImgUtils.uploadFileNew(formData).then((res) => {
          const value = res.data.original?.s3_path;
          handler(value, 'success');
          if (multipleLoading) {
            setFiles((prevState) => [...prevState, value]);
          } else {
            setFiles(() => [value]);
          }
          setIsLoading(false);
        }).catch((err) => {
          // eslint-disable-next-line no-console
          console.error('cant upload file', err);
        });
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);

  useEffect(() => () => {
    files.forEach((file) => URL.revokeObjectURL(file));
    if (typeof onChange === 'function') {
      onChange(files);
    }
  }, [files]);

  useEffect(() => {
    if (!multipleLoading && file) {
      setFiles([file]);
    }
  }, [file]);

  useEffect(() => {
    if (!multipleLoading && file) {
      setFiles([file]);
    }
  }, [file]);

  const src = input?.value || files[0];
  const previewSrc = `${src && !(src?.includes('http')) && window.SERVER_SIDE_VARS.s3_url || ''}${src}`;
  
  return (
    <FormWrapper id={formId}>
      <Dropzone
        onDrop={onDrop}
        id={inputId}
        maxFiles={multipleLoading ? null : 1}
        isEmpty={!multipleLoading && !src}
        accepted={accepted}
      >
        {isLoading && <i className="ui tiny active loader" />}
        {!multipleLoading && src && (
          <SingleFilePreview
            src={previewSrc}
            onDelete={onDelete}
            onEdit={onEdit}
            isLoading={isLoading}
            editable={editable}
          />
        )}
        {!src && !multipleLoading && (
        <>
          <Upload />
          <p className="dropzone-content">
            Перенесите файл сюда или кликните, чтобы загрузить файл.
          </p>
        </>
        )}
        {customDir && (
          <Input
            name="path"
            style={{ visibility: 'hidden', height: 0 }}
            placeholder={customDirPlaceholder}
            value={customPath}
          />
        )}
      </Dropzone>
    </FormWrapper>
  );
};

export default FileUploader;
