import React from 'react';

const Result = ({ result, siteBaseURL, imageBaseURL }) => {
  const {
    title,
    url,
    image,
    body,
    // eslint-disable-next-line camelcase
    publication_date,
    section,
  } = result;
  return (
    <li className="sui-result">
      <div className="sui-result__header" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
        {title && !url && (
        <span
          className="sui-result__title"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: title.snippet }}
        />
        )}
        {title && url && (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <a
          className="sui-result__title sui-result__title-link"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: title.snippet }}
          href={`${siteBaseURL}${url.raw}`}
          target="_blank"
          rel="noopener noreferrer"
          // onClick={onClickLink}
        />
        )}
        <p>
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: new Date(publication_date.raw).toLocaleDateString('ru-RU') }}
          />
          {' '}
          <span
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: section.raw }}
          />
        </p>
      </div>

      <div className="sui-result__body">
        {image.raw && image.raw !== 'null' && (
        <div className="sui-result__image">
          <img src={`${imageBaseURL}${image.raw}`} alt="" />
        </div>
        )}
        <div className="sui-result__details">
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: body.snippet }}
          />
        </div>
      </div>
    </li>
  );
};

export default Result;
