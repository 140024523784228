import React from 'react';
import PullQuoteController from './components/PullQuoteController';
import {
  nodeTypes,
  objectTypes,
} from '../../consts';
import { excludeSummaryNode } from '../../helpers';

// This function renders the data in a node.
// The function returns an object containing the children,
// attributes, and title of the node.
const extractAttributes = (attrs) => {
  const { children, attributes, node } = attrs;
  const { data } = node;

  return {
    children: excludeSummaryNode(children),
    attributes,
    title: data.get('title'),
  };
};

export const CutPullQuoteNode = {
  type: nodeTypes.CUT_PULL_QUOTE,
  object: objectTypes.BLOCK,

  create(attrs) {
    const { title, editor } = attrs;
    return {
      type: this.type,
      object: this.object,
      nodes: [
        editor.node.Paragraph(),
      ],
      data: {
        title,
      },
    };
  },

  schema: {
    nodes: [
      {
        type: nodeTypes.SUMMARY,
        min: 1,
      },
    ],
  },

  htmlRender(attrs) {
    const { children, attributes, title } = extractAttributes(attrs);
    return (
      <details
        {...attributes}
        className="mz-publish-cut-pull-quote"
      >
        <summary>
          {title}
        </summary>
        {children}
      </details>
    );
  },

  editorRender(attrs) {
    const { children, attributes, title } = extractAttributes(attrs);
    return (
      <PullQuoteController {...attrs}>
        <div className="mz-publish-cut-pullquote">
          <h3 {...attributes}>{title}</h3>
          {children}
        </div>
      </PullQuoteController>
    );
  },
};

export default { CutPullQuoteNode };
