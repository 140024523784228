/* @flow */
import React from 'react';
import type { Node as ReactNode } from 'react';
import type { Inline } from 'slate';
import type { Editor as SlateEditor } from 'slate-react';
import {
  PreviewLinkContainer,
  PreviewLinkController,
  PreviewLinkUrl,
  PreviewLinkButton,
} from '../../atoms';
import {
  TextPopupWrapper,
} from '../../../../molecules';
import {
  LinkForm,
} from '../index';
import type { LinkFormState } from '../index';
import Editor from '../../../../Editor';

export type LinkWrapperProps = {
  editor: SlateEditor,
  node: Inline,
  attributes: {},
  children: ReactNode[]
}

export type LinkWrapperState = {
  isShowPopup: boolean,
  isShowPreview: boolean,
  isLinkChanged: boolean
}

export default class LinkWrapper extends React.Component<LinkWrapperProps, LinkWrapperState> {
  state = {
    isLinkChanged: false,
    isShowPopup: false,
    isShowPreview: false,
  };

  componentDidMount() {
    const isCreated = this.props.node.data.get('isCreated');
    if (isCreated) {
      this.handleShowPopup(true);
      this.props.editor.deselect();
    }
  }

  componentWillUpdate(nextProps: LinkWrapperProps, nextState: LinkWrapperState) {
    const {
      node,
    } = nextProps;

    const isShowPopupUpdated = this.state.isShowPopup !== nextState.isShowPopup;

    if (isShowPopupUpdated && !nextState.isShowPopup) {
      // check exist href(if href not existed, delete link)
      const href = node.data.get('href');

      if (!href || href.trim().length === 0) {
        this.deleteLink();
      }
    }
  }

  componentDidUpdate(prevProps: LinkWrapperProps) {
    const {
      node,
    } = this.props;

    const {
      isLinkChanged,
    } = this.state;

    const isNodeUpdate = node !== prevProps.node;

    // if link changed, close popup
    if (isLinkChanged && isNodeUpdate) {
      this.handleShowPopup(false);
    }
  }

  changeLink = ({ text, href }: LinkFormState) => {
    const {
      editor,
      node,
    } = this.props;
    const firstText = node.getFirstText();

    if (!href || href.trim().length === 0) {
      this.handleShowPopup(false);
      return;
    }

    if (!text || text.trim().length === 0) {
      editor.removeNodeByKey(node.key);
      return;
    }

    editor.setTextByKey(firstText.key, text);
    editor.setNodeByKey(node.key, Editor.node.Link({ href }));

    this.setState(() => ({
      isLinkChanged: true,
    }));
  };

  deleteLink = () => {
    const {
      node,
      editor,
    } = this.props;

    editor.unwrapInlineByKey(node.key, 'a');
  };

  onDeleteLink = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    this.deleteLink();
    this.handleShowPopup(false);
  };

  handleShowPopup = (isShowed: boolean) => {
    this.setState(() => ({
      isShowPopup: isShowed,
    }));
  };

  onShowPreview = () => {
    const { editor } = this.props;

    this.setState(() => ({
      isShowPopup: true,
      isShowPreview: true,
      isLinkChanged: false,
    }));

    editor.deselect();
  };

  onClosePreview = (e: SyntheticEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState(() => ({
      isShowPreview: false,
    }));
  };

  render() {
    const {
      isShowPopup,
      isShowPreview,
    } = this.state;

    const {
      children,
      attributes,
      editor,
      node,
    } = this.props;

    const href = node.data.get('href');

    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer nofollow"
        onClick={this.onShowPreview}
        {...attributes}
      >
        <TextPopupWrapper
          editor={editor}
          node={node}
          isShowPopup={isShowPopup}
          handleShowPopup={this.handleShowPopup}
        >
          {isShowPreview ? (
            <PreviewLinkContainer>
              <PreviewLinkUrl
                target="_blank"
                href={href}
              >{href}
              </PreviewLinkUrl>
              <PreviewLinkController>
                <PreviewLinkButton onClick={this.onClosePreview}>Изменить</PreviewLinkButton>
                <PreviewLinkButton
                  onClick={this.onDeleteLink}
                  color={'#fd6073'}
                >
                  Удалить
                </PreviewLinkButton>
              </PreviewLinkController>
            </PreviewLinkContainer>
          ) : (
            <LinkForm
              href={href}
              text={node.text}
              onSubmit={this.changeLink}
              childrens={children}
            />
          )}
        </TextPopupWrapper>
        {children}
      </a>
    );
  }
}
