function authors(state = {}, action){
	switch(action.type){
		case "SEARCH_AUTHORS_POST":
			return {
				...state,
				search_authors: action.authors
			}
		default: 
			return  state;
	}
}

export default authors;