/* @flow */

import * as React from 'react';
import { connect } from 'react-redux';
import * as actions from '../../actions/actions';
import Report from './Report';

type Props = {
  reports: Object,
  fetchReports: () => void,
};

@connect(
  (state, props) => ({
    reports: state.reports || {},
  }),
  dispatch => ({
    fetchReports: () => {
      dispatch(actions.fetchReports());
    }
  })
)
export default class InfoList extends React.Component<Props> {
  componentDidMount() {
    this.props.fetchReports();
  }

  render() {
    const { reports } = this.props;
    return (
      <div className="ui container">
        <div className="ui vertically padded grid">
          <div className="column">

            <div className="ui dividing red header">
              Ошибки
            </div>

            <table className="ui very basic fixed celled table">
              <thead className="report">
                <tr>
                  <td className="report__error">
                    Ошибка
                  </td>

                  <td className="report__comment">
                    Коммент
                  </td>

                  <td className="report__link">
                    Ссылка на сайте
                  </td>

                  <td className="report__link">
                    Ссылка в админке
                  </td>

                  <td className="report__execute">
                    Статус
                  </td>
                </tr>
              </thead>
              <tbody>

                {Object.keys(reports).map((k, index) => (
                  <Report
                    key={index + reports[k].id}
                    item={reports[k]}
                  />
                ))}
              </tbody>
            </table>
            <ul className="pager ui pagination menu">
              <li className="pager__page item">
                1
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
};
