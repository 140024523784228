// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import type { OnDragEndResponder } from 'react-beautiful-dnd';
import {
  Label,
  Paragraph,
  Icon,
} from '/ui/atoms';
import {
  Button,
} from '/ui/molecules';
import {
  DnDList,
} from '/ui/organism'
import { types } from '/utils/constants';
import { updateRelatedEntities } from '/actions/actions';
import { syncCollection } from '/lib/sync-form';
import {
  Container,
  Item,
  TitleContainer,
  TypeContainer,
} from './styles';

type Props = {|
  syncedId: number,
  syncedEntity: string,
  items: any,
  updateRelatedEntities: updateRelatedEntities,
  onDelete: (entityId: number) => void,
|}

type State = {|
  items: any,
|}

@connect(
  (state, props) => ({
    syncedEntity: props.entity,
    syncedId: props.id,
  }),
  dispatch => ({
    updateRelatedEntities: (props) => {
      dispatch(updateRelatedEntities(props));
    }
  })
)
@syncCollection()
class RelatedWidgetList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      items: props.items,
    };
  }

  updateRelatedEntities = (list: any) => {
    this.props.updateRelatedEntities({
      list,
      entity: this.props.syncedEntity,
      entityId: this.props.syncedId,
    });
  };

  componentWillUpdate(nextProps: Props) {
    if (nextProps.items !== this.props.items) {
      this.setState(() => ({
        items: this.props.items,
      }));
    }
  }

  onDragEnd = (value: OnDragEndResponder) => {
    const {
      destination,
      source,
    } = value;

    if (!destination || !source) return;

    const reorderItems = R
      .move(source.index, destination.index, this.props.items)
      .map((item, index) => ({ ...item, id: item.id, sort_id: index, }));

    this.setState(() => ({
      items: reorderItems,
    }));

    this.props.onDrug && this.props.onDrug(reorderItems);

    this.updateRelatedEntities(reorderItems);
  };

  render() {
    const {
      items,
    } = this.state;

    const {
      onDelete,
    } = this.props;

    const itemsList = items.map(item => ({
      id: item.id,
      component: (
        <Item>
          <TypeContainer>
            <Label
              color="gray"
            >
              {types[item.type] ? types[item.type] : 'Материал'}
            </Label>
          </TypeContainer>
          <TitleContainer>
            <Paragraph
              color="blue"
            >
              {item.title}
            </Paragraph>
          </TitleContainer>
          <Button
            width="24px"
            height="24px"
            backgroundColor="white"
            onClick={() => onDelete(item.id)}
            icon={
              <Icon
                type="crossFill"
                width="12px"
                height="12px"
              />
            }
          />
        </Item>
      ),
    }));

    return (
      <Container>
        <DnDList
          onDragEnd={this.onDragEnd}
          listId={this.props.syncedEntity}
          items={itemsList}
        />
      </Container>
    );
  }
}

export default RelatedWidgetList;
