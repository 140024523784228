import React, { Component, PureComponent } from 'react';
import './WebPushButton.styl';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import Modal from '../Modal/Modal';
import { send_webpush } from '../../actions/actions.js';
import config from '../../const/config';

const renderField = ({ input, label, type, name, meta: { touched, error, warning } }) => {
  const { value } = input;
  return (
    <div>
      <label>{label}</label>
      <div className="webpush__text-wrapper">
        {type == 'text' && (
          <span className="webpush__text-counter">{`Знаков:${value.length}`}</span>
        )}
        <input {...input} placeholder={label} type={type} />
        {(error && (
          <div className="ui negative message" style={{ display: 'block' }}>
            {error}
          </div>
        )) ||
          (warning && (
            <div className="ui warning message" style={{ display: 'block' }}>
              {warning}
            </div>
          ))}
      </div>
    </div>
  );
};

const checkTitle = (value) => {
  let warning;
  if (value && value.length > 20) {
    warning = 'Длина строки в заголовке превышает 20 символов';
  }
  return warning;
};

const checkDescription = (value) => {
  let warning;
  if (!value) {
    warning = 'Описание желательно';
  }
  return warning;
};

const validate = (values) => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Обязательное поле';
  }
  if (!values.ttl) {
    errors.ttl = 'Обязательное поле';
  }
  if (!values.description) {
    errors.description = 'Обязательное поле';
  }
  return errors;
};

@connect(
  (state, props) => {
    const selector = formValueSelector('webpush');
    let title,
      description;
    switch (props.type) {
      case 'news':
        title = 'Новость';
        description = props.title;
        break;
      default:
        title = props.title;
        description = props.announce;
        break;
    }
    return {
      initialValues: {
        title,
        description,
        ttl: '3600',
        url: props.urls[0].canonical,
        image: config.regionData.faviconUrl,
      },
      title: selector(state, 'title'),
      description: selector(state, 'description'),
      ttl: selector(state, 'ttl'),
      url: selector(state, 'url'),
      image: config.regionData.faviconUrl,
      enableReinitialize: true,
    };
  },
  dispatch => ({
    dispatch,
  }),
)
@reduxForm({
  form: 'webpush',
  validate,
})
class WebPushForm extends PureComponent {
  constructor(props) {
    super(props);
    this.push = this.push.bind(this);
  }

  push(values) {
    const { title, description, url, image, ttl } = values;
    console.log(values);
    this.props.dispatch(
      send_webpush({
        title,
        description,
        url,
        image,
        ttl,
      }),
    );
    this.props.handleClose();
  }

  render() {
    const { handleSubmit, pristine, submitting, handleOpen, handleClose, isOpen } = this.props;
    return (
      <Modal
        handleOpen={handleOpen}
        handleClose={handleClose}
        isOpen={isOpen}
      >
        <div className="web-push__popup">
          <div className="react_modal__header">Веб-пуши</div>
          <div className="react_modal__content">
            <form className="ui form web-push__form" onSubmit={handleSubmit(this.push)}>
              <div className="field">
                <label>Заголовок</label>
                {/* <input type="text" name="title"/> */}
                <Field name="title" type="text" component={renderField} warn={checkTitle} />
              </div>
              <div className="field">
                <label>Описание</label>
                <Field
                  type="text"
                  name="description"
                  component={renderField}
                  warn={checkDescription}
                />
              </div>
              <div className="field">
                <label>TTL(время актуальности)</label>
                <div className="inline fields">
                  <div className="field">
                    <div className="ui radio checkbox">
                      <Field
                        id="ttl-1"
                        type="radio"
                        name="ttl"
                        value="3600"
                        component="input"
                        checked={this.props.ttl === '3600'}
                      />
                      <label htmlFor="ttl-1">1 час</label>
                    </div>
                  </div>
                  <div className="field">
                    <div className="ui radio checkbox">
                      <Field id="ttl-2" type="radio" name="ttl" value="10800" component="input" />
                      <label htmlFor="ttl-2">3 часа</label>
                    </div>
                  </div>
                  <div className="field">
                    <div className="ui radio checkbox">
                      <Field id="ttl-3" type="radio" name="ttl" value="86400" component="input" />
                      <label htmlFor="ttl-3">24 часа</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="actions">
                <button type="button" onClick={handleClose} className="ui black deny button" disabled={pristine || submitting}>
                  Отменить
                </button>
                {this.props.valid ? (
                  <button
                    className="ui positive right labeled icon button web-push__button"
                    onClick={handleSubmit(this.push)}
                  >
                    Отправить
                    <i className="checkmark icon" />
                  </button>
                ) : null}
              </div>
            </form>
          </div>
        </div>
      </Modal>
    );
  }
}

export default class WebPushButton extends Component {

  state = {
    isOpen: false
  }

  handleOpen = () => {
    this.setState(() => ({ isOpen: true }));
  };

  handleClose = () => {
    this.setState(() => ({ isOpen: false }));
  };

  render() {
    return (
      <div>
        <button
          className="ui secondary button fluid web-push"
          onClick={this.handleOpen}
        >
          Web Push
        </button>
        <WebPushForm
          handleOpen={this.handleOpen}
          handleClose={this.handleClose}
          isOpen={this.state.isOpen}
          {...this.props}
        />
      </div>
    );
  }
}
