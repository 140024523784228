import React from 'react';
import styled from 'styled-components';
import {
  normalizeImgSrc,
} from '../../../../helpers';

const TextPullQuoteWrapper = styled.aside`
	padding: 0px 30px;
	border-left: 1px solid #e5e5e5;
	margin: 40px 0px;

  .mz-publish__list {
    font-size: 14px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    margin: 40px 0;
    padding: 0;
  }

  .mz-publish__list a {
    color: #EF2530;
  }

  .mz-publish__list a:hover {
    color: #d7212b;
  }

  .mz-publish__list-item {
    margin: 0 0 8px 16px;
  }

  .mz-publish__list-item:last-child {
    margin-bottom: 0;
  }

  .mz-publish__list b,
  .mz-publish__list strong {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
  }

  .mz-publish-text-pull-quote_side-right {
    width: 100%;
    float: right;
    margin-left: 30px;
    margin-right: 30px;
    }

  .mz-publish-text-pull-quote_side {
    float: left;
    margin-right: 30px;
    width: 100%;
  }
`;

const Image = styled.img`
  max-width: 100%;
  margin-bottom: 20px;
`;

const TextPullQuote = (props) => {
  const { children, attributes, node } = props;
  const { data } = node;
  const imgSrc = data.get('imgSrc');
  const viewType = data.get('viewType');
  const alt = data.get('alt');

  const normalizeImg = normalizeImgSrc(imgSrc);
  return (
    <TextPullQuoteWrapper
      {...attributes}
      className={`mz-publish-text-pull-quote ${viewType ? `mz-publish-text-pull-quote_${viewType}` : ''}`}
    >
      <>
        {normalizeImg ?
          <Image
            className="mz-publish-text-pull-quote__image"
            src={normalizeImg}
            alt={alt}
          />
          : null}
        {children}
      </>
    </TextPullQuoteWrapper>

  );
};

export default TextPullQuote;
