import React from 'react';
import config from '/const/config';

const ContextPullQuote = (props) => {
  const { node, attributes } = props;
  const { data } = node;

  const imgSrc = data.get('imgSrc');
  const alt = data.get('alt');
  const context = data.get('context');
  const viewType = data.get('viewType');

  if (!context) return null;

  // If context.url does not start with http, add the publicUrl
  const href = context.url && !context.url.startsWith('http')
    ? config.publicUrl + context.url
    : context.url;

  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer nofollow"
      {...attributes}
    >
      <aside className={`mz-publish-context-cite ${viewType ? `mz-publish-context-cite_${viewType}` : ''}`}>
        {imgSrc && (
          <img
            className="mz-publish-context-cite__image"
            src={imgSrc}
            alt={alt}
          />
        )}
        <div className="mz-publish-context-cite__wrapper.clearfix">
          <span className="mz-publish-context-cite__text_meta">{context.type}</span>
          <span className="mz-publish-context-cite__text">{context.title}</span>
        </div>
      </aside>
    </a>
  );
};


export default ContextPullQuote;
