import React, { Component } from 'react';
import {
  ToolTip,
} from '/components/SlateRichText/molecules';
import {
  TopBarButton,
} from '../../atoms';
import {
  Icons,
  ModuleContainer,
} from '../../../../atoms';
import {
  PullQuoteModal,
} from '../../../PullQuote';
import Editor from '../../../../Editor';

const {
  CreatePullQuote,
} = Icons;

class PullQuoteToolModule extends Component {
  state = {
    isOpenModal: false,
  };

  handleModal = () => {
    this.setState(prev => ({
      isOpenModal: !prev.isOpenModal,
    }));
  };

  closeModal = () => {
    this.setState(() => ({
      isOpenModal: false,
    }));
  };

  onSubmit = (props) => {
    const { editor } = this.props;
    const { viewType, imgSrc, type, alt, context, multiContext, contextTitle, source, quoteTitle, text, quoteText, title, bannerId } = props;
    const { value } = editor;
    const { focusBlock } = value;

    let PullQuote = null;

    switch (type) {
      case Editor.NODE.SIMPLE_PULL_QUOTE:
        PullQuote = Editor.node.PullQuote({
          viewType,
          imgSrc,
          alt,
        });
        break;
      case Editor.NODE.DOCUMENT_PULL_QUOTE:
        PullQuote = Editor.node.DocsPullQuote({
          viewType,
          imgSrc,
          alt,
        });
        break;
      case Editor.NODE.TEXT_PULL_QUOTE:
          PullQuote = Editor.node.TextPullQuote({
            quoteTitle,
            quoteText,
            viewType,
            imgSrc,
            alt,
            type,
            text,
          });
          break;
      case Editor.NODE.CONTEXT_PULL_QUOTE:
        PullQuote = Editor.node.ContextPullQuote({
          viewType,
          context,
          imgSrc,
          alt,
        });
        break;
      case Editor.NODE.MULTICONTEXT_PULL_QUOTE:
        PullQuote = Editor.node.MultiContextPullQuote({
          multiContext,
          contextTitle,
        });
        break;
      case Editor.NODE.DONATE:
        PullQuote = Editor.node.Donate({ bannerId });
        break;
      case Editor.NODE.NEWSLETTER:
        PullQuote = Editor.node.Newsletter({});
        break;
      case Editor.NODE.QUOTE:
        PullQuote = Editor.node.Quote({
          imgSrc,
          alt,
          source,
        });
        break;
      case Editor.NODE.CUT_PULL_QUOTE:
        PullQuote = Editor.node.CutPullQuote({ title });
        break;
      default:
        return;
    }

    if (!focusBlock) return;

    if (focusBlock.text.length === 0) {
      editor.replaceNodeByKey(focusBlock.key, PullQuote);
    } else {
      editor.insertBlock(PullQuote);
    }
  };

  render() {
    const { editor, enabled } = this.props;
    const { isOpenModal } = this.state;
    const type = Editor.NODE.SIMPLE_PULL_QUOTE;
    return (
      <ModuleContainer>
        {isOpenModal ? (
          <PullQuoteModal
            closeModal={this.closeModal}
            isOpen={isOpenModal}
            onSubmit={this.onSubmit}
            submitText={'Вставка'}
            title={'Вставить врез:'}
            editor={editor}
          />) : null
        }
        <TopBarButton
          id={Editor.EDITOR_IDS.PULLQUOTE + editor.props.editorId}
          onClick={enabled ? this.handleModal : null}
          data-for={type}
          data-tip
          enabled={enabled}
        >
          <CreatePullQuote />
          <ToolTip
            id={type}
            tooltip={Editor.TOOLTIPS.PULLQUOTE}
          />
        </TopBarButton>
      </ModuleContainer>
    );
  }
}

export default PullQuoteToolModule;

