import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, Field, change } from 'redux-form';
import syncForm from '/lib/sync-form';
import Label from '/ui/atoms/Label';
import syncInfo from '/../common/sync-channel';

const FormField = ({ label, name, component = 'input', type = 'text' }) => (
  <div className="field">
    <Label>
      {label}
    </Label>
    <Field
      name={name}
      type={type}
      component={component}
    />
  </div>);

@connect(
  (state, ownProps) => {
    const formName = syncInfo.formFunc('advertCampaign', ownProps.params.id);
    const selector = formValueSelector(formName);

    return {
      title: selector(state, 'title'),
      weight: selector(state, 'weight'),
      shows_limit: selector(state, 'shows_limit'),
      is_published: selector(state, 'is_published'),
      is_archived: selector(state, 'is_archived'),
      created_at: selector(state, 'created_at'),
      updated_at: selector(state, 'updated_at'),
      is_external_advert: selector(state, 'is_external_advert'),

      syncedEntity: 'advertCampaign',
      syncedId: ownProps.params.id,
    };
  },
)
@syncForm()
@reduxForm()
class ExternalCampaignForm extends React.Component {
  toggleArchived = (e) => {
    e.preventDefault();
    const { dispatch, form } = this.props;
    dispatch(change(form, 'is_archived', !this.props.is_archived));
  };

  toggleActive = (e) => {
    e.preventDefault();
    const { dispatch, form } = this.props;
    dispatch(change(form, 'is_published', !this.props.is_published));
  };

  disableSubmitButton = () => {
    if (this.props.is_published) {
      return false;
    }

    return this.props.is_archived ||
      !this.props.title ||
      this.props.weight < 1;
  };

  render() {
    const { formValues, form } = this.props;
    if (!formValues || !formValues.id) return null;
    return (
      <form id="EditorForm" className="ui form">
        <div className="ui container basic segment">
          <div className="ui vertically padded grid">
            <div className="ui dividing large header eleven wide column">
              Сторонняя кампания
            </div>
            <div className="ui two wide column">
              <button
                type="button"
                onClick={this.toggleArchived}
                disabled={this.props.is_published}
                className="ui button secondary"
              >
                {this.props.is_archived ? 'Восстановить' : 'Архивировать'}
              </button>
            </div>
            <div className="ui two wide column">
              <button
                type="button"
                style={{ marginLeft: '10px' }}
                onClick={this.toggleActive}
                disabled={this.disableSubmitButton()}
                className="ui button primary"
              >
                {this.props.is_published ? 'Выключить' : 'Включить'}
              </button>
            </div>
          </div>
          <fieldset style={{ border: 'none' }} disabled={this.props.is_published}>
            <div className="ui vertically padded grid">
              <div className="sixteen wide column">
                <FormField label="Заголовок" name="title" />
                <FormField label="Весы/приоритет" name="weight" type="number" />
                <FormField label="Лимит показов" name="shows_limit" type="number" />
              </div>
            </div>
          </fieldset>
        </div>
      </form>
    );
  }
}

export default ExternalCampaignForm;
