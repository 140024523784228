/* eslint-disable no-confusing-arrow */
import styled from 'react-emotion';

import Group from './Group';
import Item from './Item';
import Row from './Row';
import Cell from './Cell';

const Feed = styled('div')`
  display: flex;
  flex-direction: column;
`;

Feed.Group = Group;
Feed.Item = Item;
Feed.Row = Row;
Feed.Cell = Cell;

export default Feed;
