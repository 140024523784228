import React, { Component } from 'react';
import {
  callInstagramProcess
} from '../../../../helpers';

class EmbedWrapper extends Component {
  componentDidMount() {
    this.instagramProcess();
  }

  instagramProcess = () => {
    const { node } = this.props;
    const url = node.data.get('url');

    callInstagramProcess(url);
  };

  render() {
    return (
      <React.Fragment>
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default EmbedWrapper;
