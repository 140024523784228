import React, { Component } from 'react';
import styled from 'styled-components';
import {
  ModalCancel,
  ModalController,
  ModalSubmit,
} from '../../../../atoms';

const Image = styled.img`
  max-width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;
`;

class DonateForm extends Component {
  constructor(props) {
    super(props);
    const { node } = props;
  }

  onSubmit = (event) => {
    const {
      onSubmit,
      onClose,
      type,
    } = this.props;
    event.persist();
    event.preventDefault();

    onSubmit({ type });
    onClose();
  };

  render() {

    return (
      <form onSubmit={this.onSubmit}>
        <Image src="assets/donate-banner.png" />
        <ModalController>
          <ModalCancel onClick={this.props.onClose}>Отменить</ModalCancel>
            <ModalSubmit
              type="submit"
              value="Применить"
            />
        </ModalController>
      </form>
    );
  }
}

export default DonateForm;
