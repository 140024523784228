import { createSelector } from 'reselect';
import splitPostsByDateAndStatus from '/utils/splitPosts';

const sortItemsDates = groupedItems => Object
  .keys(groupedItems)
  .map(x => +x)
  .sort((a, b) => {
    if (a === 0) {
      return -1;
    } else if (b === 0) {
      return 1;
    }
    return b - a;
  });

export default ({ groupByFunc, listSelector }) => {
  const groupedPostsSelector = createSelector(
    state => listSelector(state),
    posts => splitPostsByDateAndStatus(posts, groupByFunc),
  );

  const groupedPostsDatesSelector = createSelector(
    groupedPostsSelector,
    sortItemsDates,
  );

  return {
    groupedPostsSelector,
    groupedPostsDatesSelector,
  };
};
