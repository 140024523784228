import React from 'react';
import {
  markTypes,
  nodeTypes,
  objectTypes,
} from '../../consts';

const HeadingThreeNode = {
  type: nodeTypes.HEADING_THREE,
  object: objectTypes.BLOCK,
  isTag: true,

  create() {
    return {
      type: this.type,
      object: this.object,
      nodes: [
        {
          object: objectTypes.TEXT,
        },
      ],
    };
  },

  schema: {
    marks: [{ type: markTypes.ITALIC }],
  },

  htmlRender({ attributes, children }) {
    return <h3 {...attributes}>{children}</h3>;
  },
};

export default HeadingThreeNode;
