import React, { Component } from 'react';
import {
  Input,
  InputError,
  InputTitle,
} from '../../atoms';
import {
  FAILED,
  LOADING,
  UPLOADING,
  SUCCESS,
  ImageView,
} from '../../../UploadImage/ImageView';
import { SingleFileUploader } from '/features/FileUploader';

class ImageField extends Component {
  onUploadImage = (value, status) => {
    const { error, src, onUpload } = this.props;
    const data = {
      isLoading: false,
      error,
      src,
    };

    switch (true) {
      case status === UPLOADING || status === LOADING:
        data.isLoading = true;
        data.error = '';
        break;
      case status === FAILED:
        data.error = 'Ошибка при загрузке изображения';
        break;
      case status === SUCCESS:
        data.src = `${window.SERVER_SIDE_VARS.s3_url}${value}`;
        data.error = '';
        break;
      default:
        data.error = '';
        break;
    }

    onUpload(data);
  };

  render() {
    const {
      src,
      alt,
      error,
      onChangeAlt,
    } = this.props;

    return (
      <div>
        <InputTitle>
          Выберите файл для изображения, если оно нужно
        </InputTitle>
        <SingleFileUploader
          file={src}
          handler={this.onUploadImage}
          accepted="image/*"
        />
        {error ? (<InputError>{error}</InputError>) : null}
        {src ? (
          <div>
            <InputTitle>
              Альтернативный текст
            </InputTitle>
            <Input
              type="text"
              value={alt}
              onChange={onChangeAlt}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ImageField;
