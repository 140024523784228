import * as actions from '../actions/actions';

export const feedReducer = (state = [], action = {}) => {
    switch (action.type) {
        case actions.FETCH_POSTS:
            return [];

        case actions.FETCH_POSTS_SUCCESS:
            return action.result.map((post) => {
                return {
                    ...post,
                    publish_date: (new Date(post.publish_date)).getTime()
                };
            });
        default:
            return state;
    }
};

export default feedReducer;