import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import {
  callInstagramProcess,
  youTubeID,
  isYouTube,
  fetchEmbed,
  hasLink
} from '../../../../helpers';
import {
  PreviewContainer,
  PreviewContent,
  PreviewError
} from '../../../../atoms';

class Preview extends Component {
  state = {
    previewValue: null,
    isValueInvalid: false
  };

  async componentDidMount() {
    await this.updatePreview();
    callInstagramProcess(this.props.value);
  }

  componentWillReceiveProps() {
    this.debouncedUpdatePreview();
  }

  debouncedUpdatePreview = debounce(async () => {
    await this.updatePreview();
  }, 1000, { maxWait: 3000 });


  validateEmbed = (previewEmbed) => {
    this.setState(() => ({
      isValueInvalid: previewEmbed instanceof Error
    }));
  };

  updatePreview = async () => {
    try {
      const {
        value
      } = this.props;

      let previewHtml;

      switch (true) {
        case hasLink(value):
          previewHtml = await fetchEmbed(value);
          break;
        case isYouTube(value):
          previewHtml = (
            `<iframe
                allowFullScreen=""
                frameBorder="0"
                height="100%"
                width="100%"
                src='//www.youtube.com/embed/${youTubeID(value)}'
                style={{position: "absolute", top: 0 ,left: 0, width: "100%", height: "100%"}}
              > 
              </iframe>`
          );
          break;
        default:
          previewHtml = value;
      }

      this.validateEmbed(previewHtml);

      this.setState(() => ({
        previewValue: previewHtml,
      }));
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    const {
      previewValue,
      isValueInvalid
    } = this.state;

    if (!previewValue) return null;

    if (isValueInvalid) {
      return (
        <PreviewContainer>
          <PreviewError>
            Неправильная ссылка, попробуйте вставить еще раз
          </PreviewError>
        </PreviewContainer>
      );
    }

    return (
      <PreviewContainer>
        <PreviewContent
          dangerouslySetInnerHTML={{ __html: this.state.previewValue }}
        />
      </PreviewContainer>
    );
  }
}

export default Preview;
