import React from 'react';

const Embed = (props) => {
  const { node, attributes, className } = props;

  const html = node.data.get('html');

  return (
    <div
      data-html={html}
      className={className || ''}
      dangerouslySetInnerHTML={{ __html: html }}
      {...attributes}
    />
  );
};

export default Embed;