// @ts-nocheck
/* eslint-disable no-alert */
/* eslint-disable import/prefer-default-export */
// const checkExtr = require('../../common/extr');
// const config = require('../const/config');
// export const confirmExtr = (values) => {
//   // if (config.REGION === 'ru' && values) {
//   //   const text = `${values.title} ${values.lead} ${values.announce} ${values.body}`;
//   //   const orgs = checkExtr(text, false);
//   //   if (orgs && orgs.length) {
//   //     const result = window.confirm(
//   //       `Возможно, в тексте присутствуют следующие инагенты или экстремисткие организации
// без упоминания о запрещенности: ${orgs.join(
//   //         ', ',
//   //       )}`,
//   //     );
//   //     return result;
//   //   }
//   // }
//   // Default return true;
//   return true;
// };

// 2 июня 2022 отключаем проверку на экстремистов и зепрещёнки
// в связи с отказом выполнять требования РКН
export const confirmExtr = () => true;
