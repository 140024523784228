// @flow
import React from 'react';
import styled from 'react-emotion';

type Props = {|
  count: number
|};

const Counter = styled('span')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  font-family: 'GraphikLC', sans-serif !important;
  font-size: 8px;
  font-weight: 500;
  color: #293043;
  background-color: #e9eaec;
  border-radius: 2px;
`;

export default ({ count = 0 }: Props) => (
  <Counter>
    {count}
  </Counter>
);
