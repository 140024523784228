import React from 'react';
import styled from 'styled-components';

const Field = styled.div`
  margin-bottom: 14px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export default ({ children, style }) => (
  <Field
    style={style}
  >
    {children}
  </Field>
);
