/* @flow */
const { change } = require('redux-form');
const uploadImgUtils = require('../lib/upload-form-image');
// todo: Разделить экшны по файлам

const E = {};

E.FETCH_POSTS = 'FETCH_POSTS';
E.FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
E.FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE';

E.AUTH = 'AUTH';
E.UNAUTH = 'UNAUTH';
E.AUTH_SUCCESS = 'AUTH_SUCCESS';
E.AUTH_FAILURE = 'AUTH_FAILURE';
E.AUTH_REQUEST_OTP_CODE = 'AUTH_REQUEST_OTP_CODE';
E.AUTH_ROLES_UPDATE = 'AUTH_ROLES_UPDATE';
E.SEARCH_QUERY_MAIN = 'SEARCH_QUERY_MAIN';
E.CHANGE_IS_MAJOR = 'CHANGE_IS_MAJOR';

E.FETCH_USERS_LIST = 'FETCH_USERS_LIST';
E.FETCH_USERS_LIST_SUCCESS = 'FETCH_USERS_LIST_SUCCESS';
E.FETCH_USERS_LIST_FAILURE = 'FETCH_USERS_LIST_FAILURE';

E.FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
E.FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
E.FETCH_USER_PROFILE_FAILURE = 'FETCH_USER_PROFILE_FAILURE';

E.FETCH_USER_SESSION_LIST = 'FETCH_USER_SESSION_LIST';
E.FETCH_USER_SESSION_LIST_SUCCESS = 'FETCH_USER_SESSION_LIST_SUCCESS';
E.FETCH_USER_SESSION_LIST_FAILURE = 'FETCH_USER_SESSION_LIST_FAILURE';

E.CREATE_USER_PROFILE = 'CREATE_USER_PROFILE';
E.UPDATE_SESSION = 'UPDATE_SESSION';
E.END_ALL_ACTIVE_SESSIONS = 'END_ALL_ACTIVE_SESSIONS';

E.REFRESH_FEED = 'REFRESH_FEED';

E.POST_WAS_UPDATED = 'POST_WAS_UPDATED';
E.POST_FIELD_WAS_UPDATED = 'POST_FIELD_WAS_UPDATED';

E.GET_POST_SERVER = 'GET_POST_SERVER';
E.GET_POST = 'GET_POST';
E.REDUX_FORM_CHANGE = '@@redux-form/CHANGE';
E.UPDATE_FIELD = 'UPDATE_FIELD';
E.CHANGE_SNAPSHOT = 'CHANGE_SNAPSHOT';
E.OBSERVE_FIELD_CHANGE = 'OBSERVE_FIELD_CHANGE';
E.DELETE_POST = 'DELETE_POST';
E.RESTORE_POST = 'RESTORE_POST';
E.PUBLISH_POST = 'PUBLISH_POST';
E.SET_CURRENT_VERSION = 'SET_CURRENT_VERSION';
E.GET_RELATED_THEMES = 'GET_RELATED_THEMES';
E.GET_RELATED_CONTEXT = 'GET_RELATED_CONTEXT';
E.GET_RELATED_CATEGORIES = 'GET_RELATED_CATEGORIES';
E.SEARCH_RELATED_THEMES = 'SEARCH_RELATED_THEMES';
E.SEARCH_RELATED_CATEGORIES = 'SEARCH_RELATED_CATEGORIES';
E.SEARCH_RELATED_CONTEXT = 'SEARCH_RELATED_CONTEXT';
E.SEARCH_RELATED_THEME_CONTEXT = 'SEARCH_RELATED_THEME_CONTEXT';
E.ADD_RELATED_THEMES = 'ADD_RELATED_THEMES';
E.ADD_RELATED_CATEGORIES = 'ADD_RELATED_CATEGORIES';
E.ADD_RELATED_CONTEXT = 'ADD_RELATED_CONTEXT';
E.DELETE_RELATED_THEMES_FROM_POST = 'DELETE_RELATED_THEMES_FROM_POST';
E.DELETE_RELATED_CATEGORIES_FROM_POST = 'DELETE_RELATED_CATEGORIES_FROM_POST';
E.DELETE_RELATED_CONTEXT_FROM_POST = 'DELETE_RELATED_CONTEXT_FROM_POST';

E.UPDATE_RELATED_CONTEXT = 'UPDATE_RELATED_CONTEXT';

E.GET_ALL_AUTHORS = 'GET_ALL_AUTHORS';
E.UPDATE_AUTHORS_IN_POST = 'UPDATE_AUTHORS_IN_POST';
E.SEARCH_AUTHORS_POST = 'SEARCH_AUTHORS_POST';

E.SAVE_EVENT = 'SAVE_EVENT';
E.PUBLISH_EVENT = 'PUBLISH_EVENT';
E.CREATE_EVENT = 'CREATE_EVENT';
E.CHANGE_CURRENT_VERSION_EVENT = 'CHANGE_CURRENT_VERSION_EVENT';
E.PUBLISH_NEW_EVENT = 'PUBLISH_NEW_EVENT';
// events
E.DELETE_EVENT = 'DELETE_EVENT';
E.CHANGE_CURRENT_VERSION_EVENT_ON_CLIENT = 'CHANGE_CURRENT_VERSION_EVENT_ON_CLIENT';
E.SWITCH_MODE_MAJOR_EVENT_SNAPSHOT = 'SWITCH_MODE_MAJOR_EVENT_SNAPSHOT';
E.CREATE_POST_BY_ENTITY = 'CREATE_POST_BY_ENTITY';

E.FETCH_REPORTS = 'FETCH_REPORTS';
E.FETCH_REPORTS_COUNT = 'FETCH_REPORTS_COUNT';
E.TRY_REPORTS_COUNT = 'TRY_REPORTS_COUNT';
E.ARCHIVE_REPORT = 'ARCHIVE_REPORT';

E.FETCH_THEMES = 'FETCH_THEMES';
E.FETCH_THEME = 'FETCH_THEME';
E.FETCH_THEMES_SUCCESS = 'FETCH_THEMES_SUCCESS';
E.FETCH_THEME_SUCCESS = 'FETCH_THEME_SUCCESS';
E.UPDATE_THEME = 'UPDATE_THEME';
E.CREATE_THEME = 'CREATE_THEME';
E.DELETE_THEME = 'DELETE_THEME';

E.ADD_NEW_USER = 'ADD_NEW_USER';
E.GET_RELATED_FOR_THEME = 'GET_RELATED_FOR_THEME';

E.CREATE_SYNC_COLLECTION = 'CREATE_SYNC_COLLECTION';
E.CHANGE_ELEMENT_IN_SYNC_COLLECTION = 'CHANGE_ELEMENT_IN_SYNC_COLLECTION';
E.ADD_ELEMENT_TO_SYNC_COLLECTION = 'ADD_ELEMENT_TO_SYNC_COLLECTION';
E.REMOVE_ELEMENT_FROM_SYNC_COLLECTION = 'REMOVE_ELEMENT_FROM_SYNC_COLLECTION';
E.SET_ELEMENTS_SYNC_COLLECTION = 'SET_ELEMENTS_SYNC_COLLECTION';
E.UPDATE_ITEM_IN_SYNC_COLLECTION = 'UPDATE_ITEM_IN_SYNC_COLLECTION';
E.DESTROY_SYNC_COLLECTION = 'DESTROY_SYNC_COLLECTION';
E.LOAD_ACTION_SYNC_COLLECTION = 'LOAD_ACTION_SYNC_COLLECTION';
E.SEARCH_RELATED_EVENT_CONTEXT = 'SEARCH_RELATED_EVENT_CONTEXT';
E.SEARCH_RELATED_FEATURES_CONTEXT = 'SEARCH_RELATED_FEATURES_CONTEXT';

E.REFRESH_FEATURE_CONTEXT = 'REFRESH_FEATURE_CONTEXT';

E.SEND_WEBPUSH = 'SEND_WEBPUSH';
E.SUBSCRIBE_TO_WEBPUSHES = 'SUBSCRIBE_TO_WEBPUSHES';
E.UNSUBSCRIBE_TO_WEBPUSHES = 'UNSUBSCRIBE_TO_WEBPUSHES';
E.FETCH_WEBPUSHES_LIST = 'FETCH_WEBPUSHES_LIST';
E.RECEIVE_WEBPUSHES_LIST = 'RECEIVE_WEBPUSHES_LIST';
E.WEBPUSH_WAS_SENT = 'WEBPUSH_WAS_SENT';
E.GET_PUBLISHED_VERSION = 'GET_PUBLISHED_VERSION';

E.UPDATE_TRANSACTION_ID = 'UPDATE_TRANSACTION_ID';
E.GET_ALL_THEMES_FOR_POST = 'GET_ALL_THEMES_FOR_POST';

E.CHANGE_PASSWORD = 'CHANGE_PASSWORD';
E.CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
E.CHANGE_PASSWORD_RESET = 'CHANGE_PASSWORD_RESET';
E.CHECK_SLUG_RESULT = 'CHECK_SLUG_RESULT';

E.SWITCH_EVENT_EDIT_MODE = 'SWITCH_EVENT_EDIT_MODE';
E.SET_EVENT_EDIT_MODE = 'SET_EVENT_EDIT_MODE';
E.SET_EVENT_PUBLISHED_STATUS = 'SET_EVENT_PUBLISHED_STATUS';

E.FIELD_FOCUSED = 'FIELD_FOCUSED';
E.FIELD_BLURED = 'FIELD_BLURED';

E.FETCH_SOCIAL_PICTURE_LIST = 'FETCH_SOCIAL_PICTURE';
E.FETCH_SOCIAL_PICTURE_LIST_SUCCESS = 'FETCH_SOCIAL_PICTURE_SUCCESS';
E.FETCH_SOCIAL_PICTURE_LIST_FAIL = 'FETCH_SOCIAL_PICTURE_FAIL';

E.fetchSocialList = () => ({ type: E.FETCH_SOCIAL_PICTURE_LIST, meta: { remote: true } });

E.CREATE_SOCIAL_PICTURE = 'CREATE_SOCIAL_PICTURE';
E.CREATE_SOCIAL_PICTURE_SUCCESS = 'CREATE_SOCIAL_PICTURE_SUCCESS';
E.CREATE_SOCIAL_PICTURE_FAIL = 'CREATE_SOCIAL_PICTURE_FAIL';

E.REMOVE_SOCIAL_PICTURE = 'REMOVE_SOCIAL_PICTURE';
E.REMOVE_SOCIAL_PICTURE_SUCCESS = 'REMOVE_SOCIAL_PICTURE_SUCCESS';
E.REMOVE_SOCIAL_PICTURE_FAIL = 'REMOVE_SOCIAL_PICTURE_FAIL';

E.CHANGE_POST_TYPE = 'CHANGE_POST_TYPE';
E.CHANGE_POST_TYPE_FAILURE = 'CHANGE_POST_TYPE_FAILURE';
E.POST_TYPE_CHANGED_RELOAD = 'POST_TYPE_CHANGED_RELOAD';
E.POST_TYPE_RELOAD_COMPLETE = 'POST_TYPE_RELOAD_COMPLETE';

E.ATTACH_OTP = 'ATTACH_OTP';
E.DETACH_OTP = 'DETACH_OTP';
E.DELETE_SECRET = 'DELETE_SECRET';

E.createSocialPicture = (type = 'quote') => ({
  type: E.CREATE_SOCIAL_PICTURE,
  meta: { remote: true },
  payload: {
    type,
  },
});

E.fieldFocused = ({ entity, id, field, user }, remote = true) => ({
  type: E.FIELD_FOCUSED,
  entity,
  id,
  field,
  user,
  meta: { remote },
});

E.fieldBlured = ({ entity, id, field, user }, remote = true) => ({
  type: E.FIELD_BLURED,
  entity,
  id,
  field,
  user,
  meta: { remote },
});

E.setEventPublishedStatus = (id, isPublished) => ({
  type: E.SET_EVENT_PUBLISHED_STATUS,
  id,
  isPublished,
});

E.switchEventEditMode = eventId => ({
  type: E.SWITCH_EVENT_EDIT_MODE,
  id: eventId,
});

E.setEventEditMode = (eventId, status) => ({
  type: E.SET_EVENT_EDIT_MODE,
  id: eventId,
  status,
});

E.updateTransactionId = (transactionId, fromServer = false) => ({
  type: E.UPDATE_TRANSACTION_ID,
  transactionId,
  fromServer,
});

E.refreshFeed = (params) => {
  const mapPathToPostType = {
    news: 'news',
    articles: 'article',
    onlines: 'online',
    chronicles: 'chronicle',
    numbers: 'number',
    advertCampaigns: 'advertCampaign',
    tests: 'test',
    pages: 'page',
    cards: 'card',
    podcasts: 'podcast',
  };

  return {
    type: E.REFRESH_FEED,
    meta: { remote: true },
    originalParams: params,
    request: {
      formats: [mapPathToPostType[params.type] || 'news'],
      year: params.year,
      month: params.month,
      day: params.day,
    },
  };
};

E.postWasUpdated = postId => ({
  type: E.POST_WAS_UPDATED,
  postId,
});

E.postFieldWasUpdated = ({ id, field, value }) => ({
  type: E.POST_FIELD_WAS_UPDATED,
  id,
  field,
  value,
});

E.fetchUserProfile = (userId) => ({
  type: E.FETCH_USER_PROFILE,
  meta: { remote: true },
  userId,
});

E.fetchUserProfileSuccess = (payload) => ({
  type: E.FETCH_USER_PROFILE_SUCCESS,
  payload,
});

E.fetchUserProfileFailure = (error) => ({
  type: E.FETCH_USER_PROFILE_FAILURE,
  error,
});

E.createUser = () => ({
  type: E.CREATE_USER_PROFILE,
  meta: {
    remote: true,
  },
});

E.fetchUsersList = () => ({
  type: E.FETCH_USERS_LIST,
  meta: {
    remote: true,
  },
});

E.fetchUserSessionList = (userId) => ({
  type: E.FETCH_USER_SESSION_LIST,
  userId,
  meta: {
    remote: true,
  },
});

E.fetchUserSessionListSuccess = (sessions) => ({
  type: E.FETCH_USER_SESSION_LIST_SUCCESS,
  sessions,
});

E.fetchUserSessionListFailure = (error) => ({
  type: E.FETCH_USER_SESSION_LIST_FAILURE,
  error,
});

E.fetchUsersListSuccess = usersList => ({
  type: E.FETCH_USERS_LIST_SUCCESS,
  payload: usersList,
});

E.fetchUsersListFailure = error => ({
  type: E.FETCH_USERS_LIST_FAILURE,
  error,
});

function setCookie(name, value, options) {
  options = options || {};

  let expires = options.expires;

  if (typeof expires === 'number' && expires) {
    const d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  let updatedCookie = `${name}=${value}`;

  for (const propName in options) {
    updatedCookie += `; ${propName}`;
    const propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += `=${propValue}`;
    }
  }

  document.cookie = updatedCookie;
}

E.fetchPosts = (params) => {
  const mapPathToPostType = {
    news: 'news',
    articles: 'article',
    onlines: 'online',
    chronicles: 'chronicle',
    numbers: 'number',
    advertCampaigns: 'advertCampaign',
    tests: 'test',
    pages: 'page',
    cards: 'card',
    podcasts: 'podcast',
  };

  return {
    type: E.FETCH_POSTS,
    meta: { remote: true },
    originalParams: params,
    request: {
      formats: [mapPathToPostType[params.type] || 'news'],
      year: params.year,
      month: params.month,
      day: params.day,
      searchQuery: params.query,
    },
  };
};

E.fetchPostsSuccess = (result, count, totalCount) => ({
  type: E.FETCH_POSTS_SUCCESS,
  result,
  count,
  totalCount,
});

E.fetchPostsFailure = error => ({
  type: E.FETCH_POSTS_FAILURE,
  error,
});

E.removeSocialPicture = id => ({
  type: E.REMOVE_SOCIAL_PICTURE,
  meta: { remote: true },
  id,
});
E.removeSocialPictureSuccess = id => ({
  type: E.REMOVE_SOCIAL_PICTURE_SUCCESS,
  id,
});

E.removeSocialPictureFailure = erro => ({
  type: E.REMOVE_SOCIAL_PICTURE_FAILURE,
  error,
});

E.setActiveMenuItem = index => ({
  type: 'SET_ACTIVE',
  index,
});

E.changePath = name => ({
  type: 'CHANGE_PATH',
  name,
});

E.updateFilterDate = data => ({
  type: 'GET_DATAFILTER',
  data,
});

E.searchAction = searchList => ({
  type: 'UPDATE_FEED',
  searchList,
});

E.searchQuery = function (query) {
  return (dispatch) => {
    dispatch({ type: E.SEARCH_QUERY_MAIN, query });
  };
};

E.replaceSnapshot = post => ({
  type: 'UPDATE_SNAPSHOT',
  post,
});

E.setVisibilityCkToolbar = isVisible => ({
  type: 'SET_VISIBILITY_CK_TOOLBAR',
  isVisible,
});

E.updateSession = function () {
  return {
    type: E.UPDATE_SESSION,
    meta: {
      remote: true,
    },
  };
};

E.endAllActiveSessions = (userId) => ({
  type: E.END_ALL_ACTIVE_SESSIONS,
  userId,
  meta: {
    remote: true,
  },
});

E.auth = (email, password, otp_code) => ({
  type: E.AUTH,
  meta: { remote: true },
  email,
  password,
  otp_code,
});

E.unauth = () => {
  if (typeof window !== 'undefined') {
    setCookie('authToken', token, { expires: 60 * 60 * 1000 * 24 * 365, path: '/' });
  }
  return {
    type: E.UNAUTH,
    meta: { remote: true },
  };
};

E.logout = () => ({
  type: E.UNAUTH,
  meta: { remote: true },
});

E.authSuccess = (user, token, sessionKey) => {
  const userId = user.id;

  return {
    type: E.AUTH_SUCCESS,
    userId,
    user,
    token,
    sessionKey,
  };
};

E.authFailure = error => ({
  type: E.AUTH_FAILURE,
  error,
});

E.authRequestOTPCode = () => ({
  type: E.AUTH_REQUEST_OTP_CODE,
});

E.login = (userData) => {
  console.log(userData);
  return {
    type: 'LOGIN',
    userData,
  };
};

E.userRolesUpdate = (userData) => ({
  type: E.AUTH_ROLES_UPDATE,
  userData,
});

E.getPost = idPost => ({
  type: E.GET_POST_SERVER,
  meta: { remote: true },
  idPost,
});

E.getPostSuccess = post => ({
  type: E.GET_POST,
  post,
});

E.uploadImageFile = (file, type, formName = 'post-form') => (dispatch) => {
  uploadImgUtils
    .uploadImageFile(file)
    .then((res) => {
      const value = res.results.original.s3_path;

      const mapImageType = {
        normal: 'image',
        retina: 'retina_image',
      };

      const imageField = mapImageType[type];

      dispatch(change(formName, imageField, value));
    })
    .catch((err) => {
      console.log(`Image uploading error: ${err}`);
    });
};

E.changeFieldForm = ({ post_id, field, value }) => (dispatch) => {
  dispatch({
    // todo: Поменять на PUSH_POST_FIELD_UPDATE?
    type: E.UPDATE_FIELD,
    post_id,
    field,
    value,
    meta: { remote: true },
  });

  dispatch(change('post-form', field, value));
};

E.changeAuthors = data =>
  Object.assign({}, data, {
    type: E.UPDATE_AUTHOR_POST,
    meta: { remote: true },
  });

E.changeSnapshot = ({ post_id, snapshot_id }) => ({
  type: E.CHANGE_SNAPSHOT,
  post_id,
  snapshot_id,
  meta: { remote: true },
});

E.observerFieldsChange = isChanged => ({
  type: E.OBSERVE_FIELD_CHANGE,
  isChanged,
});

E.deletePost = ({ post_id }) => ({
  type: E.DELETE_POST,
  post_id,
  meta: { remote: true },
});

E.restorePost = ({ post_id }) => ({
  type: E.RESTORE_POST,
  post_id,
  meta: { remote: true },
});

E.getRelatedById = ({ entity, post_id }) => ({
  type: `GET_RELATED_${entity}`,
  post_id,
  meta: { remote: true },
});

E.relatedThemes = themes => ({
  type: 'RELATED_THEMES',
  themes,
});

E.receiveRestThemesForPost = themes => ({
  type: 'RECEIVE_REST_THEMES_FOR_POST',
  themes,
});

E.searchRelated = ({ entity, id, text }) => ({
  type: `SEARCH_RELATED_${entity}`.toUpperCase(),
  id,
  text,
  meta: { remote: true },
});

E.updateRelatedEntities = (props: {
  entity: string,
  list: Array<any>,
  entityId: number
}) => {
  const {
    entity,
    list,
    entityId,
  } = props;

  return {
    type: `UPDATE_RELATED_${entity}`.toUpperCase(),
    list,
    entityId,
    meta: { remote: true },
  };
};

E.getAllThemesForPost = ({ post_id }) => ({
  type: 'GET_ALL_THEMES_FOR_POST',
  post_id,
  meta: { remote: true },
});

E.searchThemes = searched => ({
  type: 'SEARCH_THEMES',
  searched,
});

E.searchCategories = searched => ({
  type: 'SEARCH_CATEGORIES',
  searched,
});

E.searchContext = searched => ({
  type: 'SEARCH_CONTEXT',
  searched,
});

E.searchPostsForEvent = searched => ({
  type: 'SEARCH_EVENT_CONTEXT',
  searched,
});

E.searchPostsForTheme = searched => ({
  type: 'SEARCH_CONTEXT_FOR_THEME',
  searched,
});

E.addRelatedToPost = dataObj => Object.assign({}, dataObj, {
  type: `ADD_RELATED_${dataObj.action}`,
  sort_id: dataObj.sort_id,
  meta: { remote: true },
});

E.deleteRelated = dataObj => Object.assign({}, dataObj, {
  type: `DELETE_RELATED_${dataObj.action}_FROM_POST`,
  meta: { remote: true },
});

E.relatedCategories = categories => ({
  type: 'RELATED_CATEGORIES',
  categories,
});

E.relatedContext = context => ({
  type: 'RELATED_CONTEXT',
  context,
});

E.getAuthors = () => ({
  type: E.GET_ALL_AUTHORS,
  meta: { remote: true },
});

E.updateAuthorsFromPost = ({
  list,
  postId,
}: {
  postId: number,
  list: Array<any>,
}) => ({
  type: E.UPDATE_AUTHORS_IN_POST,
  list,
  postId,
  meta: {
    remote: true,
  },
});

E.getSearchAuthorsPost = authors => ({
  type: E.SEARCH_AUTHORS_POST,
  authors,
});

E.deleteEvent = event_id => ({
  type: E.DELETE_EVENT,
  event_id,
  meta: { remote: true },
});

E.updateEventAfterDelete = event_id => ({
  type: 'DELETE_EVENT_ON_CLIENT',
  event_id,
});

E.saveEvent = (eventObj) => {
  console.log(eventObj);
  return {
    type: E.SAVE_EVENT,
    eventObj,
    meta: { remote: true },
  };
};

E.publishEvent = eventObj => (dispatch) => {
  dispatch({
    type: E.PUBLISH_EVENT,
    eventObj,
    meta: { remote: true },
  });

  dispatch(E.updateTransactionId(eventObj.transactionId, false));
};

E.updateEventAfterSave = events => ({
  type: 'UPDATE_EVENTS',
  events,
});

E.createEvent = eventData => ({
  type: E.CREATE_EVENT,
  eventData,
  meta: { remote: true },
});

// E.publishNewEvent = function(eventData){
// 	return {
// 		type: E.PUBLISH_NEW_EVENT,
// 		eventData,
// 		meta: {remote: true}
// 	}
// }

E.changeCurrentVersionEvent = eventData => ({
  type: E.CHANGE_CURRENT_VERSION_EVENT,
  eventData,
  meta: { remote: true },
});

E.updateEventAfterChangeVersion = eventData => ({
  type: E.CHANGE_CURRENT_VERSION_EVENT_ON_CLIENT,
  event: eventData,
});

E.addEventOnClient = id => ({
  type: 'IS_EDITING_NEW_EVENT',
  id,
});

E.changeMajorEvent = snapshotData => ({
  type: E.SWITCH_MODE_MAJOR_EVENT_SNAPSHOT,
  event: snapshotData,
  meta: { remote: true },
});

E.updateMajorAfterChange = eventData => ({
  type: 'UPDATE_MAJOR_EVENT',
  event: eventData,
});

E.publishPost = ({ post_id, values, region }) => ({
  type: E.PUBLISH_POST,
  post_id,
  meta: { remote: true },
});

E.setCurrentVersion = ({ post_id, snapshot_id }) => ({
  type: E.SET_CURRENT_VERSION,
  post_id,
  snapshot_id,
  meta: { remote: true },
});

E.createPostByEntity = type => ({
  type: E.CREATE_POST_BY_ENTITY,
  entity: type,
  meta: { remote: true },
});

E.fetchReports = () => ({
  type: E.FETCH_REPORTS,
  meta: { remote: true },
});

E.tryReportCount = () => ({
  type: E.TRY_REPORTS_COUNT,
  meta: { remote: true },
});

E.fetchReportCount = count => ({
  type: E.FETCH_REPORTS_COUNT,
  count,
});

E.fetchReportsSuccess = reports => ({
  type: 'FETCH_REPORTS_SUCCESS',
  reports,
});

E.archiveReport = id => ({
  type: E.ARCHIVE_REPORT,
  id,
  meta: { remote: true },
});

E.archiveReportSuccess = id => ({
  type: 'ARCHIVE_REPORT_SUCCESS',
  id,
});

E.fetchThemes = () => ({
  type: E.FETCH_THEMES,
  meta: { remote: true },
});

E.fetchThemesSuccess = data => ({
  type: E.FETCH_THEMES_SUCCESS,
  res: data,
});

E.fetchTheme = id => ({
  type: E.FETCH_THEME,
  id,
  meta: { remote: true },
});

E.fetchThemeSuccess = theme => ({
  type: 'FETCH_THEME_SUCCESS',
  theme,
});

E.updateTheme = (themeObj, id) => ({
  type: E.UPDATE_THEME,
  themeObj,
  id,
  meta: { remote: true },
});

E.updateThemeSuccess = id => ({
  type: 'UPDATE_THEME_SUCCESS',
});

E.createTheme = () => ({
  type: E.CREATE_THEME,
  meta: { remote: true },
});

E.deleteTheme = id => ({
  type: E.DELETE_THEME,
  id,
  meta: { remote: true },
});

E.setUsersInRoom = users => ({
  type: 'SET_USERS_IN_ROOM',
  users,
});

E.getRelatedEntitiesForTheme = theme_id => ({
  type: E.GET_RELATED_FOR_THEME,
  theme_id,
  meta: { remote: true },
});

E.getRelatedForThemeSuccess = rows => ({
  type: 'GET_RELATED_FOR_THEME_SUCCESS',
  related: rows,
});

E.createSyncCollection = name => ({
  type: E.CREATE_SYNC_COLLECTION,
  name,
});

E.destroySyncCollection = name => ({
  type: E.DESTROY_SYNC_COLLECTION,
  name,
});

E.addItemToSyncCollection = (name, item) => ({
  type: E.ADD_ELEMENT_TO_SYNC_COLLECTION,
  name,
  item,
});

E.removeItemFromSyncCollection = (name, id) => ({
  type: E.REMOVE_ELEMENT_FROM_SYNC_COLLECTION,
  name,
  id,
});

E.setItemsInSyncCollection = (name, items) => ({
  type: E.SET_ELEMENTS_SYNC_COLLECTION,
  name,
  items,
});

E.updateItemInSyncCollection = (collectionName, itemId, values) => ({
  type: E.UPDATE_ITEM_IN_SYNC_COLLECTION,
  name: collectionName,
  itemId,
  values,
});

E.searchPostsForFeatures = searched => ({
  type: 'SEARCH_FEATURES_CONTEXT',
  searched,
});

E.refreshFeatureContext = id => ({
  type: E.REFRESH_FEATURE_CONTEXT,
  id,
});

E.send_webpush = data => ({
  type: E.SEND_WEBPUSH,
  data,
  meta: { remote: true },
});

E.fetchPushesList = (): Object => ({
  type: E.FETCH_WEBPUSHES_LIST,
  meta: {
    remote: true,
  },
});

E.receivePushesList = ({ pushes }: {pushes: Array<string>}): Object => ({
  type: E.RECEIVE_WEBPUSHES_LIST,
  pushes,
  meta: {
    remote: true,
  },
});

E.webpushWasSent = arrayElem => ({
  type: E.WEBPUSH_WAS_SENT,
  arrayElem,
});

E.subscribeToWebpushes = (): Object => ({
  type: E.SUBSCRIBE_TO_WEBPUSHES,
  meta: {
    remote: true,
  },
});

E.unsubscribeFromWebpushes = (): Object => ({
  type: E.UNSUBSCRIBE_TO_WEBPUSHES,
  meta: {
    remote: true,
  },
});

E.validatePostForm = errors => ({
  type: 'VALIDATION_POST_FORM',
  errors,
});

E.getPublishedPost = post_id => ({
  type: E.GET_PUBLISHED_VERSION,
  post_id,
  meta: { remote: true },
});

E.getPublishedPostSuccess = post => ({
  type: 'GET_PUBLISHED_VERSION_SUCCESS',
  post,
});

E.testEditQuestions = (qid, isEdited) => ({
  type: 'EXPAND',
  expand: isEdited,
  qid,
});

E.changePassword = ({ password }) => ({
  type: E.CHANGE_PASSWORD,
  password,
  meta: { remote: true },
});

E.changePasswordSuccess = () => ({
  type: E.CHANGE_PASSWORD_SUCCESS,
});

E.changePasswordReset = () => ({
  type: E.CHANGE_PASSWORD_RESET,
});

E.validateSlug = ({ is_valid, is_exists, post_id }) => ({
  type: E.CHECK_SLUG_RESULT,
  is_valid_slug: is_valid,
  is_exists_slug: is_exists,
  post_id,
});

E.editCard = ({ cardId, isEdited }) => ({
  type: 'EXPAND_CARD',
  isEdited,
  cardId,
});

E.attachOTP = (user_id) => ({
    type: E.ATTACH_OTP,
    user_id,
    meta: {
      remote: true,
    },
  });

E.detachOTP = (user_id) => ({
    type: E.DETACH_OTP,
    user_id,
    meta: {
      remote: true,
    },
  });

E.deleteSecret = (user_id) => ({
    type: E.DELETE_SECRET,
    user_id,
    meta: {
      remote: true,
    },
  });

E.changePostType = (future_type, user_id, post_id, form) => ({
  type: E.CHANGE_POST_TYPE,
  future_type,
  user_id,
  post_id,
  form,
  meta: {
    remote: true,
  },
});

E.getArchivedSources = post_id => ({
  type: E.GET_ARCHIVED_SOURCES,
  post_id,
  meta: {
    remote: true,
  },
});

module.exports = E;
