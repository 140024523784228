import { combineReducers, compose } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import feedReducer from './feedReducer';
import focusReducer from './ui/focus';
import ui from './ui';
import related from './related';
import authors from './authors';
import post from './post';
import form from './form';
import reports from './reports';
import themes from './themes';
import features from './features';
import test from './test';
import syncCollection from './syncCollection.js';
import online_users from './online_users.js';
import transformPost from './transformPost';
import note from './note';
import { partners } from '../features/Partners';

import * as actions from '../actions/actions';
import generateId from '../utils/generateId';
import cards from '../reducers/cards';
import quoteList from '../reducers/quoteListReducer';

function setCookie(name, value, options) {
  options = options || {};

  let expires = options.expires;

  if (typeof expires === 'number' && expires) {
    const d = new Date();
    d.setTime(d.getTime() + expires * 1000);
    expires = options.expires = d;
  }
  if (expires && expires.toUTCString) {
    options.expires = expires.toUTCString();
  }

  value = encodeURIComponent(value);

  let updatedCookie = `${name}=${value}`;

  for (const propName in options) {
    updatedCookie += `; ${propName}`;
    const propValue = options[propName];
    if (propValue !== true) {
      updatedCookie += `=${propValue}`;
    }
  }

  document.cookie = updatedCookie;
}

function plainCombine(reducers) {
  return function (state, action) {
    return reducers.reduce((state, reducer) => reducer(state, action), state);
  };
}

export default combineReducers({
  routing: routerReducer,

  post,
  listPosts: feedReducer,
  themes,
  // ui: (state = {}, action) => {
  //     switch (action.type) {
  //         case actions.SET_VISIBILITY_CK_TOOLBAR:
  //             return {
  //                 ...state,
  //                 ...{ ckToolbar: action.isVisible }
  //             };

  //         default:
  //             return state;
  //     }
  // },
  ui,
  focusUi: focusReducer,
  eventsUi: (state = {}, action = {}) => {
    switch (action.type) {
      case actions.SWITCH_EVENT_EDIT_MODE:
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            isEditing: !(state[action.id] || {}).isEditing,
          },
        };
      case actions.SET_EVENT_EDIT_MODE:
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            isEditing: action.status,
          },
        };
      case actions.SET_EVENT_PUBLISHED_STATUS:
        return {
          ...state,
          [action.id]: {
            ...state[action.id],
            isPublished: !(state[action.id] || {}).isPublished,
          },
        };
      default:
        return state;
    }
  },
  related,
  form: plainCombine([formReducer, form]),
  authors,
  reports,
  features,
  online_users,
  collections: syncCollection,
  test,
  cards,
  transactions: (state = {}, action) => {
    if (!action.transactionId) {
      return state;
    }
    switch (action.type) {
      case actions.UPDATE_TRANSACTION_ID:
        if (action.fromServer) {
          // action from someone
          return {
            ...state,
            lastServerTransaction: action.transactionId,
          };
        }
        // my action
        return {
          ...state,
          lastMyTransaction: action.transactionId,
        };


      default:
        return state;
    }
  },
  // Временно
  // Необходимо передавать параметры fetch-инга
  // feed-а, а параметры-то мы берем из роута...
  feedParams: (state = {}, action) => {
    switch (action.type) {
      case actions.FETCH_POSTS:
        return action.originalParams;

      default:
        return state;
    }
  },

  user: (state = null, action) => {
    switch (action.type) {
      case actions.AUTH_SUCCESS:
        if (typeof window !== 'undefined') {
          if (action.token) {
            setCookie('authToken', action.token, { expires: 60 * 60 * 1000 * 24 * 365, path: '/' });
          }

          if (action.sessionKey) {
            setCookie('sessionToken', action.sessionKey);
          }
        }

        if (action.token) {
          return {
            userId: action.userId,
            user: action.user,
            token: action.token,
          };
        }

        return state;
      
      case actions.AUTH_ROLES_UPDATE:
        if (state && action.userData) {
          return {
            ...state,
            user: {
              ...state.user,
              ...action.userData,
            },
          };
        }

        return state;

      case actions.AUTH_FAILURE:
        return null;

      case actions.UNAUTH:
        return null;

      default:
        return state;
    }
  },

  // 2fa reducer
  otp: (state = { enabled: false }, action) => {
    switch (action.type) {
      case actions.AUTH_REQUEST_OTP_CODE:
        return {
          ...state,
          enabled: true,
        };

      default:
        return state;
    }
  },

  userProfile: (state = {}, action) => {
    switch (action.type) {
      case actions.FETCH_USER_PROFILE:
        return {};

      case actions.FETCH_USER_PROFILE_SUCCESS:
        return action.payload;

      case actions.FETCH_USER_SESSION_LIST:
        return {
          ...state,
          sessions: null,
          sessionsErorr: null,
          sessionsLoading: true,
        };

      case actions.FETCH_USER_SESSION_LIST_SUCCESS:
        return {
          ...state,
          sessions: action.sessions,
          sessionsErorr: null,
          sessionsLoading: false,
        };

      case actions.FETCH_USER_SESSION_LIST_FAILURE:
        return {
          ...state,
          sessions: null,
          sessionsErorr: action.error,
          sessionsLoading: false,
        };

      default:
        return state;
    }
  },

  usersList: (state = [], action) => {
    switch (action.type) {
      case actions.FETCH_USERS_LIST_SUCCESS:
        return action.payload;

      default:
        return state;
    }
  },
  searchQuery: (state = '', action) => {
    switch (action.type) {
      case actions.SEARCH_QUERY_MAIN:
        return action.query;
      default:
        return state;
    }
  },
  dateFilter: (state = {}, action) => {
    switch (action.type) {
      case actions.SEARCH_QUERY_MAIN:
        return {};
      case actions.FETCH_POSTS:
        return {};
      case actions.FETCH_POSTS_SUCCESS:
        const { count, totalCount } = action;
        return { count, totalCount } || {};
      default:
        return state;
    }
  },

  authErr(state = '', action) {
    switch (action.type) {
      case actions.AUTH_SUCCESS:
        return '';

      case actions.AUTH_FAILURE:
        return action.error;

      default:
        return state;
    }
  },
  windowId(state = generateId(), action) {
    switch (action.type) {
      case '@@INIT':
        return generateId();
      default:
        return state;
    }
  },
  reportCount(state = 0, action) {
    switch (action.type) {
      case actions.FETCH_REPORTS_COUNT:
        return action.count;
      case actions.ARCHIVE_REPORT:
        return state - 1;
      default:
        return state;
    }
  },
  changePassword(state = { changed: false }, action) {
    switch (action.type) {
      case actions.CHANGE_PASSWORD_SUCCESS:
        return { ...state, changed: true };
      case actions.CHANGE_PASSWORD_RESET:
        return { ...state, changed: false };
      default:
        return state;
    }
  },
  webPushes(state = {}, action) {
    switch (action.type) {
      case actions.RECEIVE_WEBPUSHES_LIST:
        const pushes = action.pushes.reduce((acc, item) => {
          acc.push({
            time: item[0],
            title: item[1],
            description: item[2],
            link: item[3],
            image: item[4],
          });
          return acc;
        }, []);
        return [...pushes];
      case actions.WEBPUSH_WAS_SENT:
        const elem = action.arrayElem[0];

        return [
          {
            time: elem[0],
            title: elem[1],
            description: elem[2],
            link: elem[3],
            image: elem[4],
          },
          ...state,
        ];
      default:
        return state;
    }
  },
  quoteList,
  transformPost,
  note,
  partners,
});
