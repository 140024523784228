import React from 'react';

const EmbedContainer = (props) => {
  const { attributes, children } = props;

  return (
    <figure
      className="mz-publish-embed"
      {...attributes}
    >
      {children}
    </figure>
  );
};

export default EmbedContainer;