export const imageSizes = {
  standard: '_1400x850',
  small: '_470x290',
  medium: '_810x500',
  wide: '_1400',
};

const URL_PREFIX = SERVER_SIDE_VARS.s3_url || '';

const setSize = (url, size) => {
  const imageSize = imageSizes[size] || '';
  return `${url}${imageSize}`;
};

const isURLStartFromProtocol = (url) => {
  const protocol = url.split(':')[0];
  return protocol === 'http' || protocol === 'https';
};

export default (imgSrc, size) => {
  if (!imgSrc || typeof imgSrc !== 'string') {
    return imgSrc;
  }
  if (isURLStartFromProtocol(imgSrc)) {
    return setSize(imgSrc, size);
  }
  return `${URL_PREFIX}${setSize(imgSrc, size)}`;
};
