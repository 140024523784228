/**
 * Created by kseniamahorkina on 07.11.16.
 */
import React from 'react';

export default ({
  handleAddEvent,
}) => (
  <div className="ui segment">
    <div id="EventForm" className="ui form">
      <button
        className="ui submit button"
        type="submit"
        onClick={handleAddEvent}
      >
        Добавить событие

      </button>
    </div>
  </div>
);
