import React, {Component} from 'react';
import '../RelatedField/RelatedField.styl';
import {connect} from 'react-redux';
import { DragSource } from 'react-dnd';
import {syncCollection} from '../../lib/sync-form';
import { Link } from 'react-router';
import {types} from '../../utils/constants';
import {searchRelated} from '../../actions/actions.js';
import ItemTypes from './ItemTypes.js';
import debounce from 'lodash/debounce';

import { formatTitlePost } from '../../lib/formatString';

const contextSource = {
	beginDrag(props) {
		return {
			post_id: props.field.id,
			index: 6,
			type: props.field.type,
			title: formatTitlePost(props.field)
		};
	},
	endDrag: function (props, monitor, component) {
		// if (!monitor.didDrop()) {
		// 	return;
		// }

		// When dropped on a compatible target, do something
		// var item = monitor.getItem();
		// var dropResult = monitor.getDropResult();
		// console.log(item, dropResult);
		// console.log(item.id, '____', dropResult.id);

		// props.onDragEnd();
		// CardActions.moveCardToList(item.id, dropResult.listId);
	}
};


@DragSource(ItemTypes.CONTEXT, contextSource, (connect, monitor) => ({
	connectDragSource: connect.dragSource(),
	isDragging: monitor.isDragging(),
}))


@connect(
	(state, props) => {
		return {

		}
	},
	(dispatch, ownProps) => {
		return {
			onDragEndContext: ownProps.onDragEndContext
		}
	}
)
class ContextItem extends Component {
	constructor(props){
		super(props);
	}
	addToFeature(){
		const {field} = this.props;
		this.props.onDragEndContext({
            post_id: field.id,
            index: 6,
            type: field.type,
            title: formatTitlePost(field)
		}, 0);
	}
	render(){
		const {field, isDropped, isDragging, connectDragSource} = this.props;
		field.title = field.title? field.title: field.name;
		return connectDragSource(
			<div className="relatedPostsItem relatedPostsItem_feature" >
				<div className="PostTitle">
					<div className="format"> {types[field.type]} </div>
					<div className="title">
						<Link to={'/post/'+field.id}>{formatTitlePost(field)}</Link>
					</div>
					<i className="ui blue add icon addPostToFeature" onClick={()=> { this.addToFeature(field.id)}}></i>
				</div>
			</div>
		);
	}
}

@connect(
	(state, props)=> {
		return {
			syncedEntity: 'features_context',
			syncedId: 'features_context',
			searched: state.features.search_features_context,
			isLoading: state.features.isLoading || false
		}
	},
	(dispatch, ownProps) => {
		return {
			dispatch: dispatch,
			onDragEndContext: ownProps.onDragEndContext
		}
	}
)
@syncCollection()

export default class Context extends React.Component {
	constructor(props){	
		super(props);
		this.search = this.search.bind(this);
		this.state = {
			searchText: '',
			isLoading: false
		};
		this.debounceSearch = debounce((value) => {
			return this.search(value);
		}, 1000, {maxWait: 10000});
	}

	search(searchValue){
		this.setState({'searchText': searchValue });
		if (searchValue !=''){
			this.props.dispatch(searchRelated({
	              entity: 'features_context',
	              text: searchValue
	        }));
		}
		else {
			this.setState({isLoading: false});
		}
	}

	componentWillReceiveProps(nextProps){
		console.log(nextProps.isLoading);
		this.setState({isLoading: nextProps.isLoading!==this.state.isLoading ? nextProps.isLoading: this.state.isLoading});
	}

	render(){
		const {entity, formValues, excludeContextIds, searched}  = this.props;
		let list = [];
		let placeholder = '';
		let title = '';
		for (var p in formValues)
		{
			list.push(formValues[p]);
		}

		list = list.sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date));


		// list = list.map((item, i)=>{
		// 	if ( excludeContextIds.indexOf(item.id)<0 )
		// 		return item;
		// });
		//
		// if (searched && searched.length>0){
		// 	searched = searched.map((item, i)=> {
		// 		if ( excludeContextIds.indexOf(item.id)<0 )
		// 			return item;
		// 	});
		// }

		switch(entity){
			case "features_context":
				placeholder = 'Связать с публикацией';
				title = 'Последние публикации';
				break;
			default: break;
		}
		// options = this.props.searched.reduce((result, item)=> {
		// 	if (!formValues[item.id]) {
		// 		result.push({
		// 			            "label": item.title || item.name,
		// 			            "value": item.id
		// 		            });
		// 	}
		// 	return result;
		// }, []);

		return (
			<div className="relatedField">
				<div className="field field_related">
					<div className="ui icon input feature-input">
						<input type="text" className="text" placeholder={placeholder}  onChange={(e) => {
							this.setState({isLoading: true});
							this.debounceSearch(e.target.value);
						}}/>
						<i className="search icon"></i>
						{this.state.isLoading &&  <i className="ui tiny active loader"></i>}
					</div>
				</div>
				<div className="ui bottom attached segment">
					{(searched && searched.length>0 && this.state.searchText!='')
						? (<div className={"relatedPosts basic"} style={{'marginBottom': '20px'}}>
							<div className="ui small dividing header">Найдено по поиску
								<div className="ui horizontal tiny right floated label">{searched.length}</div>
							</div>

							<div className="relatedContainer">
								{
									searched.map((field)=>{
										return (<ContextItem key={field.id} field={field}
										                     onDragEndContext={this.props.onDragEndContext}/>);
									})
								}
							</div>
						</div>)
						: null
					}


					<div className="relatedPosts basic">
						<div className="ui small dividing header">{title}
							<div className="ui horizontal tiny right floated label">{list.length}</div>
						</div>

						<div className="relatedContainer">
							{
								list.map((field)=>{
									return (<ContextItem key={field.id} field={field}
									                     onDragEndContext={this.props.onDragEndContext}/>);
								})
							}
						</div>
					</div>
				</div>
			</div>
		)
	}

}
