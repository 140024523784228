module.exports = {
    update_event: 'sync_update',
    get_event: 'sync_get',
    join_event: 'sync_join',
    left_event: 'sync_left',
    channel: 'form_channel_',
    formFunc: (entity, id) => 'sync-form-'+entity+'-'+id,
    formDestructor: (formName) => {
        const split = formName.split('-');
        return {
            entity: split[2],
            id: split[3]
        };
    },
    model_get_event: 'sync_model_get',
    model_update_event: 'sync_model_update',
    channelFunc: (entity, id) => 'form_channel_'+entity+'_'+id,
    set_users_in_room: 'set_users_in_room',
    me_in_room_event: 'me_in_room',
    collection_add_event: 'sync_collection_add_event',
    collection_operation_event: 'sync_collection_operation_event',
    collection_get_event: 'sync_collection_get_event',
    collection_sync_event: 'sync_collection_sync_event',
    collection_remove_event: 'sync_collection_remove_event',
    collection_map_event: 'sync_collection_map_event',
    setFunc: ({entity, id}) => `set_entity_${entity}_${id}`,
    setDestructor: (setName) =>  {
	    const splitted = setName.split('_');
	    return {entity: splitted[2], id: splitted[3]};
    },
    generateTransactionId: (clientId) => {
        const timestamp = new Date().getTime();
        const randomNumber = parseInt( Math.random() * 1000 );

        return `${timestamp}_${randomNumber}@${clientId}`;
    },
    destructTransactionId: (transactionId) => {
        const [ timestampAndRand, clientId ] = transactionId.split('@');
        const [ timestamp, rand ] = timestampAndRand.split('_');
        return {
            timestamp: +(timestamp),
            rand,
            clientId
        };
    }
};
