import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import { PostCount } from './styles';

const DateFilterElement = (props) => {
  const {
    label,
    count,
    totalCount,
    url,
  } = props;

  const relativeAmount = Math.round((100 * (+count)) / (+totalCount));

  const chartsStyles = {
    background: `linear-gradient(0deg, #ddd 0%, #ddd ${relativeAmount}%, transparent ${relativeAmount}%`,
  };

  return (
    <Link to={url} style={chartsStyles}>
      {label}
      <br />
      <PostCount>{count}</PostCount>
    </Link>
  );
};

DateFilterElement.propTypes = {
  label: PropTypes.any,
  count: PropTypes.any,
  totalCount: PropTypes.any,
  url: PropTypes.string,
};

export default DateFilterElement;
