import React, { Component } from 'react';
import { Portal } from 'react-portal';
import {
  fetchEmbed,
  isYouTube,
  hasLink
} from '../../../../helpers';
import Editor from '../../../../Editor';
import Modal from '../../../../../Modal/Modal';
import {
  ModalTitleContainer,
  ModalCloseButton,
  ModalCancel,
  ModalSubmit,
  WideModalController,
  ModalContent,
  InputTitle,
  Input,
  TextArea,
  EmbedFormContainer,
  Icons
} from '../../../../atoms';
import {
  Preview
} from '../../molecules';

const {
  Close
} = Icons;

const initialState = {
  isValueInvalid: false,
  value: '',
  embedType: 'embed'
};

class EmbedForm extends Component {
  constructor(props) {
    super(props);

    if (props.initialState) {
      this.state = { ...initialState, ...props.initialState };
    } else {
      this.state = initialState;
    }
  }

  onSubmit = async (event) => {
    try {
      event.preventDefault();
      event.persist();

      const {
        value
      } = this.state;

      let url = '';
      let embedValue = '';
      let embedType = '';

      if (typeof value !== 'string') return null;

      switch (true) {
        case isYouTube(value):
          embedType = Editor.EMBED.YOUTUBE;
          embedValue = value;
          url = value;
          break;
        case hasLink(value):
          embedType = Editor.EMBED.DEFAULT;
          embedValue = await fetchEmbed(value);
          url = value;
          break;
        default:
          embedType = Editor.EMBED.DEFAULT;
          embedValue = value;
      }

      const submitValue = {
        type: embedType,
        embedValue,
        url
      };

      this.props.onSubmit(submitValue);
    } catch (e) {
      console.log(e);
    } finally {
      this.props.closeModal();
    }
  };

  onChange = (event) => {
    event.persist();

    this.setState(() => ({
      value: event.target.value
    }));
  };

  onSelect = fieldName => (option) => {
    this.setState(() => ({
      [fieldName]: option
    }));
  };

  render() {
    const { embedType, value } = this.state;
    const { isOpen, title, submitText, closeModal } = this.props;

    return (
      <Portal>
        <Modal isOpen={isOpen} handleClose={closeModal}>
          <ModalTitleContainer>
            <h2>{title}</h2>
            <ModalCloseButton onClick={closeModal}>
              <Close />
            </ModalCloseButton>
          </ModalTitleContainer>
          <EmbedFormContainer onSubmit={this.onSubmit}>
            <ModalContent>
              <InputTitle>
                Вставьте ссылку или код
              </InputTitle>
              <TextArea
                type="text"
                value={value}
                onChange={this.onChange}
              />
              <WideModalController>
                <ModalCancel onClick={closeModal}>Отменить</ModalCancel>
                <ModalSubmit type="submit" value={submitText} />
              </WideModalController>
            </ModalContent>
          </EmbedFormContainer>
          <Preview
            value={value}
            embedType={embedType}
          />
        </Modal>
      </Portal>
    );
  }
}

export default EmbedForm;
