import React from 'react';


export default class SearchField extends React.Component {
	constructor(props){
		super(props)
		this.handleSearch = this.handleSearch.bind(this);
		//this.state = {
		//	posts: this.props && this.props.posts || []
		//};

	}
	render(){
		const props = this.props;
		// console.log(props);
		return (
			<div className="SearchField">
				<div className="ui fluid icon input">
					<input type="text" name="searchField" placeholder="Фильтр по заголовкам, адресам, авторам" onChange={this.handleSearch}/>
					<i className="search icon"></i>
				</div>
			</div>
		)
	}
	handleSearch(event){
		this.props.actionCreators.search({
			text: event.target.value,
			type: 'news'
		});
	}
}



