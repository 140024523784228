/* @flow */
import React from 'react';
import {
  LinkFormContainer,
  LinkFormRow,
  LinkFormInputTitle,
  LinkFormAccept,
} from '../../atoms';
import {
  LinkInput,
} from '../../molecules';
import {
  Input,
} from '/ui/atoms';


export type LinkFormState = {
  text: string,
  href: string
}

export type LinkFormProps = {
  text: string,
  href: string,
  onSubmit: (LinkFormState) => void
}

class LinkForm extends React.Component<LinkFormProps, LinkFormState> {
  static defaultProps = {
    text: '',
    href: '',
    childrens: [],
  };

  constructor(props: LinkFormProps) {
    super(props);

    this.state = {
      text: props.text,
      href: props.href,
      childrens: props.childrens,
    };
  }

  componentDidMount() {
    // fix for link that have more than one node (formatting)
    if (this.state.childrens.length > 1) {
      this.setState(() => ({ text: this.state.childrens[0].props.node.text }));
    }
  }

  onSubmit = (event: SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.props.onSubmit(this.state);
  };

  handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    event.persist();

    const fieldName: string = event.target.name;

    if (!fieldName) {
      throw TypeError('event.target.name is not found, check name attr');
    }

    this.setState(() => ({
      [fieldName]: event.target.value.trim(),
    }));
  };

  render() {
    const {
      text,
      href,
    } = this.state;

    return (
      <LinkFormContainer
        onClick={(e: SyntheticEvent<HTMLElement>) => e.stopPropagation()}
        onSubmit={this.onSubmit}
      >
        <LinkFormRow>
          <LinkFormInputTitle>Текст</LinkFormInputTitle>
          <Input
            type="text"
            name="text"
            value={text}
            onChange={this.handleChange}
          />
        </LinkFormRow>
        <LinkFormRow>
          <LinkFormInputTitle>Ссылка</LinkFormInputTitle>
          <LinkInput
            href={href}
            handleChange={this.handleChange}
          />
        </LinkFormRow>
        <LinkFormRow>
          <LinkFormAccept>
            Сохранить
          </LinkFormAccept>
        </LinkFormRow>
      </LinkFormContainer>
    );
  }
}

export default LinkForm;
