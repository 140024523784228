//
// Created on 11-03-2022.
//

export default {
  1646998844495: {
    types: [
      'text-pull-quote',
    ],
    up: (el) => {
      const newEl = el;

      const textElement = el.querySelector('.mz-publish-text-pull-quote__text');
      const titleElement = el.getElementsByTagName('h3')[0];

      titleElement && textElement && textElement.prepend(titleElement)

      return newEl;
    },
  },
};
