import React from 'react';
import {
  Icons,
  BlockControllerButton
} from "../../atoms";

const {
  RemoveBlock
} = Icons;

const deleteBlock = ({ node, editor }) => (event) => {
  event.preventDefault();
  event.stopPropagation();

  editor.removeNodeByKey(node.key);
};

const DeleteBlock = (props) => (
  <BlockControllerButton onClick={deleteBlock(props)}>
    <RemoveBlock />
  </BlockControllerButton>
);

export default DeleteBlock;