import React from 'react';
import styled from 'styled-components';
import {
  Newsletter,
} from './components';
import PullQuoteController from '../PullQuote/components/PullQuoteController';
import {
  nodeTypes,
  objectTypes,
} from '../../consts';

const Image = styled.img`
  max-width: 100%;
`;

const NewsletterNode = {
  type: nodeTypes.NEWSLETTER,
  object: objectTypes.BLOCK,

  schema: {
    isVoid: true,
  },

  create({ nodes }) {
    return {
      type: this.type,
      object: this.object,
      nodes,
    };
  },

  htmlRender(attrs) {
    return (
      <Newsletter
        {...attrs}
      />
    );
  },

  editorRender(attrs) {
    const { node, attributes, type } = attrs;
    return (
      <PullQuoteController
        {...attrs}
      >
        <Image src="/assets/newsletter-desktop.png" />
        <Newsletter
          attributes={attributes}
          node={node}
          type={type}
        >
        </Newsletter>
      </PullQuoteController>
    );
  },
};

export default NewsletterNode;
