export default function findDOMByKey(node) {
  if(!node) return null;

  const key = node.key;

  if(!window) return null;

  const el = window.document.querySelector('[data-key="' + key + '"]');

  if(!el) return null;

  return el;
}