import crossFill from './crossFill';
import checkmark from './checkmark';
import warning from './warning';
import trashAlt from './trashAlt';

export default {
  crossFill,
  checkmark,
  warning,
  trashAlt,
};
