import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { Input } from '/ui/atoms';
import { updateTransactionId } from '../../actions/actions';

@connect()
class ReduxFormInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.input.value || '',
      pendingTransaction: true,
    };
  }

  static defaultProps = {
    debounceTime: 0,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.input.value !== this.state.value) {
      this.setState(() => ({
        value: nextProps.input.value,
      }));
    }
  }

  debouncedChangeForm = debounce((value) => {
    this.setState(() => ({
      pendingTransaction: true,
    }));
    this.props.input.onChange(value);
  }, this.props.debounceTime, { maxWait: 5000 });

  onChange = (event) => {
    const { value } = event.target;
    const {
      pendingTransaction,
    } = this.state;

    if (pendingTransaction) {
      this.props.dispatch(updateTransactionId('awaiting', false));
    }

    this.debouncedChangeForm(value);
    this.setState(() => ({
      value,
      pendingTransaction: false,
    }));
  };

  render() {
    const { value } = this.state;
    const {
      label,
      type,
      meta: { warning },
      containerStyle,
      disabled,
    } = this.props;

    // Set flex container if children are passed
    // for dislpay elements in a row
    let style = {
      width: '100%',
    };
    if (containerStyle) {
      style = {
        ...containerStyle,
      };
    }

    return (
      <div style={style}>
        <Input
          onChange={this.onChange}
          placeholder={label}
          type={type}
          value={value}
          autoComplete="off"
          disabled={disabled}
        />
        {(warning && <span className="webpush__text-counter">{warning}</span>)}
      </div>
    );
  }
}

export default ReduxFormInput;
