import React, { Component } from 'react';
import {
  BlockControllerBar
} from '../../../../atoms';
import {
  BlockWrapper,
  DeleteBlock
} from '../../../../molecules';
import {
  PhotoChanger
} from '../index';

export default class PhotoController extends Component {
  render() {
    const { children, attributes, node, editor } = this.props;

    const viewType = node.data.get('viewType') || '';

    let float;
    let width;

    switch (viewType) {
      case 'left':
        float = 'left';
        width = '375px';
        break;
      default:
        float = 'none';
        width = 'auto';
    }

    return (
      <BlockWrapper
        node={node}
        editor={editor}
        float={float}
        width={width}
        {...attributes}
      >
        <BlockControllerBar>
          <DeleteBlock
            node={node}
            editor={editor}
          />
          <PhotoChanger
            editor={editor}
            node={node}
          />
        </BlockControllerBar>
        {children}
      </BlockWrapper>
    );
  }
}
