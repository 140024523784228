/* eslint-disable no-param-reassign */
// @ts-nocheck
import engineConfig from './engine';

/**
 * This file abstracts most logic around the configuration of the Reference UI.
 *
 * Configuration is an important part of the "reusability" and "generic-ness" of
 * the Reference UI, but if you are using this app as a starting point for own
 * project, everything related to configuration can largely be thrown away. To
 * that end, this file attempts to contain most of that logic to one place.
 */

const getConfig = () => {
  if (process.env.NODE_ENV === 'test') {
    return {};
  }

  if (engineConfig.engineName) return engineConfig;

  if (
    typeof window !== 'undefined'
    && window.appConfig
    && window.appConfig.engineName
  ) {
    return window.appConfig;
  }

  return {};
};

const toLowerCase = (string) => {
  if (typeof string === 'string') {
    return string.toLowerCase();
  }
  return string;
};
const capitalizeFirstLetter = (string) => {
  if (typeof string === 'string') {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};

// If no title field configuration has been provided, we attempt
// to use a "title" field, if one exists
const getTitleField = () => getConfig().titleField || 'title';
const getUrlField = () => getConfig().urlField;
const getFacetFields = () => getConfig().facets || [];
const getSortFields = () => getConfig().sortFields || [];
const getResultTitle = (result) => result.getSnippet(getTitleField());
const getSiteBaseURL = () => getConfig().siteBaseURL;
const getImageBaseURL = () => getConfig().imageBaseURL;

// Because if a field is configured to display as a 'title', we don't want
// to display it again in the fields list
const stripUnnecessaryResultFields = (resultFields) =>
  Object.keys(resultFields).reduce((acc, n) => {
    if (
      [
        '_meta',
        'id',
        toLowerCase(getTitleField()),
        toLowerCase(getUrlField()),
      ].includes(toLowerCase(n))
    ) {
      return acc;
    }

    acc[n] = resultFields[n];
    return acc;
  }, {});

const buildSearchOptionsFromConfig = () => {
  const config = getConfig();
  const searchFields = (config.searchFields || config.fields || []).reduce(
    (acc, n) => {
      acc = acc || {};
      acc[n] = {};
      return acc;
    },
    undefined,
  );

  const resultFields = (config.resultFields || config.fields || []).reduce(
    (acc, n) => {
      acc = acc || {};
      acc[n] = {
        raw: {},
        snippet: {
          size: 100,
          fallback: true,
        },
      };
      return acc;
    },
    undefined,
  );

  // We can't use url or title fields unless they're actually
  // in the reuslts.
  if (config.urlField) {
    resultFields[config.urlField] = {
      raw: {},
      snippet: {
        size: 100,
        fallback: true,
      },
    };
  }

  if (config.titleField) {
    resultFields[config.titleField] = {
      raw: {},
      snippet: {
        size: 100,
        fallback: true,
      },
    };
  }

  const searchOptions = {};
  searchOptions.result_fields = resultFields;
  searchOptions.search_fields = searchFields;
  return searchOptions;
};

const buildFacetConfigFromConfig = () => {
  const config = getConfig();

  const facets = (config.facets || []).reduce((acc, n) => {
    acc = acc || {};
    acc[n] = {
      type: 'value',
      size: 100,
    };
    return acc;
  }, undefined);

  return facets;
};

const buildSortOptionsFromConfig = () => {
  const config = getConfig();
  return [
    {
      name: 'По релевантности',
      value: '',
      direction: '',
    },
    ...(config.sortFields || []).reduce((acc, sortField) => {
      acc.push({
        name: 'Сначала более старые',
        value: sortField,
        direction: 'asc',
      });
      acc.push({
        name: 'Сначала более новые',
        value: sortField,
        direction: 'desc',
      });
      return acc;
    }, []),
  ];
};

const buildAutocompleteQueryConfig = () => {
  const { querySuggestFields } = getConfig();
  if (
    !querySuggestFields
    || !Array.isArray(querySuggestFields)
    || querySuggestFields.length === 0
  ) {
    return {};
  }

  return {
    suggestions: {
      types: {
        documents: {
          fields: getConfig().querySuggestFields,
        },
      },
    },
  };
};

export {
  getConfig,
  toLowerCase,
  capitalizeFirstLetter,
  getTitleField,
  getUrlField,
  getFacetFields,
  getSortFields,
  getResultTitle,
  getSiteBaseURL,
  getImageBaseURL,
  stripUnnecessaryResultFields,
  buildSearchOptionsFromConfig,
  buildFacetConfigFromConfig,
  buildSortOptionsFromConfig,
  buildAutocompleteQueryConfig,
};
