import * as R from 'ramda';

const unacceptableKeys = [
  'class',
  'data-type',
  'data-slate-custom'
];

export default (data) => {
  const objData = data.toObject();

  return R.omit(unacceptableKeys, objData);
};