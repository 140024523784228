import clientConfig from '/const/config';

const config = {
  siteBaseURL: clientConfig.publicUrl,
  imageBaseURL: process.env.AWS_S3_IMAGES_URL || 'https://s3.zona.media/',
  resultFields: [
    'image',
    'publication_date',
    'section',
    'body',
    'title',
    'url',
    'id',
  ],
  sortFields: [
    'publication_date',
  ],
  facets: [
    'section',
  ],
  titleField: 'title',
  urlField: 'url',
  thumbnailField: 'image',
  ...clientConfig.appSearchEngine,
};

export default config;
