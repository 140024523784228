function test(state = {}, action) {
	switch(action.type) {
		case 'EXPAND':
			//ui = Object.assign({}, state.ui, );
			return {
				...state,
				qid: action.qid,
				expand: action.expand
			};
		default:
			return state;
	}
}

export default test;
