import React, { Component } from 'react';
import {
  Icons,
  ModuleContainer,
  BlockControllerButton
} from '../../../../atoms';
import Editor from '../../../../Editor';
import {
  PhotoForm
} from '../index';

const {
  EditBlock
} = Icons;

class PhotoChanger extends Component {
  state = {
    isOpenModal: false
  };

  handleModal = () => {
    this.setState(prev => ({
      isOpenModal: !prev.isOpenModal
    }));
  };

  closeModal = () => {
    this.setState(() => ({
      isOpenModal: false
    }));
  };

  onSubmit = async (props) => {
    const {
      imgSrc,
      alt,
      viewType
    } = props;

    const {
      node
    } = this.props;

    const PhotoCaption = node.nodes.find(n => n.type === Editor.NODE.PHOTO_CAPTION);

    if (!PhotoCaption) {
      throw new TypeError('Photo-caption is not found');
    }

    const Photo = Editor.node.PhotoContainer({
      nodes: [
        Editor.node.Photo({ imgSrc, alt, viewType }),
        PhotoCaption
      ],
      viewType
    });

    this.props.editor.replaceNodeByKey(node.key, Photo);
  };

  render() {
    const { isOpenModal } = this.state;

    const {
      node
    } = this.props;

    if (!node) return null;

    const imageNode = node.nodes.get(0);

    if (!imageNode) return null;

    const ContainerData = node.data;
    const ImgData = imageNode.data;

    if (!ImgData || !ContainerData) return null;

    const imgSrc = ImgData.get('imgSrc');
    const alt = ImgData.get('alt');
    const viewType = ContainerData.get('viewType');

    return (
      <ModuleContainer>
        {isOpenModal ? (
          <PhotoForm
            handleModal={this.handleModal}
            closeModal={this.closeModal}
            isOpen={isOpenModal}
            onSubmit={this.onSubmit}
            submitText={'Изменить'}
            title={'Изменить картинку:'}
            initialState={{
              imgSrc,
              viewType,
              alt
            }}
          />) : null
        }
        <BlockControllerButton
          onMouseDown={this.handleModal}
        >
          <EditBlock />
        </BlockControllerButton>
      </ModuleContainer>
    );
  }
}

export default PhotoChanger;
