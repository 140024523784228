import styled from 'styled-components';

const ActionButton = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 31px;
	height: 31px;
	opacity: 0.7;
	background-color: #222222;
	cursor: pointer;
`;

export default ActionButton;
