//import styles from './styles.styl';
import React, { PropTypes } from 'react';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { auth } from '../../actions/actions';
import {connect} from 'react-redux';
import {history} from '../../store/store.js';
import "./Auth.styl";

import Notifications from '../Notifications';
import { channels } from '../../../common/notify-channel';

class Auth extends React.Component {
	async handleSubmit(e){
		const {dispatch, email, password, otp_code } = this.props;
		e.preventDefault();
		dispatch(auth(email, password, otp_code));
	}
	componentWillMount() {
		if (this.props.isAuthenticated)
		{

			history.push(`/news`);
		}
	}
	componentWillReceiveProps(props){
		if (props.isAuthenticated)
		{
			history.push(`/news`);
		}
	}
	render() {
		const { handleSubmit, err, otp_enabled} = this.props;
		return (
			<div className="ui centered grid">
				<div className="six wide column">
					<div className="LoginForm">
						<div className="ui dividing header">медиазона</div>
						<form className="ui form" onSubmit={(e) => this.handleSubmit(e)}>
								<div className="field">
									<div className="ui transparent massive input">
										<Field type="email" component="input" placeholder="Электронная почта" name="email"/>
									</div>
								</div>
								<div className="field">
									<div className="ui transparent massive input">
										<Field type="password" component="input" placeholder="пароль" name="password"/>
									</div>
								</div>
								{ otp_enabled && 
									(<div className="field">
										<div className="ui transparent massive input">
											<Field type="otp_code" component="input" placeholder="код двухфакторной аутентификации" name="otp_code"/>
										</div>
									</div>)
								}
								<div>
									<button className="ui button basic right floated large button_auth"
									        type="submit" onClick={(e) => this.handleSubmit(e)}>Войти</button>
									{err
										? <div>{err.message}</div>
										: null}
								</div>
						</form>
					</div>
				</div>
				<Notifications
					limit={3}
					socketEvents={[
						channels.authActions
					]}
				/>
			</div>
		);
	}
}

const selector = formValueSelector('auth-form');

const AuthForm = reduxForm({
	form: 'auth-form', // a unique identifier for this form
})(Auth);

const ConnectedPostForm = connect(
   (state) => ({
		isAuthenticated: !!state.user,
		email: selector(state, 'email'),
		password: selector(state, 'password'),
		otp_code: selector(state, 'otp_code'),
		otp_enabled: state.otp.enabled,
		err: state.authErr,
	})
)(AuthForm);

export default ConnectedPostForm;
