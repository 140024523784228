import React, { PureComponent } from 'react';
import './RelatedField.styl';
import {
    getAllThemesForPost
} from '../../actions/actions.js'
import {connect} from 'react-redux';
import Select from 'react-select';
import {syncCollection} from '../../lib/sync-form';
import {relatedNames} from '../../utils/types.js';
import { Link } from 'react-router';
import {types} from '../../utils/constants.js';
import {
    Label
} from '/ui/atoms';

@connect(
    (state, props)=> {
        return {
            syncedEntity: props.entity.toLowerCase(),
            syncedId: props.id,
            initial_list:state.related.initial_themes ? state.related.initial_themes: []
        }
    },
    (dispatch, ownProps) => {
        return {
            dispatch: dispatch,
        }
    }
)
@syncCollection()

export default class ThemesPost extends PureComponent {
    constructor(props){
        super(props);
        this.addRelated = this.addRelated.bind(this);
        this.state = {value: ''};
    }

    addRelated(values){
        this.props.add(values.value);
    }

    componentDidMount(){
        this.props.dispatch(getAllThemesForPost({
            post_id: this.props.syncedId,
        }));
    }

    componentDidUpdate(){

    }

    deleteRelated(entity_id){
        this.props.remove(entity_id);
    }

    render(){
        const {
            entity,
            formValues,
            title,
            placeholder,
            filter,
            disabled,
        }  = this.props;

        if (!formValues) return null;

        const url = '/theme/';
        const initial_list = typeof(filter) === 'function' ? filter(this.props.initial_list) : this.props.initial_list;
        let list = [];
        let options_initial = [];
        for (var p in formValues) {
            list.push(formValues[p]);
        }

        
        options_initial = initial_list.reduce(
            (result, item) => {
                if (!formValues[item.id]) {
                    result.push({
                        "label": item.title || item.name,
                        "value": item.id,
                        "is_region_selector": item.is_region_selector,
                    });
                }
                return result;
        }, [])

        list = typeof(this.props.filter) === 'function' ? this.props.filter(list) : list;

        if (!(options_initial.length || list.length)) return null;
        
        return (
            <div className="relatedField">
                {!disabled &&
                    (<div className="field field_related">
                        <Label>{title}</Label>
                        <div className="relatedField ui block">
                            <div className="PostSuggest ui search selection" id={"PostSuggest-"+entity}>
                                <Select
                                    options={options_initial}
                                    onChange={this.addRelated}
                                    placeholder={placeholder}
                                    searchable={true}
                                    value={null}
                                    styles={{
                                        menu: styles => ({
                                            ...styles,
                                            zIndex: 100,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>)
                }
                {list.length>0
                    ?
                    (
                        <div className={"ui bottom attached "+(entity!='event_context' ? 'segment': '')}>
                            <div className={"relatedPosts ui basic "+(entity!='event_context' ? 'segment': '')}>
                                <div className="ui small dividing header">{title}
                                    <div className="ui horizontal tiny right floated label">{list.length}</div>
                                </div>
                                <div className="relatedContainer">
                                    {
                                        list.map((field)=>{
                                            field.title = field.title? field.title: field.name;
                                            return (
                                                <p className="relatedPostsItem" key={field.id}>
                                                    <div className="PostTitle">
                                                        <div className="title">
                                                            <Link to={url + field.id}>{field.title}</Link>
                                                            <i className="unlinkPost ui grey delete icon"
                                                               onClick={this.deleteRelated.bind(this, field.id)}></i>
                                                        </div>
                                                    </div>
                                                </p>
                                            );
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    )
                    :
                    null}

            </div>
        )
    }

}



