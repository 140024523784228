import React from 'react';
import {
  DocsPullQuote,
  PullQuoteController,
} from './components';
import {
  nodeTypes,
  objectTypes,
} from '../../consts';
import {
  BlockPlaceholder,
} from '../../molecules';

const DocsPullQuoteNode = {
  type: nodeTypes.DOCUMENT_PULL_QUOTE,
  object: objectTypes.BLOCK,

  create({ editor, viewType, imgSrc, alt }) {
    return {
      type: this.type,
      object: this.object,
      data: {
        imgSrc,
        viewType,
        alt,
      },
      nodes: [
        editor.node.Paragraph(),
      ],
    };
  },

  schema: {
    nodes: [{
      match: [
        {
          type: nodeTypes.PARAGRAPH,
        },
        {
          type: nodeTypes.BULLETS,
        },
        {
          type: nodeTypes.BULLET,
        },
      ],
      min: 1,
    }],
  },

  htmlRender(attrs) {
    return (
      <DocsPullQuote
        {...attrs}
      />
    );
  },

  editorRender(attrs) {
    const { node, attributes } = attrs;
    return (
      <PullQuoteController
        {...attrs}
      >
        <DocsPullQuote
          attributes={attributes}
          node={node}
        >
          <BlockPlaceholder
            text={node.text}
            placeholder={'Текст...'}
            {...attrs}
          />
        </DocsPullQuote>
      </PullQuoteController>
    );
  }
};

export default DocsPullQuoteNode;

