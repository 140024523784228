import React, { Component } from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import { allTransactionsApplied } from '../../store/selectors';
import './PostForm.styl';

@connect(
  (state) => {
    return {
      transaction: allTransactionsApplied(state)
    };
  }
)
class TransformPostButton extends Component {

  state = {
    isActiveButton: true
  }


  componentWillReceiveProps(next) {
    this.setState(() => ({ isActiveButton: next.transaction }));
  }

  render() {
    const { id, typePost, disabled } = this.props;
    if (typePost === 'news') {
      if (disabled) {
        return (<div>трансформация новости недоуступна</div>);
      }
      if (this.state.isActiveButton) {
        return (
          <Link className="ui button" to={`/transformer_post/${id}`}>Трансформировать новость</Link>
        );
      }
      return (
        <div className="ui button transform-post button_disable">Подождите...</div>
      );
    }
    return null;
  }
}


export default TransformPostButton;

