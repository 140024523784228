import React from 'react';
import { EmbedController } from './organisms';
import { EmbedContainer } from './atoms';
import {
  nodeTypes,
  objectTypes
} from '../../consts';


const EmbedContainerNode = {
  type: nodeTypes.EMBED_CONTAINER,
  object: objectTypes.BLOCK,

  create({ nodes }) {
    return {
      type: this.type,
      object: this.object,
      nodes
    };
  },

  schema: {
    nodes: [{
      match: [
        { type: nodeTypes.EMBED },
        { type: nodeTypes.EMBED_CAPTION },
        { type: nodeTypes.YOUTUBE }
      ],
      min: 2
    }],
  },

  editorRender(attrs) {
    return (
      <EmbedController
        {...attrs}
      >
        <EmbedContainer
          {...attrs}
        />
      </EmbedController>
    );
  },

  htmlRender(attrs) {
    return (
      <EmbedContainer
        {...attrs}
      />
    );
  }
};

export default EmbedContainerNode;

