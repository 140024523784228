/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router';

import format from 'date-fns/format';
import ruLocale from 'date-fns/locale/ru';

export default (props) => {
  const {
    id,
    title,
    partner_name,
    is_archived,
    is_published,
    createdAt,
    showsCount,
    showsLimit,
    weight,
    is_external_advert,
  } = props;

  const path = is_external_advert ? '/external_adcampaign/' : '/adcampaign/';
  return (
    <tr key={id}>
      <td className="two wide">
        { format(new Date(createdAt), 'd MMM HH:mm', { locale: ruLocale }) }
      </td>
      <td className="three wide column">
        <Link to={path + id} className="post-item">
          { title || 'Без названия' }
        </Link>
      </td>
      <td className="two wide column">
        <Link to={path + id} className="post-item">
          { partner_name || 'Партнер не указан' }
        </Link>
      </td>
      <td className="one wide column">
        <span><i className="eye icon" />{showsCount} / {showsLimit}</span>
      </td>
      <td className="one wide column">
        <span><i className="filter icon" />{weight}</span>
      </td>
      <td className="two wide column">
        {is_published && <span><i className="world icon" />На сайте</span>}
        {is_archived && <span><i className="trash icon" />В архиве</span>}
      </td>
    </tr>
  );
};
