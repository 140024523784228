import React from 'react';
import { SelectedRange } from './atoms';
import {
  markTypes
} from './consts';

const MarkTags = {
  em: markTypes.ITALIC,
  i: markTypes.ITALIC,
  strong: markTypes.BOLD,
  b: markTypes.BOLD,
  u: markTypes.UNDERLINE,
  sup: markTypes.SUP
};

const Mark = {
  TAGS: MarkTags,

  render({ type, children, attributes }) {
    switch (type) {
      case markTypes.BOLD:
        return <b {...attributes}>{children}</b>
      case markTypes.SUP:
        return <sup {...attributes}>{children}</sup>
      case markTypes.ITALIC:
        return <em {...attributes}>{children}</em>
      case markTypes.UNDERLINE:
        return <u {...attributes}>{children}</u>
      case markTypes.SELECTED_RANGE:
        return <SelectedRange {...attributes}>{children}</SelectedRange>
      default:
        return null;
    }
  }
};

export default Mark;
