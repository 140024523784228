import React from 'react';

const EmbedCaption = (props) => {
  const { attributes, children } = props;

  return (
    <figcaption
      className="mz-publish-embed__comment"
      {...attributes}
    >
      {children}
    </figcaption>
  );
};

export default EmbedCaption;