import fetchJsonp from 'fetch-jsonp';
import getEmbedType from './getEmbedType';
import Editor from '../Editor';

const iframelyApiKey = 'fba778db35e06c16e60e4c';

const fetchEmbed = async (url) => {
  try {
    let data = null;

    const options = {
      method: 'GET'
    };

    const embedType = getEmbedType(url);

    switch (embedType) {
      case Editor.EMBED.TWITTER: {
        data = await fetchJsonp(`https://publish.twitter.com/oembed?url=${url}`, options);
      }
        break;
      case Editor.EMBED.FACEBOOK: {
        data = await fetchJsonp(`https://www.facebook.com/plugins/post/oembed.json/?url=${url}`, options);
      }
        break;
      case Editor.EMBED.INSTAGRAM: {
        data = await fetch(`https://api.instagram.com/oembed?url=${url}`, options);
      }
        break;
      default: {
        data = await fetch(`https://iframe.ly/api/oembed?api_key=${iframelyApiKey}&url=${url}`, options);
      }
    }

    if (!data) return null;

    const json = await data.json();

    return json.html;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export default fetchEmbed;
