import React, { Component } from 'react';
import { connect } from 'react-redux';
import Masonry from 'react-masonry-component';
import { getNotes } from '../../actions/notes';
import { notesSelector } from '../../store/selectors';
import Note from './Note';
import config from '../../const/config';
import './NotesList.styl';

@connect((state) => {
  return {
    note: state.note,
    error: state.ui.error,
    noteLoaded: state.ui.noteLoaded,
    noteCompleted: state.ui.noteCompleted,
    noteList: notesSelector(state.note.normalize),
    searchQuery: state.note.searchQuery
  };
})
export default class NotesList extends Component {
  noteContainerRef = null;

  onScroll = () => {
    if (this.props.noteCompleted || !this.props.noteLoaded)
      return;
    if (this.noteContainerRef.getBoundingClientRect().bottom < window.innerHeight * 1.1) {
      let ts = null;
      if (this.props.noteList.length)
        ts = this.props.noteList[this.props.noteList.length - 1].ts;
      this.props.dispatch(getNotes(this.props.searchQuery, ts));
    }
  } 

  componentDidMount() {
    this.props.dispatch(getNotes());
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  render() {
    const { error, noteList } = this.props;

    if (error) {
      return (
        <p>{error}</p>
      );
    }
    const itemList = noteList.map(value => (
      <Note type={value.type} key={value.id} text={value.text} date={value.publish_date} url={config.publicUrl + value.canonical} />
    ));

    return (
      <div ref={(el) => this.noteContainerRef = el} className="notes-list__note-container">
        <Masonry className="notes-list__notes-container">
          {itemList}
        </Masonry>
      </div>
    );
  }
}
