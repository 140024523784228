// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as R from 'ramda';
import type { OnDragEndResponder } from 'react-beautiful-dnd';
import {
  Label,
  Input,
} from '/ui/atoms';
import {
  DnDList,
} from '/ui/organism';
import {
  syncCollection,
} from '/lib/sync-form';
import {
  updateAuthorsFromPost,
} from '/actions/actions';

type Props = {|
  syncedId: number,
  syncedEntity: string,
  authors: any,
  onDelete: (entityId: number) => void,
|}

type State = {|
  authors: any,
|}

@connect(
  (state, props) => ({
    syncedEntity: 'authors',
    syncedId: props.syncedId,
  }), (dispatch) => ({
    updateAuthorsFromPost: (props) => {
      dispatch(updateAuthorsFromPost(props));
    },
  }),
)
@syncCollection()
class AuthorList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      authors: props.authors,
    };
  }

  componentWillUpdate(prevProps: Props) {
    if (prevProps.authors !== this.props.authors) {
      this.setState(() => ({
        authors: this.props.authors,
      }));
    }
  }

  onDragEnd = (value: OnDragEndResponder) => {
    const {
      destination,
      source,
    } = value;

    if (!destination || !source) return;

    const reorderItems = R
      .move(source.index, destination.index, this.props.authors)
      .map((item, index) => ({ ...item, id: item.id, sort_id: index }));

    this.setState(() => ({
      authors: reorderItems,
    }));
    this.props.updateAuthorsFromPost({
      list: reorderItems,
      postId: this.props.syncedId,
    });
  };

  authorItem = author => (
    <span className="authors__left">
      {`${author.name} (${author.eng_name || 'translate not avaliable'})${author.smi ? (` (${author.smi.trim()})`) : ''}${author.place ? (`, ${author.place}`) : ''}`}
    </span>
  );

  authorItemEditable = author => (
    <div className="ui stackable celled grid container">
      <div className="two column row">
        <div className="column">
          <Label className="authors__header">Имя</Label>
          <Input type="text" defaultValue={author.name} placeholder="Имя автора" onChange={(e) => { this.props.onChange('name', e.target.value); }} />
        </div>
        <div className="column">
          <Label className="authors__header">Имя латиницей</Label>
          <Input type="text" defaultValue={author.eng_name} placeholder="Name" onChange={(e) => { this.props.onChange('eng_name', e.target.value); }} />
        </div>
      </div>
      <div className="two column row">
        <div className="column">
          <Label className="authors__header">Издание</Label>
          <Input type="text" defaultValue={author.smi} placeholder="Издание" onChange={(e) => { this.props.onChange('smi', e.target.value); }} />
        </div>
        <div className="column">
          <Label className="authors__header">Место</Label>
          <Input type="text" defaultValue={author.place} placeholder="Место" onChange={(e) => { this.props.onChange('place', e.target.value); }} />
        </div>
      </div>
    </div>
  );

  createAuthorsList = () => {
    const {
      isEditable,
      isEditedId,
      onDelete,
      onSave,
      onEdit,
    } = this.props;

    return this.state.authors.map(author => ({
      id: author.id,
      component: (
        <div
          className={`autoform-array-item ${((isEditable && isEditedId === author.id) ? 'ui container' : '')}`}
          style={{
            clear: 'both',
            marginBottom: '14px',
          }}
          key={author.id}
        >
          {
            (isEditable && isEditedId === author.id)
              ? this.authorItemEditable(author)
              : this.authorItem(author)
          }
          <div className="white ui icon buttons authors__right">
            {(isEditedId === author.id) ? (
              <button className="ui button" onClick={() => { onSave(author.id); }}>
                <i className="save layout icon" />
              </button>
            ) : (
              <button className="ui button" onClick={() => { onEdit(author.id); }}>
                <i className="edit layout icon" />
              </button>
            )
            }
            <button className="ui button" onClick={() => { onDelete(author.id); }}>
              <i className="delete layout icon" />
            </button>
          </div>
        </div>
      ),
    }));
  };

  render() {
    const {
      authors,
    } = this.state;

    const {
      syncedId,
    } = this.props;

    if (!authors || authors.length <= 0) return null;

    const authorsList = this.createAuthorsList();

    return (
      <ul className="authors__list">
        <DnDList
          onDragEnd={this.onDragEnd}
          listId={syncedId}
          items={authorsList}
        />
      </ul>
    );
  }
}

export default AuthorList;
