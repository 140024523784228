import styled from 'styled-components';

export const FormWrapper = styled.form`
  display: flex;
  flex-flow: column wrap;
  margin-top: 14px;

  .dropzone-content {
    margin-top: 14px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const ActionWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
`;

export const PreviewWrapper = styled.div`
  position: relative;
`;
