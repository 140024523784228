import React from 'react';
import {
  LinkWrapper,
} from './organisms';
import {
  nodeTypes,
  objectTypes,
} from '../../consts';

const LinkNode = {
  type: nodeTypes.LINK,
  object: objectTypes.INLINE,
  isTag: true,

  create({ href = '', isCreated = false }) {
    return {
      type: this.type,
      object: this.object,
      data: {
        href,
        isCreated,
      },
    };
  },

  htmlRender({ node, attributes, children }) {
    const href = node.data.get('href');

    if (!href) {
      return (
        <React.Fragment>
          {children}
        </React.Fragment>
      );
    }

    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer nofollow"
        {...attributes}
      >
        {children}
      </a>
    );
  },

  editorRender(attrs) {
    return (
      <LinkWrapper
        {...attrs}
      />
    );
  }
};

export default LinkNode;
