import React, { Component } from 'react';
import EmbedChanger from '../EmbedChanger';
import {
  BlockControllerBar
} from '../../../../atoms';
import {
  DeleteBlock,
  BlockWrapper
} from '../../../../molecules';

export default class EmbedController extends Component {
  render() {
    const { children, attributes, node, editor } = this.props;

    return (
      <BlockWrapper
        node={node}
        editor={editor}
        {...attributes}
      >
        <BlockControllerBar>
          <DeleteBlock
            node={node}
            editor={editor}
          />
          <EmbedChanger
            editor={editor}
            node={node}
          />
        </BlockControllerBar>
        {children}
      </BlockWrapper>
    )
  }
}