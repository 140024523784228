import React from 'react';
import {
  nodeTypes,
  objectTypes,
  markTypes,
} from '../../consts';

const HeadingOneNode = {
  type: nodeTypes.HEADING_ONE,
  object: objectTypes.BLOCK,
  isTag: true,

  create() {
    return {
      type: this.type,
      object: this.object,
      nodes: [
        {
          object: objectTypes.TEXT,
        },
      ],
    };
  },

  schema: {
    marks: [{ type: markTypes.ITALIC }],
  },

  htmlRender({ attributes, children }) {
    return <h1 {...attributes}>{children}</h1>;
  },
};

export default HeadingOneNode;
