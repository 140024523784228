import React from 'react';

const RoleTags = ({ size, style, title, isAdmin, isEditor, isActive }) => {
  return (
    <div style={style}>
      {title && <p><strong>{title}</strong></p>}
      {isAdmin
        ? (<div className={`ui ${size || '' || ''} label`}><i className="key icon"></i>Администратор</div>)
        : null
      }
      {isEditor
        ? (<div className={`ui ${size || ''} label`}><i className="pencil icon"></i>Редактор</div>)
        : null
      }
      {isActive
        ? (<div className={`ui ${size || ''} green label`}><i className="check icon"></i>Активен</div>)
        : (<div className={`ui ${size || ''} red label`}><i className="ban icon"></i>Неактивен</div>)
      }
    </div>
  )
}

export default RoleTags;
