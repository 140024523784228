import React from 'react';
import config from '/const/config';

const MultiContextPullQuote = (props) => {
  const { node, attributes } = props;
  const { data } = node;

  const multiContext = data.get('multiContext');
  const contextTitle = data.get('contextTitle');

  if (!multiContext) return null;

  return (
    <ul className={'mz-publish-context-list'} {...attributes}>
      <div className="mz-publish-context-list__title">{contextTitle}</div>
      {multiContext.map(item => (
        <li className="mz-publish-context-list__item" key={item.id}>
          <a
            href={config.publicUrl + item.url}
            target="_blank"
            rel="noopener noreferrer nofollow"
            title={item.title}
          >
            {item.title}
          </a>
        </li>))}
    </ul>
  );
};


export default MultiContextPullQuote;
