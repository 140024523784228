// @flow
import styled from 'react-emotion';

const Input = styled('input')`
  height: 40px;
  width: 100%;
  border: 1px solid #E9EAEC;
  border-radius: 2px;
  padding-left: 14px;
  font-family: 'Graphik LC',sans-serif !important;
  font-size: 13px;
  color: #293043;
  
  &:focus {
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  
  &:disabled {
    background: #E9EAEC;
    color: rgba(41, 48, 67, 0.5);
  }
  
  &::placeholder {
   color: rgba(41, 48, 67, 0.5);
  }
`;

export default Input;
