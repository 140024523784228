import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../actions/actions'
import { usersListSelector } from './users-selectors';
import RoleTags from './RoleTags';

@connect(
    state => ({
        usersList: usersListSelector(state),
        user: state.user.user,
    }),
    dispatch => ({
        dispatch
    })
)
export default class UsersList extends React.Component {

    static propTypes = {
        usersList: PropTypes.array,

        fetchUsersList: PropTypes.func
    }

    constructor (props) {
        super(props);
        this.newUser = this.newUser.bind(this);
    }

    componentDidMount () {
        this.props.dispatch(actions.fetchUsersList());
    }

    newUser() {
        this.props.dispatch(actions.createUser());
    }

    render () {

        const { usersList } = this.props;

        return (
            <div className="ui container">
                <div className="ui padded grid">
                    <div className="column">
                        <table className="ui selectable very padded table">

                            <tbody>

                            {usersList.map(user => {
                                return (
                                    <tr key={user.id}>
                                        <td>

                                            <Link to={`/users/${user.id}`}>
                                                { user.name }
                                            </Link>
                                            <RoleTags
                                                isAdmin={user.is_admin}
                                                isEditor={user.is_editor}
                                                isActive={user.active}
                                                style={{display:'inline-flex', marginLeft:'10px'}}
                                                size="mini"
                                            />
                                        </td>
                                    </tr>
                                );

                            })}

                            </tbody>

                        </table>
                        {this.props.user.is_admin
                            &&(<a
                                    className="ui link basic blue button"
                                    onClick={this.newUser}
                                >
                                    Добавить пользователя
                                </a>
                            )
                        }
                    </div>
                </div>
            </div>
        );

    }

};

/*

 table.ui.selectable.very.padded.table
 tbody
 each users
 tr
 td
 if isInRole "admin"
 a(href="{{ pathFor 'usersEdit' userId=_id}}") {{ name }}
 else
 | {{ name }}
 td

 * */