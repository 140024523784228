import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import syncForm from '/lib/sync-form';
import SlateRichText from "/components/SlateRichText/SlateRichText";
import Preview from '/components/Preview/Preview';

import RelatedField from '/components/RelatedField/RelatedField';

import {
  updateTheme,
  deleteTheme,
} from '/actions/actions';

import { generateSlug } from '/lib/generate-slug';

import {
  Field as FormField,
} from '/ui/molecules';

import {
  Label,
  Comment,
  Input,
} from '/ui/atoms';
import { SingleFileUploader } from '/features/FileUploader';
import {
  PartnerSelect,
} from '/features/Partners';

const syncInfo = require('/../common/sync-channel');


const validate = (values) => {
  const errors = {};
  const requiredMes = 'Обязательное поле';
  if (!values.title || values.title.trim() === '') {
    errors.title = requiredMes;
  }
  if (!values.slug || values.slug.trim() === '') {
    errors.slug = requiredMes;
  }
  return errors;
};

const ReduxFormInput = ({ input, label, type, meta: { warning } }) => (
  <div>
    <Input autoComplete="off" {...input} placeholder={label} type={type} />
    {(warning && <span className="webpush__text-counter">{warning}</span>)}
  </div>
);

@connect(
  (state, ownProps) => {
    const selector = formValueSelector(syncInfo.formFunc('theme', ownProps.params.id));
    return {
      id: ownProps.params.id,
      ui: state.ui,
      title: selector(state, 'title'),
      slug: selector(state, 'slug'),
      is_published: selector(state, 'is_published'),
      is_deleted: selector(state, 'is_deleted'),
      syncedEntity: 'theme',
      socpic: selector(state, 'socpic'),
      syncedId: ownProps.params.id,
      image: selector(state, 'image'),
      logo: selector(state, 'logo'),
      custom_template: selector(state, 'custom_template'),
      partner_id: selector(state, 'partner_id'),
    };
  },
  (dispatch, ownProps) => ({
    changeField: (field, value) => {
      dispatch(change(syncInfo.formFunc('theme', ownProps.params.id), field, value));
    },
    updateTheme: (themeObj, id) => {
      dispatch(updateTheme(themeObj, id));
    },

    delete: (id, checkString) => {
      const themeName = prompt('Для подтверждения удаления темы введите её название.');
      if (themeName === checkString) {
        dispatch(deleteTheme(id));
      } else {
        alert('Неверное проверочное слово. Тема не будет удалена.');
      }
    },
    changePartner: (form, id) => { dispatch(change(form, 'partner_id', id)); },
    dispatch,
  }),
)
@syncForm()
@reduxForm({
  validate,
})
class Theme extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isPublished: false,
      isPreview: false,
    };
    this.changeField = this.changeField.bind(this);
    this.generateSlug = this.generateSlug.bind(this);
    this.submitTheme = this.submitTheme.bind(this);
    this.delete = this.delete.bind(this);
    this.publish = this.publish.bind(this);
  }

  generateSlug() {
    const slug = generateSlug(this.props.title);
    this.props.dispatch(change(this.props.form, 'slug', slug));
  }

  changeField({ target: { name, value } }) {
    this.props.changeField(name, value);
  }

  publish() {
    this.setState({ isPublished: true });
  }

  submitTheme(values) {
    if (this.state.isPublished) {
      this.props.updateTheme(
        {
          title: values.title,
          slug: values.slug,
          is_published: true,
        },
        this.props.id,
      );
    } else {
      this.props.updateTheme(
        {
          title: values.title,
          slug: values.slug,
          is_published: values.is_published,
        },
        this.props.id,
      );
    }
  }

  delete() {
    this.props.delete(this.props.id, this.props.title);
  }

  handlePreview(previewMode) {
    this.setState(
      () => ({ isPreview: previewMode }),
    );
  }

  handleChangeImage = field => (s3Path) => {
    const { changeField } = this.props;
    changeField(field, s3Path);
  };

  render() {
    const {
      form,
      formValues,
      handleSubmit,
      is_published,
      valid,
      socpic,
      logo,
    } = this.props;

    const stickyStyle = {
      visibility: this.props.ui.ckToolbar ? 'visible' : 'hidden',
      marginBottom: 0,
    };

    if (!formValues) return null;
    return (
      <div className="theme-form">
        <div id="ckeditor_toolbar" name="ckeditor_toolbar" className="ui sticky" style={stickyStyle} />
        <form id="ThemeForm" className="ui form" onSubmit={handleSubmit(this.submitTheme)}>
          <div className="ui container basic segment">
            <div className="ui grid">
              <div className="left floated left aligned twelve wide column">
                <div className="ui dividing large header">Тема</div>
              </div>
              <div className="right aligned four wide column">
                <div className="ui buttons">
                  <button
                    className={`ui ${this.state.isPreview ? '' : 'primary'} button`}
                    onClick={() => { this.handlePreview(false); }}
                  >Редактировать
                  </button>
                  <button
                    className={`ui ${this.state.isPreview ? 'primary' : ''} button`}
                    onClick={() => { this.handlePreview(true); }}
                  >
                    Просмотр
                  </button>
                </div>
              </div>
            </div>
            {this.state.isPreview ? (
              <Preview
                special="theme"
                id={this.props.id}
                isReady={this.state.isPreview}
                entryImage={this.props.image}
                socpic={socpic}
              />
            ) : (
              <div className="ui vertically padded grid">
                <div className="ten wide column">
                  <FormField>
                    <Label>Заголовок</Label>
                    <Field name="title" type="text" label="Заголовок" component={ReduxFormInput} />
                  </FormField>

                  <FormField>
                    <Label>Анонс</Label>
                    <Field name="announce" label="Анонс" component={SlateRichText} />
                  </FormField>

                  <FormField>
                    <Label>URL</Label>
                    <Field name="slug" id="slug" type="text" component={ReduxFormInput} />
                  </FormField>

                  <FormField>
                    <span onClick={this.generateSlug} className="ui button">
                        Автоматически сгенерировать
                    </span>
                  </FormField>

                  {!is_published && valid && (
                    <button
                      type="submit"
                      className="ui inverted green button"
                      onClick={this.publish}
                    >
                      Опубликовать
                    </button>
                  )}

                  <FormField>
                    <button className="ui inverted red button" onClick={this.delete}>
                        Удалить
                    </button>
                  </FormField>

                  <FormField>
                    <Label>Партнер</Label>
                    <Field
                      name="partner_id"
                      id="partner_id"
                      component={PartnerSelect}
                      onOptionSelect={(value) => { this.props.changePartner(form, value); }}
                      defaultValue={this.props.partner_id}
                    />
                  </FormField>

                  <FormField>
                    <RelatedField
                      entity="theme_context"
                      id={this.props.id}
                      key={`theme_context${this.props.id}`}
                    />
                  </FormField>
                </div>

                <div className="six wide column">
                  <FormField>
                    <div className="ui top attached block header">SMM изображение</div>
                    <div className="ui bottom attached segment">
                      <div className="ui fluid image">
                        {socpic && <img src={SERVER_SIDE_VARS.s3_url + socpic} />}
                      </div>
                    </div>
                  </FormField>

                  <FormField>
                    <Label>Иконка</Label>
                    <SingleFileUploader
                      name="logo"
                      form={form}
                      file={logo}
                      handler={this.handleChangeImage('logo')}
                      accepted="image/*"
                    />
                    <Comment>*рекомендуемое разрешение 50х50</Comment>
                  </FormField>
                </div>
              </div>
            )
            }
          </div>
        </form>
      </div>
    );
  }
}

export default Theme;
