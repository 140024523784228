// @flow
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Field as ReduxFormField
} from 'redux-form';
import ReduxFormInput from '/components/ReduxFormInput';
import { searchableValue } from '/lib/searchableValue';
import {
  Container
} from './styles';
import {
  Label
} from '/ui/atoms';
import {
  Field,
} from '/ui/molecules';
import {
  syncCollection,
} from '/lib/sync-form';
import {
  RelatedWidgetList,
  RelatedSearch,
  RelatedWidgetHeader,
} from '../index';
import type {
  RelatedItem,
  Searched
} from '../index';

type Props = {|
  syncedEntity: string,
  formValues: any,
  add: Function,
  remove: Function,
  syncedId: number,
  isTitle: boolean,
  searched: Searched
|}

@connect(
  (state, props) => ({
    syncedEntity: props.entity.toLowerCase(),
    syncedId: props.id,
    searched: state.related[`search_${props.entity}`] || [],
  })
)
@syncCollection()
class RelatedWidget extends Component<Props> {
  titleFieldName = `${this.props.syncedEntity}_title`;

  addRelatedItem = (values: RelatedItem) => {
    const {
      formValues,
    } = this.props;

    const { id: postId } = searchableValue.destructure(values.value);

    this.props.add(postId, {
      sortId: Object.values(formValues).length,
    });
  };

  deleteRelatedItem = (entityId: number) => {
    this.props.remove(entityId);
  };

  render() {
    const {
      formValues,
      syncedEntity,
      syncedId,
      isTitle,
      searched,
    } = this.props;

    let sortedValuesList = [];
    let options;

    if (formValues) {
      sortedValuesList = Object.values(formValues).sort((a: any, b: any) => a.sort_id - b.sort_id);

      options = searched.filter((el) => !formValues[el.id] && el.id !== parseInt(syncedId));
    }

    return (
      <Container>
        <RelatedWidgetHeader
          count={sortedValuesList.length}
        />
        {isTitle ? (
          <Field>
            <Label>Заголовок</Label>
            <ReduxFormField
              id={this.titleFieldName}
              name={this.titleFieldName}
              placeholder="Заголовок"
              component={ReduxFormInput}
            />
          </Field>) : null
        }
        <RelatedSearch
          onChange={this.addRelatedItem}
          placeholder="Связать с публикацией..."
          entity={syncedEntity}
          id={syncedId}
          searched={options}
        />
        <RelatedWidgetList
          entity={syncedEntity}
          id={syncedId}
          items={sortedValuesList}
          onDelete={this.deleteRelatedItem}
        />
      </Container>
    );
  }
}

export default RelatedWidget;
