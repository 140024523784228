import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, Field } from 'redux-form';
import Label from '/ui/atoms/Label';
import syncForm from '/lib/sync-form';
import syncInfo from '/../common/sync-channel';

@connect(
  (state) => {
    const syncedEntity = 'advertSettings';
    const syncedId = '1';
    const formName = syncInfo.formFunc(syncedEntity, syncedId);
    const selector = formValueSelector(formName);
    return {
      turbo_enabled: selector(state, 'turbo_enabled'),
      syncedEntity,
      syncedId,
    };
  },
)
@syncForm()
@reduxForm()
class AdvertSettingsForm extends React.Component {
  render() {
    return (
      <form id="EditorForm" className="ui form">
        <div className="ui dividing header">
          Общие настройки
        </div>
        <div className="ui toggle checkbox">
          <Field
            name="turbo_enabled"
            type="checkbox"
            component="input"
          />
          <Label>
            Высокий приоритет для внутренних партнеров
          </Label>
        </div>
      </form>
    );
  }
}

export default AdvertSettingsForm;
