const E = {};

E.CREATE_PARTNER = 'CREATE_PARTNER';
E.CREATE_PARTNER_SUCCESS = 'CREATE_PARTNER_SUCCESS';
E.CREATE_PARTNER_FAILURE = 'CREATE_PARTNER_FAILURE';

E.GET_PARTNERS = 'GET_PARTNERS';
E.GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
E.GET_PARTNERS_FAILURE = 'GET_PARTNERS_FAILURE';

E.SEARCH_PARTNERS = 'SEARCH_PARTNERS';
E.SEARCH_PARTNERS_SUCCESS = 'SEARCH_PARTNERS_SUCCESS';
E.SEARCH_PARTNERS_FAILURE = 'SEARCH_PARTNERS_FAILURE';

E.ADD_PARTNER_TO_POST = 'ADD_PARTNER_TO_POST';
E.ADD_PARTNER_TO_POST_SUCCESS = 'ADD_PARTNER_TO_POST_SUCCESS';
E.ADD_PARTNER_TO_POST_FAILURE = 'ADD_PARTNER_TO_POST_FAILURE';

E.ADD_PARTNER_TO_THEME = 'ADD_PARTNER_TO_THEME';
E.ADD_PARTNER_TO_THEME_SUCCESS = 'ADD_PARTNER_TO_THEME_SUCCESS';
E.ADD_PARTNER_TO_THEME_FAILURE = 'ADD_PARTNER_TO_THEME_FAILURE';

E.createPartner = () => ({
  type: E.CREATE_PARTNER,
  meta: { remote: true },
});

E.createPartnerSuccess = () => ({
  type: E.CREATE_PARTNER_SUCCESS,
});

E.createPartnerFailure = ({ error }) => ({
  type: E.CREATE_PARTNER_FAILURE,
  payload: { error },
});

E.getPartners = () => ({
  type: E.GET_PARTNERS,
  meta: { remote: true },
});

E.getPartnersSuccess = ({ partners }) => ({
  type: E.GET_PARTNERS_SUCCESS,
  payload: { partners },
});

E.getPartnersFailure = ({ error }) => ({
  type: E.GET_PARTNERS_FAILURE,
  payload: { error },
});

E.searchPartners = ({ text }) => ({
  type: E.SEARCH_PARTNERS,
  payload: { text },
  meta: { remote: true },
});

E.searchPartnersSuccess = ({ partners }) => ({
  type: E.SEARCH_PARTNERS_SUCCESS,
  payload: { partners },
});

E.searchPartnersFailure = ({ error }) => ({
  type: E.SEARCH_PARTNERS_FAILURE,
  payload: { error },
});

E.addPartnerToPost = ({ post_id, partner_id }) => ({
  type: E.ADD_PARTNER_TO_POST,
  payload: { post_id, partner_id },
  meta: { remote: true },
});

E.addPartnerToPostSuccess = () => ({
  type: E.ADD_PARTNER_TO_POST_SUCCESS,
});

E.addPartnerToPostFailure = ({ error }) => ({
  type: E.ADD_PARTNER_TO_POST_FAILURE,
  payload: { error },
});

E.addPartnerToTheme = ({ theme_id, partner_id }) => ({
  type: E.ADD_PARTNER_TO_THEME,
  payload: { theme_id, partner_id },
  meta: { remote: true },
});

E.addPartnerToThemeSuccess = () => ({
  type: E.ADD_PARTNER_TO_THEME_SUCCESS,
});

E.addPartnerToThemeFailure = ({ error }) => ({
  type: E.ADD_PARTNER_TO_THEME_FAILURE,
  payload: { error },
});

E.partners = (state = {
  list: [],
  error: '',
  pending: false,
}, action) => {
  switch (action.type) {
    case E.CREATE_PARTNER_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    case E.GET_PARTNERS:
      return {
        ...state,
        pending: true,
      };
    case E.GET_PARTNERS_SUCCESS:
      return {
        ...state,
        list: action.payload.partners,
        pending: false,
      };
    case E.GET_PARTNERS_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };

    case E.SEARCH_PARTNERS:
      return {
        ...state,
        pending: true,
      };
    case E.SEARCH_PARTNERS_SUCCESS:
      return {
        ...state,
        list: action.payload.partners,
        pending: false,
      };
    case E.SEARCH_PARTNERS_FAILURE:
      return {
        ...state,
        error: action.error,
        pending: false,
      };

    case E.ADD_PARTNER_TO_POST_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case E.ADD_PARTNER_TO_THEME_FAILURE:
      return {
        ...state,
        error: action.error,
      };

    default:
      return state;
  }
};

module.exports = E;
