import React from 'react';
import Select from 'react-select';

export default (props) => {
  let value = null;
  if (typeof props.value === 'string') {
    value = props.options.find(option => option.value === props.value);
  } else {
    value = props.value;
  }

  let onChange = props.onChange;
  if (typeof onChange === 'function') {
    onChange = (option, ...rest) => {
      if (option?.value) {
        props.onChange(option.value);
      } else {
        props.onChange(option, ...rest);
      }
    };
  }

  return (
    <Select
      {...props}
      value={value}
      onChange={onChange}
    />
  );
};
