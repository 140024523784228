import React from 'react';

const DocsPullQuote = (props) => {
  const { children, node, attributes } = props;
  const { data } = node;

  const imgSrc = data.get('imgSrc');
  const alt = data.get('alt');
  const viewType = data.get('viewType');

  return (
    <aside
      className={`mz-publish-docscite ${viewType ? `mz-publish-docscite_${viewType}` : ''}`}
      {...attributes}
    >
      <div className="mz-publish-docscite__wrapper clearfix">
        {imgSrc ?
          <img
            className="mz-publish-docscite__image"
            src={imgSrc}
            alt={alt}
          />
          : null}
        <div className="mz-publish-docscite__text mz-publish__placeholder">
          {children}
        </div>
      </div>
    </aside>
  );
};

export default DocsPullQuote;