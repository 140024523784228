import React, {Component} from 'react';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import SearchComponent from '/components/SearchField/SearchField.js';
import { searchQuery } from '/actions/actions.js';
var moment = require('moment');
require('moment/locale/ru');
import {history} from '/store/store.js';


@connect( ({ searchQuery }) => ({ query: searchQuery }) )
export default class Search extends Component{
  handleSearch(e){
    history.push('/'+this.props.type);
    this.props.dispatch(searchQuery(e.target.value));
  }
  render(){
		return (
			<div className="SearchField">
				<div className="ui fluid icon input">
					<input type="text" name="searchField" placeholder="Фильтр по заголовкам, адресам, авторам" value={this.props.query} onChange={(e) => this.handleSearch(e)}/>
					<i className="search icon"></i>
				</div>
			</div>
		)
  }
}
