/* flow */
import React from 'react';
import { Hint } from './atoms';
import { HintWrapper } from './organisms';
import {
  nodeTypes,
  objectTypes
} from '../../consts';

const HintNode = {
  type: nodeTypes.HINT,
  object: objectTypes.INLINE,

  create({ body = '', isCreated = false }) {
    return {
      type: this.type,
      object: this.object,
      data: {
        body,
        isCreated
      },
    };
  },

  htmlRender(attrs) {
    return (
      <Hint
        {...attrs}
      />
    );
  },

  editorRender(attrs) {
    return (
      <HintWrapper {...attrs} />
    );
  }
};

export default HintNode;

