/* @flow */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Field, FieldArray, reduxForm, change, formValueSelector, getFormValues } from 'redux-form';
import QuestionContainer from './Questions';
import Results from './Results';
import { SingleFileUploader } from '../../features/FileUploader';
import './Test.styl';

// const {addQuestion, removeQuestion, moveQuestion, updateQuestion, updateAnswer, addAnswer, removeAnswer,
// 	addResult, removeResult, updateResult} = actions;

const generateId = () => `${Date.now()}_${Math.round(Math.random() * 100000)}`;

type Props = {
  questions: Object,
  answers: Object,
  post_id: string,
  background_test_image: string,
  form: string,
  is_quotes: boolean,
  results: Object,
  dispatch: (Object) => void,
  is_hide_results: boolean
};

@connect((state, props) => ({
  questions: props.questions,
  answers: props.answers,
  results: props.results,
  is_quotes: props.is_quotes,
  background_test_image: props.background_test_image,
  form: props.form,
  post_id: props.post_id,
  is_hide_results: props.is_hide_results
}))
export default class Test extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.sort = this.sort.bind(this);
  }
  props: Props;

  addQuestion() {
    const { questions } = this.props;
    const question = {
      id: generateId(),
      name: '',
      description: '',
      order: Object.keys(this.props.questions).length,
    };
    this.props.dispatch(
      change(this.props.form, 'questions', { ...questions, [question.id]: question }),
    );
  }
  addQuestion: () => void;

  removeQuestion(id: string) {
    const { questions, answers } = this.props;
    const clone = Object.assign({}, questions);
    // this.props.dispatch(change(this.props.form, 'questions', {...questions, [id]: null}));
    this.recalculateOrder(clone, id);
    delete clone[id];
    const new_answers = {};
    for (const a in answers) {
      if (answers[a].qid !== id) {
        new_answers[a] = answers[a];
      }
    }
    this.props.dispatch(change(this.props.form, 'questions', { ...clone }));
    this.props.dispatch(change(this.props.form, 'answers', { ...new_answers }));
  }
  removeQuestion: (string) => void;

  recalculateOrder(clone: Object, id: string) {
    for (const q in clone) {
      if (clone[q].order > clone[id].order) {
        clone[q].order -= 1;
      }
    }
  }
  recalculateOrder: (Object, string) => void;

  sort(id1: string, id2: string) {
    const copy_questions = { ...this.props.questions };
    const temp_order: number = copy_questions[id1].order;
    copy_questions[id1].order = copy_questions[id2].order;
    copy_questions[id2].order = temp_order;
    return copy_questions;
  }
  sort: (string, string) => void;

  handleField(obj: Object) {
    this.props.dispatch(change(this.props.form, obj.field, obj.value));
  }
  handleField: (Object) => void;

  render() {
    const { dispatch, questions, results, answers, form } = this.props;

    let questionList = [];

    const countQuestions = Object.keys(questions).length;
    const countResults = Object.keys(results).length;
    const questionsArr = Object.keys(questions)
      .map((key, index) => questions[key])
      .sort((a: { order: number }, b: { order: number }) => a.order - b.order);

    questionList = questionsArr.map((q: { id: string }, i) => (
      // q = question

      <QuestionContainer
        key={q.id}
        question={questions[q.id]}
        countQuestions={countQuestions}
        post_id={this.props.post_id}
        form={this.props.form}
        updateHandler={(item) => {
          this.props.dispatch(
            change(form, 'questions', {
              ...questions,
              [item.id]: item,
            }),
          );
        }}
        sortHandler={(id1, dir) => {
          const id2 = questionsArr.filter(
            question => question.order == questions[id1].order + dir,
          )[0].id;

          this.props.dispatch(
            change(form, 'questions', {
              ...this.sort(id1, id2),
            }),
          );
        }}
        removeHandler={(id) => {
          this.removeQuestion(id);
        }}
      />
    ));
    // for (var q in questions)

    return (
      <div className="test">
        <div className="ui dividing header">Вопросы</div>
        <div className="ui checkbox test_checkbox">
          <input
            type="checkbox"
            id="is_quotes"
            checked={this.props.is_quotes}
            onChange={(e: { target: { checked: string } }) => {
              this.handleField({
                field: 'is_quotes',
                value: e.target.checked,
              });
            }}
          />
          <label htmlFor="is_quotes">Кавычки в тексте вопроса на картинке</label>
        </div>
        <div className="ui checkbox test_checkbox">
          <input
            type="checkbox"
            id="is_hide_results"
            checked={this.props.is_hide_results}
            onChange={(e: { target: { checked: string } }) => {
              this.handleField({
                field: 'is_hide_results',
                value: e.target.checked,
              });
            }}
          />
          <label htmlFor="is_hide_results">Скрыть результаты</label>
        </div>
        <div className="field test__field">
          <label htmlFor="">Картинка в фоновом изображении:</label>
          <SingleFileUploader
            handler={(image: string) =>
              this.handleField({
                field: 'background_test_image',
                value: image,
              })}
            file={this.props.background_test_image}
            accepted="image/*"
          />
        </div>
        <div className="questions-list">{questionList}</div>
        <div className="ui segment">
          <span
            className="ui button blue addQuestion"
            onClick={() => {
              this.addQuestion(countQuestions);
            }}
          >
            Добавить вопрос
          </span>
        </div>
        <div className="ui segment">
          <Results post_id={this.props.post_id} form={this.props.form} />
        </div>
        {/* <div className="results"> */}
        {/* <h2>Результаты</h2> */}
        {/* <ul> */}
        {/* {results.map( (x, num) => {return (<div><h3>{(num+1)}.</h3> */}
        {/* <ResultContainer result={x} key={'r_'+num} num={num} /> */}
        {/* </div>);})} */}
        {/* </ul> */}
        {/* <button onClick={() => {this.addResult();}}>Добавить результат</button> */}
        {/* </div> */}
      </div>
    );
  }
}
