import React from 'react';
import {
  Icons,
  ModuleContainer,
} from '/components/SlateRichText/atoms';
import {
  ToolTip,
} from '/components/SlateRichText/molecules';
import {
  FormatBarButton,
} from '../../atoms';
import Editor from '../../../../Editor';

const {
  UpperIndexFormat,
  ItalicFormat,
  BoldFormat,
} = Icons;

const toolTips = {
  [Editor.MARK.BOLD]: Editor.TOOLTIPS.BOLD,
  [Editor.MARK.ITALIC]: Editor.TOOLTIPS.ITALIC,
};

const hasMark = ({ editor, type }) => editor.value.activeMarks.some(mark => mark.type === type);

const onClickMark = ({ editor, event, type }) => {
  editor.change((change) => {
    change.toggleMark(type);
  });
  event.preventDefault();
};

const renderMarkButton = (editor, enabled, type, icon) => {
  const isActive = hasMark({ editor, type });
  const tooltipCaption = enabled ? toolTips[type] : Editor.TOOLTIPS.NOT_AVALIABLE;
  const markButtonId = `${editor.props.editorId}-toolbar-${type}`;

  return (
    <FormatBarButton
      active={enabled && isActive}
      onMouseDown={() => enabled && onClickMark({ editor, event, type })}
      data-for={markButtonId}
      data-tip
      enabled={enabled}
    >
      {icon}
      <ToolTip
        id={markButtonId}
        tooltip={tooltipCaption}
      />
    </FormatBarButton>
  );
};

const MarksToolModule = ({ editor, enabled }) => {
  const carryRenderMarkButton = renderMarkButton.bind(this, editor, enabled);
  return (
    <ModuleContainer>
      {carryRenderMarkButton(Editor.MARK.BOLD, <BoldFormat />)}
      {carryRenderMarkButton(Editor.MARK.ITALIC, <ItalicFormat />)}
      {carryRenderMarkButton(Editor.MARK.SUP, <UpperIndexFormat />)}
    </ModuleContainer>
  );
};

export default MarksToolModule;
