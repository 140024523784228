// @flow
import React from 'react';
import type { Node } from 'react';
import styled from 'react-emotion';

const colors = {
  gray: 'rgba(41, 48, 67, 0.5)',
  red: 'rgba(199, 0, 0, 0.8)',
};

type Props = {|
  color: ?$Keys<typeof colors>,
  weight: number,
  size: number,
  online: boolean,
  children: Node[],
|};

const Label = styled('label')`
  display: flex;
  font-family: 'Graphik LC',sans-serif !important;
  font-weight: ${({ weight }) => weight || 500};
  font-size: ${({ size }) => size || 13}px;
  color: ${({ color }) => colors[color] || '#293043'};

  ${({ online }) => online && `
    &:after {
      content: '';
      display: inline-flex;
      border-radius: 100%;
      width: 8px; height: 8px;
      background: ${colors.red};
      margin-left: 4px;
      margin-top: 5px;
    }
  `}

  b, strong {
    font-weight: 500;
  }
`;

export default (props: Props) => (
  <Label
    color={props.color}
    weight={props.weight}
    size={props.size}
    online={props.online}
  >
    {props.children}
  </Label>
);
