/* @flow */
import React, { Component } from 'react';
import type { Node as ReactNode } from 'react';
import config from '../../const/config';
import './Preview.styl';

const IFRAME_MIN_WIDTH = 320;
const IFRAME_MAX_WIDTH = 1450;
const IFRAME_WIDTH_STEP = 10;
const IFRAME_WIDTH_DEFAULT = 1000;
const IFRAME_WIDTH_MOBILE_DEFAULT = 360;
const IFRAME_WIDTH_MOBILE_MAX = 768;
const IFRAME_WIDTH_TABLET_DEFAULT = 780;
const IFRAME_WIDTH_TABLET_MAX = 991;
const IFRAME_WIDTH_DESKTOP_DEFAULT = 1450;

const MOBILE = 'MOBILE';
const TABLET = 'TABLET';
const DESKTOP = 'DESKTOP';
const SHARES = 'SHARES';

const widthStatus = (width) => {
  if ((width > 0) && (width < IFRAME_WIDTH_MOBILE_MAX)) return MOBILE;
  if ((width >= IFRAME_WIDTH_MOBILE_MAX) && (width <= IFRAME_WIDTH_TABLET_MAX)) return TABLET;
  if ((width > IFRAME_WIDTH_TABLET_MAX)) return DESKTOP;
  return null;
}

type PreviewProps = {
  special: string,
  isReady?: boolean,
  entryImage: string,
  socpic: string,
  id: number,
};

type PreviewState = {
  iframeWidth: number,
  selected: string
};

export default class extends Component<PreviewProps, PreviewState> {
  constructor(props: PreviewProps) {
    super(props);
    this.state = {
      iframeWidth: IFRAME_WIDTH_DEFAULT,
      selected: widthStatus(IFRAME_WIDTH_DEFAULT)
    };
  }

  showShares = (): void => {
    this.setState(() => ({
        selected: SHARES,
      })
    )
  }

  setIframeWidth = (width: number): void => {
    this.setState(
      () => ({
        iframeWidth: width,
        selected: widthStatus(width)
      })
    );
  }

    handleWidthSlider = (e: SyntheticInputEvent<HTMLInputElement>): void => {
      const { value }: {value: string} = e.target;
      this.setIframeWidth(parseInt(value));
    }

    widthSlider = (): ReactNode => (<div>
      <div className="ui label">
        {`${IFRAME_MIN_WIDTH}px\u00A0`}
        <input
          type="range"
          min={IFRAME_MIN_WIDTH}
          max={IFRAME_MAX_WIDTH}
          step={IFRAME_WIDTH_STEP}
          value={this.state.iframeWidth}
          onChange={this.handleWidthSlider}
        />
        {`\u00A0${IFRAME_MAX_WIDTH}px`}
      </div>
    </div>)

    sizeButtons = (): ReactNode => {
      const { selected } = this.state;
      return (<div className="ui icon buttons">
        <button
          className={`ui compact ${selected === SHARES ? 'primary' : ''} button`}
          onClick={() => { this.showShares(); }}
        >
          <i className={'large share alternate icon preview__size-icon'} />
        </button>
        <button
          className={`ui compact ${selected === MOBILE ? 'primary' : ''} button`}
          onClick={() => { this.setIframeWidth(IFRAME_WIDTH_MOBILE_DEFAULT); }}
        >
          <i className={'large mobile alternate icon preview__size-icon'} />
        </button>
        <button
          className={`ui compact ${selected === TABLET ? 'primary' : ''} button`}
          onClick={() => { this.setIframeWidth(IFRAME_WIDTH_TABLET_DEFAULT); }}
        >
          <i className={'large tablet alternate icon preview__size-icon'} />
        </button>
        <button
          className={`ui compact ${selected === DESKTOP ? 'primary' : ''} button`}
          onClick={() => { this.setIframeWidth(IFRAME_WIDTH_DESKTOP_DEFAULT); }}
        >
          <i className={'large desktop icon preview__size-icon'} />
        </button>
      </div>)
    }

    imagePreview = (url: string): ReactNode => {
      const entryImageStyle = {
        backgroundImage: `url(${url})`
      };
      return (<div style={entryImageStyle} className='preview__social-container' />);
    }

    render() {
      const showIframe = this.state.selected !== SHARES;
      const { id, uuid, special } = this.props;
      let path = '';
      switch (special) {
        case 'theme':
          path = `/preview/theme/${id}`;
          break;
        case 'podcast':
          path = `/preview/podcast/${id}`;
          break;
        default:
          path = `/preview/${uuid}`;
          break;
      }
      const iframeSrc = `${config.publicUrl}${path}`;
      const iframeWidth = `${this.state.iframeWidth}px`;

      const socpicSrc = this.props.socpic
        ? SERVER_SIDE_VARS.s3_url + this.props.socpic
        : 'https://zona.media/s/defaultShare.png';

      const socialImageSrc = this.props.entryImage
        ? SERVER_SIDE_VARS.s3_url + this.props.entryImage + '_1400x850'
        : socpicSrc;

      return this.props.isReady
        ? (<div className="preview">
          <div className="ui hidden divider" />
          <div className="ui grid">
            <div className="ui five wide column" />
            <div className="ui center aligned six wide column">
              {showIframe && this.widthSlider()}
            </div>
            <div className="ui right aligned five wide column">
              {this.sizeButtons()}
            </div>
          </div>
          <div className="ui hidden divider" />
          {this.state.selected === SHARES && this.imagePreview(socialImageSrc)}
          <div className={`${showIframe ? 'preview__frame-container' : 'preview__hidden'}`}>
            <iframe src={iframeSrc} width={iframeWidth} className='preview__frame-content' />
          </div>
        </div>)
        : false;
    }
}
