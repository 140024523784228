import React, { Component } from 'react';
import styled from 'styled-components';
import {
  ModalCancel,
  ModalController,
  ModalSubmit,
} from '../../../../atoms';
import BannerSlider from '../../../DonateSlider';
import { REGION } from '/const/config';

const banners = {
  by: [
    {
      id: 0,
      desktop: '/assets/donate-banner/by.png',
    },
  ],
  ca: [
    {
      id: 0,
      desktop: '/assets/donate-banner/ca.png',
    },
  ],
  ru: [
    {
      id: 0,
      desktop: '/assets/donate-banner/1-desktop.png',
      mobile: '/assets/donate-banner/1.png',
    },
    {
      id: 1,
      desktop: '/assets/donate-banner/2-desktop.png',
      mobile: '/assets/donate-banner/2.png',
    },
    {
      id: 2,
      desktop: '/assets/donate-banner/3-desktop.png',
      mobile: '/assets/donate-banner/3.png',
    },
    {
      id: 3,
      desktop: '/assets/donate-banner/4-desktop.png',
      mobile: '/assets/donate-banner/4.png',
    },
    {
      id: 4,
      desktop: '/assets/donate-banner/5-desktop.png',
      mobile: '/assets/donate-banner/5.png',
    },
    {
      id: 5,
      desktop: '/assets/donate-banner/default.png',
    },
    {
      id: 6,
      desktop: '/assets/donate-banner/vpn-desktop.png',
    },
  ],
};

class DonateForm extends Component {
  constructor(props) {
    super(props);
    const { node } = props;
    this.state = {
      bannerId: props?.initialState?.bannerId || 0,
    };
  }

  onSubmit = (event) => {
    const {
      onSubmit,
      onClose,
      type,
    } = this.props;

    const { bannerId } = this.state;
    event.persist();
    event.preventDefault();

    onSubmit({ type, bannerId });
    onClose();
  };

  render() {
    const {
      onClose,
    } = this.props;
    return (
      <form onSubmit={this.onSubmit}>
        <BannerSlider banners={banners[REGION]} initialState={this.state.bannerId} setBanner={(id) => this.setState(() => ({ bannerId: id }))} />
        <ModalController>
          <ModalCancel onClick={onClose}>Отменить</ModalCancel>
          <ModalSubmit
            type="submit"
            value="Применить"
          />
        </ModalController>
      </form>
    );
  }
}

export default DonateForm;
