const cards = (state = {}, action) => {
    switch(action.type){
        case "EXPAND_CARD":
            return {
                ...state,
                [action.cardId] : {
                    "isEdited": action.isEdited
                }
            }
        default: return state;
    }
}
export default cards;