import Editor from '../Editor';
import {
  sanitizeNodeData,
} from '../helpers';

export default {
  serialize(obj, children) {
    if (obj) {
      const node = Editor.node.map[obj.type];
      const attributes = {};

      if (!node) return;

      const sanitizedData = sanitizeNodeData(obj.data);

      if (!node.isTag) {
        attributes['data-type'] = obj.type;
        attributes['data-slate-custom'] = JSON.stringify(sanitizedData || {});
      }

      return Editor.htmlRenderNodeByType({
        type: node,
        node: obj,
        children,
        attributes,
      });
    }
  },
  deserialize(el, next) {
    if (!el.dataset) return;

    const type = el.dataset.type || null;
    const customData = el.dataset.slateCustom ? JSON.parse(el.dataset.slateCustom) : {};
    const htmlData = el.dataset.html || null;
    const attrs = el.attributes ? Object.values(el.attributes) : [];
    const attrsData = attrs
      .filter(attr => attr.name !== 'style')
      .reduce((acc, curr) => ({ ...acc, [curr.name]: curr.value }), {});
    let Node = null;

    if (type) {
      Node = Editor.node.map[type];
    } else {
      Node = Editor.node.map[el.tagName.toLowerCase()];
    }
    if (Node) {
      return {
        object: Node.object,
        type: Node.type,
        nodes: Node.schema && Node.schema.isVoid ? [] : next(el.childNodes),
        data: {
          ...attrsData,
          ...customData,
          html: htmlData,
        },
      };
    }
  },
};
