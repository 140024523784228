import React from 'react';
import {
  Photo
} from './atoms';
import {
  nodeTypes,
  objectTypes
} from '../../consts';

const PhotoNode = {
  type: nodeTypes.PHOTO,
  object: objectTypes.BLOCK,

  schema: {
    isVoid: true,
    parent: [{
      type: nodeTypes.PHOTO_CONTAINER
    }],
  },

  create({ imgSrc = '', alt = '' }) {
    return {
      type: this.type,
      object: this.object,
      data: {
        imgSrc,
        alt,
      },
    };
  },

  htmlRender(attrs) {
    return (
      <Photo
        {...attrs}
      />
    );
  },
};

export default PhotoNode;

