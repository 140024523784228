/* @flow */
import React from 'react';
import {
  Input
} from '/ui/atoms';

type LinkInputProps = {
  href: string,
  handleChange: (event: SyntheticInputEvent<HTMLInputElement>) => void
}

class LinkInput extends React.Component<LinkInputProps> {
  inputRef: ?HTMLInputElement = null;

  componentDidMount() {
    if (this.inputRef) {
      this.inputRef.focus({ preventScroll: true });

      const value = this.inputRef.value;

      if (value) {
        const len = value.length;
        this.inputRef.setSelectionRange(len, len);
      }
    }
  }

  render() {
    return (
      <Input
        type="text"
        name="href"
        value={this.props.href}
        innerRef={(ref) => { this.inputRef = ref; }}
        onChange={this.props.handleChange}
      />
    );
  }
}

export default LinkInput;
