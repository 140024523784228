import {
  UPLOADING,
  LOADING,
  FAILED,
  SUCCESS,
  IDLE,
} from '/components/UploadImage/ImageView';

export default function ProcessUploadImage() {
  this.src = '';
  this.error = '';
  this.isLoading = false;

  this.changeStatus = (value, status) => {
    switch (true) {
      case status === UPLOADING || status === LOADING:
        this.error = '';
        this.isLoading = true;
        break;
      case status === FAILED:
        this.error = 'Ошибка при загрузке изображения';
        this.isLoading = false;
        break;
      case status === IDLE:
        this.isLoading = false;
        break;
      case status === SUCCESS:
        this.src = value;
        this.error = '';
        this.isLoading = false;
        break;
      default:
        break;
    }
  };
}
