import React, { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;  
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  max-width: 100%;
  object-fit: contain;
  border-radius: 10px;
`;

const RadioGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const BannerSlider = ({ banners, setBanner, initialState }) => {
  const [selectedIndex, setSelectedIndex] = useState(initialState || 0);

  const handleChange = (id) => {
    setBanner(id);
    setSelectedIndex(id);
  };

  return (
    <Container>
      <ImageWrapper>
        <Image src={banners[selectedIndex].desktop} alt={`Banner ${selectedIndex}`} />
      </ImageWrapper>
      <RadioGroup>
        {banners.map((b) => (
          <input
            key={b.id}
            type="radio"
            name="banner"
            checked={selectedIndex === b.id}
            onChange={() => handleChange(b.id)}
          />
        ))}
      </RadioGroup>
    </Container>
  );
};

export default BannerSlider;
