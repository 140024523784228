import React from 'react';
import './AuthorsForm.styl';
import { connect } from 'react-redux';
import Select from 'react-select';
import { syncCollection } from '/lib/sync-form';
import {
	getAuthors,
} from '/actions/actions'
import {
	Field
} from '/ui/molecules';
import {
	Input,
	Label,
} from '/ui/atoms';
import AuthorList from './AuthorList';


@connect(
	(state, props)=> {
		return {
			syncedEntity: 'authors',
			syncedId: props.post_id,
			search_authors: state.authors.search_authors || [], //список поиска
		}
	}, 
	(dispatch) => {
		return {
			getAuthors: () => {
				dispatch( getAuthors() );
			}
		}
	}
)
@syncCollection()
export default class AuthorsForm extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			name: '',
			smi: '',
			place: '',
			isEditable: false,
			isEditedId: null,
			showAddButton: false,
		};
	}

	componentDidMount(){
		this.props.getAuthors();
	}

	deleteAuthor = (author_id) => {
		this.props.remove(author_id);
	};

	edit = (author_id) => {
		const author = this.props.formValues[author_id];
		//возможный баг:
		//нажимаем редактировать одного из доб. авторов
		//пишем в "добавить нового автора" имя
		//жмем сохранить редактирование(не создание нового)
		this.setState({
			isEditable: true,
			isEditedId: author_id,
			name: author.name,
			eng_name: author.eng_name,
			smi: author.smi,
			place: author.place
		});
	};

	save = (author_id) => {
		let { name, smi, place, eng_name } = this.props.formValues[author_id];
		if (name !== this.state.name || eng_name !== this.state.eng_name || smi !== this.state.smi || place !== this.state.place )
			this.props.set(author_id, {
				name: this.state.name,
				eng_name: this.state.eng_name,
				smi: this.state.smi,
				place: this.state.place
			});

		this.setState(() => ({
			isEditable: false,
			isEditedId: null,
		}));
	};

	eventAddAuthor = () => {
		this.props.add(null, {
			name: this.name.value,
			smi: this.smi.value,
			place: this.place.value,
			sortId: Object.values(this.props.formValues || {}).length,
		});
		this.name.value = '';
		this.smi.value = '';
		this.place.value = '';
	};

	onSelect = (selected) => {
		this.props.add(selected.value, {
			name: selected.label,
			sortId: Object.values(this.props.formValues || {}).length,
		});
	};

	onChange = (field, value) => {
		this.setState({
			[field]: value
		})
	};

	showAddButton = () => {
		if(this.name && this.name.value)
			this.setState({
				showAddButton: true
			});
		else
			this.setState({
				showAddButton: false
			})
	};

	render(){
		const {
			search_authors,
			formValues,
			syncedId,
			disabled,
		} = this.props;

		const {
			isEditable,
			isEditedId,
		} = this.state;

		let options = [];
		let sortedAuthorsList = [];

		if(formValues) {
			options = search_authors.reduce((state, author) => {
				if (!formValues[author.id]) {
					state.push({
						label: author.name,
						value: author.id
					});
				}
				return state;
			}, []);

			sortedAuthorsList = Object.values(formValues).sort((a: any, b: any) => a.sort_id - b.sort_id);
		}

		if (disabled) {
			return (
				<Field>
					<div className="ui segment">
						<Label>Авторы</Label>
						<AuthorList
							syncedId={syncedId}
							authors={sortedAuthorsList}
							isEditable={false}
							isEditedId={false}
							onChange={()=>{}}
							onSave={()=>{}}
							onDelete={()=>{}}
							onEdit={()=>{}}
						/>
					</div>
				</Field>
			)
		}

		return (
			<Field>
				<Label>Авторы</Label>
			    <div className="ui segment">
				    <AuthorList
					    syncedId={syncedId}
					    authors={sortedAuthorsList}
					    isEditable={isEditable}
					    isEditedId={isEditedId}
					    onChange={this.onChange}
					    onSave={this.save}
					    onDelete={this.deleteAuthor}
					    onEdit={this.edit}
				    />
				    <Field>
					    <Label className="authors__header">Выбрать из списка авторов</Label>
					    <Select
						    name="form-authors"
						    options={options}
						    onChange={this.onSelect}
						    searchable={true}
						    placeholder="Выбрать автора"
						    value={null}
					    />
				    </Field>
				    <div className="ui stackable celled grid">
					    <div className="column row">
						    <div className="column">
							    <Label className="authors__header">Добавить автора</Label>
							    <Input
								    name="name"
								    type="text"
								    placeholder="Введите автора"
								    innerRef={(input) => { this.name = input; }}
								    onChange={()=> {this.showAddButton()}}
							    />
						    </div>
					    </div>
					    <div className="two column row">
						    <div className="column">
							    <Label className="authors__header">Издание</Label>
							    <Input
								    name="name"
								    type="text"
								    placeholder="Издание"
								    innerRef={(input) => { this.smi = input; }}
							    />

						    </div>
						    <div className="column">
							    <Label className="authors__header">Место</Label>
							    <Input
								    name="place"
								    type="text"
								    placeholder="Место"
								    innerRef={(input) => { this.place = input; }}/>
						    </div>
					    </div>
				    </div>
				    <Field>
					    {( this.state.showAddButton )
						    ?
						    (
							    <div className="ui small compact button"
							         onClick={() => {this.eventAddAuthor()}}>Добавить</div>
						    )
						    :
						    null}
				    </Field>
			    </div>
			</Field>
		)
	}
}





