import React, {Component} from 'react';
import {connect} from 'react-redux';
import { change,formValueSelector } from 'redux-form';
import { SingleFileUploader } from '../../features/FileUploader';
import Textarea from 'react-textarea-autosize';

import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';

import syncInfo from '/../common/sync-channel';
import {
	QuillClass,
	QuillContainerStyles
} from './styled';

const quillModules = {
	toolbar: [
	  ['bold'],
	  ['italic'],
	  ['link'],
	  ['clean'],
	],
  };
  
const quillFormats = [
'bold', 'italic', 'link', 'image'
];

const generateId = () => Date.now()+'_'+Math.round(Math.random()*100000);

@connect((state, props)=>{
	const selector = formValueSelector(syncInfo.formFunc('post', props.post_id));
	return {
		results: selector(state, 'results')
	}
})

export default class Results extends Component {
	constructor(props){
		super(props);
	}
	addResult(){
		const {results} = this.props;
		const result = {
			id: generateId(),
			name: '',
			description: '',
			min:0,
			max:0,
			image: '',
			order: Object.keys(this.props.results).length
		};
		this.props.dispatch(change(this.props.form, 'results', {...results, [result.id]: result}));
	}
	sortHandler(id1, dir) {
		const {results} = this.props;
		let id2 = Object.keys(results).filter((result_id)=> {
			return (results[result_id].order == (results[id1].order + dir));
		})[0];
		this.props.dispatch(change(this.props.form, 'results', {
			...results,
			...this.sort(id1, id2)
		}));
	}
	removeResult(id) {
		const {results} = this.props;
		let clone = Object.assign({}, results);
		this.recalculateOrder(clone, id);
		delete clone[id];
		this.props.dispatch(change(this.props.form, 'results', {...clone}));
	}
	recalculateOrder(clone, id){
		for(var a in clone){
			if (clone[a].order>clone[id].order){
				clone[a].order-=1;
			}
		}
	}
	sort(id1, id2){
		let copy_results= this.props.results;
		let temp_order = copy_results[id1].order;
		copy_results[id1].order = copy_results[id2].order;
		copy_results[id2].order = temp_order;
		return copy_results;
	}
	render() {
		const {results, form} = this.props;
		let resultsList =  [];
		const count = Object.keys(results).length;
		let resultsArr = [];
		if (results)
		{
			resultsArr = Object.keys(results)
				.map((key, index) => {
					return results[key];
				}).sort((a, b) => {
					return a.order - b.order;
				});
		}
		resultsList = resultsArr.map((result, i)=> {
			return (<Result result={result}
			                         count={count}
			                         key={i}
			                         sortHandler={(id, dir)=> {this.sortHandler(id, dir)}}
			                         updateHandler={(item) => {
				                         this.props.dispatch(change(form, 'results', {...results, [item.id]: {
					                         ...results[item.id],
					                         ...item
				                         }}));
			                         }}
			                         removeHandler={(id) => { this.removeResult(id); }}

			/>)
		})
		return (
			<div className="ui segment results">
				{resultsList}
				<span className="ui button teal addResult"
				      onClick={(e) => {this.addResult(count);}}>Добавить результат
				</span>
			</div>
		);
	}
}

class Result extends Component {
	constructor(props){
		super(props);
		this.state = {
			isEdited: false,
			name: this.props.result.name,
			description: this.props.result.description,
		};

		this.handleName = this.handleName.bind(this);
    	this.handleDescription = this.handleDescription.bind(this);
	}
	sortResult(direction){
		this.props.sortHandler(this.props.result.id, direction);
	}
	getValues(){
		let refs = this.refs;
		return {
			name: this.state.name,
			description: this.state.description,
			min: +refs.rmin.value,
			max: +refs.rmax.value,
			// image: refs.rimage.value
		}
	}
	saveResult(){
		this.setState({isEdited: false});
		this.props.updateHandler({
             ...this.props.result,
             ...this.getValues()
        });
	}
	editResult(){
		this.setState({isEdited: true});
	}
	removeResult(id){
		if (confirm("Вы уверены, что хотите удалить этот результат?"))
		{
			this.props.removeHandler(id);
		}
	}
	handleImage(image){
		this.props.updateHandler({
             ...this.props.result,
             image: image
		});
	}
	

	handleName(e: SyntheticInputEvent){
		this.setState({
			name: e.target.value,
		});
	}
	handleName: () => void;

	handleDescription(description: Object){
		this.setState({ description });
	}
	handleDescription: () => void;

	render(){
		const {result: {id, name, description, max, min, image, order}, form, count, dispatch} = this.props;
		return (<form className="ui form segment result" onSubmit={()=>{this.saveResult();}} >
			<div style={{"textAlign": "right"}}>
				{ (count == order + 1) ?
					null
					:
					<div className="moveDown ui icon small right floated button" onClick={(e)=>{this.sortResult(1);}}>
						<i className="angle down icon"></i>
					</div>
				}
				{(order == 0) ?
					null
					: <div className="moveUp ui icon small right floated button" onClick={(e)=>{this.sortResult(-1);}}>
						<i className="angle up icon"></i>
					</div>
				}
				<span className="ui icon small button right floated red result__remove" onClick={() => {this.removeResult(id);}}>
					<i className="icon remove"></i>
				</span>
				{ (this.state.isEdited)
					?
					<span className="ui icon small button green right floated result__save" onClick={()=> {this.saveResult(id);}}>
						<i className="save down icon"></i>
					</span>
					:
					<span className="ui icon small button green right floated result__edit" onClick={()=> {this.editResult(id);}}>
						<i className="edit down icon"></i>
					</span>
				}

			</div>
			<div className="field result__field">
				<label>Результат <b>{order + 1}</b></label>
				{(this.state.isEdited)?
					<Textarea
						minRows={1}
						ref="rname"
						onChange={this.handleName}
						value={this.state.name}
					>
					</Textarea>
					:
					<div className="ui basic segment"  dangerouslySetInnerHTML={{__html: name}}>
					</div>
				}
			</div>
			<div className="field result__field">
				<label>Описание</label>
				{(this.state.isEdited)?
					(<ReactQuill
						theme={'snow'}
						className={QuillClass}
						style={QuillContainerStyles}
						ref="rdescription"
						onChange={this.handleDescription}
						defaultValue={this?.state?.description || ''}
						formats={quillFormats}
						modules={quillModules}
						bounds={'.quill'}
					/>)
					:
					<div className="ui basic segment"  dangerouslySetInnerHTML={{__html: description}}>
					</div>
				}
			</div>
			<div className="field required result__field">
				<label>Min </label>
				{(this.state.isEdited)?
					<input type="number" required className="test__textarea test__textarea_expand" ref="rmin"
					       defaultValue={min||0}></input>
					:
					<div className="ui basic segment"  dangerouslySetInnerHTML={{__html: min||0}}>
					</div>
				}
			</div>
			<div className="field required result__field">
				<label>Max </label>
				{(this.state.isEdited)?
					<input type="number" required className="test__textarea test__textarea_expand" ref="rmax"
					       defaultValue={max||0}></input>
					:
					<div className="ui basic segment"  dangerouslySetInnerHTML={{__html: max||0}}>
					</div>
				}
			</div>
			<div className="question__results">
				{/*{ <AnswersContainer qid={question.id}/> }*/}
				{/*<button className="answers-add-btn" onClick={() => {dispatch(addAnswer({qid: question.id}));} }>Добавить ответ</button>*/}
			</div>
			<div className="field result__field">
				<label htmlFor="">Картинка:</label>
				<SingleFileUploader
					handler={(image) => this.handleImage(image)}
					file={this.props.result.image} 
					accepted="image/*"
				/>
			</div>

		</form>);
	}
}

