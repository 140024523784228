import React from 'react';
import {
  nodeTypes,
  objectTypes
} from '../../consts';

const HighLightNode = {
  type: nodeTypes.HIGHLIGHT,
  object: objectTypes.BLOCK,

  create() {
    return {
      type: this.type,
      object: this.object,
      nodes: [
        {
          object: objectTypes.TEXT,
        }
      ],
    };
  },

  htmlRender({ attributes, children }) {
    return <p className="mz-publish__text__highlight-lead" {...attributes}>{children}</p>;
  }
};

export default HighLightNode;
