import { push } from 'react-router-redux';
import {
    CREATE_SOCIAL_PICTURE_SUCCESS,
    REMOVE_SOCIAL_PICTURE_SUCCESS,
    POST_TYPE_RELOAD_COMPLETE
} from '../actions/actions';

export default (store) => (next) => (action) => {
    if (action && action.type)
    {
        switch(action.type)
        {
            case CREATE_SOCIAL_PICTURE_SUCCESS:
                return void store.dispatch(push(`/social_pictures/${action.id}`));
            case REMOVE_SOCIAL_PICTURE_SUCCESS:
                return void store.dispatch(push(`/social_pictures`));
            case POST_TYPE_RELOAD_COMPLETE:
                return void store.dispatch(push(`/post/${action.id}`))
        }
    }
    return next(action);
};