import React, { Component } from 'react';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import ruLocale from 'date-fns/locale/ru';
import './archiveSource.styl';
import {syncCollection, collectionSelector} from '../../lib/sync-form';

const archiveDateFormat = date => format(new Date(date), 'dd.MM.yyyy HH:mm', { locale: ruLocale });

@connect((state, props) => {
  return {
    syncedEntity: 'archive_source',
    syncedId: props.id,
  }
})
@syncCollection()
export default class ArchiveSource extends Component {
  state = {
    isOpen: true
  }

  handleClick = () => {
    this.setState((prev) => ({
      isOpen: !prev.isOpen
    }))
  }

  render() {
    const { isOpen } = this.state;
    const { formValues } = this.props;

    if (!formValues) return null;

    let list = [];
    for (let p in formValues) {
      list.push(formValues[p]);
    }

    const sources = list.map((v) => {
      const decodeLink = decodeURIComponent(v.url);
      return (
        <div className="archive-source__source-card">
          <p className="archive-source__date">{archiveDateFormat(v.createdAt)}</p>
          <a href={decodeLink} target="_blank" className="archive-source__source-link">{decodeLink}</a>
          <div className="archive-source__source-bottom">
            {(v.archive_url ? <a target="_blank" href={v.archive_url} className="archive-source__link">Web Archive</a> : null)}
            {(v.s3_img ? <a target="_blank" href={v.s3_img} className="archive-source__link">Скришнот</a> : null)}
            {(v.s3_zip ? <a href={v.s3_zip} className="archive-source__link archive-source__link_zip"><i className="download icon" />ZIP-архив</a>: null)}
          </div>
        </div>
      )
    });

    return (
      <div className="archive-source__container">
        <div className="ui top attached block header archive-source__header">
          Источники в статье
          <button onClick={this.handleClick} className="archive-source__drop-button">
            {isOpen ? "Свернуть" : "Развернуть"}
          </button>
        </div>
        {isOpen ? (
          <div className="archive-source__source-container">
            {sources.length > 0 ? sources : "В материале нет опубликованых источников"}
          </div>
        ) : null}
      </div>
    )
  }
}