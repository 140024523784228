// @wailorman + @pulpiks
import React from 'react';
import * as uploadImgUtils from '../../lib/upload-form-image';
import {Edit, Delete} from "./icons";
import './PictureUpload.styl';
import {
  Input,
  Label
} from '/ui/atoms';

type Props = {
  image: string,
  handler: Function,
};

export const IDLE = 'idle';
export const LOADING = 'loading';
export const FAILED = 'failed';
export const UPLOADING = 'uploading';
export const SUCCESS = 'success';

export class ImageView extends React.PureComponent<Props> {
  state = {
    status: IDLE
  };

  componentDidUpdate(prevProps: Props) {
    const prevImage = prevProps.image;
    const image = this.props.image;
    if(!image) return false;
    if (prevImage !== image) {
      this.setState(() => ({
        status: LOADING
      }));
      this.props.handler(null, LOADING);

      const loadImage = new Image();

      loadImage.onload = () => {
        this.setState(() => ({
          status: IDLE
        }));
        this.props.handler(null, IDLE);
      };

      loadImage.onerror = () => {
        this.setState(() => ({
          status: FAILED
        }));
        this.props.handler(null, FAILED);
      };
      loadImage.src = image;
    }
  }

  handleChange = (e) => {
    const formFile = e.target.files[0];
    this.uploadImageFile(formFile);
  };
  handleChange: Function;

  handleClick() {
    // this.refs.
  }
  handleClick: Function;

  uploadImageFile(file) {
    this.setState(() => ({
      status: UPLOADING
    }));
    this.props.handler(null, UPLOADING);
    uploadImgUtils
      .uploadImageFile(file)
      .then((res) => {
        this.setState(() => ({
          status: SUCCESS
        }));
        const value = res.results.original.s3_path;
        const urlPrefix = SERVER_SIDE_VARS.s3_url || 'https://mediazona.s3.eu-central-1.amazonaws.com/';
        this.props.handler(urlPrefix + value, SUCCESS);
      })
      .catch((err) => {
        this.setState(() => ({
          status: FAILED
        }));
        this.props.handler(null, FAILED);
        console.log(`Image uploading error: ${err}`);
      });
  }
  uploadImageFile: Function;

  handleTextInputChange(e){
    const value = e.target.value;
    this.props.handler(value, SUCCESS);
  }

  render() {
    const { title, image } = this.props;
    const { status } = this.state;

    const imageInput = (
      <Input
        style={{ display: 'none' }}
        type="file"
        ref="imageInputRef"
        onChange={e => this.handleChange(e)}
      />
    );

    const uploadingInformer = status === UPLOADING ? <div className={"upload-image__upload-informer"}>Обработка</div> : null;

    const showImage =
      ![LOADING, FAILED, UPLOADING].includes(status)
      && image
      && image.length;

    return (
      <div className="upload-image">
        {showImage ? (
          <div className="upload-image__current-picture">
            <img className="upload-image__current-img" src={image} />
            <div className="upload-image__current-pictures-actions">
              <Label className="upload-image__actions-button">
                <Edit/>
                {imageInput}
              </Label>
              <div
                className="upload-image__actions-button"
                onClick={() => {
                  this.props.handler(null, SUCCESS);
                }}
              >
                <Delete/>
              </div>
            </div>
          </div>
        ) : (
          <div className="upload-image__load">
            {uploadingInformer}
            { (status === FAILED && image) && <div className={"upload-image__failed"}>Ошибка</div> }
            { status === LOADING  && <div className={"upload-image__loading"}>Загрузка</div> }
            <label className="upload-image__load-button">
              {title}
              {imageInput}
            </label>
          </div>
        )}
        <div className="upload-image__direct">
          <div className={"upload-image__direct-label"}>URL</div>
          <Input
            type="text"
            value={image ? image : ""}
            onChange={(e) => this.handleTextInputChange(e)}
          />
        </div>
      </div>
    );
  }
}
