import {
    ADD_ELEMENT_TO_SYNC_COLLECTION,
    SET_ELEMENTS_SYNC_COLLECTION,
    CREATE_SYNC_COLLECTION,
    REMOVE_ELEMENT_FROM_SYNC_COLLECTION,
    CHANGE_ELEMENT_IN_SYNC_COLLECTION,
    DESTROY_SYNC_COLLECTION,
    UPDATE_ITEM_IN_SYNC_COLLECTION
} from '../actions/actions';

export default function (state = {}, action){
    switch(action.type){
        case CREATE_SYNC_COLLECTION:
            if (!state[action.name])
                return {...state, [action.name]: {}};
            return state;
        case DESTROY_SYNC_COLLECTION:
            var obj = {...state};
            delete obj[action.name];
            return obj;
        case ADD_ELEMENT_TO_SYNC_COLLECTION:
            if (!state[action.name])
                return state;
            return {...state, [action.name]: {...state[action.name], [action.item.id]: action.item}};
        case REMOVE_ELEMENT_FROM_SYNC_COLLECTION:
            if (!state[action.name])
                return state;
            var obj = {...state[action.name]};
            delete obj[action.id];
            return {...state, [action.name]: obj};
        case SET_ELEMENTS_SYNC_COLLECTION:
            return {...state, [action.name]: action.items};
        case UPDATE_ITEM_IN_SYNC_COLLECTION:
            return {...state, [action.name]: {
                ...state[action.name],
                [action.itemId]: {
                    ...(state[action.name] || {})[action.itemId],
                    ...action.values
                }
            }};
        default:
            return state;
    }
}
