import React from 'react';
import MainMenu from './MainMenu/MainMenu.js';
import {connect} from 'react-redux';
import {history} from '../store/store.js';
import Notifications from '/components/Notifications';
import { channels, browserEvents } from '../../common/notify-channel';

//import Footer from './Footer'
//import AddTodo from '../containers/AddTodo'
//import VisibleTodoList from '../containers/VisibleTodoList'
//import UndoRedo from '../containers/UndoRedo'

//const App = () => (
//	<div>
//		<MainMenu />
//		<ListPosts />
//	</div>
//)



const mapStateToProps = (state) => ({
	isAuthenticated: !!state.user,
	userId: state?.user?.userId,
});

export default function requireAuthentication(Component) {

	class AuthenticatedComponent extends React.Component {

		componentWillMount() {
			this.checkAuth(this.props.isAuthenticated);
		}

		componentWillReceiveProps(nextProps) {
			this.checkAuth(nextProps.isAuthenticated);
		}

		checkAuth(isAuthenticated) {
			if (!isAuthenticated)
			{
				history.push(`/auth`);
			}
		}

		render() {
			const props = this.props;
			return (
				<div>
					{this.props.isAuthenticated
						? <Component {...props}/>
						: null
					}
					<Notifications 
						limit={5}
						userId={this.props.userId}
						socketEvents={[
							channels.authActions,
						]}
						browserEvents={[
							browserEvents.networkStatus,
						]}
					/>
				</div>
			)

		}
	}


	return connect(mapStateToProps)(AuthenticatedComponent);
}





