//
// Created on 28-02-2019.
//

// migrate {type}-pull-quote-{view-type} to {type}-pull with viewType slateCustom
export default {
  1551339185537: {
    types: [
      'simple-pull-quote-justify',
      'simple-pull-quote-right',
      'simple-pull-quote-left',
      'simple-pull-quote-two-col',
      'document-pull-quote-justify',
      'document-pull-quote-right',
      'document-pull-quote-left',
      'document-pull-quote-two-col'
    ],
    up: (el) => {
      const newEl = el;

      // get type pull-quote
      const pullQuoteType = el.dataset.type.split('-')[0];

      // set new type
      newEl.dataset.type = `${pullQuoteType}-pull-quote`;

      const oldSlateCustom = el.dataset.slateCustom;
      const slateCustom = oldSlateCustom
        ? JSON.parse(oldSlateCustom)
        : {};

      // rename viewType
      let viewType = slateCustom.selectedViewType;
      switch (slateCustom.selectedViewType) {
        case 'right':
          viewType = 'side-right';
          break;
        case 'left':
          viewType = 'side';
          break;
      }

      // set new slateCustom
      newEl.dataset.slateCustom = JSON.stringify({
        ...slateCustom,
        viewType
      });

      return newEl;
    }
  }
};