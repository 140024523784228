 import React from 'react';

 export default ({
   isSummaryShow,
   handleSummary,
 }) => (
   <div className="ui segment">
     <div id="EventForm" className="ui form">
           <button
             type="button"
             className="ui submit button"
             style={isSummaryShow ? { backgroundColor: '#DB2828', color: 'white' } : { backgroundColor: '#E0E1E2' }}
             onClick={() => handleSummary()}
           >
             {isSummaryShow ? 'Удалить саммари' : 'Добавить саммари'}
           </button>
     </div>
   </div>
 );
 