import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { getPartners } from '../../store';

const prepareValue = obj => ({ label: obj.name, value: obj.id });
const prepareOptions = arr => arr.map(prepareValue);

@connect(
  state => ({
    options: prepareOptions(state.partners.list),
    pending: state.partners.pending,
  }),
)
class PartnerSelect extends Component {
  componentDidMount() {
    this.props.dispatch(getPartners());
  }

  getOption(id) {
    return id ? this.props.options.find(opt => opt.value === id) : null;
  }

  onChange(option, action) {
    if (action && action.action === 'clear') { this.props.onOptionSelect(null); }
    if (option && option.value) { this.props.onOptionSelect(option.value); }
  }

  render() {
    const {
      id,
      input,
      defaultValue,
      disabled = false,
      placeholder = 'Добавить партнёра...',
    } = this.props;

    return (
      <Select
        {...input}
        id={id}
        onChange={(option, action) => this.onChange(option, action)}
        value={this.getOption(input.value)}
        defaultValue={this.getOption(defaultValue)}
        options={this.props.options}
        placeholder={placeholder}
        onBlur={() => true}
        disabled={disabled}
        isClearable
      />
    );
  }
}

export default PartnerSelect;
