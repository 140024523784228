import axios from 'axios';

const E = {};

/**
 * @param file {File} Файл из input[type=file] (https://developer.mozilla.org/ru/docs/Web/API/File)
 */
E.uploadImageFile = (file) => {
  const form = E.generateImageForm({ file });

  return E.upload(form);
};

/**
 * @param url {string}
 */
E.uploadImageUrl = (url) => {
  const form = E.generateImageForm({ url });

  return E.upload(form);
};

E.uploadImageFileNew = (file) => {
  const form = E.generateImageForm({ file });

  return E.uploadNew(form);
};

E.uploadFileNew = (form) => {
    console.log('form in upload img', form)
    return E.uploadFile(form);
};

E.upload = (form) => new Promise((resolve, reject) => {
  $.ajax({
    type: 'POST',
    timeout: 120000,
    url: '/upload',
    data: form,
    contentType: false,
    processData: false,
    success(data) {
      resolve(data);
    },
    error(e) {
      reject(e);
    },
  });
});

// TODO: (new-image-generator) временная для загрузки изображение в CurrentVersion.image_entry
E.uploadNew = (form) => axios({
  data: form,
  url: '/upload2',
  method: 'post',
  timeout: 120000,
});

E.uploadFile = (form) => axios({
    data: form,
    url: '/uploadfile',
    method: 'post',
    timeout: 120000,
});

E.generateImageForm = ({ file, url, doResize }) => {
  const form = new FormData();

  form.append('doResize', '1');

  if (url) {
    form.append('imageUrl', url);
    return form;
  }

  if (file) {
    form.append('image', file);
    return form;
  }
};

E.uploadImage = async (params) => {
  if (params.image === undefined) {
    throw new Error('upload image without file');
  }

  const form = new FormData();
  for (const key in params) {
    form.append(key, params[key])
  }

  const fetchResponse = await fetch('/upload', {
    body: form,
    method: 'POST',
  });
  const response = await fetchResponse.json();
  return response.results;
};

module.exports = E;
