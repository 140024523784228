import React from 'react';
import {
  BlockController,
} from '../../atoms';

const themes = {
  blue: {
    color: 'rgb(45, 208, 234, 0.16)',
    focusColor: 'rgb(45, 208, 234, 0.5)'
  },
  red: {
    color: 'rgba(208, 2, 27, 0.06)',
    focusColor: 'rgba(208, 2, 27, 0.2)'
  }
};

const BlockWrapper = (props) => {
  const { theme = 'blue', editor, node, children, float, width, className } = props;
  const { selection, document } = editor.value;
  let focused = false;

  if (document.isInRange(node.key, selection)) {
    focused = true;
  }

  return (
    <BlockController
      color={themes[theme].color}
      focusColor={themes[theme].focusColor}
      focused={focused}
      float={float}
      width={width}
      className={className}
    >
      {children}
    </BlockController>
  );
};

export default BlockWrapper;
