import React from 'react';
import {
  nodeTypes,
  objectTypes
} from '../../consts';

const Paragraph = {
  type: nodeTypes.PARAGRAPH,
  object: objectTypes.BLOCK,
  isTag: true,

  create() {
    return {
      type: this.type,
      object: this.object,
      nodes: [
        {
          object: objectTypes.TEXT,
        }
      ],
    };
  },

  htmlRender({ attributes, children }) {
    return <p {...attributes}>{children}</p>;
  }
};

export default Paragraph;
