import {
  Editor as SlateEditor,
  Document as SlateDocument,
} from 'slate';
import flatten from 'lodash/flatten';

import normalizeQuotes from '../../../lib/Typographus/normalizeQuotes';
import replaceSymbols from '../../../lib/Typographus/replaceSymbols';

// eslint-disable-next-line import/named
import { REGION } from '/const/config';

const editorCopy = (editor) => {
  if (!editor || !editor.value) return null;
  const clone = new SlateEditor();
  clone.setValue(editor.value);
  return clone;
};

const replaceAt = (str, index, replacement) => str
  .substr(0, index) + replacement + str.substr(index + replacement.length);

const emptyStringRE = new RegExp('^\\s*$', 'gim');
const isEmptyString = (str) => emptyStringRE.test(str);
const newLineBreakRe = new RegExp('\\n', 'gim');

const removeParentNode = (editor, node) => {
  const parent = editor.value.document.getParent(node.key);
  if (parent && parent.type === 'p') {
    // удалить родителя, только если его родитель - документ.
    if (!SlateDocument.isDocument(editor.value.document.getParent(parent.key))) return;
    // удалить только если это была единственная нода у родителя.
    if (parent.nodes.size > 1) return;
    editor.removeNodeByKey(parent.key);
  }
};

const firstNodeWithLineBreak = (editor) => editor
  .value
  .document
  .getTexts()
  .find((node) => newLineBreakRe.test(node.text));

const removeLineBreaks = (editor) => {
  let nodeWithLineBreak = firstNodeWithLineBreak(editor);

  while (nodeWithLineBreak) {
    const offset = nodeWithLineBreak.text.search(newLineBreakRe);
    editor
      .moveTo(nodeWithLineBreak.key, offset)
      .deleteForward()
      .splitBlock();
    nodeWithLineBreak = firstNodeWithLineBreak(editor);
  }
};

const typographSymbols = (editor) => editor
  .value
  .document
  .getTexts()
  .map((node) => {
    editor.replaceTextByKey(
      node.key,
      0,
      node.text.length,
      replaceSymbols(node.text.replace(/\u200B/g, ''), REGION),
    );
    return node;
  });

const removeEmptyLines = (editor) => editor
  .value
  .document
  .getTexts()
  .map((node) => {
    if (isEmptyString(node.text)) {
      removeParentNode(editor, node);
    }
    return node;
  });

const findQoutes = (str) => [...str.matchAll(new RegExp('[„“«»“”‘’„”\'"]', 'gim'))];

const typographQoutes = (editor) => {
  // Выдеилм из текста все параграфы
  // и объединим их в одну текстовую строку
  // с сохранением переноса строк
  // Если бы мы взяли только текстовые ноды,
  // То информация о переносе строки была бы неизвестна
  const nodes = editor
    .value
    .document
    .nodes
    .toArray()
    // eslint-disable-next-line no-confusing-arrow
    .map((node) => node.getBlocks().count() > 0 ? node.getBlocks().toArray() : node);

  const paragraphs = flatten(nodes)
    .map((p) => p.getTexts());

  // Внутри параграфа находится List с нодами
  // поэтому для каждого параграфа нужен вложеный обход.
  const text = paragraphs
    .map((node) => node.reduce((acc, n) => `${acc}${n.text.length > 0 ? n.text : '\n'}`, ''))
    .reduce((acc, t) => `${acc}${t}\n`, '');

  // Нормализуем кавычки в объединённом тексте
  // и ищем все вхождения кавычек.
  const normilizedText = normalizeQuotes(text);
  const qoutes = findQoutes(normilizedText).map((match) => match[0]);

  // Второй обход всех параграфов для восстановлнеия кавчек в нодах.
  paragraphs.forEach((tN) => tN.forEach((node) => {
    let t = node.text;
    // Найдём все скобки в текущей ноде
    // и заменим их вынимая из начала массива
    // нормализованных скобок.
    const matches = findQoutes(t);
    matches.forEach((m) => {
      t = replaceAt(t, m.index, qoutes.shift());
    });
    // Заменим текст ноды на новый
    if (t !== node.text) {
      editor.replaceTextByKey(node.key, 0, t.length, t);
    }
  }));
};

export default (editor) => {
  // Сделаем копию значения редактора
  // В этой копии проведём изменения текста
  const editorClone = editorCopy(editor);
  removeLineBreaks(editorClone);
  removeEmptyLines(editorClone);
  typographSymbols(editorClone);
  typographQoutes(editorClone);
  // Обновим значение исходного редактора
  editor.onChange({ value: editorClone.value });
  editor.focus();
};
