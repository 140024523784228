import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  Field as ReduxFormField,
} from 'redux-form';
import ReduxFormInput from '/components/ReduxFormInput';

import {
  getPost,
} from '/actions/actions';
import {
  RelatedSearch,
  RelatedWidgetList,
} from '/features/RelatedEntity';
import {
  InputError,
} from '/components/SlateRichText/atoms';
import {
  Label,
} from '/ui/atoms';
import {
  Field,
} from '/ui/molecules';
import { searchableValue } from '/lib/searchableValue';
import {
  Container,
} from './styles';

@connect(
  (state, props) => ({
    syncedEntity: 'context',
    syncedId: props.id,
    post: state.post,
    searched: state.related.search_context || [],
    multiContext: props.multiContext,
    contextTitle: props.contextTitle,
  }),
  dispatch => ({
    getPost: (id) => {
      dispatch(getPost(id));
    },
  }),
)

class MultiContextField extends Component {
  titleFieldName = `${this.props.syncedEntity}_title`;

  componentDidUpdate(prevProps) {
    const { post } = this.props;

    if (prevProps.post !== post) {
      this.props.onChange({
        multiContext: [
          ...this.props.multiContext,
          {
            id: post.id,
            type: post.type,
            url: post.published ? post.Urls[0].canonical : '',
            title: post.CurrentVersion.title,
            sort_id: this.props.multiContext.length,
          },
        ],
        isLoading: false,
      });
    }
  }

  addItem = (option) => {
    const { id: postId } = searchableValue.destructure(option.value);
    this.props.getPost(postId);
    this.props.onChange({
      isLoading: true,
    });
  }

  deleteItem = (id) => {
    const updatedList = [...this.props.multiContext].filter(item => item.id !== id);

    this.props.onChange({
      multiContext: [...updatedList],
    });
  }

  onDrugItem = (reorderedItems) => {
    this.props.onChange({
      multiContext: [...reorderedItems],
    });
  }

  onTitleChange = (value) => {
    this.props.onChange({
      contextTitle: value,
    });
  }

  render() {
    const {
      syncedEntity,
      syncedId,
      searched,
      error,
      multiContext,
    } = this.props;

    const sortedValuesList = multiContext.sort((a, b) => a.sort_id - b.sort_id);
    const options = searched.filter(el =>
      multiContext.filter(item => item.id === el.id).length === 0 && el.id !== parseInt(syncedId));

    return (
      <Container>
        <Field>
          <Label>Заголовок</Label>
          <ReduxFormField
            id={this.titleFieldName}
            name={this.titleFieldName}
            placeholder="Заголовок"
            component={ReduxFormInput}
            onChange={this.onTitleChange}
          />
          {error ? (<InputError>{error}</InputError>) : null}
        </Field>
        <RelatedSearch
          onChange={this.addItem}
          placeholder="Введите заголовок или ссылку..."
          entity={syncedEntity}
          id={syncedId}
          searched={options}
        />
        <RelatedWidgetList
          entity={syncedEntity}
          id={syncedId}
          items={sortedValuesList}
          onDelete={this.deleteItem}
          onDrug={this.onDrugItem}
        />
      </Container>
    );
  }
}

export default MultiContextField;
