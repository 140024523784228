const E = {};

E.CREATE_ADVERT_CAMPAIGN = 'CREATE_ADVERT_CAMPAIGN';
E.CREATE_EXTERNAL_CAMPAIGN = 'CREATE_EXTERNAL_CAMPAIGN';

E.createAdvertCampaign = () => ({
  type: E.CREATE_ADVERT_CAMPAIGN,
  meta: { remote: true },
});

E.createExternalCampaign = () => ({
  type: E.CREATE_EXTERNAL_CAMPAIGN,
  meta: { remote: true },
});

module.exports = E;
