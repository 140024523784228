import React from 'react';
import { Link } from 'react-router';
import { connect } from 'react-redux';
import format from 'date-fns/format';
import ruLocale from 'date-fns/locale/ru';
import { syncCollection } from '/lib/sync-form';
import FeedBox from '/features/Feed';
import {
  createPartner,
} from '../../store';

@connect(() => ({
  syncedEntity: 'partners',
  syncedId: 'all',
}), dispatch => ({
  createPartner: () => {
    dispatch(createPartner());
  },
}))
@syncCollection()
class PartnerList extends React.Component {
  state = {
    showArchived: false,
  };

  createPartnerClick = () => {
    this.props.createPartner();
  };

  toggleArchived = () => {
    this.setState(prevState => ({
      showArchived: !prevState.showArchived,
    }));
  };

  render() {
    const { formValues } = this.props;
    return (
      <div className="ui container">
        <div className="ui vertically padded grid">
          <div className="column">
            <button
              className="ui primary button"
              style={{ marginBottom: '20px' }}
              onClick={this.createPartnerClick}
            >
              Новый партнер
            </button>
            <button
              className="ui secondary button"
              style={{ marginBottom: '20px' }}
              onClick={this.toggleArchived}
            >
              {this.state.showArchived ? 'Показать активные' : 'Показать архивные'}
            </button>
            <table className="ui very basic fixed divided table">
              <tbody>
                {formValues && Array.isArray(formValues) &&
                  formValues
                    .filter(item => (this.state.showArchived ? item.is_archived : !item.is_archived))
                    .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
                    .map(({ id, name, url, createdAt }) => (
                      <tr key={id}>
                        <td className="two wide">
                          { format(new Date(createdAt), 'd MMM HH:mm', { locale: ruLocale }) }
                        </td>
                        <td className="one column">
                          <Link to={`/partner/${id}`} className="post-item">
                            { name || 'Партнер не указан' }
                          </Link>
                        </td>
                        <td className="one column">
                          {url ? (
                            <a href={url} target="_blank" rel="noopener noreferrer" className="post-item">
                              Ссылка на партнера
                            </a>
                          ) : 'Ссылка не указана'}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default PartnerList;
