import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { syncCollection } from '/lib/sync-form';
import Button from '/ui/molecules/Button';

@connect(
	(state, props)=> {
		return {
			syncedEntity: 'translates',
			syncedId: props.postId,
		}
	},
	(dispatch, ownProps) => {
		return {
			dispatch,
		}
	}
)
@syncCollection()
export default class TranslateCollection extends PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      formValues,
      listClassName,
    } = this.props;
    const list = [];
    for (const p in formValues) {
      list.push(formValues[p]);
    }

    return (<>
      {
        this.props.addButton && (<><Button
          onClick={() => this.props.add()}
          backgroundColor="black"
          height="36px"
        >Добавить перевод</Button>
        <br /></>)
      }
      <div className={listClassName || ''}>
        {
          list.map((translatePost) => {
            if (!translatePost) {
              return null;
            }

            if (translatePost.Urls?.length) {
              translatePost.url = translatePost.Urls[0]?.canonical
            }

            return (this.props.component(translatePost))
          })
        }
      </div>
    </>)
  }
}
