import { REGION } from '../../const/config';

const colorByRegion = REGION === 'ru' ? 'green' : 'white'

const menuItems = [
  {
    title: 'список новостей',
    route: '/news',
    caption: 'Новости',
    extraClass: colorByRegion,
    regions: ['ru', 'ca', 'by'],
  },
  {
    title: 'список статей',
    route: '/articles',
    caption: 'Статьи',
    extraClass: colorByRegion,
    regions: ['ru', 'ca', 'by'],
  },
  {
    title: 'список онлайнов',
    route: '/onlines',
    caption: 'Онлайны',
    extraClass: colorByRegion,
    regions: ['ru', 'ca', 'by'],
  },
  {
    title: 'список сюжетов',
    route: '/chronicles',
    caption: 'Сюжеты',
    extraClass: colorByRegion,
    regions: ['ru', 'ca', 'by'],
  },
  {
    title: 'список тестов',
    route: '/tests',
    caption: 'Тесты',
    extraClass: colorByRegion,
    regions: ['ru', 'ca', 'by'],
  },
  {
    title: 'список карточек',
    route: '/cards',
    caption: 'Расклады',
    extraClass: colorByRegion,
    regions: ['ru', 'by'],
  },
  {
    title: 'список подкастов',
    route: '/podcasts',
    caption: 'Подкасты',
    extraClass: colorByRegion,
    regions: ['ru', 'by'],
  },
  {
    title: 'управление темами',
    route: '/themes',
    icon: 'icon hashtag',
    regions: ['ru', 'ca', 'by'],
  },
  {
    title: 'рекламные кампании',
    route: '/advertCampaigns',
    icon: 'item partner',
    regions: ['ru'],
  }, {
    title: 'управление партнёрами',
    route: '/partners',
    icon: 'icon thumbs up',
    regions: ['ru'],
  },
  {
    title: 'технические страницы',
    route: '/pages',
    icon: 'icon folder',
    regions: ['ru', 'ca', 'by'],
    roles: ['admin'],
  },
  {
    title: 'управление пользователями',
    route: '/users',
    icon: 'icon users',
    regions: ['ru', 'ca', 'by'],
  },
  {
    title: 'отчёты об ошибках',
    route: '/reports',
    icon: 'edit icon',
    regions: ['ru', 'ca', 'by'],
  },
  {
    title: 'фичеры главной страницы',
    route: '/features',
    icon: 'star icon empty',
    regions: ['ru', 'ca', 'by'],
  },
  {
    title: 'список отправленных уведомлений',
    route: '/pushes',
    icon: 'bell icon',
    regions: ['ru', 'ca', 'by'],
  },
  {
    title: 'расширенный поиск',
    route: '/advanced_search',
    icon: 'search icon',
    regions: ['ru'],
  },
];

export const menuList = menuItems.filter(item => item.regions.includes(REGION));

export default menuList;
