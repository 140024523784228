/* @flow */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import Textarea from 'react-textarea-autosize';
import Answers from './Answers';
import { testEditQuestions } from '/actions/actions';
import syncInfo from '/../common/sync-channel';

const generateId = () => `${Date.now()}_${Math.round(Math.random() * 100000)}`;

type State = {
  isEdited: boolean,
  name: string,
  description: string,
};

type Props = {
  answers: Object,
  question: {
    description: string,
    id: string,
    name: string,
    order: number,
  },
  countQuestions: number,
  form: string,
  post_id: string,
  dispatch: (Object) => void,
  updateHandler: (Object) => void,
  removeHandler: (string) => void,
  sortHandler: (string, number) => void,
};

@connect(
  (state, props) => {
    const selector = formValueSelector(syncInfo.formFunc('post', props.post_id));
    return {
      answers: selector(state, 'answers'),
    };
  },
  dispatch => ({
    dispatch,
  }),
)

export default class Question extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isEdited: false,
      name: props.question.name,
      description: props.question.description,
    };
    const fields: Array<string> = ['name', 'description', 'sort'];

    this.saveQuestion = this.saveQuestion.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
  }
  state: State;

  getValues() {
    return {
      name: this.state.name,
      description: this.state.description,
    };
  }
  getValues: () => { name: string, description: string };
  props: Props;

  editQuestion() {
    this.setState({ isEdited: true });
    this.props.dispatch(testEditQuestions(this.props.question.id, true));
  }
  editQuestion: () => void;

  /* eslint-disable no-alert */
  deleteQuestion(id: string) {
    if (confirm('Вы уверены, что хотите удалить этот вопрос?')) {
      this.removeQuestion(id);
    }
  }
  /* eslint-enable no-alert */
  deleteQuestion: (string) => void;

  saveQuestion() {
    this.setState({ isEdited: false });
    this.props.updateHandler({
      ...this.props.question,
      ...this.getValues(),
    });
    this.props.dispatch(testEditQuestions(this.props.question.id, false));
  }
  saveQuestion: () => void;

  removeQuestion(id: string) {
    this.props.removeHandler(id);
  }
  removeQuestion: (string) => void;

  sortQuestion(direction: number) {
    this.props.sortHandler(this.props.question.id, direction);
  }
  sortQuestion: number => void;

  handleName(e: SyntheticInputEvent) {
    this.setState({
      name: e.target.value,
    })
  }
  handleName: () => void;

  handleDescription(e: SyntheticInputEvent) {
    this.setState({
      description: e.target.value
    })
  }
  handleDescription: () => void;

  /* eslint-disable react/no-string-refs, react/no-danger */
  render() {
    const { question, answers, countQuestions, dispatch, post_id, form } = this.props;
    const { name, description, order } = question;
    return (
      <form className="ui form segment question" onSubmit={this.saveQuestion}>
        <div className="field question__field">
          <div className="ui blue ribbon label">
            Вопрос <b>{order + 1}</b>
          </div>
          {countQuestions === order + 1 ? null : (
            <div
              className="moveDown ui icon small button right floated"
              onClick={() => {
                this.sortQuestion(1);
              }}
            >
              <i className="angle down icon" />
            </div>
          )}
          {order === 0 ? null : (
            <div
              className="moveUp ui icon small button right floated"
              onClick={() => {
                this.sortQuestion(-1);
              }}
            >
              <i className="angle up icon" />
            </div>
          )}
          <span
            className="ui icon right floated red small button question__remove"
            onClick={() => {
              this.deleteQuestion(question.id);
            }}
          >
            <i className="remove icon" />
          </span>
          {this.state.isEdited ? (
            <span
              className="ui icon right floated red small button question__save"
              onClick={() => {
                this.saveQuestion();
              }}
            >
              <i className="save icon" />
            </span>
          ) : (
            <span
              className="ui icon right floated green small button question__edit"
              onClick={() => {
                this.editQuestion();
              }}
            >
              <i className="edit icon" />
            </span>
          )}

          {this.state.isEdited ? (
            <Textarea
              minRows={2}
              ref="qname"
              defaultValue={name || ''}
              onChange={this.handleName}
              value={this.state.name}
            />
          ) : (
            <div className="ui basic segment" dangerouslySetInnerHTML={{ __html: name }} />
          )}
        </div>
        <div className="field question__field">
          <label>Текст на фоновом изображении</label>
          {this.state.isEdited ? (
            <Textarea
              minRows={3}
              ref="qdescription"
              defaultValue={description || ''}
              onChange={this.handleDescription}
              value={this.state.description}
            />
          ) : (
            <div className="ui basic segment" dangerouslySetInnerHTML={{ __html: description }} />
          )}
        </div>
        <div className="question__answers">
          {/* { <AnswersContainer qid={question.id}/> } */}
          {/* <button classNam e="answers-add-btn" onClick={() => {dispatch(addAnswer({qid: question.id}));} }>Добавить ответ</button> */}
        </div>

        <Answers qid={question.id} post_id={this.props.post_id} form={this.props.form} />
      </form>
    );
  }
}
