import React, { PureComponent } from 'react';
import './RelatedField.styl';
import {
	searchRelated,
    getAllThemesForPost
} from '../../actions/actions.js'
import {connect} from 'react-redux';
import Select, { components }  from 'react-select';
import {syncCollection} from '../../lib/sync-form';
import {relatedNames} from '../../utils/types.js';
import { Link } from 'react-router';
import {types} from '../../utils/constants.js';
import debounce from "lodash/debounce";


import { formatTitlePost } from '../../lib/formatString';

const Menu = props => {
	return (
		<div
			className="related-select"
		>
			<components.Menu {...props}>
				{props.children}
			</components.Menu>
		</div>
	);
};

@connect(
	(state, props)=> {
		return {
			searched: state.related['search_'+props.entity] || [],
			syncedEntity: props.entity.toLowerCase(),
			syncedId: props.id,
			isLoading: state.related.isLoading || false
		}
	},
	(dispatch, ownProps) => {
		return {
			dispatch: dispatch,
			searchRelated: ({entity, id, text}) => {
				dispatch(searchRelated({entity, id, text}));
			}
		}
	}
)
@syncCollection()

export default class RelatedField extends PureComponent {
	constructor(props){
		super(props);
		this.search = this.search.bind(this);
		this.addRelated = this.addRelated.bind(this);
		this.state = {
			value: '',
			initialized: false,
			isLoading: false
		};
		this.onInputChange = this.onInputChange.bind(this);
		this.debounceSearch = debounce((data) => {
			this.search(data);
		}, 1000, {maxWait: 10000});
	}

	onInputChange(data){
		this.setState({isLoading: true});
		this.debounceSearch(data);
	}
	search(inputValue){
		this.props.searchRelated({
			entity: this.props.entity,
			id: this.props.id,
			text: inputValue
		});
	}

	addRelated(values){
		this.props.add(values.value);
	}

	componentDidMount(){
	}

	componentWillReceiveProps(nextProps){
		this.setState({isLoading: nextProps.isLoading!==this.state.isLoading ? nextProps.isLoading: this.state.isLoading});
	}

	deleteRelated(entity_id){
		this.props.remove(entity_id);
	}

	handleSelectChange(selected){
		this.search();
	}

  onFocus(){
    if (!this.state.initialized)
    {
      this.props.searchRelated({
        entity: this.props.entity,
        id: this.props.id,
        text: ''
      });
      this.setState({initialized: true});
    }
  }

	render(){
		const {entity, formValues, dispatch, style, disabled}  = this.props;
        let list = [];
        let placeholder = '';
        let title = '';
        let options = [];
        let options_initial = [];
        let url = '';
        for (var p in formValues)
        {
            list.push(formValues[p]);
        }

				list = list.sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date));
		switch(entity){
			// case "themes":
			// 	placeholder = 'Связать с темой...';
			// 	title = 'Темы';
			// 	url = '/theme/';
			// 	break;
			case "categories":
				placeholder = 'Связать с разделом...';
				title = 'Раздел';
				break;
			case "context":
				placeholder = 'Связать с публикацией...';
				title = 'Контекст';
				url = '/post/';
				break;
			case "theme_context":
				placeholder = "Связать с публикацией";
				title = 'Контекст';
				url = '/post/';
			case "event_context":
				placeholder = "Связать с публикацией";
				title = "Контекст";
				url = '/post/';
			case "features_context":
				placeholder = 'Связать с публикацией';
				title = 'Связанные публикации';
				url = '/post/'
		}
		options = (this.props.searched && this.props.searched.length>0) ? this.props.searched.reduce((result, item)=> {
			if (formValues && !formValues[item.id]) {
				result.push({
					"label": formatTitlePost(item),
					"value": item.id
				});
			}
			return result;
		}, []): [];

		if (disabled) {
			return (
				<div
					className="relatedField"
					style={style}
				>
					<div className="field field_related">
						<label>{title}</label>
					</div>
					{list.length>0
						? (<div className={"ui bottom attached "+(entity!='event_context' ? 'segment': '')}>
							<div className={"relatedPosts ui basic "+(entity!='event_context' ? 'segment': '')}>
								<div className="ui small dividing header">{title}
									<div className="ui horizontal tiny right floated label">{list.length}</div>
								</div>
								<div className="relatedContainer">
									{list.map((field)=>{return (
										<p className="relatedPostsItem" key={field.id}>
											<div className="PostTitle">
												{(entity == 'themes' || entity == 'categories')
													? null
													: (<div className="format"> {types[field.type]} </div>)
												}
												<div className="title">
													<Link to={url + field.id}>{formatTitlePost(field)}</Link>
												</div>
											</div>
										</p>
									)})}
								</div>
							</div>
						</div>)
						:null
					}
				</div>
			)
		}

		return (
			<div
				className="relatedField"
				style={style}
			>
				<div className="field field_related">
					<label>{title}</label>
					<div className="relatedField ui block">
						<div className="PostSuggest ui search selection" id={"PostSuggest-"+entity}>
							<Select
								options={options}
								onFocus={() => this.onFocus()}
								onChange={this.addRelated}
								onInputChange={this.onInputChange}
								placeholder={placeholder}
								searchable={true}
								value={null}
								components={{ Menu }}
							    isLoading={this.state.isLoading ? true : false}
							/>
						</div>
					</div>
				</div>
				{list.length>0
					?
					(
						<div className={"ui bottom attached "+(entity!='event_context' ? 'segment': '')}>
							<div className={"relatedPosts ui basic "+(entity!='event_context' ? 'segment': '')}>
								<div className="ui small dividing header">{title}
									<div className="ui horizontal tiny right floated label">{list.length}</div>
								</div>
								<div className="relatedContainer">
									{
										list.map((field)=>{
											return (
												<p className="relatedPostsItem" key={field.id}>
													<div className="PostTitle">
														{(entity == 'themes' || entity == 'categories')
															? null
															: (<div className="format"> {types[field.type]} </div>)
														}
														<div className="title">
															<Link to={url + field.id}>{formatTitlePost(field)}</Link>
															<i className="unlinkPost ui grey delete icon"
															   onClick={this.deleteRelated.bind(this, field.id)}></i>
														</div>
													</div>
												</p>
											);
										})
									}
								</div>
							</div>
						</div>
					)
					:
					null}

			</div>
		)
	}

}
