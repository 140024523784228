import React from 'react';
import {
  ToolTip,
} from '/components/SlateRichText/molecules';
import {
  TopBarButton,
} from '../../atoms';
import {
  Icons,
  ModuleContainer,
} from '../../../../atoms';
import {
  typographus,
} from '../../../../helpers';
import Editor from '../../../../Editor';

const {
  Typographus,
} = Icons;

const onClick = editor => (event) => {
  event.preventDefault();
  if (!editor) return null;
  typographus(editor);
};

const TypographusToolModule = ({ editor, enabled }) => {
  const id = Editor.EDITOR_IDS.TYPOGRAPHY;

  return (
    <ModuleContainer>
      <TopBarButton
        id={id + editor.props.editorId}
        onClick={onClick(editor)}
        data-for={id}
        data-tip
        enabled={enabled}
      >
        <Typographus />
        <ToolTip
          id={id}
          tooltip={Editor.TOOLTIPS.TYPOGRAPHY}
        />
      </TopBarButton>
    </ModuleContainer>
  );
};

export default TypographusToolModule;
