import { isString } from 'lodash';

const DEFAULT_SPACE = ' ';
const NON_BREAKING_HYPHEN = '‑'; // Hypen (англ) дефис
const COMMON_SPACES = '\u00A0\u1680\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u202F\u205F\u3000';
const ZERO_WIDTH_SPACES = '\u180E\u200B\uFEFF';
const LATIN = 'a-zA-Z';
const CYRILLIC = '\u0400-\u04FF';
const NUMBERS = '0-9';
const DASHES = '−–_—';
const PUNCTUATION = '!?.:;,…,\\s';
const SYMBOLS = '@$%^&№#*+=/\\|';
const QUOTES = '"„“«»“”‘’„”\'';
const BRACKETS = '(){}\\[\\]<>';
const HYPHENS = '\u2010\u002D';

const INPUT_CHARS_WHITELIST = `${LATIN}${CYRILLIC}${NUMBERS}${DASHES}${PUNCTUATION}${SYMBOLS}${QUOTES}${BRACKETS}${NON_BREAKING_HYPHEN}`;

const INPUT_CHARS_BLACKLIST = new RegExp(
  `([^${INPUT_CHARS_WHITELIST}])`,
  'g',
);
const SPACES_WHITELIST = new RegExp(
  `([${COMMON_SPACES}])`,
  'g',
);
const SPACES_BLACKLIST = new RegExp(
  `([${ZERO_WIDTH_SPACES}])`,
  'g',
);
const HYPHEN_WHITELIST = new RegExp(
  `([${HYPHENS}])`,
  'g',
);

class Formatted {
  constructor(value) {
    this.value = value;
  }

  removeDisallowSpaces() {
    this.value = this.value.replace(SPACES_BLACKLIST, '');
    return this;
  }

  normalizeAllowSpaces() {
    this.value = this.value.replace(SPACES_WHITELIST, DEFAULT_SPACE);
    return this;
  }

  removeIllegalCharacters() {
    this.value = this.value.replace(INPUT_CHARS_BLACKLIST, '');
    return this;
  }

  normalizeHyphen() {
    this.value = this.value.replace(HYPHEN_WHITELIST, NON_BREAKING_HYPHEN);
    return this;
  }

  get text() {
    return this.value;
  }
}

export default (value) => {
  if (!isString(value)) return value;

  const formatted = new Formatted(value);
  formatted
    .removeDisallowSpaces()
    .normalizeAllowSpaces()
    .normalizeHyphen()
    .removeIllegalCharacters();

  return formatted.text;
};
