import Editor from './Editor';

const Schema = {
  document: {
    last: { type: Editor.NODE.PARAGRAPH },
    normalize: (change, { code, node }) => {
      if (code === 'last_child_type_invalid') {
        const paragraph = Editor.node.Paragraph();
        return change.insertNodeByKey(node.key, node.nodes.size, paragraph);
      }
    }
  },

  create() {
    const nodesSchema = {
      blocks: {},
      inlines: {},
      document: this.document,
    };

    for (const node of Editor.node.all) {
      // eslint-disable-next-line no-continue
      if (!node.schema) continue;

      switch (true) {
        case Editor.isBlock(node.object):
          nodesSchema.blocks[node.type] = node.schema;
          break;
        case Editor.isInline(node.object):
          nodesSchema.inlines[node.type] = node.schema;
          break;
        default:
          throw new Error(node, 'node object in not valid');
      }
    }
    return nodesSchema;
  }
};

export default Schema;
