import io from '../io';
import generateId from './generateId';

const requestPool = {};

io.on('mz_protocol_answer', (res) => {
  const id = res.request_id;
  if (requestPool.hasOwnProperty(id)) {
    if (res.code === 'ok') {
      requestPool[id].resolve(res.data);
    }
    if (res.code === 'error') {
      requestPool[id].reject(res.message);
    }
  }
});

export default function sendNikitaRequest(command, ...params) {
  return new Promise((resolve, reject) => {
    const request = { command, params };
    const id = generateId();
    const ts = Date.now();
    request.ts = ts;
    request.request_id = id;
    io.emit('mz_protocol', request);
    requestPool[id] = { resolve, reject, ts };
  });
}
