import React, { Component } from 'react';
import {
  Icons,
  ModuleContainer,
  BlockControllerButton
} from '../../../../atoms';
import {
  EmbedForm
} from '../index';
import Editor from '../../../../Editor';

const {
  EditBlock
} = Icons;

class EmbedChanger extends Component {
  state = {
    isOpenModal: false
  };

  handleModal = () => {
    this.setState(prev => ({
      isOpenModal: !prev.isOpenModal
    }));
  };

  closeModal = () => {
    this.setState(() => ({
      isOpenModal: false
    }));
  };

  onSubmit = async (props) => {
    const {
      type,
      embedValue,
      url
    } = props;

    const {
      node
    } = this.props;

    if (!node || !node.key || !node.nodes) {
      return;
    }

    let embed;

    switch (type) {
      case Editor.EMBED.YOUTUBE:
        embed = Editor.node.Youtube({ url });
        break;
      case Editor.EMBED.DEFAULT:
        embed = Editor.node.Embed({ html: embedValue, url });
        break;
      default: {
        return;
      }
    }

    const embedCaption = node.nodes.find(n => n.type === Editor.NODE.EMBED_CAPTION);

    if (!embedCaption) {
      throw new TypeError('Photo-caption is not found');
    }

    const EmbedContainer = Editor.node.EmbedContainer({
      nodes: [
        embed,
        embedCaption
      ]
    });

    this.props.editor.replaceNodeByKey(node.key, EmbedContainer);
  };

  render() {
    const { isOpenModal } = this.state;
    const {
      node
    } = this.props;

    if (!node) return null;

    const initialState = {};

    const embedNode = node.nodes.get(0);

    if (!embedNode) return null;

    const url = embedNode.data.get('url');
    if (url) {
      initialState.value = url;
    } else {
      const codeValue = embedNode.data.get('html');
      if (codeValue) {
        initialState.value = codeValue;
      }
    }

    initialState.embedType = embedNode.type;

    return (
      <ModuleContainer>
        {isOpenModal ? (
          <EmbedForm
            handleModal={this.handleModal}
            closeModal={this.closeModal}
            isOpen={isOpenModal}
            onSubmit={this.onSubmit}
            initialState={initialState}
            submitText={'Изменить'}
            title={'Изменить медиа:'}
          />) : null
        }
        <BlockControllerButton
          onMouseDown={this.handleModal}
        >
          <EditBlock />
        </BlockControllerButton>
      </ModuleContainer>
    );
  }
}

export default EmbedChanger;