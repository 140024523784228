import React from 'react';
import {
  nodeTypes,
  objectTypes,
} from '../../../../consts';

const BulletNode = {
  type: nodeTypes.BULLET,
  object: objectTypes.BLOCK,
  isTag: false,

  create() {
    return {
      type: this.type,
      object: this.object,
    };
  },

  htmlRender({ attributes, children }) {
    return (
      <li
        {...attributes}
        className="mz-publish__list-item"
      >
        {children}
      </li>
    );
  },

  editorRender(attrs) {
    return (
      <li
        {...attrs}
        className="mz-publish__list-item"
      />
    );
  },
};

export default BulletNode;
