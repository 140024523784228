import mz_proto from '../utils/mz_protocol';

export const GET_NOTES = 'GET_NOTES';
export const GET_NOTES_SUCCESS = 'GET_NOTES_SUCCESS';
export const GET_NOTES_FAILURE = 'GET_NOTES_FAILURE';
export const CHANGE_SEARCH_KEY = 'CHANGE_SEARCH_KEY';

export const getNotes = (searchQuery = '', ts = null) => async (dispatch) => {
  try {
    dispatch({ type: GET_NOTES });
    const notes = await mz_proto('getNotes', {searchQuery, ts});
    console.log('notes from ts', ts, notes);
    dispatch({ type: GET_NOTES_SUCCESS, searchQuery, payload: notes });
  } catch (e) {
    dispatch({ type: GET_NOTES_FAILURE, error: e.message });
    console.log(e);
  }
};

