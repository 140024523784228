import React, { Component } from 'react';
import {
  BlockControllerBar,
} from '../../../../atoms';
import {
  BlockWrapper,
  DeleteBlock,
} from '../../../../molecules/';
import {
  PullQuoteChanger,
} from '../index';


export default class PullQuoteController extends Component {
  render() {
    const { children, attributes, classNames, node, editor, data } = this.props;

    if (!node) return null;

    const viewType = node.data.get('viewType') || '';
    const quoteTitle = node.data.get('quoteTitle') || '';
    const quoteText = node.data.get('quoteText') || '';

    let float;
    let width;

    switch (viewType) {
      case 'side':
        float = 'left';
        width = '270px';
        break;
      case 'side-right':
        float = 'right';
        width = '270px';
        break;
      default:
        float = 'none';
        width = 'auto';
    }

    return (
      <BlockWrapper
        node={node}
        editor={editor}
        float={float}
        width={width}
        className={classNames}
        viewType={viewType}
        theme="red"
        {...attributes}
      >
        <BlockControllerBar>
          <DeleteBlock
            node={node}
            editor={editor}
          />
          <PullQuoteChanger
            editor={editor}
            node={node}
            data={data}
            quoteTitle={quoteTitle}
            quoteText={quoteText}
            viewType={viewType}
          />
        </BlockControllerBar>
        {children}
      </BlockWrapper>
    );
  }
}
