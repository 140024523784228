import React from 'react';
import {
  nodeTypes,
  objectTypes
} from '../../consts';
import {
  EmbedCaption
} from './atoms';
import {
  BlockPlaceholder
} from '../../molecules';

const EmbedCaptionNode = {
  type: nodeTypes.EMBED_CAPTION,
  object: objectTypes.BLOCK,

  create({ editor }) {
    return {
      type: this.type,
      object: this.object,
      nodes: [
        editor.node.Paragraph()
      ]
    };
  },

  schema: {
    nodes: [{
      match: [
        {
          type: nodeTypes.PARAGRAPH
        },
      ],
      min: 1,
    }],
    parent: {
      type: nodeTypes.EMBED_CONTAINER
    },
    normalize: (_, { code }) => {
      if (code === 'child_type_invalid') return null;
    }
  },

  htmlRender(attrs) {
    return (
      <EmbedCaption
        {...attrs}
      />
    );
  },

  editorRender(attrs) {
    const { attributes, node } = attrs;
    return (
      <EmbedCaption
        attributes={attributes}
      >
        <BlockPlaceholder
          text={node.text}
          placeholder={'Текст...'}
          {...attrs}
        />
      </EmbedCaption>
    );
  }
};

export default EmbedCaptionNode;

