import React from 'react';

const DISPLAY_SESSIONS_LIMIT = 10;

const SessionInfo = ({ lastSeenAt, ip, metadata }) => {
  let seenDate = null;
  try {
    seenDate = new Date(lastSeenAt);
  } catch (e) {
    seenDate = null;
  }

  if (!seenDate) {
    return null;
  }

  return (
    <div className="item">
      <span>
        <strong>
          {seenDate.toLocaleString()}
        </strong>
      </span>
      <details>
        <summary style={{ color: 'blue', cursor: 'pointer' }}>
          {ip}
          {' подробнее'}
        </summary>
        {JSON.stringify(metadata)}
      </details>
    </div>
  );
};

const UserSessionList = ({
  isLoading,
  sessionList,
  errorMessage,
}) => (
  <>
    {isLoading && (
      <div className="ui segment" style={{ height: '15em' }}>
        <div className="ui active dimmer">
          <div className="ui loader" />
          <h3 style={{ color: 'white' }}>Сессии загружаются...</h3>
        </div>
      </div>
    )}

    {errorMessage && (
      <h3>
        Ошибка:
        {errorMessage}
      </h3>
    )}
    {sessionList && sessionList.length === 0 && (
      <h3>У пользователя нет активных сессий</h3>
    )}
    {sessionList && sessionList.length > 0 && (
      <div className="ui relaxed divided list">
        <h3>Сессии:</h3>
        {sessionList
          .slice(0, DISPLAY_SESSIONS_LIMIT)
          .map(({ lastSeenAt, ip, metadata }) => (
            <SessionInfo
              lastSeenAt={lastSeenAt}
              ip={ip}
              metadata={metadata}
            />
          ))}
      </div>
    )}
    {sessionList && sessionList.length > DISPLAY_SESSIONS_LIMIT && (
      <p>
        + ещё
        {' '}
        {sessionList.length - DISPLAY_SESSIONS_LIMIT}
      </p>
    )}
  </>
);

export default UserSessionList;
