/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from 'react';
import '../RelatedField/RelatedField.styl';
import { DragSource } from 'react-dnd';
import { Link } from 'react-router';
import { types } from '../../utils/constants';
import ItemTypes from './ItemTypes';

import { formatTitlePost } from '../../lib/formatString';

const fieldToDragContex = (field) => ({
  post_id: field.post_id,
  type: field.type,
  title: formatTitlePost(field),
});

const contextSource = {
  beginDrag(props) {
    return fieldToDragContex(props.field);
  },
};

@DragSource(ItemTypes.CONTEXT, contextSource, (connector, monitor) => ({
  connectDragSource: connector.dragSource(),
  isDragging: monitor.isDragging(),
}))

class RecentFeatureItem extends Component {
  addToFeature() {
    const { field, onDragEndContext } = this.props;
    onDragEndContext(fieldToDragContex(field), 0);
  }

  render() {
    const { field, connectDragSource } = this.props;
    field.title = field.title ? field.title : field.name;
    return connectDragSource(
      <div className="relatedPostsItem relatedPostsItem_feature">
        <div className="PostTitle">
          <div className="format">
            {' '}
            {types[field.type]}
            {' '}
          </div>
          <div className="title">
            <Link to={`/post/${field.post_id}`}>{formatTitlePost(field)}</Link>
          </div>
          <i className="ui blue add icon addPostToFeature" onClick={() => { this.addToFeature(); }} />
        </div>
      </div>,
    );
  }
}

export default RecentFeatureItem;
