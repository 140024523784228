import React, { Component } from "react";
import format from 'date-fns/format';
import './csvAnalytics.styl';

export default class CsvAnalytics extends Component {
  state = {
    startDate: format(new Date() - 30 * 24 * 60 * 60 * 1000, 'YYYY-MM-DD'),
    endDate: format(new Date(), 'YYYY-MM-DD')
  };

  onChangeStart = (e) => {
    this.setState((state) => ({
      startDate: e.target.value
    }));
    e.persist();
  };

  onChangeEnd = (e) => {
    this.setState((state) => ({
      endDate: e.target.value
    }));
    e.persist();
  };

  render() {
    const {
      startDate,
      endDate
    } = this.state;

    return (
      <div className="ui container csv-analytics__container">
        <h2 className="ui large header">CSV Аналитика</h2>
        <div className="csv-analytics__field">
          <label>От:</label>
          <input type="date"
                 name="trip"
                 onChange={this.onChangeStart}
                 value={startDate}/>
        </div>
        <div className="csv-analytics__field">
          <label>До:</label>
          <input type="date"
                 name="trip"
                 onChange={this.onChangeEnd}
                 value={endDate}/>
        </div>
        <a href={`/webservices/csv_analytics/?start_date=${startDate}&end_date=${endDate}`}>
          <button>Скачать CSV</button>
        </a>
      </div>
    )
  }
}
