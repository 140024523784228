import {
  objectTypes,
  nodeTypes,
  markTypes,
  embedTypes,
  annotationTypes,
  tooltips,
  editorIds,
} from './consts';
import NodeFactory from './NodeFactory';
import Mark from './Mark';
import Annotation from './Annotation';

const Editor = {
  OBJECT: objectTypes,
  NODE: nodeTypes,
  MARK: markTypes,
  ANNOTATION: annotationTypes,
  EMBED: embedTypes,
  TOOLTIPS: tooltips,
  mark: Mark,
  annotation: Annotation,

  EDITOR_IDS: editorIds,
  get node() {
    return new NodeFactory(this);
  },

  createNodeByType(attr) {
    if (!(attr && attr.type)) throw new Error('type is required params');

    const Node = this.node.map[attr.type];
    return Node(attr);
  },

  htmlRenderNodeByType(el) {
    const { type } = el;
    return type.htmlRender(el);
  },

  renderNodeByType(el) {
    const { node } = el;

    const type = this.node.map[node.type];
    if (!type) return null;

    if (type.editorRender) return type.editorRender(el);
    return type.htmlRender(el);
  },

  isBlock(object) {
    return object === this.OBJECT.BLOCK;
  },

  isInline(object) {
    return object === this.OBJECT.INLINE;
  },
};

export default Editor;
