import React, { Component } from 'react';
import { Portal } from 'react-portal';
import styled from 'styled-components';
import Modal from '/components/Modal/Modal';
import { Select } from '/ui/atoms';
import { nodeTypes } from '/components/SlateRichText/consts';
import {
  ModalTitleContainer,
  ModalCloseButton,
  Icons,
  ModalContent,
  InputTitle,
} from '../../../../atoms';
import {
  selectStyle,
} from '../../../../styles';
import PullQuoteForm from '../PullQuoteForm';
import TextPullQuoteForm from '../TextPullQuoteForm';
import CutPullQuoteForm from '../CutPullQuoteForm';
import QuoteForm from '../QuoteForm';
import DonateForm from '../DonateForm';
import NewsletterForm from '../NewsletterForm';

const {
  Close,
} = Icons;

const FormContainer = styled('div')`
  width: 594px;
`;

class PullQuoteModal extends Component {
  constructor(props) {
    super(props);
    this.pullQuoteTypes = {
      [nodeTypes.CONTEXT_PULL_QUOTE]: {
        label: 'Контекстный врез',
        Form: PullQuoteForm,
      },
      [nodeTypes.MULTICONTEXT_PULL_QUOTE]: {
        label: 'Контекстный врез (список)',
        Form: PullQuoteForm,
      },
      [nodeTypes.CUT_PULL_QUOTE]: {
        label: 'Врез-кат',
        Form: CutPullQuoteForm,
      },
      [nodeTypes.TEXT_PULL_QUOTE]: {
        label: 'Текстовый врез',
        Form: TextPullQuoteForm,
      },
      [nodeTypes.QUOTE]: {
        label: 'Врез-справка (Бета)',
        Form: QuoteForm,
      },
      [nodeTypes.DONATE]: {
        label: 'Донатный врез',
        Form: DonateForm,
      },
      [nodeTypes.NEWSLETTER]: {
        label: 'Рассылочный врез',
        Form: NewsletterForm,
      },
    };

    this.pullQuoteTypeOptions = Object
      .entries(this.pullQuoteTypes)
      .filter(([key]) => (props.types.length > 0 ? props.types.includes(key) : true))
      .map(([key, value]) => ({
        value: key,
        ...value,
      }));

    this.state = {
      type: props?.node?.type || 'context-pull-quote',
      isOpenModal: false,
    };
  }

  static defaultProps = {
    data: {},
    types: [],
  };

  onSelect = fieldName => (option) => {
    this.setState(() => ({
      [fieldName]: option,
    }));
  };

  render() {
    const {
      type,
    } = this.state;

    const {
      isOpen,
      title,
      closeModal,
      submitText,
      onSubmit,
      editor,
      initialState,
    } = this.props;

    const Form = this.pullQuoteTypes[type]?.Form;

    if (!Form) {
      console.error(`No form eq ${type} in pullQuoteTypes`);
      return null;
    }

    return (
      <Portal>
        <Modal isOpen={isOpen} handleClose={closeModal}>
          <ModalTitleContainer>
            <h2>{title}</h2>
            <ModalCloseButton onClick={closeModal}>
              <Close />
            </ModalCloseButton>
          </ModalTitleContainer>
          <ModalContent>
            <FormContainer>
              {this.pullQuoteTypeOptions.length > 1 && (
                <>
                  <InputTitle>
                    Тип вреза
                  </InputTitle>
                  <Select
                    clearable={false}
                    className={selectStyle}
                    menuContainerStyle={{ zIndex: 1000 }}
                    value={type}
                    onChange={this.onSelect('type')}
                    options={this.pullQuoteTypeOptions}
                  />
                </>
              )}
              <Form
                type={type}
                onSubmit={onSubmit}
                onClose={closeModal}
                submitText={submitText}
                editor={editor}
                initialState={initialState}
              />
            </FormContainer>
          </ModalContent>
        </Modal>
      </Portal>
    );
  }
}

export default PullQuoteModal;
