import styled, { css } from 'react-emotion';

export const QuillContainerStyles = {
  cursor: 'text',
  overflow: 'scroll',
  border: '1px solid rgba(34, 36, 38, 0.15)',
  backgroundColor: 'white',
};

export const QuillClass = css`
  .ql-editor {
    min-height: 120px;

    p {
      font: 14px "Graphik LC", sans-serif !important;
      white-space: pre-wrap;
      line-height: 1.43 !important;
      color: #151515;
      margin-bottom: 10px;

      :last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;
