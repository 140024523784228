import React from 'react';
import FileUploader from './FileUploader';

const SingleFileUploader = (props) => (
  <FileUploader
    multipleLoading={false}
    {...props}
  />
);

export default SingleFileUploader;
