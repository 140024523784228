import React from 'react';
import styled from 'styled-components';
import {
  TextPullQuote,
} from './components';
import PullQuoteController from '../PullQuote/components/PullQuoteController';
import {
  nodeTypes,
  objectTypes,
} from '../../consts';
import {
  BlockPlaceholder,
} from '../../molecules';


const PullQuoteBody = styled.div`
  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }
`;

export const TextPullQuoteBodyNode = {
  type: nodeTypes.TEXT_BODY,
  object: objectTypes.BLOCK,

  create({ editor }) {
    return {
      type: this.type,
      object: this.object,
      nodes: [
        editor.node.Paragraph(),
      ],
    };
  },

  schema: {
    nodes: [
      {
        type: nodeTypes.PARAGRAPH,
        min: 1,
      },
    ],
  },

  htmlRender(attrs) {
    const { children, attributes } = attrs;

    return (
      <div className="mz-publish-text-pull-quote__text" {...attributes}>
        {children}
      </div>
    );
  },

  editorRender(attrs) {
    const { node, attributes } = attrs;

    return (
      <PullQuoteBody className="mz-publish-text-pull-quote__text" {...attributes}>
        <BlockPlaceholder
          text={node.text}
          placeholder={'Текст...'}
          {...attrs}
        />
      </PullQuoteBody>
    );
  },
};

export const TextPullQuoteNode = {
  type: nodeTypes.TEXT_PULL_QUOTE,
  object: objectTypes.BLOCK,

  create({ imgSrc, nodes, viewType, type, alt }) {
    return {
      type: this.type,
      object: this.object,
      data: {
        imgSrc,
        viewType,
        type,
        alt,
      },
      nodes,
    };
  },

  htmlRender(attrs) {
    return (
      <TextPullQuote
        {...attrs}
      />
    );
  },

  editorRender(attrs) {
    const { node, attributes, children, type } = attrs;
    return (
      <PullQuoteController
        {...attrs}
      >
        <TextPullQuote
          attributes={attributes}
          node={node}
          type={type}
        >
          {children}
        </TextPullQuote>
      </PullQuoteController>
    );
  },
};
