import {
  isHotKey,
} from '../helpers';
import Editor from '../Editor';

const isBlockBackspace = (change) => {
  const { selection, focusBlock, document } = change.value;
  const { text, type, nodes } = document.getParent(focusBlock.key);
  return type
    && (nodes.size <= 1 || focusBlock === nodes.first())
    && (!text.length || (!selection.isExpanded && selection.anchor.offset <= 0));
};

const isBlockDelete = (change) => {
  const { selection, focusBlock, document } = change.value;
  const { text, type, nodes } = document.getParent(focusBlock.key);
  return type
    && (nodes.size <= 1 || focusBlock === nodes.last())
    && (!text.length
      || (!selection.isExpanded
        && (selection.anchor.offset >= text.length
          || selection.anchor.offset === focusBlock.text.length
        )
      )
    );
};

function onKeyDown(event, change, next) {
  let command;

  switch (true) {
    case isHotKey(event, 'bold'):
      command = change.toggleMark(Editor.MARK.BOLD);
      break;
    case isHotKey(event, 'italic'):
      command = change.toggleMark(Editor.MARK.ITALIC);
      break;
    case isHotKey(event, 'underline'):
      command = change.toggleMark(Editor.MARK.UNDERLINE);
      break;
    case isHotKey(event, 'soft-enter'):
      command = change.insertText('\n');
      break;
    case isHotKey(event, 'enter'): {
      const { value } = change;
      const { selection, focusBlock } = value;

      if (!selection || !focusBlock) return next();

      if (focusBlock.type === 'h3') {
        break;
      }

      if (selection.focus.offset >= focusBlock.text.length) {
        command = change.insertBlock(
          Editor.node.Paragraph(),
        );
        if (focusBlock.type === Editor.NODE.BULLET) {
          command = change.setBlocks(
            Editor.node.Bullet(),
          );
        }
      } else {
        return next();
      }
    }
      break;
    case isHotKey(event, 'backspace'):
      const { value } = change;
      const { selection, focusBlock } = value;
      if (focusBlock.type === Editor.NODE.BULLET &&
      selection &&
      selection.focus.offset === 0 &&
      selection.anchor.offset === 0) {
        command = change
          .unwrapBlock({ type: Editor.NODE.BULLET })
          .unwrapBlock({ type: Editor.NODE.BULLETS })
          .setBlocks(
            Editor.node.Paragraph(),
          );
      }
      if (isBlockBackspace(change)) {
        break;
      }
      return next();
    case isHotKey(event, 'link'):
      command = change
        .wrapInline(Editor.node.Link({
          href: null,
          isCreated: true,
        }));
      break;
    case isHotKey(event, 'undo'):
      command = change.undo();
      break;
    case isHotKey(event, 'redo'):
      command = change.redo();
      break;
    case isHotKey(event, 'hint'):
      command = change
        .wrapInline(Editor.node.Hint({
          isCreated: true
        }));
      break;
    case isHotKey(event, 'pullquote'):
      command = true;
      const pullQuoteModalButton = document.getElementById(Editor.EDITOR_IDS.PULLQUOTE + change.props.editorId);
      pullQuoteModalButton.click();
      break;
    case isHotKey(event, 'media'):
      command = true;
      const mediaModalButton = document.getElementById(Editor.EDITOR_IDS.EMBED + change.props.editorId);
      mediaModalButton.click();
      break;
    case isHotKey(event, 'photo'):
      command = true;
      const photoModalButton = document.getElementById(Editor.EDITOR_IDS.PHOTO + change.props.editorId);
      photoModalButton.click();
      break;
    case isHotKey(event, 'typography'):
      command = true;
      const typographyButton = document.getElementById(Editor.EDITOR_IDS.TYPOGRAPHY + change.props.editorId);
      typographyButton.click();
      break;
    case isHotKey(event, 'delete'):
      if (isBlockDelete(change)) {
        break;
      }
      return next();
    default:
      return next();
  }

  if (command) {
    event.preventDefault();
    return command;
  }

  return true;
}


export default function marksHotKeys() {
  return {
    onKeyDown
  };
}
