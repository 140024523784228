// @flow
import React, { Component } from 'react';
import Select, { components } from 'react-select';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';
import { searchRelated } from '/actions/actions';
import { syncCollection } from '/lib/sync-form';
import { formatTitlePost } from '/lib/formatString';
import { publicUrl } from '/const/config';
import { searchableValue } from '/lib/searchableValue';

export type Searched = {|
  title: string,
  id: number,
|}

export type RelatedItem = {|
  entity: string,
  text: string,
  id: number,
|}

const NonHidingInput = (props) => {
  const newProps = {
    ...props,
    isHidden: false,
  };
  return components.Input(newProps);
};

type Props = {|
  onChange: Function,
  searched: Searched[],
  searchRelated: (RelatedItem) => void,
  isLoading: boolean,
  entity: string,
  id: number,
  placeholder: string,
  disabled: boolean,
|}

type State = {|
  initialized: boolean,
  isLoading: boolean,
|}

@connect(
  (state, props) => ({
    syncedEntity: props.entity,
    syncedId: props.id,
  }),
  dispatch => ({
    searchRelated: (props) => {
      dispatch(searchRelated(props));
    },
  }),
)
@syncCollection()
class RelatedSearch extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      initialized: false,
      isLoading: false,
      inputValue: '',
    };
  }


  componentWillReceiveProps(nextProps: Props) {
    const isUpdateLoading = nextProps.isLoading !== this.state.isLoading;

    if (isUpdateLoading) {
      this.setState({
        isLoading: nextProps.isLoading,
      });
    }
  }

  setInitialized = (initialized: boolean) => this.setState(() => ({ initialized }));
  setIsLoading = (isLoading: boolean) => this.setState(() => ({ isLoading }));

  searchRelated = (text: string) => {
    this.props.searchRelated({
      entity: this.props.entity,
      id: this.props.id,
      text,
    });
  };

  debounceSearch = debounce((text: string) => {
    this.searchRelated(text);
  }, 1000, { maxWait: 10000 });

  onFocus = () => {
    if (!this.state.initialized) {
      this.searchRelated('');
      this.setInitialized(true);
    }
  };

  onInputChange = (text: string, action: string) => {
    if (action.action === 'input-change') {
      this.setIsLoading(true);
      this.debounceSearch(text);
      this.setState({ inputValue: text });
    }
  };

  onChange = (p) => {
    this.searchRelated(this.state.inputValue);
    this.props.onChange(p);
  };

  render() {
    const {
      isLoading,
    } = this.state;

    const {
      onChange,
      placeholder,
      searched,
      disabled,
      multi,
    } = this.props;

    let options;

    if (searched) {
      options = searched.map(el => {
        let link = '';
        const { id, title, url } = el;
        if (url) {
          link = `${publicUrl}${url}`;
        }
        return {
          label: formatTitlePost(el),
          value: searchableValue.generate({ id, title, link }),
        };
      });
    };

    return (
      <Select
        inputValue={this.state.inputValue}
        value={this.state.inputValue}
        options={options}
        onFocus={this.onFocus}
        onChange={this.onChange}
        onInputChange={this.onInputChange}
        placeholder={placeholder}
        isLoading={isLoading}
        disabled={disabled}
        components={{ Input: NonHidingInput }}
        searchable
      />
    );
  }
}

export default RelatedSearch;
