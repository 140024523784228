import React from 'react';

const CkeEditorToolBar = (props) => {
  const stickyStyle = {
    visibility: props.ui.ckToolbar ? 'visible' : 'hidden',
  };
  return (
    <div id="ckeditor_toolbar" name="ckeditor_toolbar" className="ui sticky" style={stickyStyle} />
  );
};

export default CkeEditorToolBar;
