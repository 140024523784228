import * as actionsNotes from '../actions/notes';
import * as actions from '../actions/actions';

const initialState = {
	ckToolbar: false,
	isChanged: false,
	noteLoaded: false,
	noteCompleted: true,
};

function ui(state = initialState, action) {
	switch(action.type) {
		case 'SET_VISIBILITY_CK_TOOLBAR':
			//ui = Object.assign({}, state.ui, );
			return {...state, ckToolbar: action.isVisible};
		case 'OBSERVE_FIELD_CHANGE':
			return {...state, isChanged: action.isChanged};
		case 'PUBLISH_POST':
			return {...state, isChanged: false};
		case actionsNotes.GET_NOTES:
			return { ...state, noteLoaded: false, noteCompleted: false };
		case actionsNotes.GET_NOTES_SUCCESS:
			return { ...state, noteLoaded: true, noteCompleted: action.payload.length === 0 };
		case actionsNotes.GET_NOTES_FAILURE:
			return { ...state, noteLoaded: true, error: action.error, noteCompleted: true };
		case actions.CHANGE_POST_TYPE_FAILURE:
			return { ...state, error: action.error };
		default:
			return state;
	}
}

export default ui
