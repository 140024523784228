export const generateUrl = (label, type, year, month, day) => {
  let url = `/${type}/`;
  if (year) {
    url += `${year}/`;
  } else {
    return `${url}${label}`;
  }

  if (month) {
    url += `${month}/`;
  } else {
    return `${url}${label}`;
  }

  return `${url}${label}`;
};

export const generateResetFilterLabel = (year, month, day) => {
  let label = '';
  if (year) {
    label += `${year} / `;
  }

  if (month) {
    label += `${month} / `;
  }

  if (day) {
    label += `${day} / `;
  }

  return label;
};

export default generateUrl;
