import React, { PureComponent } from 'react';
import MainMenu from './MainMenu/MainMenu.js';
import {connect} from 'react-redux';
import NotificationSystem from 'react-notification-system';

const defaultNotificationSettings = {
	position: "br"
};

class Layout extends PureComponent {
	constructor(props){
		super(props);
		this.handleNotification = this.handleNotification.bind(this);
	}
	componentWillMount() {
		document.addEventListener('custom-notify', this.handleNotification);
	}
	componentWillUnmount() {
		document.removeEventListener('custom-notify', this.handleNotification);
	}
	render() {
		const props = this.props;
        return <div id="layout">
            {props.isAuthenticated === true
                ? <MainMenu />
                : null
            }
	        <NotificationSystem ref={(node) => {this.notificationSystem = node;}} />
            {props.children}
		</div>

	}
	handleNotification(event) {
	    this.notificationSystem.addNotification(
	    	Object.assign({}, defaultNotificationSettings, event.detail)
	    );
	}
}

const mapStateToProps = (state) => ({
	isAuthenticated: !!state.user
});

module.exports = connect(mapStateToProps)(Layout);




