import React, { Component } from 'react';
import {
  Icons,
  ModuleContainer,
  BlockControllerButton,
} from '../../../../atoms';
import PullQuoteModal from '../PullQuoteModal';
import Editor from '../../../../Editor';

const {
  EditBlock,
} = Icons;

class PullQuoteChanger extends Component {
  state = {
    isOpenModal: false,
  };

  disabledChangeTypes = [
    Editor.NODE.CONTEXT_PULL_QUOTE,
  ];

  onSubmit = (props) => {
    const { viewType, imgSrc, type, alt, context, multiContext, contextTitle, source, title, quoteText, quoteTitle, bannerId } = props;
    const { editor, node } = this.props;

    if (!node.key) return;

    let PullQuote = null;

    switch (type) {
      case Editor.NODE.SIMPLE_PULL_QUOTE:
        PullQuote = Editor.node.PullQuote({
          viewType,
          imgSrc,
          alt,
        });
        break;
      case Editor.NODE.DOCUMENT_PULL_QUOTE:
        PullQuote = Editor.node.DocsPullQuote({
          viewType,
          imgSrc,
          alt,
        });
        break;
      case Editor.NODE.CONTEXT_PULL_QUOTE:
        PullQuote = Editor.node.ContextPullQuote({
          viewType,
          context,
          imgSrc,
          alt,
        });
        break;
      case Editor.NODE.MULTICONTEXT_PULL_QUOTE:
        PullQuote = Editor.node.MultiContextPullQuote({
          multiContext,
          contextTitle,
        });
        break;
      case Editor.NODE.TEXT_PULL_QUOTE:
        PullQuote = Editor.node.TextPullQuote({
          viewType,
          quoteTitle,
          imgSrc,
          quoteText,
          alt,
        });
        break;
      case Editor.NODE.DONATE:
        PullQuote = Editor.node.Donate({ bannerId });
        break;
      case Editor.NODE.NEWSLETTER:
        PullQuote = Editor.node.Newsletter({});
        break;
      case Editor.NODE.QUOTE:
        PullQuote = Editor.node.Quote({
          imgSrc,
          alt,
          source,
        });
        break;
      case Editor.NODE.CUT_PULL_QUOTE:
        PullQuote = Editor.node.CutPullQuote({
          title,
        });
        break;
      default:
        return;
    }

    editor.setNodeByKey(node.key, PullQuote);
  };

  closeModal = () => {
    this.setState(() => ({
      isOpenModal: false,
    }));
  };

  handleModal = () => {
    this.setState(prev => ({
      isOpenModal: !prev.isOpenModal,
    }));
  };

  render() {
    const { isOpenModal } = this.state;

    const {
      node,
      editor,
    } = this.props;

    if (!node) return null;

    const { data } = node;

    let initialState = {
      type: node.type,
      imgSrc: data.get('imgSrc'),
      viewType: data.get('viewType'),
      alt: data.get('alt'),
      contextId: data.get('contextId'),
      header: data.get('header'),
      context: data.get('context'),
      source: data.get('source'),
    };

    if (node.type === Editor.NODE.MULTICONTEXT_PULL_QUOTE) {
      initialState = {
        type: node.type,
        multiContext: data.get('multiContext'),
        contextTitle: data.get('contextTitle'),
      };
    }

    if (node.type === Editor.NODE.TEXT_PULL_QUOTE) {
      initialState = {
        type: node.type,
        imgSrc: data.get('imgSrc'),
        viewType: data.get('viewType'),
        alt: data.get('alt'),
        quoteTitle: data.get('quoteTitle'),
        text: data.get('text'),
      };
    }

    if (node.type === Editor.NODE.CUT_PULL_QUOTE) {
      initialState = {
        type: node.type,
        title: data.get('title'),
      };
    }

    if (node.type === Editor.NODE.DONATE) {
      initialState = {
        type: node.type,
        bannerId: data.get('bannerId'),
      };
    }

    return (
      <ModuleContainer>
        {isOpenModal ? (
          <PullQuoteModal
            handleModal={this.handleModal}
            closeModal={this.closeModal}
            isOpen={isOpenModal}
            onSubmit={this.onSubmit}
            submitText={'Изменить'}
            title={'Изменить врез:'}
            node={node}
            isDisableChangeType={this.disabledChangeTypes.includes(node.type)}
            editor={editor}
            initialState={initialState}
          />
        ) : null
        }
        <BlockControllerButton
          onMouseDown={this.handleModal}
        >
          <EditBlock />
        </BlockControllerButton>
      </ModuleContainer>
    );
  }
}

export default PullQuoteChanger;
