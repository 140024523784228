import React from 'react';
import {
  markTypes,
  nodeTypes,
  objectTypes,
} from '../../consts';

const HeadingTwoNode = {
  type: nodeTypes.HEADING_TWO,
  object: objectTypes.BLOCK,
  isTag: true,

  create() {
    return {
      type: this.type,
      object: this.object,
      nodes: [
        {
          object: objectTypes.TEXT,
        },
      ],
    };
  },

  schema: {
    marks: [{ type: markTypes.ITALIC }],
  },

  htmlRender({ attributes, children }) {
    return <h2 {...attributes}>{children}</h2>;
  },
};

export default HeadingTwoNode;

