import styled, { css } from 'react-emotion';

export Hint from './Hint';

export const HintInline = styled('span')`
  line-height: 1;
  border-bottom: 1px dotted #151515;
  background-color: #FFFE73;
`;

export const Container = styled('form')`
  width: 400px;
  padding: 20px;
  flex-direction: column;
  box-shadow: 0 0 50px -20px rgba(41, 48, 67, 0.74);
  background-color: #ffffff;
`;

export const Row = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  :last-of-type {
    margin-bottom: 0;
  }
`;

export const InputTitle = styled('span')`
  font: 12px "Graphik LC", sans-serif;
  line-height: 1.67;
  color: #293043;
  min-width: 70px;
  align-self: center;
`;

export const Input = styled('input')`
  height: 30px;
`;

export const RichTextTitle = styled('span')`
  font: 12px "Graphik LC", sans-serif;
  line-height: 1.67;
  color: #293043;
  min-width: 70px;
  align-self: start;
`;

export const RichText = styled('div')`
  width: 290px;
`;

export const Control = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const Accept = styled('button')`
  width: 47%;
  height: 30px;
  background-color: #00c656;
  border: none;
  font: 500 11px "Graphik LC", sans-serif;
  line-height: 2.73;
  letter-spacing: 1px;
  color: #ffffff;
  text-transform: uppercase;
`;

export const Delete = styled('button')`
  width: 47%;
  height: 30px;
  background-color: #fd6073;
  border: none;
  font: 500 11px "Graphik LC", sans-serif;
  line-height: 2.73;
  letter-spacing: 1px;
  color: #ffffff;
  text-transform: uppercase;
  
  &[disabled] {
    background-color: #8a8a8a;
    color: #383838;
  }
`;

export const Error = styled('div')`
  font: 500 10px "Graphik LC", sans-serif;
  color: #fd6073;
  margin: 3px 0;
`;
