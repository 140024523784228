import React from 'react';
import * as R from 'ramda';
import Editor from '../../../../Editor';
import {
  HeadingTwoSelect,
  HeadingThreeSelect,
  ParagraphSelect,
  HighlightSelect
} from '../../atoms';
import {
  CustomSelect,
  ModuleContainer,
} from '../../../../atoms';

const findOptionByIndex = (value) => {
  const index = R.findIndex(R.propEq('value', value))(options);
  if (index < 0) { return { value: 'unknown', label: '' }; }
  return options[index];
};

const options = [
  { value: Editor.NODE.PARAGRAPH, label: <ParagraphSelect>Обычный</ParagraphSelect> },
  { value: Editor.NODE.HEADING_TWO, label: <HeadingTwoSelect>Заголовок 2</HeadingTwoSelect> },
  { value: Editor.NODE.HEADING_THREE, label: <HeadingThreeSelect>Заголовок 3</HeadingThreeSelect> },
  { value: Editor.NODE.HIGHLIGHT, label: <HighlightSelect>Выделенный</HighlightSelect> }
];

let currentOption = findOptionByIndex('normal');

const updateBlockType = (value) => {
  const blockTypeList = R.pluck('type')(value.blocks).toArray();
  const uniqueTypeList = R.uniq(blockTypeList);

  if (uniqueTypeList.length === 1) {
    currentOption = findOptionByIndex(blockTypeList[0]);
  } else {
    currentOption = { value: 'unknown', label: '' };
  }
};

const handleChange = editor => (type) => {
  if (type.value === 'unknown') return;
  editor.setBlocks({ type: type.value });
};

const HeadingToolModule = ({ editor, allowList }) => {
  updateBlockType(editor.value);
  const opt = allowList
    ? options.filter(el => allowList.includes(el.value))
    : options;

  return (
    <ModuleContainer>
      <CustomSelect
        onChange={handleChange(editor)}
        options={opt}
        currentOption={currentOption}
      />
    </ModuleContainer>
  );
};

export default HeadingToolModule;
