import React from 'react';
import {
  youTubeID
} from '../../../../helpers';

const YouTube = (props) => {
  const { node, attributes } = props;

  const youTubeUrl = node.data.get('url');
  const youTubeIDVideo = youTubeID(youTubeUrl);

  return (
    <div className="mz-yt-wrap" {...attributes}>
      <div className="youtube-embed-wrapper" style={{ position: 'relative', paddingBottom: '56.25%', paddingTop: '30px', height: 0, overflow: 'hidden' }}>
        <iframe
          allowFullScreen=""
          frameBorder="0"
          height="100%"
          src={`//www.youtube.com/embed/${youTubeIDVideo}`}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} width="100%"
        />
      </div>
    </div>
  );
};

export default YouTube;