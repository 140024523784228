import React from 'react';
import {
  PhotoContainer
} from './atoms';
import {
  PhotoController
} from './organisms';
import {
  nodeTypes,
  objectTypes
} from '../../consts';

const PhotoContainerNode = {
  type: nodeTypes.PHOTO_CONTAINER,
  object: objectTypes.BLOCK,

  create({ viewType, nodes }) {
    return {
      type: this.type,
      object: this.object,
      data: {
        viewType,
      },
      nodes,
    };
  },

  schema: {
    nodes: [{
      match: [
        { type: nodeTypes.PHOTO },
        { type: nodeTypes.PHOTO_CAPTION },
      ],
      min: 2,
    }],
  },

  htmlRender(attrs) {
    return (
      <PhotoContainer
        {...attrs}
      />
    );
  },

  editorRender(attrs) {
    return (
      <PhotoController
        {...attrs}
      >
        <PhotoContainer
          {...attrs}
        />
      </PhotoController>
    );
  }
};

export default PhotoContainerNode;
