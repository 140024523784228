module.exports = {
  channels: {
    authActions: 'auth_actions_notification_channel',
  },

  browserEvents: {
    networkStatus: 'network_status_notification_event',
  },

  Notification: class Notification {
    constructor(socket, channel) {
      this.socket = socket;
      this.channel = channel;
    }

    #check = (cutomSocket) => {
      if (!cutomSocket && !this.socket) {
        console.error('Socket is not defined');
        return false;
      }
      if (!this.channel) {
        console.error('Channel is not defined');
        return false;
      }
      return true;
    }

    recive = (cb) => {
      if (!this.#check()) {
        return this;
      }
      this.socket.on(this.channel, cb);

      return this;
    }

    stopRecive = (cb) => {
      if (!this.#check()) {
        return this;
      }
      this.socket.off(this.channel, cb);

      return this;
    }

    send = (socket) => (data) => {
      if (!this.#check(socket)) {
        return this;
      }

      const s = socket || this.socket;
      s.emit(this.channel, data);

      return this;
    }

    message(type, lines) {
      if (!lines || lines.length === 0) {
        console.error('No data for socket notification message');
        return this;
      }
      const dataToSend = {
       type,
       message: lines[0], 
      }
      if (lines.length > 1) {
        dataToSend['header'] = lines[0];
        dataToSend['message'] = lines[1];
      }

      return {
        direct: (socket) => {
          this.send(socket)(dataToSend)},
        broadcast: () => {
          this.send()(dataToSend)
        },
        userId: (uid) => {
          if (!uid) {
            console.error('No uid for socket notification message by userId');
          }
          if (typeof uid !== 'number') {
            console.warn('Notify-channel warning: type of uid is not number. It may have problems at reciever.')
          }
          dataToSend['userId'] = uid;
          this.send()(dataToSend);
        }
      }
      // console.error('No data for message');
      // return this;
    }

    error(...lines) {
      return this.message('error', lines);
    }

    success(...lines) {
      return this.message('success', lines);
    }

    warning(...lines) {
      return this.message('warning', lines);
    }

    info(...lines) {
      return this.message('info', lines);
    }

    custom(type, ...lines) {
      return this.message(type, lines);
    }
  },
};
