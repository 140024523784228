import generateId from '../utils/generateId'
export default  {
	//'users': [{
	//	id : 'ZdEudTGzcR3d7XuEH', //ключ как я поняла
	//	name: 'Николай Мендяев',
	//	role: 'author'
	//}],
	ui: {
		ckToolbar: false,
		isChanged: false,
		focus: {}
	},
	focusUi: {},
	user: null,
	authErr: '',
	//'unpublished': {
	//	// пушится фид по свежести
	//	'news': [{
	//		id: 1,
	//		title: 'werwer',
	//		authors: [{
	//			name: 'Николай Мендяев'
	//		}, {
	//			name: 'Ксения Львова'
	//		}],
	//		updatedAt: 1475750672084,
	//		createdAt: 1475750572084,
	//		isPublished: false
	//	}]
	//},
	listPosts:[],
	post: {},
	windowId: generateId(),
	collections: {}
	//post: {
	//	id: 1,
	//	snapshot:{
	//		id: 1,
	//		title: 'qweqwewqe',
	//		url: '/qweqwe1212/',
	//		data:{
	//			number_title: 1,
	//			source_title: 'Море',
	//			is_over: true//online, chronicles
	//		},
	//		title: 'тайтл',
	//		body: '<p>kjwlejlwer</p>',
	//		lead: 'Очень важные слова',
	//		announce: "Вышла статья - кликай",
	//		retina_image: 'https://s3.zona.media/entry/tKBc2e9tSPAubHEAn_1476772934877_810x500',
	//		image_title: "картинка с рамзаном",
	//		slug: "eng-url",
	//		custom_format: 'video',
	//		is_current: true,
	//		fullWidth: false
	//	},
	//	//показывать кнопку опубликовать -
	//	active_snapshot: 1, //id current version с чем я сейчас работаю можно изменять
	//	published_version: 1, //id опубликованная версия то какая сейчас опубликована
	//	createdAt: 1476788880075,
	//	modifiedAt:1476788881075,
	//	published: true, //должно появиться кнока опубликована кем-то в истории авторов
	//	published_date: 1476788881075,
	//	urls: [], //ссылаются на каноникл
	//	revisions: [
	//		{ //если есть то значит это ревизии
	//			id: 1,
	//			date: 1476789881075,
	//			author: 'Ксения Львова'
	//		},
	//		{
	//			id: 2,
	//			date: 1476788880072,
	//			author: 'Николай Мендяев'
	//		}
	//	],
	//	isArchieved: false,
	//	events: [
	//		{
	//			'snapshot':{
	//				post_id: 1, //тольуо в сюжете
	//				body: '',
	//				date: 1476788180075,
	//				editor_id: 10, //хз
	//				is_major: 1, //галочка главный айтем в сюжете
	//			},
	//			is_published: 1,//в админке показывается но сайте не показывается
	//			id: 10 //event id из БД приходит и только для backend
	//		}
	//	],
	//	searchRelatedTypes: [],
	//	searchRelatedThemes: [],
	//	searchRelatedContext: [],
	//	types: [
	//		{
	//			id: 1,
	//			title: 'Новости',
	//			url: ''
	//		},
	//		{
	//			id: 2,
	//			title: 'Цифра',
	//			url: ''
	//		},
	//		{
	//			id: 3,
	//			title: 'Сюжет',
	//			url: ''
	//		}
	//	],
	//	themes: [
	//		{
	//			id: 1,
	//			title: 'Дело Шакро',
	//			url: ''
	//		},
	//		{
	//			id: 2,
	//			title: 'Дойче Велле',
	//			url: ''
	//		},
	//		{
	//			id: 3,
	//			title: 'Убийство Немцова',
	//			url: ''
	//		}
	//	],
	//	context:[
	//		{
	//			id: '1',
	//			title: 'Название новости - 1',
	//			url: '/news/876867876'
	//		}
	//	]
	//}
}
