/* eslint-disable no-undef */
import * as React from 'react';
import {
  Field,
  reduxForm,
  formValueSelector,
  getFormValues,
  reset,
  initialize,
  change
} from 'redux-form';
import { connect } from 'react-redux';
import lodash from 'lodash';
import { Link } from 'react-router';
import Select from 'react-select';
import {
  SimpleRichText,
} from '/ui/organism';
import Illustration from '/features/Illustration';
import RichText from '../RichText/RichText';
import SlateRichText from '../SlateRichText/SlateRichText';
import RenderField from './RenderField';
import { generateSlug } from '../../lib/generate-slug';
import {changePostType, setVisibilityCkToolbar} from '../../actions/actions';
import { getPostTransform } from '../../actions/transformPost';
import CkeEditorToolBar from './CkeEditorToolBar';
import mz_proto from '../../utils/mz_protocol';

const ReduxSimpleRichText = ({ input }) => (
  <SimpleRichText
    value={input.value}
    onChange={input.onChange}
  />
);

@connect((state) => {
  const form = 'tranformPostTypeForm';
  const selector = formValueSelector(form);
  return {
    isLoading: state.transformPost.isLoading,
    error: state.transformPost.error,
    post: state.transformPost.value,
    form,
    formValues: getFormValues(form)(state),
    title: selector(state, 'title'),
    ui: state.ui
  };
})
@reduxForm()
class TransformerPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLeadError: false,
      isSelectError: false,
      isTitleError: false,
      isUrlError: '',
      selectedFutureType: ''
    };
  }

  componentDidMount() {
    reset(this.props.form);
    this.props.dispatch(getPostTransform(this.props.params.id));
    if (lodash.get(this.props.post, 'id')) {
      this.initializeInitialState(this.props.post.CurrentVersion);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isLoading !== this.props.isLoading && this.props.isLoading && !this.props.error) {
      reset(this.props.form);
      this.initializeInitialState(nextProps.post.CurrentVersion);
    }
    if (nextProps.lead && this.state.isLeadError) {
      this.setState(() => ({
        isLeadError: false
      }));
    }

    if (this.props.formValues && nextProps.formValues.slug.target) {
      if ((nextProps.formValues.slug.target.value !== this.props.formValues.slug) && this.state.isUrlError !== '') {
        this.setState(() => ({
          isUrlError: ''
        }));
      }
    }
  }

  onChange = (value, prevValue, _, name) => {
    const { dispatch, form } = this.props;
    dispatch(change(form, name, value));
  };

  handleChange = (selectedOption) => {
    this.setState(() => ({
      isSelectError: false,
      selectedFutureType: selectedOption,
    }));
  };

  validateUrl = async (url) => {
    if(!url) {
      this.setState(() => ({
        isUrlError: 'Добавьте URL!'
      }));
      return false;
    }

    const isUniqueUrl = await mz_proto(
      'checkUniqueUrl',
      url,
      this.state.selectedFutureType.value,
      Date.now()
    );

    if (!isUniqueUrl) {
      this.setState(() => ({
        isUrlError: `Сюжет с адресом ${url} уже существует`
      }));
    }

    return isUniqueUrl;
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const { dispatch, formValues, post } = this.props;

    const isPublished = post.published_version && new Date(post.publish_date).getTime() < Date.now();
    let isExistedRequiredParams = !!this.state.selectedFutureType;
    const validateError = {
      isSelectError: !this.state.selectedFutureType
    };

    if(isPublished) {
      const isValidUrl = await this.validateUrl(formValues.slug);
      isExistedRequiredParams = isExistedRequiredParams && isValidUrl && formValues.lead && formValues.title;
      validateError.isTitleError = !formValues.title;
      validateError.isLeadError = !formValues.lead;
    }

    if (isExistedRequiredParams) {
      dispatch(changePostType(
        this.state.selectedFutureType.value,
        this.props.post.CurrentVersion.user_id,
        this.props.params.id,
        this.props.formValues
      ));
      return;
    }

    this.setState(() => (validateError));
  };

  initializeInitialState = (post) => {
    this.props.dispatch(initialize(this.props.form, {
      ...post
    }));
  }

  generateSlug = () => {
    const slug = generateSlug(this.props.title);
    this.props.dispatch(change(this.props.form, 'slug', slug));
  }

  render() {
    const {
      form,
      formValues,
    } = this.props;

    const {
      isSelectError,
      isTitleError,
      isUrlError,
      isLeadError
    } = this.state;

    if (this.props.isLoading || !formValues) return null;

    if (this.props.error) {
      return (
        <p>{this.props.error}</p>
      );
    }

    const RichTextEditor = formValues.data && formValues.data.is_slate_rich_text ? SlateRichText : RichText;
    return (
      <div id="EditorForm" className="ui form">
        <CkeEditorToolBar ui={this.props.ui} />
        <div className="ui container basic segment">
          <form className="ui form vertically padded grid" onSubmit={this.handleSubmit}>
            <div className="ten wide column">
              <div className="field">
                <label>Тип материала</label>
                <Select
                  name="form_choice_post_type"
                  value={this.state.selectedFutureType}
                  onChange={this.handleChange}
                  options={[
                    { value: 'online', label: 'Онлайн' },
                    { value: 'article', label: 'Статья' },
                    { value: 'chronicle', label: 'Cюжет' }
                  ]}
                  clearable={false}
                />
                {isSelectError && (
                  <span className="ui negative message input_error">Выберите тип поста!</span>
                )}
              </div>
              <div className="field">
                <label>Заголовок</label>
                <Field
                  name="title"
                  type="text"
                  component={RenderField}
                  label="Заголовок"
                  onChange={this.onChange}
                />
                {isTitleError && (
                  <span className="ui negative message input_error">Добавьте заголовок!</span>
                )}
              </div>
              <div className="field">
                <label>URL</label>
                <Field
                  name="slug"
                  type="text"
                  component={RenderField}
                  label="URL"
                  onChange={this.onChange}
                />
                {isUrlError && (
                  <span className="ui negative message input_error">{this.state.isUrlError}</span>
                )}
                <span className="ui button btn_autogenerateurl" onClick={this.generateSlug}>
                  Автоматически сгенерировать
							  </span>
              </div>
              <div className="field">
                <label>Анонс</label>
                <Field
                  name="announce"
                  type="text"
                  component={RenderField}
                  label="Анонс"
                  onChange={this.onChange}
                />
              </div>
              <div className="ckeditor field">
                <label>Лид</label>
                <Field
                  className="ui bottom attached segment"
                  name="lead"
                  type="text"
                  component={RichTextEditor}
                  height="200"
                  key="lead"
                  onChange={this.onChange}
                />
                {isLeadError && (
                  <span className="ui negative message input_error">Добавьте лид!</span>
                )}
              </div>
              <div className="ckeditor field">
                <label>Текст</label>
                <Field
                  className="ui bottom attached segment"
                  name="body"
                  type="text"
                  component={RichTextEditor}
                  height="200"
                  key="body"
                  onChange={this.onChange}
                />
              </div>
              <button to={`/post/${this.props.params.id}`} className="ui button blue" type="submit">
                Изменить
              </button>
              <Link to={`/post/${this.props.params.id}`} className="ui button red">
                Отмена
              </Link>
            </div>
            <div className="six wide column">
              <Illustration
                form={form}
                richTextComponent={ReduxSimpleRichText}
              />
            </div>
          </form>
        </div>
      </div>
    ) 
  }
}

export default TransformerPost;
