const isHotKey = (e, type) => {
  const meta = e.ctrlKey || e.metaKey;
  const hotKeys = {
    bold: meta && e.keyCode === 66,
    underline: meta && e.keyCode === 85,
    italic: meta && e.keyCode === 73,
    undo: meta && e.keyCode === 90,
    redo: meta && (e.shiftKey && e.keyCode === 90),
    'soft-enter': e.shiftKey && e.keyCode === 13,
    enter: e.keyCode === 13 && !e.shiftKey,
    backspace: e.keyCode === 8,
    link: (e.metaKey && e.keyCode === 75) || (e.ctrlKey && e.keyCode === 76),
    hint: meta && e.keyCode === 72,
    pullquote: meta && e.keyCode === 74,
    typography: meta && e.keyCode === 89,
    media: meta && e.keyCode === 77,
    photo: meta && e.keyCode === 80,
    headingTwo: meta && e.keyCode === 79,
    delete: e.keyCode === 46,
  };

  return !!hotKeys[type];
};

export default isHotKey;
