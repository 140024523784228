import React from 'react';
import ReactTooltip from 'react-tooltip';
import detectOS from '/utils/detectOS';
import constants from '/utils/constants';

const ToolTip = ({ id, tooltip }) => {
  let toolTipText = '';

  if (!tooltip) return null;

  switch (detectOS()) {
    case constants.os.WIN:
      toolTipText = tooltip[constants.os.WIN];
      break;
    case constants.os.MAC:
      toolTipText = tooltip[constants.os.MAC];
      break;
    default:
      break;
  }

  return (
    <ReactTooltip
      place="bottom"
      delayShow={500}
      id={id}
    >
      {toolTipText || ''}
    </ReactTooltip>
  );
};

export default ToolTip;
