// @ts-nocheck
import React, { useEffect } from 'react';
import { uniqueId } from 'lodash';
import useNotifications from './useNotifications';
import NotificationMessageBox from './NotificationMessageBox';

const CONTAINER_ALIGN = 'left'; // or right
const NOTIFICATION_ID_PREFIX = 'notify_';

const NotificationsContainer = ({ children }) => (
  <div
    style={{
      position: 'fixed',
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'flex-start',
      flexWrap: 'nowrap',
      zIndex: 1000,
      width: 'auto',
      height: 'auto',
      maxWidth: '30vw',
      maxHeight: '600px',
      [CONTAINER_ALIGN]: 0,
      bottom: 0,
      padding: '10px',
    }}
  >
    {children}
  </div>
);

const Notifications = ({
  limit,
  socketEvents,
  browserEvents,
  userId,
}) => {
  /*
    Регистрация хука сообщений:
    Отдаём ему списки серверных и браузерных событий и пользователя;
    В ответ получаем длину очереди сообщений, доступных для извлечения,
    и объект с функциями над очередью сообщений.
  */
  const [
    notificationsQueueLength,
    notificationsQueueOperations,
  ] = useNotifications({ socketEvents, browserEvents, userId });

  /*
    Присваиваем более короткие имена ф-циям над очередью сообщений.
  */
  const {
    pop: notificationsQueuePop,
    prune: notificationsQueuePrune,
    swap: notificationsQueueSwap,
  } = notificationsQueueOperations;

  /*
    Тут сообщения, которые показываем пользователю
  */
  const [
    notificationsOnScreen,
    setNotificationsOnScreen,
  ] = React.useState([]);

  /*
    Состояние, чтобы следить за самым последним сообщением,
    и оно не потерялось при переполнении очереди сообщений
  */
  const [
    latestNotification,
    setLatestNotification,
  ] = React.useState(null);

  /*
    Этот хук реагирует на изменение длины очереди уведомлений
    и добавляет новое уведомление в список уведомлений на экране
  */
  useEffect(() => {
    try {
      let incomingNotification = {};
      if (notificationsOnScreen.length < limit) {
        incomingNotification = notificationsQueuePop();
        if (incomingNotification) {
          incomingNotification.id = uniqueId(NOTIFICATION_ID_PREFIX);
          setNotificationsOnScreen((previousNotifications) => [
            ...previousNotifications,
            incomingNotification,
          ]);
        }
      } else {
        const natificationToSwap = notificationsOnScreen.at(0);
        incomingNotification = notificationsQueueSwap(natificationToSwap);
        if (incomingNotification) {
          incomingNotification.id = uniqueId(NOTIFICATION_ID_PREFIX);
          setNotificationsOnScreen((previousNotifications) => [
            ...previousNotifications.slice(1),
            incomingNotification,
          ]);
        }
      }
    } catch (e) {
      console.error(
        'Cannot add notification from queue',
        e.message,
      );
      setNotificationsOnScreen([]);
    }
  }, [notificationsQueueLength]);

  /*
    Этот хук реагирует на изменение длины
    демонстрируемых сообщений на экране.
    Он нужен нам, чтобы отслеживать состояние,
    когда пользователь удалил всплывающее сообщение,
    но в хранилище сообщений ещё остались сообщения,
    которые необходимо показать    
  */
  useEffect(() => {
    try {
      if (
        notificationsQueueLength > 0
        && notificationsOnScreen.length < limit
      ) {
        const incomingNotification = notificationsQueuePop();
        if (incomingNotification) {
          incomingNotification.id = uniqueId(NOTIFICATION_ID_PREFIX);
          setNotificationsOnScreen((previousNotifications) => [
            ...previousNotifications,
            incomingNotification,
          ]);
        }
      }
    } catch (e) {
      console.error(
        'Cannot add notification from queue',
        e.message,
      );
      setNotificationsOnScreen([]);
    }
  }, [notificationsOnScreen]);

  /*
    Эта ф-ция удаляет конкретное уведомление
    по его uId из списка уведомлений на экране
  */
  const removeNotifiactionFromScreen = (idToRemove) => {
    try {
      setNotificationsOnScreen((prev) => prev
        .filter(({ id }) => id !== idToRemove));
      if (latestNotification && latestNotification.id === idToRemove) {
        setLatestNotification(null);
      }
    } catch (e) {
      console.error(
        'Cannot remove notification',
        idToRemove,
        e.message,
      );
      setLatestNotification(null);
      setNotificationsOnScreen([]);
    }
  };

  /*
    Эта функция удаляет все уведомления
    из списка уведомлений на экране
    и вызывает очистку очереди уведомлений
    в стейте хука useNotifications
  */
  const clearAllNotifications = () => {
    try {
      setLatestNotification(null);
      setNotificationsOnScreen([]);
      notificationsQueuePrune();
    } catch (e) {
      console.error(
        'Cannot prune notifications',
        e.message,
      );
      setLatestNotification(null);
      setNotificationsOnScreen([]);
    }
  };

  try {
    if (notificationsOnScreen.length <= 0 && !latestNotification) {
      return null;
    }

    return (
      <NotificationsContainer>
        {[
          ...notificationsOnScreen,
          latestNotification,
        ].map((notificationToShow) => {
          if (notificationToShow) {
            const {
              type,
              header,
              message,
              id,
            } = notificationToShow;
            return (
              <NotificationMessageBox
                key={id}
                id={id}
                onRemove={removeNotifiactionFromScreen}
                type={type}
                header={header}
                message={message}
              />
            );
          }
          return null;
        })}

        { notificationsQueueLength > 0 && (
          <p
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignContent: 'space-between',
              justifyContent: 'space-between',
            }}
          >
            <span>
              {`+ ${notificationsQueueLength} ещё`}
            </span>
            {/* eslint-disable */}
            <span
              style={{
                cursor: 'pointer',
                textDecoration: 'underline',
              }}
              onClick={clearAllNotifications}
            >
              закрыть все
            </span>
            {/* eslint-enable */}
          </p>
        )}
      </NotificationsContainer>
    );
  } catch (e) {
    console.error('Cannot render notifications', e.message);
    return null;
  }
};

export default Notifications;
