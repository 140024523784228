const filterCampaigns = (a, b) => {
    if (a.is_published > b.is_published)
      return -1
    if (a.is_published < b.is_published)
      return 1
    if (a.createdAt > b.createdAt) 
      return -1
    if (a.createdAt < b.createdAt) 
      return 1
    return 0;
}

export default filterCampaigns