import React, { Component } from 'react';
import Editor from '../../../../Editor';
import {
  MarksToolModule,
  LinksToolModule,
  HeadingToolModule,
  ClearFormatToolModule,
  PullQuoteToolModule,
  PhotoToolModule,
  EmbedToolModule,
  TypographusToolModule,
  HintToolModule,
  BulletsToolModule,
} from '../index';

import {
  TopToolBarContainer,
  TopToolBarModulesGroup,
} from '../../atoms';

const defaultOptions = {
  marks: true,
  bullets: true,
  links: true,
  clearFormat: true,
  photo: true,
  embed: true,
  typographus: true,
  hint: true,
  pullQuote: true,
  headingList: [
    Editor.NODE.PARAGRAPH,
    Editor.NODE.HEADING_TWO,
    Editor.NODE.HEADING_THREE,
    Editor.NODE.HIGHLIGHT,
  ],
};

export default (props) => {
  const { editor, options } = props;
  console.log('toolbar options', props);
  const opt = {
    ...defaultOptions,
    ...options,
  };

  return (
    <TopToolBarContainer>
      <TopToolBarModulesGroup>
        <MarksToolModule
          editor={editor}
          enabled={opt.marks}
        />
        <BulletsToolModule
          editor={editor}
          enabled={opt.bullets}
        />
        <LinksToolModule
          editor={editor}
          enabled={opt.links}
        />

        <ClearFormatToolModule
          editor={editor}
          enabled={opt.clearFormat}
        />
      </TopToolBarModulesGroup>
      <TopToolBarModulesGroup>
        <PhotoToolModule
          editor={editor}
          enabled={opt.photo}
        />
        <EmbedToolModule
          editor={editor}
          enabled={opt.embed}
        />
      </TopToolBarModulesGroup>
      <TopToolBarModulesGroup>
        <TypographusToolModule
          editor={editor}
          enabled={opt.typographus}
        />
      </TopToolBarModulesGroup>
      <TopToolBarModulesGroup>
        <HeadingToolModule
          editor={editor}
          allowList={opt.headingList}
        />
      </TopToolBarModulesGroup>
      <TopToolBarModulesGroup>
        <HintToolModule
          editor={editor}
          enabled={opt.hint}
        />
        <PullQuoteToolModule
          editor={editor}
          enabled={opt.pullQuote}
        />
      </TopToolBarModulesGroup>
    </TopToolBarContainer>
  );
};
