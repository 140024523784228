import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import Reset, { ResetDateFilter, FilteredText } from './styles';

export const ResetFilter = ({ label, resetLink }) => {
  if (!label) return null;

  return (<Reset>
    <Link to={resetLink}>
      <ResetDateFilter className="tiny grey close icon" />
    </Link>
    <FilteredText>
      { label }
    </FilteredText>
  </Reset>);
};

ResetFilter.propTypes = {
  label: PropTypes.string,
  resetLink: PropTypes.string,
};

export default ResetFilter;
