const defaultList = {
    list: [],
    status: 'loading'//available status
};

import {FETCH_SOCIAL_PICTURE_LIST_SUCCESS, FETCH_SOCIAL_PICTURE_LIST_FAIL} from '../actions/actions';

export default function (state = defaultList, action) {
    switch(action.type){
        case FETCH_SOCIAL_PICTURE_LIST_SUCCESS:
            return {status: 'loaded', list: action.list};
        case FETCH_SOCIAL_PICTURE_LIST_FAIL:
            return {...state, status: 'fail'};
        default:
            return state;
    }
}