import React from 'react';
import styled from 'react-emotion';
import Icon from '../../ui/atoms/Icon';
import Button from '../../ui/molecules/Button';

const Container = styled('div')`
  display: grid;
  gap: 15px;
  margin: 40px 0;
`;

const InputContainer = styled('div')`
  display: grid; 
  grid-template-columns: 5fr 1fr; 
  grid-template-rows: 1fr 1fr 1fr; 
  gap: 5px 5px; 
  grid-template-areas: 
    "field-1 field-3"
    "field-2 field-3"
    "return return";
  margin-top: 15px;
`;

const Input = styled('input')`
  height: 20px;
  width: 100%;
  border: 1px solid #E9EAEC;
  border-radius: 2px;
  padding-left: 14px;
  font-family: 'Graphik LC',sans-serif !important;
  font-size: 12px;
  color: #293043;
  
  &:focus {
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
  
  &:disabled {
    background: #E9EAEC;
    color: rgba(41, 48, 67, 0.5);
  }
  
  &::placeholder {
   color: rgba(41, 48, 67, 0.5);
  }
`;

const Name = styled(Input)`
  grid-area: field-1;
`;

const Link = styled(Input)`
  grid-area: field-2;
`;

const DeleteField = styled('div')`
  grid-area: field-3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconButton = styled(Icon)`
  cursor: pointer;

  svg:hover {
    fill: 'red';
  }
`;

const Return = styled('div')`
  grid-area: return;
`;

const AddButton = styled(Button)`
  background-color: transperent;
`;

const Delete = ({ onClick }) => (<DeleteField><IconButton width="24px" height="24px" type="trashAlt" onClick={onClick} /></DeleteField>);

export {
  Container,
  InputContainer,
  Name,
  Link,
  Delete,
  Input,
  AddButton,
  Return,
};
