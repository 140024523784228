import * as actions from '../actions/notes';
const initialState = {
  normalize: {},
  searchQuery: ''
};

const note = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_NOTES_SUCCESS:
      const searchKey = action.searchQuery;
      const currentSearchKey = state.searchQuery;
      if (searchKey !== currentSearchKey)
        return state;
      return {
        ...state,
        normalize: {
          ...state.normalize,
          ...action.payload.reduce((acc, noteItem) => {
            return { ...acc, [noteItem.id]: noteItem };
          }, {}),
        }
      };
    case actions.CHANGE_SEARCH_KEY:
      return {
        ...state,
        searchQuery: action.payload,
        normalize: {}
      };
    default: return state;
  }
};

export default note;
