import syncInfo from '../../common/sync-channel';

function changePropInSnapshot(state, key, val){
	var obj = {
		'snapshot': {
			...state.snapshot
		}
	};
	obj.snapshot[key] = val;
	return obj;
}

import {changeFieldForm} from '../actions/actions'

function post(state = {
	is_valid_slug: true,
	is_exists_slug: false,
}, action) {
	switch(action.type) {
		case "GET_POST":
			return {
				...state,
				...action.post
			}
		case "UPDATE_SNAPSHOT":
			return {
				...state,
				...action.post
			}
		case "DELETE_POST":
			return {
				...state,
				'isArchieved': true
			}
		case "RESTORE_POST":
			return {
				...state,
				'isArchieved': false
			}
		case "VALIDATION_POST_FORM":
			return {
				...state,
				errors: action.errors
			}
		case "GET_PUBLISHED_VERSION_SUCCESS":
			return {
				...state,
				published_post: action.post
			}
		case "CHECK_SLUG_RESULT":
			return {
				...state,
				is_valid_slug: action.is_valid_slug == true,
				is_exists_slug: action.is_exists_slug,
			}
		case "POST_RESET":
			return {
				...state,
				is_valid_slug: true,
				is_exists_slug: false,
			};
		case "@@redux-form/CHANGE":
			if (action.meta.field == "slug" && action.meta.form.indexOf('-post-')>=0){
                return {
	                ...state,
                	is_valid_slug: true,
									is_exists_slug: false,
                }
			}
			return state;
		default:
			return state;
	}
}

export default post
