import React, { Component } from 'react';
import {
  Input,
} from '/ui/atoms';
import { SingleFileUploader } from '/features/FileUploader';
import ProcessUploadImage from '/utils/processUploadImage';
import {
  InputError,
  InputTitle,
  Loading,
  ModalCancel,
  ModalController,
  ModalLoadingSubmit,
  ModalSubmit,
} from '../../../../atoms';

class QuoteForm extends Component {
  constructor(props) {
    super(props);
    const { node } = props;
    this.processUploadImage = new ProcessUploadImage();

    const dataJSON = node?.data?.toJSON() || {};

    this.state = {
      imgSrc: (this.props.initialState && this.props.initialState.imgSrc) || dataJSON.imgSrc || '',
      imgError: dataJSON.imgError || '',
      alt: (this.props.initialState && this.props.initialState.alt) || dataJSON.alt || '',
      source: (this.props.initialState && this.props.initialState.source) || dataJSON.source || '',
      isLoading: false,
      type: this.props.type,
    };
  }

  onUploadImage = (value, status) => {
    this.processUploadImage.changeStatus(value, status);

    this.setState(() => ({
      imgSrc: this.processUploadImage.src,
      imgError: this.processUploadImage.error,
      isLoading: this.processUploadImage.isLoading,
    }));
  };

  onChange = (fieldName) => (event) => {
    event.persist();
    event.preventDefault();
    this.setState(() => ({
      [fieldName]: event.target.value,
    }));
  };

  onSubmit = (event) => {
    event.persist();
    event.preventDefault();

    const {
      imgError,
      imgSrc,
      alt,
      source,
      type,
    } = this.state;

    if (imgError) return;

    this.props.onSubmit({
      imgSrc, alt, source, type,
    });
    this.props.onClose();
  };

  render() {
    const {
      imgSrc,
      alt,
      imgError,
      isLoading,
    } = this.state;

    const {
      onClose,
      submitText,
    } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <InputTitle>
          Выберите файл для изображения, если оно нужно
        </InputTitle>
        <SingleFileUploader
          file={imgSrc}
          handler={this.onUploadImage}
          accepted="image/*"
        />
        {imgError ? (<InputError>{imgError}</InputError>) : null}
        {imgSrc ? (
          <div>
            <InputTitle>
              Альтернативный текст для картинки
            </InputTitle>
            <Input
              type="text"
              value={alt}
              onChange={this.onChange('alt')}
            />
          </div>
        ) : null}
        <ModalController>
          <ModalCancel onClick={onClose}>Отменить</ModalCancel>
          {isLoading ? (
            <ModalLoadingSubmit>
              <Loading />
            </ModalLoadingSubmit>
          ) : (
            <ModalSubmit
              type="submit"
              value={submitText}
            />
          )}
        </ModalController>
      </form>
    );
  }
}

export default QuoteForm;
