import React from 'react';
import constants from '/utils/constants';

export const objectTypes = {
  BLOCK: 'block',
  INLINE: 'inline',
  TEXT: 'text',
};

export const markTypes = {
  BOLD: 'bold',
  ITALIC: 'italic',
  UNDERLINE: 'underline',
  SUP: 'sup',
};

export const annotationTypes = {
  SELECTED_RANGE: 'selected_range',
};

export const nodeTypes = {
  PARAGRAPH: 'p',
  HEADING_ONE: 'h1',
  HEADING_TWO: 'h2',
  HEADING_THREE: 'h3',
  HIGHLIGHT: 'highlight',
  SUMMARY: 'summary',

  LINK: 'a',

  EMBED: 'embed',
  YOUTUBE: 'youtube',
  EMBED_CAPTION: 'embed-caption',
  EMBED_CONTAINER: 'embed-container',

  HINT: 'hint',
  BULLET: 'list-item',
  BULLETS: 'bulleted-list',

  PHOTO: 'photo',
  PHOTO_CAPTION: 'photo-caption',
  PHOTO_CONTAINER: 'photo-container',

  DOCUMENT_PULL_QUOTE: 'document-pull-quote',
  SIMPLE_PULL_QUOTE: 'simple-pull-quote',
  CONTEXT_PULL_QUOTE: 'context-pull-quote',
  MULTICONTEXT_PULL_QUOTE: 'multicontext-pull-quote',
  TEXT_PULL_QUOTE: 'text-pull-quote',
  TEXT_BODY: 'text-pull-quote-body',
  TEXT_TITLE: 'text-pull-quote-title',
  QUOTE: 'quote',
  QUOTE_SOURCE: 'quote-source',
  QUOTE_DESCRIPTION: 'quote-description',
  DONATE: 'donate',
  NEWSLETTER: 'newsletter',
  CUT_PULL_QUOTE: 'cut-pull-quote',
};

export const embedTypes = {
  DEFAULT: 'embed',
  TWITTER: 'twitter',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  YOUTUBE: 'youtube',
  CODE: 'code',
  LINK: 'link',
};

export const editorIds = {
  PULLQUOTE: 'pullquote_top_toolbar',
  EMBED: 'embed_modal_top_toolbar',
  PHOTO: 'photo_modal_top_toolbar',
  TYPOGRAPHY: 'typography_top_toolbar',
};

export const tooltips = {
  TYPOGRAPHY: {
    [constants.os.MAC]: <p>⌘ cmd + y</p>,
    [constants.os.WIN]: <p>⌃ ctrl + y</p>,
  },
  EMBED: {
    [constants.os.MAC]: <p>⌘ cmd + m</p>,
    [constants.os.WIN]: <p>⌃ ctrl + m</p>,
  },
  PHOTO: {
    [constants.os.MAC]: <p>⌘ cmd + p</p>,
    [constants.os.WIN]: <p>⌃ ctrl + p</p>,
  },
  PULLQUOTE: {
    [constants.os.MAC]: <p>⌘ cmd + j</p>,
    [constants.os.WIN]: <p>⌃ ctrl + j</p>,
  },
  HINT: {
    [constants.os.MAC]: <p>⌘ cmd + h</p>,
    [constants.os.WIN]: <p>⌃ ctrl + h</p>,
  },
  LINK: {
    [constants.os.MAC]: <p>⌘ cmd + k</p>,
    [constants.os.WIN]: <p>⌃ ctrl + l</p>,
  },
  BOLD: {
    [constants.os.MAC]: <p>⌘ cmd + b</p>,
    [constants.os.WIN]: <p>⌃ ctrl + b</p>,
  },
  ITALIC: {
    [constants.os.MAC]: <p>⌘ cmd + i</p>,
    [constants.os.WIN]: <p>⌃ ctrl + i</p>,
  },
  HEADING: {
    [constants.os.MAC]: <p>⌘ cmd + o</p>,
    [constants.os.WIN]: <p>⌃ ctrl + o</p>,
  },
  NOT_AVALIABLE: {
    [constants.os.MAC]: <p>Недоступно</p>,
    [constants.os.WIN]: <p>Недоступно</p>,
  },
};
