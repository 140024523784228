import React, { Component, PropTypes} from 'react';
import { connect } from 'react-redux';
import { setVisibilityCkToolbar } from '../../actions/actions.js';
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import ruLocale from 'date-fns/locale/ru'
import { Field, FieldArray, reduxForm, change, formValueSelector } from 'redux-form';
import DateTimePicker from '../DateTimePicker';
import Urls from './Urls';
import { getTimezone } from '../../utils/getTimezone';

//const FieldInput = ({ input, data, valueField, textField }) =>
//	<input {...input}
//		value={input.value}
//		onChange={textField}
//	/>

// @connect(
// 	(state, props) => {
// 		debugger;
// 		return {
// 			publish_date: selector(state, 'publish_date'),
// 			created_post_date: selector(state, 'createdAt'),
// 			updated_post_date: selector(state, 'updatedAt'),
// 			urls: selector(state, 'Urls') || []
// 		}
// 	},
// 	(dispatch) => ({

// 	})
// )

export default class DatesPost extends Component{
	constructor(props){
		super(props);
	}

	publishTmpl(publish_date){
		let curTime = new Date();
		let publishTime = new Date(this.props.publish_date);
		if (this.props.published_version)
		{
			return (<tr>
				<td>{( +publishTime > +curTime ? 'Будет опубликовано': 'Опубликовано')}</td>
				<td>{new Date(publish_date).toLocaleString(ruLocale, { timeZone: getTimezone() })}</td>
				<td className="metaEditor">{this.props.publisher}</td>
			</tr>);
		}
		else
			return null;
	}

	viewCountTmpl(viewCount){
		if (this.props.published_version && viewCount) {
			return (<tr>
				<td>Просмотры</td>
				<td className="metaEditor">{viewCount}</td>
				<td></td>
			</tr>)
		}
		return null;
	}


	actionTmpl(date, txt, author_name){
		let tmpl = null;
		if (date){
			tmpl = (
				<tr>
					<td>{txt}</td>
					<td>{new Date(date).toLocaleString(ruLocale,  { timeZone: getTimezone() })}</td>
					<td className="metaEditor"> {author_name}</td>
				</tr>
			);
		}
		return tmpl;
	}

	render() {
		const {
			disabled,
		} = this.props;
		const { publish_date, createdAt, updatedAt, urls, dispatch, viewCount } = this.props;
		return (<div ref={e => this.htmlEl = e}>
			<table className="metaInfo ui small basic table">
			    <tbody>
			        {this.publishTmpl(publish_date)}
					{this.viewCountTmpl(viewCount.toLocaleString())}
			        <Urls
						post_id={this.props.post_id}
						disabled={disabled}
					/>
			        {this.actionTmpl(updatedAt, 'Изменено', this.props.modifier)}
			        {this.actionTmpl(createdAt, 'Создано', this.props.creator)}
					<tr>
						<td colSpan="2">
							<Field
								name="publish_date"
								component={DateTimePicker}
								eventId={this.props.post_id}
								disabled={disabled}
							/>
						</td>
						<td></td>
					</tr>
			    </tbody>
			</table>
			
		</div>);
	}
}
