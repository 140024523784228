/* eslint-disable no-param-reassign */
const splitArrBy = (array = [], format) => array
  .reduce((resultObj, currentElem) => {
    const formattedIdentifier = format(currentElem);
    if (!resultObj[formattedIdentifier]) resultObj[formattedIdentifier] = [];
    resultObj[formattedIdentifier].push(currentElem);
    return resultObj;
  },
  {},
  );

export default (postsArray, groupByFunc) => splitArrBy(
  postsArray,
  groupByFunc,
);
