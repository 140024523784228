import {
  nodesToMap,
} from './helpers';
import {
  ParagraphNode,
  HeadingOneNode,
  HeadingTwoNode,
  HeadingThreeNode,
  HighLightNode,
  SummaryNode,
} from './features/Typography';
import {
  EmbedNode,
  YouTubeNode,
  EmbedCaptionNode,
  EmbedContainerNode,
} from './features/Embed';
import {
  PhotoNode,
  PhotoCaptionNode,
  PhotoContainerNode,
} from './features/Photo';
import {
  DocsPullQuoteNode,
  PullQuoteNode,
  MultiContextPullQuoteNode,
  ContextPullQuoteNode,
  TextPullQuoteNode,
  TextPullQuoteBodyNode,
  QuoteNode,
  QuoteSourceNode,
  QuoteDescriptionNode,
  DonateNode,
  NewsletterNode,
  CutPullQuoteNode,
} from './features/PullQuote';
import {
  HintNode,
} from './features/Hint';
import {
  LinkNode,
} from './features/Link';
import {
  BulletsNode,
  BulletNode,
} from './features/Bullets';
class NodeFactory {
  constructor(editor) {
    this.editor = editor;
  }

  all = [
    ParagraphNode,
    HeadingOneNode,
    HeadingTwoNode,
    HeadingThreeNode,
    HighLightNode,
    SummaryNode,
    EmbedNode,
    YouTubeNode,
    EmbedCaptionNode,
    EmbedContainerNode,
    PhotoNode,
    PhotoCaptionNode,
    PhotoContainerNode,
    PullQuoteNode,
    DocsPullQuoteNode,
    ContextPullQuoteNode,
    MultiContextPullQuoteNode,
    TextPullQuoteNode,
    TextPullQuoteBodyNode,
    QuoteNode,
    QuoteSourceNode,
    QuoteDescriptionNode,
    LinkNode,
    HintNode,
    BulletNode,
    BulletsNode,
    DonateNode,
    NewsletterNode,
    CutPullQuoteNode,
  ];

  get map() {
    return nodesToMap(this.all);
  }

  Paragraph(attrs) {
    return ParagraphNode.create(attrs);
  }

  HeadingOne(attrs) {
    return HeadingOneNode.create(attrs);
  }

  HeadingTwo(attrs) {
    return HeadingTwoNode.create(attrs);
  }

  HeadingThree(attrs) {
    return HeadingThreeNode.create(attrs);
  }

  Highlight(attrs) {
    return HighLightNode.create(attrs);
  }

  Embed(attrs) {
    return EmbedNode.create(attrs);
  }

  Youtube(attrs) {
    return YouTubeNode.create(attrs);
  }

  EmbedCaption() {
    return EmbedCaptionNode.create({ editor: this.editor });
  }

  EmbedContainer(attrs) {
    return EmbedContainerNode.create(attrs);
  }

  Photo(attrs) {
    return PhotoNode.create(attrs);
  }

  PhotoCaption() {
    return PhotoCaptionNode.create({ editor: this.editor });
  }

  PhotoContainer(attrs) {
    return PhotoContainerNode.create(attrs);
  }

  PullQuote(attrs) {
    return PullQuoteNode.create({ editor: this.editor, ...attrs });
  }

  DocsPullQuote(attrs) {
    return DocsPullQuoteNode.create({ editor: this.editor, ...attrs });
  }

  ContextPullQuote(attrs) {
    return ContextPullQuoteNode.create({ editor: this.editor, ...attrs });
  }

  MultiContextPullQuote(attrs) {
    return MultiContextPullQuoteNode.create({ editor: this.editor, ...attrs });
  }

  TextPullQuoteBodyNode(attrs) {
    return TextPullQuoteBodyNode.create({ editor: this.editor, ...attrs });
  }

  TextPullQuote(attrs) {
    const nodes = [
      this.editor.node.TextPullQuoteBodyNode(attrs),
    ];

    return TextPullQuoteNode.create({ editor: this.editor, nodes, ...attrs });
  }

  QuoteSource(attrs) {
    return QuoteSourceNode.create({ editor: this.editor, ...attrs });
  }

  QuoteDescription(attrs) {
    return QuoteDescriptionNode.create({ editor: this.editor, ...attrs });
  }

  Quote(attrs) {
    const nodes = [
      this.editor.node.QuoteSource(),
      this.editor.node.QuoteDescription(),
    ];

    return QuoteNode.create({ editor: this.editor, nodes, ...attrs });
  }

  CutPullQuote(attrs) {
    return CutPullQuoteNode.create({ editor: this.editor, ...attrs });
  }

  Donate(attrs) {
    return DonateNode.create({ editor: this.editor, ...attrs });
  }

  Newsletter(attrs) {
    return NewsletterNode.create({ editor: this.editor, ...attrs });
  }

  Hint(attrs) {
    return HintNode.create(attrs);
  }

  Link(attrs) {
    return LinkNode.create(attrs);
  }

  Bullet(attrs) {
    return BulletNode.create(attrs);
  }

  Bullets(attrs) {
    return BulletsNode.create({ attrs });
  }
}

export default NodeFactory;
