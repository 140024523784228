import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './modal.styl';

export default class Modal extends Component {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired
  };

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown, false);
  }

  hideModalOverlay = (e) => {
    if (e.target === e.currentTarget) {
      this.props.handleClose(e);
    }
  };

  handleKeyDown = (e) => {
    if (e.keyCode === 27) {
      this.props.handleClose(e);
    }
  };

  render() {
    const { isOpen } = this.props;

    return (
      <div
        onMouseDown={this.hideModalOverlay}
        className={`react_modal__overlay ${isOpen ? 'react_modal__overlay_opened' : ''}`}
      >
        <dialog open={isOpen} className="react_modal__container">
          {this.props.children}
        </dialog>
      </div>
    );
  }
}
