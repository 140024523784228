import React from 'react';

const Hint = ({ node, attributes, children }) => {
  const body = node.data.get('body');

  if (!body) return children;

  return (
    <span
      className="mz-publish-hint"
      data-body={body}
      {...attributes}
    >
      {children}
    </span>
  );
};

export default Hint;
