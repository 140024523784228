import React from 'react';
import {
  Embed
} from './atoms';
import {
  EmbedWrapper
} from './molecules';
import {
  nodeTypes,
  objectTypes
} from '../../consts';

const EmbedNode = {
  type: nodeTypes.EMBED,
  object: objectTypes.BLOCK,

  create({ html, url }) {
    return {
      type: this.type,
      object: this.object,
      data: {
        html,
        url
      },
    };
  },

  schema: {
    isVoid: true,
    parent: {
      type: nodeTypes.EMBED_CONTAINER
    }
  },

  htmlRender(attrs) {
    return (
      <Embed
        {...attrs}
      />
    );
  },

  editorRender(attrs) {
    const { node } = attrs;

    return (
      <EmbedWrapper
        node={node}
      >
        <Embed
          {...attrs}
        />
      </EmbedWrapper>
    );
  }
};

export default EmbedNode;
