import {
  utcToZonedTime,
  formatInTimeZone,
  toDate,
} from 'date-fns-tz';
import { regionData } from '/const/config';

const { timezone: timeZone } = regionData;

// смещение времени в часах относительно UTC
// времення зона определяется в конфиге для каждого сайта
export const timezoneDate = (date) => {
  try {
    const zonedDate = utcToZonedTime(date, timeZone);
    return zonedDate;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

// Преобразование ISO в формат, который используется в html input type="datetime-local"
// это совой специфичный формат, который необходимо преобразовать из ISO
// в формат 1970-01-01T00:00 где литреа T это разделитель даты и времени
export const convertToDateTimeLocal = (date) => {
  try {
    const zonedDate = formatInTimeZone(
      date,
      timeZone,
      "yyyy-MM-dd'T'HH:mm",
    );
    return zonedDate;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

// dateTimeString используется в html input type="datetime-local"
// это совой специфичный формат, который необходимо преобразовать в ISO
// для записи в базу данных
export const dateTimeLocalToISO = (dateTimeLocalString) => {
  try {
    const date = toDate(
      dateTimeLocalString,
      { timeZone },
    );
    return date;
  } catch (error) {
    console.error(error.message);
    return null;
  }
};

export const { timzoneShort } = regionData;
