// @flow
import React from 'react';
import type { Node as ReactNode } from 'react';
import {
  TextPlaceholderContainer,
  TextPlaceholderContent
} from '../../atoms';

type Props = {
  children: ReactNode,
  text: string,
  placeholder: string
};

export default (props: Props) => {
  const {
    children,
    text,
    placeholder
  } = props;

  return (
    <TextPlaceholderContainer>
      {text.length === 0 ? (
        <TextPlaceholderContent>
          {placeholder}
        </TextPlaceholderContent>
      ) : null
      }
      {children}
    </TextPlaceholderContainer>
  );
}
