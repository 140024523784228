/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, Field, change } from 'redux-form';
import syncForm from '/lib/sync-form';
import syncInfo from '/../common/sync-channel';
import { REGION } from '../../const/config';
import Label from '/ui/atoms/Label';
import ColorPicker from '/ui/molecules/ColorPicker';

const FormField = ({ label, name, component = 'input', type = 'text', list }) => (
  <div className="field features-field">
    <Label>
      {label}
    </Label>
    <Field
      name={name}
      type={type}
      component={component}
      list={list}
    />
  </div>);

const SwitcherField = ({ label, description, name, component = 'input', type = 'checkbox' }) => (
  <div className="field features-switcher-field">
    <div className="features-switcher-label">
      <Label>
        {label}
      </Label>
      <div className="features-switcher-description">{description}</div>
    </div>
    <Field
      name={name}
      type={type}
      component={component}
    />
  </div>
);

@connect(
  (state) => {
    const formName = syncInfo.formFunc('pageSettings', '1');
    const select = selector => formValueSelector(formName)(state, selector);

    return {
      region_id: select('region_id'),
      info_enabled: select('info_enabled'),
      info_color: select('info_color'),
      info_text: select('info_text'),
      info_url: select('info_url'),
      features_autoscroll: select('features_autoscroll'),
      features_random: select('features_random'),
      created_at: select('created_at'),
      updated_at: select('updated_at'),
      info_default_placement: select('info_default_placement'),

      syncedEntity: 'pageSettings',
      syncedId: '1',
    };
  },
)
@syncForm()
@reduxForm()
class FeaturesSettingsForm extends React.Component {
  toggleActive = (e) => {
    e.preventDefault();
    const { dispatch, form } = this.props;
    dispatch(change(form, 'info_enabled', !this.props.info_enabled));
  };

  disableSubmitButton = () => {
    if (this.props.info_enabled) {
      return false;
    }
    return !this.props.info_text || !this.props.info_url;
  };

  render() {
    const { formValues, form } = this.props;
    const isCa = REGION === 'ca';
    const isBy = REGION === 'by';

    if (!formValues || !formValues.id) return null;

    return (
      <form id="EditorForm" className="ui form features-settings-form">
        <div className="ui container basic segment">
          <SwitcherField
            label="Показывать фичеры в случайном порядке"
            description="При загрузке страницы первым будет отображаться случайный фичер из списка"
            name="features_random"
            type="checkbox"
            checked={this.props.features_random}
          />
          <SwitcherField
            label="Автоматическое пролистывание"
            description="Фичеры будут автоматически переключаться каждые 15 секунд"
            name="features_autoscroll"
            type="checkbox"
            checked={this.props.features_autoscroll}
          />
          <div className="info-form">
            <div className="ui small dividing header">Специальная плашка</div>
            <fieldset style={{ border: 'none', padding: 0, margin: 0 }} disabled={this.props.info_enabled}>
              <FormField
                label="Текст"
                name="info_text"
              />
              <FormField
                label="Url"
                name="info_url"
              />
              <ColorPicker
                label="Цвет"
                name="info_color"
                value={this.props.info_color}
                colorList={['white', 'black', isCa && '#3A59AB', isBy && '#296843']}
              />
              <SwitcherField
                label=" Переместить плашку вниз."
                description="⚡ Темы переместятся наверх под шапку!"
                name="info_default_placement"
                type="checkbox"
                checked={this.props.info_default_placement}
              />
            </fieldset>
            <button
              type="button"
              onClick={this.toggleActive}
              disabled={this.disableSubmitButton()}
              className="info-button ui button primary"
            >
              {this.props.info_enabled ? 'Снять с публикации' : 'Сохранить'}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default FeaturesSettingsForm;
