import { css } from 'react-emotion';

export const QuillContainerStyles = {
  height: '120px',
  backgroundColor: 'white',
  overflow: 'scroll',
  border: '1px solid rgba(34, 36, 38, 0.15)',
};

export const QuillClass = css`
  .ql-editor {
    p {
      font: 14px "Graphik LC", serif !important;
      white-space: pre-wrap;
      line-height: 1.43 !important;
      color: #151515;
      margin-bottom: 10px;

      :last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;
