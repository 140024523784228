import React from 'react';
import { SelectedRange } from './atoms';
import {
  annotationTypes,
} from './consts';

const Annotation = {
  render({ type, children, attributes }) {
    switch (type) {
      case annotationTypes.SELECTED_RANGE:
        return <SelectedRange {...attributes}>{children}</SelectedRange>
      default:
        return null;
    }
  },
};

export default Annotation;
