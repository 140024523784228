import React from 'react';
import { connect } from 'react-redux';

import Feed from '/ui/organism/Feed';

import filterCampaigns from '/utils/filterCampaigns';
import { syncCollection, collectionSelector } from '../../../lib/sync-form';
import AdvertCampaignFeedItem from './AdvertCampaignFeedItem';

import { createAdvertCampaign, createExternalCampaign } from '../index';
import AdvertSettingsForm from './AdvertSettingsForm'

@connect(
  (state) => {
    const items = collectionSelector('sync-form-advertCampaigns-all')(state);

    return {
      items,
      syncedEntity: 'advertCampaigns',
      syncedId: 'all',
    };
  },
)
@syncCollection()
class AdvertCampaignList extends React.Component {
    state = {
      showArchived: false,
    };

    handleAdvertCampaign = () => {
      this.props.dispatch(createAdvertCampaign());
    };

    handleExternalCampaign = () => {
      this.props.dispatch(createExternalCampaign());
    };

    toggleArchived = () => {
      this.setState({ showArchived: !this.state.showArchived });
    };

    render() {
      const { items } = this.props;
      return (
        <div className="ui container">
          <div className="ui vertically padded grid">
            <div className="column">
              <Feed>
                <Feed.Row>
                  <button
                    className="ui primary button"
                    style={{ marginBottom: '20px' }}
                    onClick={this.handleAdvertCampaign}
                  >
                    Новая кампания
                  </button>
                  <button
                    className="ui primary button"
                    style={{ marginBottom: '20px' }}
                    onClick={this.handleExternalCampaign}
                  >
                    Добавить внешнего партнера
                  </button>
                  <button
                    className="ui secondary button"
                    style={{ marginBottom: '20px' }}
                    onClick={this.toggleArchived}
                  >
                    {this.state.showArchived ? 'Показать активные' : 'Показать архивные'}
                  </button>
                </Feed.Row>
                <AdvertSettingsForm />
                <div className="ui dividing header">
                  Список кампаний
                </div>
                <table className="ui very basic fixed divided table">
                  <tbody>
                    {Array.isArray(items) && items
                      .filter(item => (this.state.showArchived ? item.is_archived : !item.is_archived))
                      .sort(filterCampaigns)
                      .map(item => (<AdvertCampaignFeedItem
                        id={item.id}
                        key={item.id}
                        title={item.title}
                        partner_name={item.partner_name}
                        is_archived={item.is_archived}
                        is_published={item.is_published}
                        createdAt={item.createdAt}
                        showsCount={item.shows_count}
                        showsLimit={item.shows_limit}
                        weight={item.weight}
                        is_external_advert={item.is_external_advert}
                      />))
                    }
                  </tbody>
                </table>
              </Feed>
            </div>
          </div>
        </div>
      );
    }
}

export default AdvertCampaignList;
