import styled from 'react-emotion';

export default styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: ${props => props.main ? '1px solid rgba(34, 36, 38, 0.15)' : '0'};

  &:last-child {
    border-bottom: 0;
  }
`;
