/* eslint-disable camelcase */
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';
import { Field as ReduxFormField } from 'redux-form';
import ReduxFormInput from '../ReduxFormInput';
import formatInput from '../../utils/formatInput';
import {
  Label,
} from '/ui/atoms';
import {
  Field,
} from '/ui/molecules';

const SEOContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}`;

const SeoButton = styled.button`
  display: block;
  position: absolute;
  right: 0;
  top: -30px;
  border: 0;
  background: transparent;
  text-decoration: none;
  border-bottom: 1px dotted #0f0f10;
  color: #0f0f10;
  padding: 0;
`;

const SEOBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
`;

const SeoControls = ({ isEditDisallow, seo_title, seo_description }) => {
  const [isSeoShow, setIsSeoShow] = useState(false);

  const handleSeo = () => {
    setIsSeoShow(!isSeoShow);
  };

  useEffect(() => {
    setIsSeoShow(seo_description || seo_title);
  }, []);
  return (
    <SEOContainer>
      <SeoButton onClick={() => handleSeo()}>
        {isSeoShow ? 'Скрыть ' : 'Показать '}
        настройки SEO
      </SeoButton>
      {isSeoShow && (
      <SEOBlock>
        <Field>
          <Label>SEO Заголовок</Label>
          <ReduxFormField
            name="seo_title"
            id="seo_title"
            autoComplete="off"
            debounceTime={1500}
            component={ReduxFormInput}
            format={formatInput}
            normalize={formatInput}
            type="text"
            disabled={isEditDisallow}
          />
        </Field>
        <Field>
          <Label>SEO Анонс</Label>
          <ReduxFormField
            name="seo_description"
            id="seo_description"
            autoComplete="off"
            debounceTime={1500}
            component={ReduxFormInput}
            format={formatInput}
            normalize={formatInput}
            type="text"
            disabled={isEditDisallow}
          />
        </Field>
      </SEOBlock>
      )}
    </SEOContainer>
  );
};

export default SeoControls;
