import { css } from 'react-emotion';
import config from '/const/config';

const regionConfig = {
  ru: {
    headFamily: '"Graphik LC", sans-serif',
    mainFamily: '"William Text", serif',
    boldFamily: '"Graphik LC", sans-serif',
    boldWeight: 700,
    citeFamily: '"Graphik LC", sans-serif',
    docsciteFamily: '"Fira Mono"',
    textPullQuoteFamily: '"Graphik LC", sans-serif',
    textPullQuoteBoldWeight: 500,
  },
  ca: {
    headFamily: '"Inter", sans-serif',
    mainFamily: '"Spectral", serif',
    boldFamily: '"Inter", sans-serif',
    boldWeight: 'bold',
    citeFamily: '"Inter", sans-serif',
    docsciteFamily: '"Fira Mono"',
    textPullQuoteFamily: '"Inter", sans-serif',
    textPullQuoteBoldWeight: 600,
  },
  by: {
    headFamily: '"Inter", sans-serif',
    mainFamily: '"Spectral", serif',
    boldFamily: '"Inter", sans-serif',
    boldWeight: 'bold',
    citeFamily: '"Inter", sans-serif',
    docsciteFamily: '"Fira Mono"',
    textPullQuoteFamily: '"Inter", sans-serif',
    textPullQuoteBoldWeight: 600,
  },
};

const {
  headFamily,
  mainFamily,
  boldFamily,
  boldWeight,
  citeFamily,
  docsciteFamily,
  textPullQuoteFamily,
  textPullQuoteBoldWeight,
} = regionConfig[config.REGION];

export const selectStyle = css`
  & .Select-control {
    height: 30px;
    border: 1px solid rgba(34, 36, 38, 0.15);
  }
`;

export const richEditorStyle = css`
  height: 100%;
  width: 100%;
  min-height: 300px;
  padding: 8px;
  overflow-y: scroll;
  p {
    font-size: 17px;
    line-height: 1.65;
    color: #293043;
  }
  h2, h3 {
    span {
      font-family: ${headFamily} !important;
    }
  }
  span {
    font-family: ${mainFamily} !important;
  }
  a span{
    color: #ab0000;
  }
  b span {
    font-family: ${boldFamily} !important;
    font-size: 17px;
    font-weight: ${boldWeight};
    em, strike, u, sub, sup {
      font-family: ${boldFamily} !important;
      font-size: 17px;
      font-weight: ${boldWeight};
    }
  }
  em, strike, u, sub, sup {
    font-family: ${mainFamily} !important;
  }
  
  & .mz-publish-cite {
    position: relative;
    display: block;
    padding: 0;
    margin: 0;

    &::before, &::after {
      background: none;
    }

    &__text {
      margin: 10px 0;
      p {
        font-family: ${citeFamily} !important;
        font-size: 15px;
        line-height: 1.45;
        color: #999;
      }
      span {
        font-family: ${citeFamily} !important;
        font-size: 15px;
        line-height: 1.45;
        color: #999;
      }
      b, em, strike, u, sub, sup {
        font-family: ${citeFamily} !important;
      }
      a {
        span {
          font-family: ${citeFamily} !important;
          color: #ab0000;
        }
      }
    }
    &_side {
      width: 100%;
    }
    &_side-right {
      width: 100%;
    }
  }
  & .mz-publish-cut-pullquote {
    p, span {
      font-family: ${headFamily} !important;
      font-size: 14px;
    }
  }

  & .mz-publish-cite__wrapper {
    &::before, &::after {
      content: normal;
    }
  }
  & .mz-publish-docscite {
    position: relative;
    display: block;
    margin: 0;
    padding: 0;

    &::before {
      background: none;
    }

    &::after {
      background: none;
    }

    &__wrapper {
      &::before, &::after {
        content: normal;
      }
    }

    &__text {
      margin: 10px 0;
      p {
        font-size: 13px;
        line-height: 1.54;
      }
      span, em, strike, u, sub, sup{
        font-family: ${docsciteFamily} !important;
      }
      a {
        span {
          font-family: ${docsciteFamily} !important;
          color: #ab0000;
        }
      }
    }
    &_side {
      width: 100%;
    }
    &_side-right {
      width: 100%;
    }
  }
  
  & .mz-publish-photo {
    margin: 0;
    &_left {
      float: left;
      width: 345px;
    }
    &__comment  {
      p {
        margin: 8px 0 0;
        padding: 0 10px;
        line-height: 1.3;
        font-family: ${headFamily} !important;
        font-size: 15px;
        color: #999;
      }
      span {
        line-height: 1.3;
        font-family: ${headFamily} !important;
        font-size: 15px;
        color: #999;
      }
      b, em, strike, u, sub, sup {
        font-family: ${headFamily} !important;
      }
      a {
        span {
          font-family: ${headFamily} !important;
          color: #ab0000;
        }
      }
    }
  }
  
  & .mz-publish-embed {
    margin: 30px 0 20px;
    iframe {
      width: 100%;
    }

    &__comment {
      p {
        margin: 8px 0 0;
        padding: 0 10px;
        line-height: 1.3;
        font-family: ${headFamily} !important;
        font-size: 15px;
        color: #999;
      }
      span {
        line-height: 1.3;
        font-family: ${headFamily} !important;
        font-size: 15px;
        color: #999;
      }
      b, em, strike, u, sub, sup {
        font-family: ${headFamily} !important;
      }
      a {
        span {
          font-family: ${headFamily} !important;
          color: #ab0000;
        }
      }
    }
  }
  
  & .mz-publish-quote {
    display: flex;
    box-sizing: border-box;
    float: right;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
    padding-bottom: 30px;
    border-bottom: 1px dashed #E5E5E5;

    @media (min-width: $media_width_xs) {
      margin: 0 0 0 40px;
      width: 300px;
    }

    @media (min-width: $media_width_s) {
      margin-right: -360px;
    }

    &__image {
      display: flex;
      margin-right: auto;
      width: 100px;
      height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      border-top-right-radius: 100%;
      border-top-left-radius: 100%;
      border-bottom-right-radius: 100%;
    }

    &__source {
      font: 500 20px ${headFamily};
      line-height: 24px;
      color: black;
      margin-bottom: 20px;
      margin-top: 30px;
    }

    &__description {
      p {
        font: 14px ${headFamily};
        line-height: 150%;
        color: #989898;
      }

      @media (min-width: $media_width_s) {
        p {
          font-size: 13px;
        }
      }
    }
  }
  
  & .mz-publish__text__highlight-lead {
    -webkit-font-smoothing: antialiased;
    span {
      font-family: ${headFamily} !important;
      font-size: 18px;
      line-height: 1.7;
    }
    em, b, strike, u, sub, sup {
      span {
        font-size: inherit;
        font-family: ${headFamily} !important;
      }
    }
  }

  & .mz-publish-text-pull-quote {
    p, span {
      font-family: ${textPullQuoteFamily} !important;
    }

    h3 {
      font-weight: ${textPullQuoteBoldWeight};
    }

    b {
      font-weight: 'bold';
      span {
        font-size: 14px !important;
      }
      em span {
        font-style: italic;
      }
      font-size: 14px !important;
    }
  }

  & .mz-publish-context-cite {
    margin: 0;
    padding: 20px 0;
    position: relative;
    display: flex;
    -webkit-font-smoothing: antialiased;
    
    &:hover {
      .mz-publish-context-cite__text {
        color: #ab0000;
      }
    }

    &_side, &_side-right {
      width: 100%;
      flex-direction: column;
      
      .mz-publish-context-cite__image {
        width: 100%;
        max-width: 100% !important;
        max-height: 100%;
        margin-bottom: 24px;
      }

      @media(min-width: $media_width_s) {
        width: 270px;
      }
    }
    &_side {
      @media(min-width: $media_width_s) {
        float: left;
        margin-right: 30px;
        margin-left: -130px;
      }
    }
    &_side-right {
      @media(min-width: $media_width_s) {
        float: right;
        margin-left: 30px;
        margin-right: 30px;
      }
    }

    &_two-col {
      @media(min-width: $media_width_s) {
        padding-top: 5px;
      }

      &:before {
        @media(min-width: $media_width_s) {
          display: block;
          content: '';
        }
      }

      .mz-publish-context-cite__image {
        @media(min-width: $media_width_s) {
          float: left;
          width: 214px;
        }
      }
    }

    &__image {
      display: block;
      max-width: 190px;
      min-width: 190px;
      max-height: 120px;
      margin-right: 30px;
    }

    &__title {
      display: block;
      margin-bottom: 7px;
      line-height: 1.43;
      font-size: 14px;
      font-family: ${headFamily} !important;
      color: #999999
    }

    &__text {
      display: block;
      font-family: ${headFamily} !important;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.22;
      color: #151515;

      &_meta {
        font-family: ${headFamily} !important;
        font-size: 14px;
        font-weight: normal;
        color: #999;
        margin-bottom: 10px;
      }
    }
  }

  summary {
    display: block;
  }
  ::-webkit-details-marker {
    display: none;
  }
}
`;
