import {
  nodeTypes,
} from '../consts';

const excludeSummaryNode = (children) => {
  const isArray = Array.isArray(children);

  let renderChildren;
  if (isArray) {
    renderChildren = children.filter((child) => child.props.node.type !== nodeTypes.SUMMARY);
  } else {
    renderChildren = children.toArray().filter((child) => child.type !== nodeTypes.SUMMARY);
  }

  return renderChildren;
};

export default excludeSummaryNode;
