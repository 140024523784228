import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  border-radius: ${({ borderRadius }) => borderRadius || 0};
  outline: none;
  background-color: ${({ backgroundColor }) => backgroundColor || '#00C656'};
  color: ${({ color }) => color || 'white'};
  width: ${({ width }) => width || '100%'};
  height: ${({ height }) => height || '48px'};
  transition: ${({ transition }) => transition || 'none'};
  
  &:disabled {
    background-color: #E9EAEC;
    color: rgba(41, 48, 67, 0.5);
  }

  &:hover {
    background-color: ${({ backgroundColorHover, backgroundColor }) => backgroundColorHover || backgroundColor || '#00C656'};
    color: ${({ colorHover, color }) => colorHover || color || 'white'};
  }
`;

export default (props) => {
  const {
    icon,
    disabled = 0,
    children,
    ...rest
  } = props;

  return (
    <Button
      disabled={disabled}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {icon}
      {children}
    </Button>
  );
};
