import {setVisibilityCkToolbar} from "../../actions/actions";
import React, { Component } from "react";
import {connect} from "react-redux";

class ToggleSlate extends Component {

  onChange = () => {
    if(!this.props.isSlateRichText) {
      const slateActivation = confirm('Внимание! Это тестовая версия нового редактора, вы уверены что хотите переключиться?');

      if (!slateActivation) return;
      this.props.dispatch(setVisibilityCkToolbar(false))
    }

    this.props.input.onChange(!this.props.isSlateRichText)
  };

  render() {
    console.log(this.props)
    return (
      <input
        type="checkbox"
        name="public"
        checked={this.props.isSlateRichText ? true : false}
        onChange={this.onChange}
      />
    )
  }
};

export default connect((state, props) => ({...props}))
(ToggleSlate)