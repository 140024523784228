import React, { Component } from 'react';
import {
  ToolTip,
} from '/components/SlateRichText/molecules';
import {
  TopBarButton,
} from '../../atoms';
import {
  Icons,
  ModuleContainer,
} from '../../../../atoms';
import {
  PhotoForm,
} from '../../../../features/Photo';
import Editor from '../../../../Editor';

const {
  CreateImage,
} = Icons;


class PhotoToolModule extends Component {
  state = {
    isOpenModal: false,
  };


  closeModal = () => {
    this.setState(() => ({
      isOpenModal: false,
    }));
  };

  handleModal = () => {
    this.setState(prev => ({
      isOpenModal: !prev.isOpenModal,
    }));
  };

  onSubmit = (props) => {
    const { editor } = this.props;
    const {
      imgSrc,
      viewType,
      alt,
    } = props;
    const { value } = editor;
    const { focusBlock } = value;

    if (!focusBlock) return null;

    const Photo = Editor.node.PhotoContainer({
      nodes: [
        Editor.node.Photo({ imgSrc, alt, viewType }),
        Editor.node.PhotoCaption(),
      ],
      viewType,
    });

    if (focusBlock.text.length === 0) {
      editor.replaceNodeByKey(focusBlock.key, Photo);
    } else {
      editor.insertBlock(Photo);
    }
  };

  render() {
    const { editor, enabled } = this.props;
    const { isOpenModal } = this.state;
    const type = Editor.NODE.PHOTO;

    return (
      <ModuleContainer>
        {isOpenModal ? (
          <PhotoForm
            handleModal={this.handleModal}
            closeModal={this.closeModal}
            isOpen={isOpenModal}
            onSubmit={this.onSubmit}
            submitText={'Вставка'}
            title={'Вставить картинку:'}
          />) : null
        }
        <TopBarButton
          id={Editor.EDITOR_IDS.PHOTO + editor.props.editorId}
          onClick={enabled ? this.handleModal : null}
          data-for={type}
          data-tip
          enabled={enabled}
        >
          <CreateImage />
          <ToolTip
            id={type}
            tooltip={Editor.TOOLTIPS.PHOTO}
          />
        </TopBarButton>
      </ModuleContainer>
    );
  }
}

export default PhotoToolModule;
