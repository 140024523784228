import React from 'react';

const CONTAINER_ALIGN = 'left'; // or right
const NOTIFICATION_TYPES = {
  default: { icon: 'bell icon', appearance: 'ui icon message' },
  info: { icon: 'bell icon', appearance: 'ui icon message info' },
  success: { icon: 'check icon', appearance: 'ui icon message success' },
  error: { icon: 'exclamation icon', appearance: 'ui icon message error' },
  warning: { icon: 'exclamation icon', appearance: 'ui icon message warning' },
};

const NotificationMessageBox = ({
  id,
  type,
  header,
  message,
  onRemove,
}) => {
  if (!onRemove || typeof onRemove !== 'function') {
    console.error('AlertContainer: onRemove is not a function');
    console.error(`[${type}] ${header}: ${message}`);
    return null;
  }

  const { icon, appearance } = NOTIFICATION_TYPES[type]
      || NOTIFICATION_TYPES.default;
  const [show, setShow] = React.useState(false);
  const [display, setDisplay] = React.useState('flex');

  React.useEffect(() => {
    try {
      const timerID = setTimeout(() => {
        setShow(true);
      }, 0);

      return () => {
        clearTimeout(timerID);
      };
    } catch (e) {
      console.error('Cannot show notification', e.message);
      onRemove(id);
      setShow(false);
    }

    return null;
  }, []);

  React.useEffect(() => {
    try {
      let timerID;
      if (!show) {
        timerID = setTimeout(() => {
          setDisplay('none');
          onRemove(id);
        }, 300);
      }

      return () => {
        clearTimeout(timerID);
      };
    } catch (e) {
      console.error('Cannot hide notification', e.message);
      onRemove(id);
      setDisplay('none');
    }

    return null;
  }, [show]);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <div
      className={appearance}
      style={{
        display,
        width: 'fit-content',
        margin: '.5em 0',
        [CONTAINER_ALIGN]: show ? '0' : '-1000px',
        opacity: show ? 1 : 0,
        transition: 'all 0.5s cubic-bezier(.47,1.64,.41,.8)',
      }}
    >
      {/* eslint-disable */}
      <i className="close icon" onClick={handleClose} />
      {/* eslint-enable */}

      <i className={icon} />
      <div className="content">
        <div className="header">
          {header || ''}
        </div>
        {message && (<p>{ message }</p>)}
      </div>
    </div>
  );
};

export default NotificationMessageBox;
