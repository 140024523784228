import React from 'react';
import PropTypes from 'prop-types';
var moment = require('moment');
require('moment/locale/ru');

export const FeedLabel = ({text}) => {

    return (
        <div className="ui dividing header">
            { text }
        </div>
    );

};

FeedLabel.propTypes = {
    text: PropTypes.string
};

export default FeedLabel;