import ruLocale from 'date-fns/locale/ru';
import {
  parseISO,
  getISODay,
  differenceInDays,
  isYesterday,
  isToday,
  format,
} from 'date-fns';

// Если публикация раньше недели, то выводить:
// "<b>понедельник</b> 12 февраля".
// Иначе "<b>31.02.2017</b> 31 февраля"

export default (date) => {
  // date = new Date(date);
  const formatedDate = new Date(date);

  const mapWeekDays = {
    1: 'понедельник',
    2: 'вторник',
    3: 'среда',
    4: 'четверг',
    5: 'пятница',
    6: 'суббота',
    7: 'воскресенье',
  };

  let humanDate;

  if (isToday(formatedDate)) {
    humanDate = 'сегодня';
  } else if (isYesterday(formatedDate)) {
    humanDate = 'вчера';
  } else if (differenceInDays(new Date(), formatedDate) < 6) {
    humanDate = mapWeekDays[getISODay(date)];
  }

  humanDate = format(formatedDate, 'dd.MM.yy', { locale: ruLocale });

  const dayWithMonth = format(formatedDate, 'd MMMM', {
    locale: ruLocale,
  });

  return {
    title: date === 0 ? 'Неопубликованные' : humanDate,
    subTitle: date === 0 ? null : dayWithMonth,
  };
};
