/* @flow */
import React from 'react';
import 'react-quill/dist/quill.core.css';
import ReactQuill from 'react-quill';
import {
  QuillClass,
  QuillContainerStyles,
} from './styles';

export type Props = {
  value: string,
  onChange: (body: string) => void,
}

class SimpleRichText extends React.Component<Props, State> {
  static defaultProps = {
    formats: ['bold', 'italic'],
    theme: 'snow',
    modules: {
      toolbar: false,
    },
    className: QuillClass,
    style: QuillContainerStyles,
  };

  render() {
    const {
      innerRef,
      onChange,
      theme,
      modules,
      className,
      style,
      value,
      formats,
      placeholder,
      disabled,
    } = this.props;

    return (
      <ReactQuill
        ref={innerRef}
        className={className}
        onChange={onChange}
        value={value}
        theme={theme}
        modules={modules}
        formats={formats}
        style={style}
        placeholder={placeholder}
        readOnly={disabled}
      />
    );
  }
}

export default SimpleRichText;
