import styled from 'react-emotion';

export const TopToolBarContainer = styled('div')`
  display: flex;
  position: sticky;
  top: ${() => `${$('.ActionMenu').height() || 0}px`};
  z-index: 10;
  height: 45px;
  justify-content: space-between;
  background-color: white;
  padding-left: 10px;
  padding-right: 20px;
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.24);
`;

export const TopToolBarModulesGroup = styled('div')`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const TopBarButton = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ enabled }) => enabled ? 'pointer' : 'not-allowed'};
  height: 100%;
  border-radius: 2px;
  opacity: ${({ enabled }) => enabled ? '1' : '0.25'};
  svg tspan {
    font-family: "Graphik LC", sans-serif !important;
  }
  svg {
    g {
      fill: #293043;
    }
  };
  :hover {
    background-color: #e9eaec;
  }
`;

export const BulletButton = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ enabled }) => enabled ? 'pointer' : 'not-allowed'};
  height: 100%;
  border-radius: 2px;
  color: red;
  opacity: ${({ enabled }) => enabled ? '1' : '0.25'};
  svg tspan {
    font-family: "Graphik LC", sans-serif !important;
  }
  :hover {
    background-color: #e9eaec;
  }
`;

export const FormatBarButton = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ enabled }) => enabled ? 'pointer' : 'not-allowed'};
  height: 100%;
  background-color: ${props => (props.active ? '#e9eaec' : 'white')};
  opacity: ${({ enabled }) => enabled ? '1' : '0.25'};
  svg {
    g {
      fill: #293043;
    }
  };
  
  }
  :hover {
    background-color: #e9eaec;
  }
`;

export const ParagraphSelect = styled('p')`
  ${({ enabled }) => enabled ? 'pointer' : 'not-allowed'};
  font: 11px "William Text";
  margin-left: 5px;
`;

export const HeadingTwoSelect = styled('h2')`
  font-size: 17px;
  margin-left: 5px;
`;

export const HeadingThreeSelect = styled('h3')`
  font-size: 15px;
  margin-left: 5px;
`;

export const HighlightSelect = styled('p')`
  font: 12px "Graphik LC", sans-serif;
  margin-left: 5px;
`;