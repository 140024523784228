// @flow
import React from 'react';
import styled from 'react-emotion';
import icons from '/assets/icons';

const Icon = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: ${({ width }) => width || '24px'};
  height: ${({ height }) => height || '24px'};
  
  svg {
    width: 100%;
    height: 100%;
  }
`;

type Props = {|
  width: string,
  height: string,
  type: $Keys<typeof icons>
|};

export default (props: Props) => {
  const {
    width,
    height,
    type
  } = props;

  const icon = icons[type];

  if (!icon) throw Error('icon not found');

  return (
    <Icon
      width={width}
      height={height}
      {...props}
    >
      {icon}
    </Icon>
  )
}
