import React from 'react';
import Select from 'react-select';

const SocpicSelect = (props) => {
  const
    {
      id,
      input,
      defaultValue,
      disabled = false,
      placeholder = 'Размер шрифта в шэринговой',
      options = [
        { value: '56', label: 'Стандартный шрифт' },
        { value: '50', label: 'Шрифт поменьше' },
        { value: '46', label: 'Шрифт еще поменьше' },
      ],
      onOptionSelect,
    } = props;
  const getOption = (inputValue) => (id ? options.find((opt) => opt.value === inputValue) : null);

  const onChange = (option, action) => {
    if (action && action.action === 'clear') { onOptionSelect(null); }
    if (option && option.value) { onOptionSelect(option.value); }
  };

  return (
    <Select
      {...input}
      {...props}
      id={id}
      onChange={(option, action) => onChange(option, action)}
      value={getOption(input.value)}
      defaultValue={getOption(defaultValue)}
      options={options}
      placeholder={placeholder}
      onBlur={() => true}
      disabled={disabled}
      isClearable
    />
  );
};

export default SocpicSelect;
