import React from 'react';
import styled from 'styled-components'
import {
  nodeTypes,
  objectTypes,
} from '../../consts';

const BulletedList = styled.ul`
  font-size: 17px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  margin: 40px 0;
  padding: 0;

  a {
      color: $color_link_contrast;

      &:hover {
          color: $color_hover_contrast;
      }
  }

  b, strong {
      font-family: $font_sans-serif;
  }

  li {
  margin: 0;
  margin-bottom: 17px;
  margin-left: 17px;

  &:last-child {
      margin-bottom: 0;
  }
}
`

const BulletsNode = {
  type: nodeTypes.BULLETS,
  object: objectTypes.BLOCK,
  isTag: false,

  create() {
    return {
      type: this.type,
      object: this.object,
    };
  },

  htmlRender({ attributes, children }) {
    return (
      <ul {...attributes} className="mz-publish__list">{children}</ul>
    );
  },

  editorRender(attrs) {
    return (
      <BulletedList {...attrs} className="mz-publish__list" />
    );
  },
};

export default BulletsNode;
