import translit from 'iso_9';

export function generateSlug (title) {

    let tr_title = translit(title, 5).toLowerCase();
    tr_title = tr_title.replace(/( +|&nbsp;+)/ig, '_');
    tr_title = tr_title.replace(/[^a-z0-9_]/ig, '');

    if (tr_title.length >= 30) {
        let words = tr_title.split('_');
        let arr = [];
        let l = 0;
        words.forEach(function(w, i){
            arr.push(w);
            l += w.length;
            l++;
        });
        tr_title = arr.join('_');
    }

    return tr_title;
}

export default generateSlug;