import React from 'react';
import format from 'date-fns/format';
import './NotesList.styl';

const Note = ({ type, text, date, url }) => {
  let typeLink = 'посту';
  switch (type) {
    case 'news': typeLink = 'новости'; break;
    case 'article': typeLink = 'статье'; break;
    case 'online': typeLink = 'онлайну'; break;
    case 'chronicle': typeLink = 'сюжету'; break;
  }
  return (
    <div className="note">
      <div dangerouslySetInnerHTML={{ __html: text }} className="note__text" />
      <div className="note__line" />
      <div className="note__bottom">
        <p className="note__date">{format(new Date(date), 'dd.MM.yyyy HH:mm')}</p>
        <a target="_blank" href={url} className="note__link">Перейти к {typeLink}</a>
      </div>
    </div>
  );
};

export default Note;

