import styled from 'react-emotion';

export const Container = styled('div')`
  margin-top: 14px;
`;

export const Preview = styled('div')`
  margin: 20px 0;
  display: flex;
  flex-flow: row nowrap;
`;

export const Title = styled('a')`
  display: block;
  width: 100%;
  font-family: 'Graphik LC', sans-serif;
  font-size: 14px !important;
  line-height: 140%;
  color: #006fff;
`;

export const Image = styled('img')`
  width: 180px;
  max-height: 110px;
  background-color: #c4c4c4;
  margin-bottom: 10px;
  object-fit: contain;
`;

export const ImageStub = styled('div')`
  width: 180px;
  height: 110px;
  background-color: rgba(196, 196, 196, 0.1);
  border: 1px solid #E9EAEC;
  box-sizing: border-box;
  border-radius: 5px;
  background-image: url('/assets/image-stub.svg');
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position: center;
`;

export const MaterialSpan = styled('span')`
  color: #999999;
  font-size: 14px;
  line-height: 140%;
  font-weight: normal;
  width: 100%;
  display: block;
`;

export const ItemContainer = styled('div')`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  width: 320px;
  margin-left: 20px;
`;
