/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import { Provider } from 'react-redux';
import { render } from 'react-dom';
import { Router, Route, IndexRoute } from 'react-router';
// TODO: revert sentry if it will be needed
// import * as Sentry from '@sentry/browser';
import App from './containers/App';
import Layout from './containers/Layout';
import Post from './containers/Post';
import Auth from './components/Auth/Auth';
import UsersList from './containers/Users/UsersList';
import UserProfileForm from './containers/Users/UserProfileForm';
import RequireAuth from './containers/RequireAuth';
import Reports from './components/Report/Reports';
import Themes from './containers/Themes/Feed';
import Theme from './containers/Themes/Theme';
import {
  AdvertCampaignList,
  AdvertCampaignForm,
  ExternalCampaignForm,
} from './features/AdvertCampaign';
import WebPushes from './components/WebPushList';
import Features from './components/Features/Features';
import TransformerPost from './components/TransformerPost/TransformerPost';
import NotesContainer from './components/Notes/NotesContainer';
import CsvAnalytics from './components/CsvAnalytics/CsvAnalytics';
import MentionSearch from './components/MentionSearch/MentionSearch';
import {
  PartnerForm,
  PartnerList,
} from './features/Partners';
import { MultiFileUploader } from './features/FileUploader';
import AppSearch from './features/AppSearch';

import config from './const/config';

import '../semantic/dist/components/dimmer';
import '../semantic/dist/components/transition';
import '../semantic/dist/components/dropdown';
import '../semantic/dist/components/modal';
import '../semantic/dist/components/rating';
import '../semantic/dist/components/tab';
import '../semantic/dist/components/popup';
import '../semantic/dist/components/sticky';
import '../semantic/dist/components/icon.css';

import './lib/button.styl';
import './lib/colors.styl';
import './lib/dragging.styl';
import './lib/text.styl';
import './lib/loading.styl';
import './lib/editor.styl';
import './ckeditor/rich.css';
import './styl/fonts.styl';
import generateId from './utils/generateId';
import { updateSession } from './actions/actions';

import { store, history } from './store/store';

if (!window.windowId) {
  window.windowId = generateId();
}

// Function to check if the current route is the auth page
function isAuthPage() {
  return window.location.hash === '#/auth';
}

setInterval(
  () => {
    if (!isAuthPage()) {
      store.dispatch(updateSession());
    }
  },
  60000,
);

// TODO: revert sentry if it will be needed
// Sentry.init({
//   dsn: 'https://8b06ee5f62d54cea9d47044e308c4a1c@sentry.zona.media/5',
//   environment: window.location.host,
//   blacklistUrls: [/localhost/gi],
// });

// страница для тестирования загрузчика файлов
const MultiUploader = () => <MultiFileUploader onChange={console.log} />;

const routeList = [
  {
    path: '/transformer_post/:id',
    component: TransformerPost,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/pushes',
    component: WebPushes,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/post/:id',
    component: Post,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/users',
    component: UsersList,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/users/:id',
    component: UserProfileForm,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/themes',
    component: Themes,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/advertCampaigns',
    component: AdvertCampaignList,
    regions: ['ru'],
  },
  {
    path: '/adcampaign/:id',
    component: AdvertCampaignForm,
    regions: ['ru'],
  },
  {
    path: '/external_adcampaign/:id',
    component: ExternalCampaignForm,
    regions: ['ru'],
  },
  {
    path: '/features',
    component: Features,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/theme/:id',
    component: Theme,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/reports',
    component: Reports,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/notes',
    component: NotesContainer,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/csv_analytics',
    component: CsvAnalytics,
    regions: ['ru'],
  },
  {
    path: '/mention_search',
    component: MentionSearch,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/uploader',
    component: MultiUploader,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/advanced_search',
    component: AppSearch,
    regions: ['ru'],
  },
  {
    path: '/partners',
    component: PartnerList,
    regions: ['ru'],
  },
  {
    path: '/partner/:id',
    component: PartnerForm,
    regions: ['ru'],
  },
  {
    path: '/:type',
    component: App,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/:type/:year',
    component: App,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/:type/:year/:month',
    component: App,
    regions: ['ru', 'ca', 'by'],
  },
  {
    path: '/:type/:year/:month/:day',
    component: App,
    regions: ['ru', 'ca', 'by'],
  },
];

const routes = () => routeList
  .filter(({ regions }) => regions.includes(config.REGION))
  .map(({ path, component }) => (<Route path={path} component={RequireAuth(component)} />));

render(
  <Provider store={store}>
    <Router history={history}>
      <Route path="/" component={Layout}>
        <IndexRoute component={Auth} />
        <Route path="/auth" component={Auth} />
        { routes() }
      </Route>
    </Router>
  </Provider>,
  document.getElementById('react-root'),
);
