import React from 'react';
import { YouTube } from './atoms';
import {
  nodeTypes,
  objectTypes
} from '../../consts';

const YouTubeNode = {
  type: nodeTypes.YOUTUBE,
  object: objectTypes.BLOCK,

  create({ url = '' }) {
    return {
      type: this.type,
      object: this.object,
      data: {
        url
      },
    };
  },

  schema: {
    isVoid: true,
    parent: {
      type: nodeTypes.EMBED_CONTAINER
    }
  },

  htmlRender(attrs) {
    return (
      <YouTube
        {...attrs}
      />
    );
  }
};

export default YouTubeNode;

