import React from 'react';

const PhotoContainer = (props) => {
  const { node, attributes, children } = props;
  const viewType = node.data.get('viewType');

  return (
    <figure
      className={`mz-publish-photo ${viewType ? `mz-publish-photo_${viewType}` : ''}`}
      {...attributes}
    >
      {children}
    </figure>
  );
};

export default PhotoContainer;