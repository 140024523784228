import React from 'react';

export const UpperIndexFormat = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
    <g fill="none" fillRule="evenodd">
      <path d="M10.23 19V9.099H7V7.394h8.635V9.1h-3.23V19H10.23zm6.234-6.957v-.166l1.879-1.983c.473-.501.758-.94.758-1.412 0-.466-.264-.759-.744-.759-.494 0-.807.286-.89.988h-.85C16.68 7.626 17.334 7 18.384 7c1.03 0 1.642.591 1.642 1.468 0 .647-.383 1.21-.974 1.78l-1.106 1.079h2.115v.716h-3.597z" />
    </g>
  </svg>

);

export const ItalicFormat = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
    <g fill="none" fillRule="evenodd">
      <path d="M10.92 18.44l1.812-10.205H9L9.215 7h9.025l-.215 1.235h-3.732L12.48 18.44z" />
    </g>
  </svg>
);

export const BoldFormat = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
    <g fill="none" fillRule="evenodd">
      <path d="M9 19V7h4.297c2.467 0 3.927.94 3.927 3.122v.067c0 1.191-.537 2.131-1.897 2.55 1.628.353 2.4 1.243 2.4 2.837v.067c0 2.233-1.477 3.357-4.112 3.357H9zm2.182-6.948h1.846c1.477 0 2.048-.537 2.048-1.762v-.068c0-1.14-.655-1.628-2.082-1.628h-1.812v3.458zm0 5.354h2.165c1.494 0 2.182-.672 2.182-1.914v-.067c0-1.242-.688-1.863-2.333-1.863h-2.014v3.844z" />
    </g>
  </svg>
);

export const BulletFormat = () => (
  <svg width="28" height="10" viewBox="0 0 28 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="1" r="1" fill="#1E2433" />
    <circle cx="10" cy="5" r="1" fill="#1E2433" />
    <circle cx="10" cy="9" r="1" fill="#1E2433" />
    <rect x="13" width="7" height="2" rx="1" fill="#1E2433" />
    <rect x="13" y="4" width="7" height="2" rx="1" fill="#1E2433" />
    <rect x="13" y="8" width="7" height="2" rx="1" fill="#1E2433" />
  </svg>
);

export const ClearFormat = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
    <g fill="none" fillRule="nonzero">
      <path d="M20.322 11.026l-3.65-3.651A1.253 1.253 0 0 0 15.762 7c-.335 0-.651.118-.908.375l-4.579 4.599-2.901 2.901a1.286 1.286 0 0 0-.375.908c0 .335.118.651.375.908l1.678 1.677c.414.395.947.632 1.52.632h2.723a2.12 2.12 0 0 0 1.52-.632l.927-.927 4.6-4.599c.256-.256.374-.592.374-.908a1.36 1.36 0 0 0-.395-.908zm-.592 1.204l-4.302 4.303-4.244-4.244 4.303-4.302a.435.435 0 0 1 .296-.119c.118 0 .217.04.296.119l3.651 3.651a.435.435 0 0 1 .119.296c0 .119-.04.217-.119.296z" />
    </g>
  </svg>
);

export const AddLink = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
    <g fill="none" fillRule="evenodd">
      <path d="M8.412 15.824c0 .183.073.36.206.492l1.08 1.074a.732.732 0 0 0 .5.19.706.706 0 0 0 .5-.198l1.515-1.522a.713.713 0 0 0-.037-1.036c-.242.242-.448.529-.823.529a.706.706 0 0 1-.706-.706c0-.375.287-.58.53-.823a.713.713 0 0 0-1.03-.03l-1.53 1.53a.7.7 0 0 0-.205.5zm5.169-5.184c0 .22.088.39.243.536.242-.242.448-.529.823-.529.39 0 .706.316.706.706 0 .375-.287.58-.53.823a.69.69 0 0 0 .53.228.706.706 0 0 0 .5-.198l1.53-1.53a.7.7 0 0 0 0-.992L16.3 8.61a.739.739 0 0 0-.5-.198.713.713 0 0 0-.5.206l-1.514 1.522a.713.713 0 0 0-.206.5zM7 15.824c0-.567.213-1.096.618-1.5l1.53-1.53a2.126 2.126 0 0 1 1.5-.618c.58 0 1.124.236 1.528.648l.648-.648a2.153 2.153 0 0 1-.648-1.536c0-.56.214-1.096.61-1.493l1.515-1.522a2.09 2.09 0 0 1 1.5-.625c.56 0 1.096.213 1.493.61l1.081 1.074c.397.39.625.934.625 1.492 0 .567-.213 1.096-.618 1.5l-1.53 1.53a2.126 2.126 0 0 1-1.5.618c-.58 0-1.124-.236-1.528-.648l-.648.648c.412.404.648.955.648 1.536 0 .56-.214 1.096-.61 1.493l-1.515 1.522a2.09 2.09 0 0 1-1.5.625 2.102 2.102 0 0 1-1.493-.61l-1.081-1.074A2.103 2.103 0 0 1 7 15.824z" />
    </g>
  </svg>
);

export const AddHint = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="91" height="27" viewBox="0 0 91 27">
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path fill="#293043" d="M18.8 7h-8.6C8.987 7 8 7.938 8 9.092v6.752c0 1.151.983 2.088 2.192 2.092V21l4.63-3.064H18.8c1.213 0 2.2-.938 2.2-2.092V9.092C21 7.938 20.013 7 18.8 7zm1.262 8.844c0 .662-.566 1.2-1.262 1.2h-4.272l-3.399 2.25v-2.25h-.93c-.695 0-1.261-.538-1.261-1.2V9.092c0-.662.566-1.2 1.262-1.2h8.6c.696 0 1.262.538 1.262 1.2v6.752z" />
        <path fill="#FD6073" d="M11 10h7v1h-7zM11 12h7v1h-7zM11 14h7v1h-7z" />
      </g>
      <text fill="#293043" fontFamily="Graphik LC" fontSize="8" fontWeight="500" letterSpacing=".444">
        <tspan x="31" y="16">ПОДСКАЗКА</tspan>
      </text>
    </g>
  </svg>

);

export const RemoveLink = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27">
    <g fill="none" fillRule="evenodd">
      <path fill="#293043" d="M7.412 15.824c0 .183.073.36.206.492l1.08 1.074a.732.732 0 0 0 .5.19.706.706 0 0 0 .5-.198l1.515-1.522a.713.713 0 0 0-.037-1.036c-.242.242-.448.529-.823.529a.706.706 0 0 1-.706-.706c0-.375.287-.58.53-.823a.713.713 0 0 0-1.03-.03l-1.53 1.53a.7.7 0 0 0-.205.5zm5.169-5.184c0 .22.088.39.243.536.242-.242.448-.529.823-.529.39 0 .706.316.706.706 0 .375-.287.58-.53.823a.69.69 0 0 0 .53.228.706.706 0 0 0 .5-.198l1.53-1.53a.7.7 0 0 0 0-.992L15.3 8.61a.739.739 0 0 0-.5-.198.713.713 0 0 0-.5.206l-1.514 1.522a.713.713 0 0 0-.206.5zM6 15.824c0-.567.213-1.096.618-1.5l1.53-1.53a2.126 2.126 0 0 1 1.5-.618c.58 0 1.124.236 1.528.648l.648-.648a2.153 2.153 0 0 1-.648-1.536c0-.56.214-1.096.61-1.493l1.515-1.522a2.09 2.09 0 0 1 1.5-.625c.56 0 1.096.213 1.493.61l1.081 1.074c.397.39.625.934.625 1.492 0 .567-.213 1.096-.618 1.5l-1.53 1.53a2.126 2.126 0 0 1-1.5.618c-.58 0-1.124-.236-1.528-.648l-.648.648c.412.404.648.955.648 1.536 0 .56-.214 1.096-.61 1.493l-1.515 1.522a2.09 2.09 0 0 1-1.5.625 2.102 2.102 0 0 1-1.493-.61l-1.081-1.074A2.103 2.103 0 0 1 6 15.824z" />
      <path fill="#293043" fillRule="nonzero" d="M15 16h5v2h-5z" />
    </g>
  </svg>
);

export const DownTriangle = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="292.362px"
    height="292.362px"
    viewBox="0 0 292.362 292.362"
  >
    <g>
      <path d="M286.935,69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952,0-9.233,1.807-12.85,5.424
		  C1.807,72.998,0,77.279,0,82.228c0,4.948,1.807,9.229,5.424,12.847l127.907,127.907c3.621,3.617,7.902,5.428,12.85,5.428
		  s9.233-1.811,12.847-5.428L286.935,95.074c3.613-3.617,5.427-7.898,5.427-12.847C292.362,77.279,290.548,72.998,286.935,69.377z"
      />
    </g>
  </svg>
);

export const CreatePullQuote = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="27" viewBox="0 0 60 27">
    <g fill="none" fillRule="evenodd">
      <text fill="#293043" fontFamily="Graphik LC" fontSize="8" fontWeight="500" letterSpacing=".444">
        <tspan x="33" y="16">ВРЕЗ</tspan>
      </text>
      <g fill="#293043">
        <path d="M11.475 7l-.75 1.714H6.857a.857.857 0 1 1 0-1.714h4.618zm5.05 0h5.618a.857.857 0 1 1 0 1.714h-4.868L16.525 7zm-8.05 6.857l-.75 1.714h-.868a.857.857 0 0 1 0-1.714h1.618zm11.05 0h2.618a.857.857 0 1 1 0 1.714h-1.868l-.75-1.714zm-9.55-3.428l-.75 1.714H6.857a.857.857 0 1 1 0-1.714h3.118zm8.05 0h4.118a.857.857 0 1 1 0 1.714h-3.368l-.75-1.714zm-11.05 6.857l-.664 1.517a.857.857 0 0 1 .546-1.518h.118zm14.05 0h1.118a.857.857 0 1 1 0 1.714h-.368l-.75-1.714z" opacity=".44" />
        <path d="M13.372 7h1.744L20 19h-2.222l-1.142-3.42h-4.912L10.222 19H8l5.372-12zm2.56 6.884l-1.744-4.527-1.776 4.527h3.52z" />
      </g>
    </g>
  </svg>
);

export const CreateImage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="62" height="27" viewBox="0 0 62 27">
    <g fill="none" fillRule="evenodd">
      <text fontFamily="Graphik LC" fontSize="8" fontWeight="500" letterSpacing=".444">
        <tspan x="31" y="16">ФОТО</tspan>
      </text>
      <g fillRule="nonzero">
        <path d="M7 7.4v11.2h14V7.4H7zm13.067 10.338H7.862V8.262h12.205v9.476zm-1.005-1.005H8.723l2.585-6.748 3.446 4.218 1.723-1.266 2.585 3.796z" />
        <path fill="#FD6073" d="M14.154 10.23a1.385 1.385 0 1 0 2.77 0 1.385 1.385 0 0 0-2.77 0z" />
      </g>
    </g>
  </svg>
);

export const CreateEmbed = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="62" height="27" viewBox="0 0 62 27">
    <g fill="none" fillRule="evenodd">
      <path fill="#293043" d="M18.656 18.344V7.656h-1.312v2h-6.688v-2H9.344v10.688h9.312zM14 6.344a.632.632 0 0 0-.464.192.632.632 0 0 0-.192.464c0 .181.064.336.192.464a.632.632 0 0 0 .464.192.632.632 0 0 0 .464-.192.632.632 0 0 0 .192-.464.632.632 0 0 0-.192-.464.632.632 0 0 0-.464-.192zm4.656 0c.363 0 .677.128.944.384.267.256.4.565.4.928v10.688c0 .363-.133.672-.4.928a1.318 1.318 0 0 1-.944.384H9.344c-.363 0-.677-.128-.944-.384a1.24 1.24 0 0 1-.4-.928V7.656c0-.363.133-.672.4-.928s.581-.384.944-.384h2.784c.139-.395.376-.717.712-.968.336-.25.723-.376 1.16-.376.437 0 .824.125 1.16.376.336.25.573.573.712.968h2.784z" />
      <text fill="#293043" fontFamily="Graphik LC" fontSize="8" fontWeight="500" letterSpacing=".444">
        <tspan x="25" y="16">МЕДИА</tspan>
      </text>
    </g>
  </svg>
);

export const Close = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
    <path fill="#293043" fillRule="evenodd" d="M11.64 1.18L7 5.82l4.64 4.64-1.18 1.18L5.82 7l-4.64 4.64L0 10.46l4.64-4.64L0 1.18 1.18 0l4.64 4.64L10.46 0z" />
  </svg>
);

export const EditBlock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
    <g fill="none" fillRule="evenodd">
      <path fill="#293043" fillRule="nonzero" d="M2.564 0h15.872c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v15.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H2.563c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 0 1-.757-.756C.093 19.65 0 19.327 0 18.437V2.563c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757C1.35.093 1.673 0 2.563 0z" />
      <path fill="#FFF" d="M10.5 12.348c.338 0 .654-.087.949-.26.295-.173.529-.405.702-.695.173-.29.26-.605.26-.943a1.84 1.84 0 0 0-.26-.949 1.947 1.947 0 0 0-.702-.702 1.84 1.84 0 0 0-.949-.26c-.338 0-.652.087-.943.26-.29.173-.522.407-.695.702a1.84 1.84 0 0 0-.26.949c0 .338.087.652.26.943.173.29.405.522.695.695.29.173.605.26.943.26zm4.043-1.365l1.131.884c.052.043.082.1.091.169a.307.307 0 0 1-.039.195l-1.092 1.872c-.035.06-.08.1-.136.117a.288.288 0 0 1-.189-.013l-1.339-.533c-.347.251-.654.429-.923.533l-.195 1.417a.33.33 0 0 1-.098.169.235.235 0 0 1-.162.065H9.408a.235.235 0 0 1-.162-.065.264.264 0 0 1-.085-.169l-.208-1.417a3.274 3.274 0 0 1-.91-.533l-1.352.533c-.139.06-.247.026-.325-.104l-1.092-1.872a.307.307 0 0 1-.039-.195.253.253 0 0 1 .091-.169l1.144-.884a4.065 4.065 0 0 1-.026-.533c0-.234.009-.412.026-.533l-1.144-.884a.253.253 0 0 1-.091-.169.307.307 0 0 1 .039-.195l1.092-1.872c.078-.13.186-.165.325-.104l1.352.533c.312-.234.615-.412.91-.533l.208-1.417a.264.264 0 0 1 .085-.169.235.235 0 0 1 .162-.065h2.184c.06 0 .115.022.162.065.048.043.08.1.098.169l.195 1.417c.338.13.646.308.923.533l1.339-.533a.288.288 0 0 1 .189-.013.238.238 0 0 1 .136.117l1.092 1.872c.035.06.048.126.039.195a.253.253 0 0 1-.091.169l-1.131.884c.017.121.026.299.026.533 0 .234-.009.412-.026.533z" />
    </g>
  </svg>
);

export const RemoveBlock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
    <g fill="none" fillRule="evenodd">
      <path fill="#FD6073" fillRule="nonzero" d="M2.564 0h15.872c.892 0 1.215.093 1.54.267.327.174.583.43.757.756.174.326.267.65.267 1.54v15.873c0 .892-.093 1.215-.267 1.54-.174.327-.43.583-.756.757-.326.174-.65.267-1.54.267H2.563c-.892 0-1.215-.093-1.54-.267a1.817 1.817 0 0 1-.757-.756C.093 19.65 0 19.327 0 18.437V2.563c0-.892.093-1.215.267-1.54.174-.327.43-.583.756-.757C1.35.093 1.673 0 2.563 0z" />
      <path fill="#FFF" d="M14.453 7.617c-.062.172-.117.258-.164.258H7.211c-.125 0-.191-.008-.2-.023-.007-.016.005-.094.036-.235l.094-.304c.047-.126.195-.211.445-.258l1.242-.164a.292.292 0 0 0 .235-.164c.03-.079.066-.164.105-.258.039-.094.066-.164.082-.211a.592.592 0 0 1 .07-.14.265.265 0 0 1 .094-.095A.26.26 0 0 1 9.531 6h2.414a.26.26 0 0 1 .117.023c.032.016.063.051.094.106l.082.14c.024.04.055.11.094.211.04.102.074.184.105.247a.292.292 0 0 0 .235.164l1.242.14c.25.047.398.133.445.258.032.078.063.188.094.328zm-.82 1.008c.187 0 .304.02.351.059.047.039.063.136.047.293l-.445 5.671c-.016.11-.031.184-.047.223-.016.04-.05.07-.105.094a.656.656 0 0 1-.246.035H8.311c-.171 0-.273-.02-.304-.059-.031-.039-.063-.136-.094-.293L7.47 8.977c-.016-.141 0-.235.047-.282.047-.047.164-.07.351-.07h5.766z" />
    </g>
  </svg>
);

export const Typographus = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27">
    <g fill="none" fillRule="evenodd">
      <path fill="#293043" d="M9.786 17.01V8.47H7V7h7.448v1.47h-2.786v8.54H9.786zm7.253-.86c1.033 0 1.602-.618 1.602-1.772v-.083c0-1.195-.697-1.782-1.614-1.782-.976 0-1.672.587-1.672 1.782v.083c0 1.205.662 1.772 1.684 1.772zm-3.043 2.73v-7.233h1.405v.866c.337-.515 1.092-.979 1.986-.979 1.521 0 2.694 1 2.694 2.74v.083c0 1.731-1.126 2.782-2.694 2.782-.964 0-1.66-.423-1.986-.948v2.69h-1.405z" />
    </g>
  </svg>
);
