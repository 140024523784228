import { createSelector } from 'reselect'
import { getFormValues } from 'redux-form';
import syncInfo from '../../common/sync-channel';
import { isEqualVersions } from '../components/Post/comparePublishAndCurrentVersion.js';
import { getProp } from '../lib/sync-form';


export const postValues = (state, props) => {
    const formName = syncInfo.formFunc('post', props.id);
    return getFormValues(formName)(state);
};

export const publishedValues = (state) => state.post.published_post;

export const isEqualState = createSelector(
    postValues,
    publishedValues,
    (form, published) => isEqualVersions(Object.assign({}, form), Object.assign({}, published))
);

export const transactionsSelector = (state) => state.transactions;
export const allTransactionsApplied = createSelector(
    transactionsSelector,
    (transactions) => {
        const { lastServerTransaction, lastMyTransaction } = transactions;

        if (!lastMyTransaction) {
            return true;
        }

        if (lastMyTransaction && !lastServerTransaction) {
            return false;
        }

        const serverTranscData = syncInfo.destructTransactionId( lastServerTransaction );
        const myTranscData = syncInfo.destructTransactionId( lastMyTransaction );

        const isBothTranscsMine = serverTranscData.clientId == myTranscData.clientId;
        const isServerTranscFreshEnough = serverTranscData.timestamp >= myTranscData.timestamp;

        return isBothTranscsMine && isServerTranscFreshEnough;
    }
);

export const eventsEditingState = (state) => {
    return Object.keys(state.eventsUi).reduce((prev, eventId) => {
        prev[eventId] = state.eventsUi[eventId].isEditing;
        return prev;
    }, {});
};

export const focusedSelector = (state) => (entity, id, field) => {
    let searchPath = `${entity}#${id}.${field}`;

    const res = getProp(state.focusUi, searchPath).length > 0;

    return res;
}

export const focusedUsersSelector = (state) => (entity, id, field) => {
    let searchPath = `${entity}#${id}`;

    if (field) {
        searchPath += `.${field}`;
    }

    const res = getProp(state.focusUi, searchPath) || [];

    return res;
}

export function notesSelector(collection) {
  return Object.values(collection)
    .map(x => ({ ...x, ts: new Date(x.publish_date) - 0}))
    .sort((a, b) => {
      return b.ts - a.ts;
    })
}
