//
// Created on 20-03-2019.
//

export default {
  1553087251646: {
    types: [
      'mz-publish-photo-justify',
      'mz-publish-photo-original',
      'mz-publish-photo-bigger',
      'mz-publish-photo-full-width',
      'mz-publish-photo-vertical',
      'mz-publish-photo-justify_caption',
      'mz-publish-photo-original_caption',
      'mz-publish-photo-bigger_caption',
      'mz-publish-photo-full-width_caption',
      'mz-publish-photo-vertical_caption'
    ],
    up: (el) => {
      const newEl = el;

      // view type pull out from el type
      const clearedType = el.dataset.type.split('_')[0];
      const splitTypes = clearedType.split('-');
      let viewType = splitTypes[splitTypes.length - 1];

      // create photo container and set data-type and view type
      const photoContainer = document.createElement('figure');
      photoContainer.dataset.type = 'photo-container';

      // full-with to fullWidth
      if (viewType === 'width') {
        viewType = 'fullWidth';
      }

      // set new slateCustom
      photoContainer.dataset.slateCustom = JSON.stringify({
        viewType
      });

      // create caption node
      const photoCaption = document.createElement('figcaption');

      const captionEl = el.querySelector('.mz-publish-photo__comment');
      let captionNodes = [document.createElement('p')];
      if (captionEl && captionEl.hasChildNodes()) {
        captionNodes = captionEl.childNodes;
      }
      for (const captionNode of captionNodes) {
        photoCaption.appendChild(captionNode);
      }
      photoCaption.dataset.type = 'photo-caption';

      // copy and set new type
      const photoNode = newEl.cloneNode();
      photoNode.dataset.type = 'photo';

      // append elements to container
      photoContainer.appendChild(photoNode);
      photoContainer.appendChild(photoCaption);

      return photoContainer;
    }
  }
};