import io from '../io';
import React, { Component } from 'react';
const syncInfo = require('../../common/sync-channel');
import {destroy, getFormValues} from 'redux-form';
import {
    createSyncCollection,
    destroySyncCollection,
    addItemToSyncCollection,
    removeItemFromSyncCollection,
    setItemsInSyncCollection
} from '../actions/actions';
import { createSelector } from 'reselect';
let timeOutId = null;
import { maxTimeDisconnect, keyNotification } from '../const/const';
import { store } from '../store/store';

//need to use between conenct and reduxForm

let subscribeList = {};

// const notification = new Notification(io, browserEvents.networkStatus);

// on reconnect restore form state
io.on('connect', function () {
    console.log('connect',Date.now());
    if (timeOutId) {
        console.log(`reload less than ${maxTimeDisconnect} ms`);
        clearTimeout(timeOutId);
        timeOutId = null;
    }
    for (let formName in subscribeList)
        io.emit(syncInfo.join_event, syncInfo.formDestructor(formName));
});

io.on("connect_error", (err) => {
    console.error(`connect_error due to ${err.message}`);
});

io.on('disconnect', function () {
    console.log('disconnect',Date.now());
    timeOutId = setTimeout(() => {
        timeOutId = null;
        if (!localStorage.getItem(keyNotification)){
            document.dispatchEvent(
              new CustomEvent('custom-notify', {
                  detail: {
                      title: `Ошибка соединения`,
                      message: `Соединение не удалось восстановить автоматически после ${Math.floor(maxTimeDisconnect/1000)} сек. ожидания`,
                      level: 'error',
                      autoDismiss:5,
                      onAdd: function(notification){
                          localStorage.setItem(keyNotification, '1');
                      },
                      onRemove: function(notification){
                          localStorage.removeItem(keyNotification);
                      }
                  }
              })
            );
        }
    }, maxTimeDisconnect);
});


export default function(){
    return function(WrappedComponent){
        class SyncForm extends Component{
            render(){
                const {syncedEntity, syncedId} = this.props;
                const state = store.getState();
                const valueSelector = getFormValues(syncInfo.formFunc(syncedEntity, syncedId));
                const formName = syncInfo.formFunc(syncedEntity, syncedId);
                return <WrappedComponent {...this.props} form={formName} formValues={valueSelector(state)} key={formName} />;
            }

            componentDidMount(){
                const {syncedEntity, syncedId} = this.props;
                io.emit(syncInfo.join_event, {entity: syncedEntity, id: syncedId});
                io.emit(syncInfo.get_event, {entity: syncedEntity, id: syncedId});
                subscribeList[syncInfo.formFunc(syncedEntity, syncedId)] = true;
            }
            componentWillReceiveProps(props){
                if (props.syncedId != this.props.syncedId)
                {
                    delete subscribeList[syncInfo.formFunc(this.props.syncedEntity, this.props.syncedId)];
                    io.emit(syncInfo.left_event, {entity: this.props.syncedEntity, id: this.props.syncedId});
                    io.emit(syncInfo.join_event, {entity: this.props.syncedEntity, id: props.syncedId});
                    io.emit(syncInfo.get_event, {entity: this.props.syncedEntity, id: props.syncedId});
                    subscribeList[syncInfo.formFunc(props.syncedEntity, props.syncedId)] = true;
                }
            }
            componentWillUnmount(){
                const {syncedEntity, syncedId} = this.props;
                store.dispatch(destroy(syncInfo.formFunc(syncedEntity, syncedId)));
                io.emit(syncInfo.left_event, {entity: this.props.syncedEntity, id: this.props.syncedId});
                delete subscribeList[syncInfo.formFunc(this.props.syncedEntity, this.props.syncedId)];
            }
        }

        return SyncForm;
    }

};

export const getProp = (obj, path, noRoute) => {
    if (typeof(noRoute) == 'undefined')
        noRoute = '';
    var chain = path.split('.');
    var target = obj;
    if (!target)
        return noRoute;
    for (var i = 0; i < chain.length; i++) {
        if (target[chain[i]])
            target = target[chain[i]];
        else
            return noRoute;
    }
    return target;
};

export const collectionSelector = (collectionName) => (state) => state['collections'][collectionName];

export const collecitonItemSelector = (itemId) => (collection) => collection[itemId];

export const collectionItemDataSelector = (collectionName, itemId) => createSelector(
  collectionSelector(collectionName),
  collecitonItemSelector(itemId)
);

export const collectionItemValueAccessor = (collectionName, itemId) => (state) => (prop) => {
    const collection = collectionSelector(collectionName)(state);
    const itemData = collecitonItemSelector(itemId)(collection);

    return getProp(itemData, prop);
};


export function syncCollection(){
    return function(WrappedComponent){
        class SyncCollection extends Component{
            constructor(props){
                super(props);
                this.unsubscribe = null;
                this.values = null;
            }
            add = (id, value) => {
                console.log(id, value);
                io.emit(syncInfo.collection_operation_event, {
                    operation: 'add',
                    entity: this.props.syncedEntity,
                    id: this.props.syncedId,
                    itemId: id,
                    value,
                    clientId: window.windowId
                });
            };
            remove = (itemId) => {
                io.emit(syncInfo.collection_operation_event, {
                    operation: 'remove',
                    entity: this.props.syncedEntity,
                    id: this.props.syncedId,
                    itemId,
                    clientId: window.windowId
                });
            };
            set = (id, value) => {
                io.emit(syncInfo.collection_operation_event, {
                    operation: 'set',
                    entity: this.props.syncedEntity,
                    id: this.props.syncedId,
                    itemId: id,
                    value,
                    clientId: window.windowId
                });
            };
            onStoreChange(){
                const {syncedEntity, syncedId} = this.props;
                const state = store.getState();
                const valueSelector = collectionSelector(syncInfo.formFunc(syncedEntity, syncedId));
                const newValues = valueSelector(state);
                if (newValues != this.values)
                {
                    this.values = newValues;
                    this.forceUpdate();
                }
            }
            render(){
                const {syncedEntity, syncedId} = this.props;
                const state = store.getState();
                const valueSelector = collectionSelector(syncInfo.formFunc(syncedEntity, syncedId));
                return <WrappedComponent {...this.props}
                                         collection={syncInfo.formFunc(syncedEntity, syncedId)}
                                         formValues={valueSelector(state)}
                                         add={this.add}
                                         remove={this.remove}
                                         set={this.set}
                />;
            }
            componentDidMount(){
                const {syncedEntity, syncedId} = this.props;
                this.unsubscribe = store.subscribe(() => this.onStoreChange());
                const form = syncInfo.formFunc(syncedEntity, syncedId);
                store.dispatch(createSyncCollection(form));
                io.emit(syncInfo.join_event, {entity: syncedEntity, id: syncedId});
                io.emit(syncInfo.collection_sync_event, {entity: syncedEntity, id: syncedId});
                subscribeList[form] = true;
            }
            componentWillReceiveProps(props){
                if (props.syncedId != this.props.syncedId)
                {
                    store.dispatch(destroySyncCollection(syncInfo.formFunc(this.props.syncedEntity, this.props.syncedId)));
                    delete subscribeList[syncInfo.formFunc(this.props.syncedEntity, this.props.syncedId)];
                    store.dispatch(createSyncCollection(syncInfo.formFunc(this.props.syncedEntity, this.props.syncedId)));
                    io.emit(syncInfo.left_event, {entity: this.props.syncedEntity, id: this.props.syncedId});
                    io.emit(syncInfo.join_event, {entity: this.props.syncedEntity, id: props.syncedId});
                    io.emit(syncInfo.collection_sync_event, {entity: this.props.syncedEntity, id: props.syncedId});
                    subscribeList[syncInfo.formFunc(props.syncedEntity, props.syncedId)] = true;
                }
            }
            componentWillUnmount(){
                const {syncedEntity, syncedId} = this.props;
                if (this.unsubscribe)
                {
                    this.unsubscribe();
                    this.unsubscribe = null;
                }
                store.dispatch(destroySyncCollection(syncInfo.formFunc(syncedEntity, syncedId)));
                io.emit(syncInfo.left_event, {entity: this.props.syncedEntity, id: this.props.syncedId});
                delete subscribeList[syncInfo.formFunc(this.props.syncedEntity, this.props.syncedId)];
            }
        }

        return SyncCollection;
    }

};
