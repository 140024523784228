// @ts-nocheck
import { REGION_BY } from '../../const/const';

// 002D - дефис-минус
// 2010 ‐ дефис
// 2011 ‑ Неразрывный дефис
// 2012 ‒ Цифровое тире
// 2013 – Среднее тире &ndash;
// 2014 — Длинное тире &mdash;
// 2015 ― горизонтальная линия
// 2212 - минус &minus;
const DASHES = '(\\u002D|[\\u2010-\\u2015]|\\u2212)';
const SPACES = '(\\u00A0|\\u180E|\\u205F|\\u3000|\\uFEFF|[\\u2000-\\u200B])';
const ZERO_WIDTH_NO_BREAK_SPACE = '\uFEFF';
const SYM_HELLIP = '…';
const SYM_COPYRIGHT = '©';
const SYM_TRADEMARK = '™';
const SYM_REG = '®';
// const SYM_FRAC_12 = '\u00BD'; // ½
// const SYM_FRAC_14 = '\u00BC'; // ¼
// const SYM_FRAC_34 = '\u00BE'; // ¾
const SYM_RIGHT_ARR = '→';
const SYM_LEFT_ARR = '←';
const SYM_PLUS_MINUS = '±';
const COMBINING_BREVE = '\u0306';

const rules = [];
const addRule = (test, replace, options = 'gim', excludeRegions = []) => {
  const ruleIndex = rules.push({
    test,
    replace,
    options,
    excludeRegions,
  });
  return {
    exludeRegions: (...regions) => {
      rules[ruleIndex - 1].excludeRegions = regions;
    },
  };
};

// любая ё
addRule('Ё', 'Е', 'gm').exludeRegions(REGION_BY);
addRule('ё', 'е', 'gm').exludeRegions(REGION_BY);
// И краткая в односимвольный вариант
addRule(`И${COMBINING_BREVE}`, 'Й', 'gm');
addRule(`и${COMBINING_BREVE}`, 'й', 'gm');
// (c) -> ©
addRule('\\((c|с)\\)', SYM_COPYRIGHT);
// (r) -> ®
addRule('\\(r\\)', SYM_REG);
// (tm) -> ™
addRule('\\(tm\\)', SYM_TRADEMARK);
// ... -> …
addRule('\\.{3,5}', SYM_HELLIP);
// +- -> ±
addRule('([^\\+]|^)\\+-', `$1${SYM_PLUS_MINUS}`);
// -> => →
addRule('([^-]|^)-(>|&gt;)', `$1${SYM_RIGHT_ARR}`);
// <- => ←
addRule('([^<]|^)(<|&lt;)-', `$1${SYM_LEFT_ARR}`);
// 1 % -> 1%
addRule('([0-9]+)\\s+%', '$1%');
// Удалить все невидимые неразрывные пробелы
addRule(`${ZERO_WIDTH_NO_BREAK_SPACE}`, '');
// Все нпробелы превратить в стандартный пробел //u0020
addRule(`${SPACES}`, ' ');
// '  ' или переносы строки -> ' '
addRule(' {2,}', ' ');
// Если вокруг дефиса или тире пробелы, то заменить на тире
addRule(`(\\s)${DASHES}(\\s)`, '$1\u2014$3');
// Если только слева от дефиса или тире пробел, то убрать пробел
addRule(`(\\s)(${DASHES}\\S)`, '$2');
// Если вокруг дефиса или тире не пробелы, то заменить на дефис
addRule(`(\\S)${DASHES}(\\S)`, '$1\u002D$3');
// Добавить неразрывный пробел между номером и цифрой
addRule('(№)(\\s+)([0-9]+)', '$1\u00A0$3');
// Подстроки от 1 до 10 символов в уголовых скобках окружаются невидимым неразрывным пробелом
addRule('(<)(.{1,10})(>)', `<${ZERO_WIDTH_NO_BREAK_SPACE}$2${ZERO_WIDTH_NO_BREAK_SPACE}>`);
// «грати» в «Ґрати» - назание украинского издания
addRule('([„“«»“”‘’„”\'"])(Грати)([„“«»“”‘’„”\'"])', '$1Ґрати$3');
// TODO: возможно понадобится правило для расставления минуса в математических выражениях

export default (str, region = '') => {
  const filteredRules = rules.filter((rule) => !rule.excludeRegions.includes(region));

  if (typeof str !== 'string') {
    throw new Error('Argument for replace symbols is not a string.');
  }
  let processed = str;

  const applyRule = ({ test, replace, options }) => {
    processed = processed.replace(new RegExp(test, options), replace);
  };

  filteredRules.forEach(applyRule);

  return processed;
};
