import styled from 'styled-components';

const Warning = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 40px;
	font-family: Graphik LC;
	font-size: 14px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0em;
	text-align: left;
	background-color: ${({ bg }) => bg || '#439B95'};
	padding-left: 15px;
	color: white;
	margin-bottom: 15px;
`;

export default Warning;
