import React, { Component } from 'react';
import { Portal } from 'react-portal';
import {
  Input,
  Select,
} from '/ui/atoms';
import Modal from '/components/Modal/Modal';
import {
  FormContainer,
  ModalTitleContainer,
  ModalController,
  ModalCloseButton,
  ModalContent,
  InputTitle,
  ModalCancel,
  ModalSubmit,
  ModalLoadingSubmit,
  Icons,
  Loading,
} from '../../../../atoms';
import {
  ImageField
} from '../../../../molecules';
import {
  selectStyle,
} from '../../../../styles';

const {
  Close,
} = Icons;

const viewTypeOptions = [
  { value: 'justify', label: 'По ширине текста' },
  { value: 'original', label: 'Оригинальный размер' },
  { value: 'bigger', label: 'Увеличенный' },
  { value: 'fullWidth', label: 'На всю ширину' },
  { value: 'vertical', label: 'Вертикальный' },
  { value: 'left', label: 'Вынос налево' },
];

const initialState = {
  isOpenModal: false,
  viewType: viewTypeOptions[0].value,
  imgSrc: '',
  imgError: '',
  alt: '',
  isLoading: false
};

class PhotoForm extends Component {
  constructor(props) {
    super(props);

    if (props.initialState) {
      this.state = { ...initialState, ...props.initialState };
    } else {
      this.state = initialState;
    }
  }

  onSubmit = (event) => {
    event.persist();
    event.preventDefault();
    const {
      imgSrc,
      imgError,
      alt,
      viewType,
    } = this.state;

    if (!imgSrc) {
      this.setState(() => ({
        imgError: 'Требуется загрузить изображение',
      }));
      return;
    }

    if (imgError) return;

    const submitValue = {
      imgSrc,
      alt,
      viewType,
    };

    this.props.onSubmit(submitValue);
    this.props.closeModal();
  };

  onSelect = fieldName => (value) => {
    this.setState(() => ({
      [fieldName]: value,
    }));
  };

  onChange = fieldName => (event) => {
    event.persist();
    event.preventDefault();
    this.setState(() => ({
      [fieldName]: event.target.value,
    }));
  };

  onUploadImage = ({ isLoading, src, error }) => {
    this.setState(() => ({
      imgSrc: src,
      imgError: error,
      isLoading,
    }));
  };

  render() {
    const {
      imgError,
      viewType,
      alt,
      isLoading,
      imgSrc,
      src,
    } = this.state;

    const {
      isOpen,
      title,
      submitText,
      closeModal,
    } = this.props;

    return (
      <Portal>
        <Modal isOpen={isOpen} handleClose={closeModal}>
          <FormContainer onSubmit={this.onSubmit}>
            <ModalTitleContainer>
              <h2>{title}</h2>
              <ModalCloseButton onClick={closeModal}>
                <Close />
              </ModalCloseButton>
            </ModalTitleContainer>
            <ModalContent>
              <ImageField
                src={imgSrc}
                error={imgError}
                alt={alt}
                onUpload={this.onUploadImage}
                onChangeAlt={this.onChange('alt')}
              />
              <InputTitle>
                Стиль изображения
              </InputTitle>
              <Select
                clearable={false}
                className={selectStyle}
                menuContainerStyle={{ zIndex: 1000 }}
                value={viewType}
                onChange={this.onSelect('viewType')}
                options={viewTypeOptions}
              />
              <ModalController>
                <ModalCancel onClick={closeModal}>Отменить</ModalCancel>
                {isLoading ? (
                  <ModalLoadingSubmit>
                    <Loading />
                  </ModalLoadingSubmit>
                ) : (
                  <ModalSubmit type="submit" value={submitText} />
                )}
              </ModalController>
            </ModalContent>
          </FormContainer>
        </Modal>
      </Portal>
    );
  }
}

export default PhotoForm;
