// @flow
import React from 'react';
import type { Node } from 'react';
import styled from 'react-emotion';

const colors = {
  blue: '#006fff',
};

type Props = {|
  color: ?$Keys<typeof colors>,
  children: Node[],
|};

const Paragraph = styled('p')`
  font-family: 'GraphikLC', sans-serif !important;
  font-size: 13px;
  color: ${({ color }) => colors[color] || '#293043'};
  
  b, strong {
    font-weight: 500;
  }
`;

export default (props: Props) => (
  <Paragraph
    color={props.color}
  >
    {props.children}
  </Paragraph>
);
