import React, { Component } from 'react';
import { reduxForm, Field as ReduxFormField, initialize, change, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import syncInfo from '../../../common/sync-channel';
import syncForm from '../../lib/sync-form';
import {
  Field,
} from '/ui/molecules';
import {
  SimpleRichText,
} from '/ui/organism';

const ReduxSimpleRichText = ({ input, disabled }) => (
  <SimpleRichText
    value={input.value}
    onChange={input.onChange}
    disabled={disabled}
  />
);

@connect((state, ownProps) => {
  const formName = syncInfo.formFunc('note', ownProps.id);
  const formValues = getFormValues(formName)(state);
  return {
    formName,
    formValues,
    syncedEntity: 'note',
    syncedId: ownProps.id,
  }
})
@syncForm()
@reduxForm()
class NoteForm extends Component {
  constructor(props){
    super(props);
    this.disabled = props.disabled;
	}

  onChange = (event, newValue, previousValue, name) => {
    if (this.disabled) {
      return;
    }
    this.props.dispatch(change(this.props.formName, name, newValue));
  }

  render() {
    if (!this.props.formValues) return null;
    return (
      <Field>
        <div className="ui top attached block header">Заметка</div>
        <ReduxFormField
          name="note_text"
          type="text"
          component={ReduxSimpleRichText}
          height="150"
          formKey={this.props.syncedId}
          onChange={this.onChange}
          disabled={this.disabled}
        />
      </Field>
    );
  }
}


export default NoteForm;