import pullQuoteViewType from './pullQuoteViewTypesToType';
import embedToEmbedContainer from './embedToEmbedContainer';
import imageToPhotoContainer from './imageToPhotoContainer';
import pullQuoteWithTitleToPullQuoteWithoutTitleField from './pullQuoteWithTitleToPullQuoteWithoutTitleField';

const migrations = [
  pullQuoteViewType,
  embedToEmbedContainer,
  imageToPhotoContainer,
  pullQuoteWithTitleToPullQuoteWithoutTitleField,
];

export default migrations;