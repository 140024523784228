import React, { useState, useEffect } from 'react';
import { convertToDateTimeLocal, dateTimeLocalToISO } from '/utils/dateFormat';
import styled from 'styled-components';

const InputPicker = styled.input`
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
  }
`;

const DateTimePicker = ({ input, eventId, onChange: customOnChange, disabled }) => {
  // TODO: the name property in old code has logic as:
  // if name === 'action_date' input has onBlur={this.handleBlur}
  const { value, name, onChange: inputOnChange } = input;
  const initValue = convertToDateTimeLocal(value || new Date());
  const [localValue, setlocalValue] = useState(initValue);

  useEffect(
    () => { setlocalValue(convertToDateTimeLocal(value)); },
    [value],
  );

  useEffect(
    () => {
      if (localValue && initValue !== localValue) {
        inputOnChange(dateTimeLocalToISO(localValue));
        if (customOnChange) {
          customOnChange();
        }
      }
    },
    [localValue],
  );

  return (
    <InputPicker
      type="datetime-local"
      id={`event-${eventId}`}
      value={localValue}
      // e: SyntheticBaseEvent<HTMLInputElement>
      onChange={(e) => setlocalValue(e.target.value)}
      disabled={disabled}
    />
  );
};

export default DateTimePicker;
