// @flow
import React from 'react';
import type { Node } from 'react';
import styled from 'styled-components';
import Icon from '../../atoms/Icon';

type Props = {|
  children: Node[],
  valid: Boolean,
|};

const Wrapper = styled.div`
  padding: 14px 40px 14px 20px;
  border-radius: 3px;
  background-color: ${({ valid }) => (valid ? '#F4FBF5' : '#FEF4F5')};
  border: ${({ valid }) => (valid ? '1px solid #23B036' : '1px solid #EF2530')};
  margin-bottom: 14px;

  display: flex;
  flex-flow: row nowrap;
`;

const Text = styled.div`
  margin-left: 12px;
  font-size: 12px;
  line-height: 12px;
  color: #212121;
`;

export default ({ children, valid }: Props) => (
  <Wrapper valid={valid}>
    <Icon
      type={valid ? 'checkmark' : 'warning'}
      width="12px"
      height="12px"
    />
    <Text>{children}</Text>
  </Wrapper>
);
