// @ts-nocheck
/* @flow */
import React from 'react';
import { Range } from 'slate';
import type { Editor as SlateEditor } from 'slate-react';
import {
  ToolTip,
} from '/components/SlateRichText/molecules';
import {
  FormatBarButton
} from '../../atoms';
import {
  Icons,
  ModuleContainer,
} from '../../../../atoms';
import Editor from '../../../../Editor';

const {
  BulletFormat,
} = Icons;

const hasBlock = (type, editor) => {
  const { value } = editor;
  return value.blocks.some(node => node.type === type);
};

const ControlButton = ({ editor, icon, onClick, enabled }) => {
  const type = Editor.NODE.BULLET;
  const isActive = hasBlock(Editor.NODE.BULLET, editor);
  return (
    <FormatBarButton
      onMouseDown={enabled ? onClick : null}
      data-for={type}
      data-tip
      active={isActive}
      enabled={enabled}
    >
      {icon}
      <ToolTip
        id={type}
        tooltip={Editor.TOOLTIPS.BULLET}
      />
    </FormatBarButton>
  );
};

type Props = {
  editor: SlateEditor
}

class BulletsToolModule extends React.Component<Props> {
  addBullets = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { editor } = this.props;
    const { value } = editor;

    const isList = hasBlock(Editor.NODE.BULLET, editor);
    const isType = value.blocks.some(b => editor.value.document.getClosest(b.key, parent => parent.type === Editor.NODE.BULLETS));

    if (isList && isType) {
      editor
        .setBlocks(Editor.NODE.PARAGRAPH)
        .unwrapBlock(Editor.NODE.BULLETS);
    } else if (isList) {
      editor
        .unwrapBlock(Editor.NODE.BULLET);
      editor
        .unwrapBlock(Editor.NODE.BULLETS);
    } else {
      editor.setBlocks(Editor.NODE.BULLET).wrapBlock(Editor.NODE.BULLETS);
    }
  };

  render() {
    const { editor, enabled } = this.props;
    return (
      <ModuleContainer>
        <ControlButton
          icon={<BulletFormat />}
          onClick={enabled ? this.addBullets : null}
          editor={editor}
          enabled={enabled}
        />
      </ModuleContainer>
    );
  }
}

export default BulletsToolModule;
