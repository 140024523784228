import constants from './constants';

export default () => {
  let OSName = 'Unknown OS';
  if (navigator.appVersion.indexOf('Win') !== -1) OSName = constants.os.WIN;
  if (navigator.appVersion.indexOf('Mac') !== -1) OSName = constants.os.MAC;
  if (navigator.appVersion.indexOf('Linux') !== -1) OSName = constants.os.LINUX;

  return OSName;
};
