import React from 'react';
import { connect } from 'react-redux';

import { Link } from 'react-router';

import { menuList } from './menu-list';
import { currentTypeSelector } from './main-menu-selectors';

import './common.styl';
import './MainMenu.styl';
import { menuright } from '../../utils/constants.js';
import { REGION } from '../../const/config';
import {
  createPostByEntity,
  createTheme,
  tryReportCount,
} from '../../actions/actions.js';

const fetchTimer = 30 * 1000;
const actionMenueClasses = {
	'ca': 'MainMenuBgCa',
	'by': 'MainMenuBgBy',
}
const actionMenuBg = actionMenueClasses[REGION] || actionMenuBg;
@connect(
  state => ({
    currentType: currentTypeSelector(state),
    user_id: state?.user?.userId,
    reportCount: state.reportCount,
    userRoles: state.user.user.roles,
  }),
  (dispatch, ownProps) => ({
    createEntity: (type) => {
      if (type == 'theme') {
        dispatch(createTheme());
      } else {
        dispatch(createPostByEntity(type));
      }
    },
    getReportCount: () => {
      dispatch(tryReportCount());
    },
  }),
)

export default class MainMenu extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    $('.js-publication-dropdown')
      .first()
      .dropdown();
    this.props.getReportCount();
    this.timer = setInterval(() => this.props.getReportCount(), fetchTimer);
  }

  componentWillUnmount() {
    if (this.timer) clearInterval(this.timer);
  }

  onClickType(type) {
    this.props.createEntity(type);
  }

  render() {
    const { currentType, user_id } = this.props;

    return (
      <div className={`${actionMenuBg} MainMenu ui inverted borderless menu`}>
        <Link to="/" className="header item">
          МЗ
        </Link>

        {menuList.map((obj, i) => {
          if (obj.roles && obj.roles.length > 0) {
            // if no intersections between two arrays obj.roles and user.roles return null
            if (this.props.userRoles
              && obj.roles
              .filter(role => this.props.userRoles.includes(role))
              .length === 0
            ) {
              return null;
            }
          }

          const isLinkActive = obj.route == `/${currentType}`;

          return (
            <Link
              title={obj.title}
              key={obj.route + i}
              to={obj.route}
              className={['item', obj.extraClass, isLinkActive ? 'active' : ''].join(' ')}
            >
              {obj.icon ? <i className={obj.icon} /> : null}
              {obj.caption ? obj.caption : obj.route == '/reports' ? this.props.reportCount : null}
            </Link>
          );
        })}
        <Link
          title="все заметки"
          className={['item', 'note__icon', '/notes' === `/${currentType}` ? 'note__icon_active' : ''].join(' ')}
          to={`/notes`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM8 14H6v-2h2v2zm0-3H6V9h2v2zm0-3H6V6h2v2zm7 6h-5v-2h5v2zm3-3h-8V9h8v2zm0-3h-8V6h8v2z"/></svg>
        </Link>

        <div className="right menu">
          <div className="item" title="добавить новость">
            <div
              className="newPost ui grey circular link button"
              data-format="news"
              onClick={this.onClickType.bind(this, 'news')}
            >
              Новость
            </div>
          </div>
          <div className="item" title="новая публикация">
            <div
              className="newPost ui grey circular dropdown meta-menu-open icon button js-publication-dropdown"
              tabIndex="-1"
            >
              Публикация &nbsp;
              <i className="icon dropdown" tabIndex="-1">
                <div className="menu" tabIndex="-1" />
              </i>
              <div className="menu" tabIndex="-1">
                {menuright
                  .filter(item => item.regions.includes(REGION))
                  .map(({ key, title }) => (
                    <div
                      className="newPost link item"
                      onClick={this.onClickType.bind(this, key)}
                      key={key}
                      data-format={key}
                   >
                    {title}
                  </div>
                  ))
                }
              </div>
            </div>
          </div>
          <div className="item">
            <Link
              title="ваш аккаунт"
              className="ui icon inverted basic circular button"
              to={`/users/${user_id}`}
            >
              <i className="user icon" />
            </Link>
          </div>
        </div>
      </div>
    );
  }
}
