function features(state = {}, action){
	switch(action.type){
		case "SEARCH_FEATURES_CONTEXT":
			return {
				...state,
				search_features_context: action.searched || [],
				isLoading: false
			}
		case "REFRESH_FEATURE_CONTEXT":
			//удаление статьи которая добавила в фичерах если редактор добавлял ее через поиск по контексу фичеров
			let context = state.search_features_context || [];
			if (context && context.length>0){
				context = context.filter((searched, i)=>{
					if (searched.id !== action.id)
						return searched;
				}) || [];
			}
			return {
				...state,
				search_features_context: context
			}
		default:
			return state;
	}
}

export default features;