import styled from 'react-emotion';

export const Container = styled('div')`
  margin-top: 14px;
`;

export const Preview = styled('div')`
  margin: 20px 0;
`;

export const Title = styled('a')`
  font-family: 'Graphik LC', sans-serif;
  font-size: 13px;
  color: #006fff;
`;

export const Image = styled('img')`
  width: 360px;
  max-height: 190px;
  background-color: #c4c4c4;
  margin-bottom: 10px;
  object-fit: contain;
`;