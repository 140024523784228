import React from 'react';
import { Link } from 'react-router';
import PropTypes from 'prop-types';

import { format } from 'date-fns';
import ruLocale from 'date-fns/locale/ru';

export default class FeedItem extends React.Component {

    static propTypes = {
        id: PropTypes.any,
        title: PropTypes.any,
        view_count: PropTypes.any,
        createdAt: PropTypes.any
    }

    render() {

        const {
            id,
            title,
            view_count,
            createdAt,
            author
        } = this.props;

        return (

            <tr key={`post/${id}`}>

                <td className="one wide">
                    { createdAt ?
                        format(new Date(createdAt), 'HH:mm', { locale: ruLocale }) : null}
                </td>

                <td className="nine wide">

                    <Link to={`/theme/${id}`} className="post-item">
                        { title || 'Без названия' }
                    </Link>

                </td>

                <td className="two wide">
                    <i className="world icon"></i>
                    <Link to={`/theme/${id}`}>
                        Ссылка
                    </Link>
                </td>
                {/*
                <td className="three wide">
                    <Link to="/users/Fc6QCwETEdn9iyyg4"> { author } </Link>
                </td>

                <td className="one wide"> { view_count } </td>
                */}
            </tr>

        );
    }
}
