import React from 'react';
import styled from 'styled-components';
import {
  Donate,
} from './components';
import PullQuoteController from '../PullQuote/components/PullQuoteController';
import {
  nodeTypes,
  objectTypes,
} from '../../consts';
import { REGION } from '/const/config';

const Image = styled.img`
  max-width: 100%;
`;

const banners = {
  by: [
    {
      id: 0,
      desktop: '/assets/donate-banner/by.png',
    },
  ],
  ca: [
    {
      id: 0,
      desktop: '/assets/donate-banner/ca.png',
    },
  ],
  ru: [
    {
      id: 0,
      desktop: '/assets/donate-banner/1-desktop.png',
      mobile: '/assets/donate-banner/1.png',
    },
    {
      id: 1,
      desktop: '/assets/donate-banner/2-desktop.png',
      mobile: '/assets/donate-banner/2.png',
    },
    {
      id: 2,
      desktop: '/assets/donate-banner/3-desktop.png',
      mobile: '/assets/donate-banner/3.png',
    },
    {
      id: 3,
      desktop: '/assets/donate-banner/4-desktop.png',
      mobile: '/assets/donate-banner/4.png',
    },
    {
      id: 4,
      desktop: '/assets/donate-banner/5-desktop.png',
      mobile: '/assets/donate-banner/5.png',
    },
    {
      id: 5,
      desktop: '/assets/donate-banner/default.png',
    },
    {
      id: 6,
      desktop: '/assets/donate-banner/vpn-desktop.png',
      mobile: '/assets/donate-banner/vpn.png',
    },
  ],
};

const DonateNode = {
  type: nodeTypes.DONATE,
  object: objectTypes.BLOCK,

  schema: {
    isVoid: true,
  },

  create({ nodes, bannerId }) {
    return {
      type: this.type,
      object: this.object,
      data: {
        bannerId,
      },
      nodes,
    };
  },

  htmlRender(attrs) {
    return (
      <Donate
        bannerId={attrs.node.data.toObject().bannerId}
        {...attrs}
      />
    );
  },

  editorRender(attrs) {
    const { node, attributes, type } = attrs;

    return (
      <PullQuoteController
        {...attrs}
      >
        <Image src={banners[REGION][node.data.toObject().bannerId]?.desktop || '/assets/donate-banner/default.png'} />
        <Donate
          attributes={attributes}
          node={node}
          type={type}
          bannerId={node.data.toObject().bannerId}
        >
        </Donate>
      </PullQuoteController>
    );
  },
};

export default DonateNode;
