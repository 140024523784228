import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import { getNotes, CHANGE_SEARCH_KEY } from '../../actions/notes';
import NotesList from './NotesList';
import './NotesList.styl';


@connect()
export default class NotesContainer extends Component {
  state = {
    query: ''
  }

  handleSearch = debounce((value) => {
    this.props.dispatch({ type: CHANGE_SEARCH_KEY, payload: value });
    this.props.dispatch(getNotes(value));
  }, 500)

  render() {
    return (
      <div className="ui container notes-list">
        <h2 className="ui large header notes-list__title">Заметки</h2>
        <div className="notes-list__search">
          <div className="ui fluid icon input">
            <input onChange={(e) => this.handleSearch(e.target.value)} type="text" name="searchField" placeholder="Фильтр по заметкам" />
            <i className="search icon" />
          </div>
        </div>
        <NotesList />
      </div>
    );
  }
}

