import React from 'react';
import { connect } from 'react-redux';
import syncForm from '/lib/sync-form';
import syncInfo from '/../common/sync-channel';
import {
  reduxForm, formValueSelector, Field as ReduxFormField, change,
} from 'redux-form';
import { SingleFileUploader } from '/features/FileUploader';
import Label from '/ui/atoms/Label';
import Button from '/ui/molecules/Button';

@connect(
  (state) => {
    const formName = syncInfo.formFunc('pageSettings', '1');
    const select = selector => formValueSelector(formName)(state, selector);

    return {
      bannerImage: select('header_banner_image'),
      bannerBackground: select('header_banner_bg'),
      bannerLink: select('header_banner_link'),
      isBannerPublished: select('header_banner_is_published'),

      syncedEntity: 'pageSettings',
      syncedId: '1',
    };
  },
  (dispatch) => ({
    dispatch,
    publishBanner: (form) => { dispatch(change(form, 'header_banner_is_published', true)); },
    unPublishBanner: (form) => { dispatch(change(form, 'header_banner_is_published', false)); },
  }),
)

@syncForm()
@reduxForm()
class HeaderBannerSettings extends React.Component {
  render() {
    const {
      bannerImage,
      bannerBackground,
      bannerLink,
      dispatch,
      form,
      isBannerPublished,
      publishBanner,
      unPublishBanner,
    } = this.props;

    const canBePublished = !!bannerImage && !!bannerBackground && !!bannerLink;

    return (
      <div className="ui container basic segment">
        <div className="info-form">
          <div className="ui small dividing header">
            Верхний баннер
          </div>

          {isBannerPublished && (
            <div
              style={{
                display: 'flex',
                gap: '1em',
                flexDirection: 'column',
              }}
            >
              <Button
                className="info-button ui button primary"
                onClick={() => { unPublishBanner(form); }}
              >
                Снять с публикации
              </Button>
              <div style={{
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
                margin: 'auto',
                width: '100%',
                backgroundColor: bannerBackground,
              }}
              >
                <img
                  style={{ margin: 'auto', maxWidth: '75%' }}
                  src={`${bannerImage && window.SERVER_SIDE_VARS.s3_url}${bannerImage}`}
                  alt="Изображение для верхнего баннера"
                />
              </div>
              <p>
                {bannerLink}
              </p>
            </div>
          )}

          {!isBannerPublished && (
            <div
              style={{
                display: 'flex',
                gap: '1em',
                flexDirection: 'column',
              }}
            >
              <ReduxFormField
                component={SingleFileUploader}
                disabled
                name="header_banner_image"
                id="image"
                key="image"
                title="Загрузить заходную картинку"
                file={bannerImage}
                handler={(s3Path) => { dispatch(change(form, 'header_banner_image', s3Path)); }}
                customPath="banner/"
                customDir
                accepted="image/*"
              />
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Label>
                  Цвет фона
                </Label>
                <ReduxFormField
                  name="header_banner_bg"
                  type="text"
                  component="input"
                  placeholder="#fff..."
                  style={{
                    width: '6em',
                    padding: '10px',
                    border: 'solid 1px gray',
                    borderRadius: '5px',
                  }}
                />
                <ReduxFormField
                  name="header_banner_bg"
                  type="color"
                  component="input"
                  className="ui input"
                />
              </div>
              <div>
                <Label>
                  Ссылка
                </Label>
                <ReduxFormField
                  name="header_banner_link"
                  type="text"
                  component="input"
                  placeholder="https://..."
                  style={{
                    width: '100%',
                    padding: '7px',
                    border: 'solid 1px gray',
                    borderRadius: '5px',
                  }}
                />
              </div>
              <Button
                disabled={!canBePublished}
                className="info-button ui button primary"
                onClick={() => { publishBanner(form); }}
              >
                {canBePublished ? 'Опубликовать баннер' : 'Заполните все поля, чтобы опубликовать баннер'}
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default HeaderBannerSettings;
