const OPEN_QUOTE = Symbol('OPEN_QUOTE');
const CLOSE_QUOTE = Symbol('CLOSE_QUOTE');
const PUNCTUATION = '!?.:;#*,…\\-,';
const RIGHT_BRACKET = '\\]\\)}>';
const LEFT_BRACKET = '\\[\\({<';
const QOUTES = '„“«»“”‘’„”\'"';
const quote = {
  [OPEN_QUOTE]: ['«', '"'],
  [CLOSE_QUOTE]: ['»', '"'],
};
const MAX_LEVEL = quote[OPEN_QUOTE].length - 1;

const isQuote = str => new RegExp(`[${QOUTES}]`, 'gim').test(str);
const isSpace = str => /\s/.test(str);
const isPunctuation = str => new RegExp(`[${PUNCTUATION}]`, 'gim').test(str);
const isRightBracket = str => new RegExp(`[${RIGHT_BRACKET}]`, 'gim').test(str);
const isLeftBracket = str => new RegExp(`[${LEFT_BRACKET}]`, 'gim').test(str);

class Replacer {
  constructor(str) {
    if (typeof str !== 'string') throw new Error('Argument for replace quotes is not a string.');
    this.str = str;
    this.normalized = str;
    this.entries = str.matchAll(new RegExp(`[${QOUTES}]`, 'gim'));

    this.level = 0;
    this.lastQuote = OPEN_QUOTE;
  }

  replaceAtIndex(index, replacement) {
    this.normalized = this.normalized.substr(0, index)
      + replacement
      + this.normalized.substr(index + replacement.length);
  }

  increaseLevel() {
    this.level = this.level + 1;
  }

  decreaseLevel() {
    this.level = this.level - 1;
  }

  selectQuote(quoteType) {
    return quote[quoteType][Math.max(Math.min(this.level, MAX_LEVEL), 0)];
  }

  replaceQuote(index, quoteType) {
    if (quoteType === CLOSE_QUOTE) this.decreaseLevel();
    const newQuote = this.selectQuote(quoteType);
    if (quoteType === OPEN_QUOTE) this.increaseLevel();
    this.replaceAtIndex(index, newQuote);
    this.lastQuote = quoteType;
  }

  replaceOpenQuote(index) {
    this.replaceQuote(index, OPEN_QUOTE);
  }

  replaceCloseQuote(index) {
    this.replaceQuote(index, CLOSE_QUOTE);
  }

  replaceSamePreviousQuote(index) {
    this.replaceQuote(index, this.lastQuote);
  }

  normalize() {
    // eslint-disable-next-line no-restricted-syntax
    for (const entry of this.entries) {
      const { index } = entry;
      if (index === 0) {
        this.replaceOpenQuote(index);
        // eslint-disable-next-line no-continue
        continue;
      }
      if (index === (this.str.length - 1)) {
        this.replaceCloseQuote(index);
        // eslint-disable-next-line no-continue
        continue;
      }
      const prev = this.str[index - 1];
      const next = this.str[index + 1];

      switch (true) {
        case isQuote(prev):
          this.replaceSamePreviousQuote(index);
          break;
        case isSpace(prev):
          this.replaceOpenQuote(index);
          break;
        case isLeftBracket(next):
          this.replaceOpenQuote(index);
          break;
        case isLeftBracket(prev):
          this.replaceOpenQuote(index);
          break;
        case isSpace(next):
          this.replaceCloseQuote(index);
          break;
        case isPunctuation(next):
          this.replaceCloseQuote(index);
          break;
        case isRightBracket(next):
          this.replaceCloseQuote(index);
          break;
        case isQuote(next):
          this.replaceCloseQuote(index);
          break;
        default:
          break;
      }
    }
    return this.normalized;
  }
}

const replaceQuotes = str => (new Replacer(str)).normalize();

export default replaceQuotes;
