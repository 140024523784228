import React from 'react';
import { PreviewWrapper, ActionWrapper, Image } from './Wrappers';
import ActionButton from './ActionButton';
import { Edit, Delete } from './icons';

const SingleFilePreview = ({
  src, onEdit, onDelete, editable = true
}) => (
  <PreviewWrapper>
    {src && (
      <ActionWrapper>
        <ActionButton onClick={onEdit}>
          <Edit />
        </ActionButton>
        {editable && (<ActionButton onClick={onDelete}>
          <Delete />
        </ActionButton>)}
      </ActionWrapper>
    )}
    <Image
      key={src}
      src={src}
    />
  </PreviewWrapper>
);

export default SingleFilePreview;
