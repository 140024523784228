/* @flow */
/* eslint-disable camelcase, react/no-string-refs, jsx-a11y/interactive-supports-focus */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { change, formValueSelector } from 'redux-form';

import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
import 'react-quill/dist/quill.core.css';
import 'react-quill/dist/quill.snow.css';

import Textarea from 'react-textarea-autosize';
import syncInfo from '/../common/sync-channel';
import { SingleFileUploader } from '../../features/FileUploader';

import {
  QuillClass,
  QuillContainerStyles
} from './styled';

const quillModules = {
  toolbar: [
    ['bold'],
    ['italic'],
    ['link'],
    ['clean'],
  ],
};

const quillFormats = [
  'bold', 'italic', 'link', 'image'
];



const generateId = () => `${Date.now()}_${Math.round(Math.random() * 100000)}`;

type AnswerContainerProps = {
  answers: Object,
  all_answers: Object,
  qid: string,
  form: string,
  post_id: string,
  dispatch: Object => void,
};

@connect((state, props) => {
  const selector = formValueSelector(syncInfo.formFunc('post', props.post_id));
  const answer_q = {};
  const all_answers = selector(state, 'answers');
  for (const a in all_answers) {
    if (all_answers[a].qid == props.qid) {
      answer_q[a] = all_answers[a];
    }
  }
  return {
    answers: answer_q,
    all_answers,
    qid: props.qid,
  };
})
class AnswerContainer extends Component<AnswerContainerProps> {
  constructor(props: AnswerContainerProps) {
    super(props);
  }

  addAnswer() {
    const { answers, all_answers } = this.props;
    const answer = {
      qid: this.props.qid,
      id: generateId(),
      name: '',
      description: '',
      points: 0,
      order: Object.keys(answers).length,
      image: '',
    };
    this.props.dispatch(
      change(this.props.form, 'answers', { ...all_answers, [answer.id]: answer }),
    );
  }
  addAnswer: () => void;

  removeAnswer(id: string) {
    const { all_answers } = this.props;
    const clone = Object.assign({}, all_answers);
    // this.props.dispatch(change(this.props.form, 'questions', {...questions, [id]: null}));
    this.recalculateOrder(clone, id);
    delete clone[id];
    this.props.dispatch(change(this.props.form, 'answers', { ...clone }));
  }
  removeAnswer: string => void;

  recalculateOrder(clone: { order: number }, id: string) {
    for (const a in clone) {
      if (clone[a].order > clone[id].order) {
        clone[a].order -= 1;
      }
    }
  }
  recalculateOrder: (Object, string) => void;

  sort(id1: string, id2: string) {
    const copy_answers = this.props.answers;
    const temp_order: number = copy_answers[id1].order;
    copy_answers[id1].order = copy_answers[id2].order;
    copy_answers[id2].order = temp_order;
    return copy_answers;
  }
  sort: (string, string) => Object;

  render() {
    const { qid, answers, all_answers, form } = this.props;
    let answersArr = [];
    if (answers) {
      answersArr = Object.keys(answers)
        .map(key => answers[key])
        .sort((a: { order: number }, b: { order: number }) => a.order - b.order);
    }
    const answersList = answersArr.map((answer: Object, i: number) => (
      <Answer
        i={i}
        answer={answer}
        count={answersArr.length}
        key={`answer_${answer.id}`}
        post_id={this.props.post_id}
        sortHandler={(id1, dir) => {
          const id2 = answersArr.filter(answ => answ.order === answers[id1].order + dir)[0].id;
          this.props.dispatch(
            change(
              form,
              'answers',
              {
              ...all_answers,
              ...this.sort(id1, id2),
              }
            ),
          );
        }}
        updateHandler={(item) => {
          this.props.dispatch(
            change(
              form,
              'answers',
              {
                ...all_answers,
                [item.id]: {
                  ...answers[item.id],
                  ...item,
              },
            }),
          );
        }}
        removeHandler={(id) => {
          this.removeAnswer(id);
        }}
      />
    ));
    return (
      <div className="answers-list ui segment">
        <h3>Ответы</h3>
        {answersList}
        <span
          role="button"
          className="ui button blue addAnswer"
          onClick={() => {
            this.addAnswer();
          }}
        >
          Добавить ответ
        </span>
      </div>
    );
  }
}

type AnswerProps = {
  answer: {
    id: string,
    image: string,
    description: string,
  },
  isEdited: boolean,
  removeHandler: (string) => void,
  sortHandler: (string, number) => void,
  updateHandler: (Object) => void,
  i: number,
  count: number,
};

type AnswerState = {
  isEdited: boolean,
  adescription: Object,
  aname?: Object,
  image?: string,
};

const displayHtml = (html: string) => (<div className="ui basic segment" dangerouslySetInnerHTML={{ __html: html }} />);

@connect((state, props) => ({
  ...props,
  isEdited: !!(state.test.qid === props.answer.qid && state.test.expand),
}))
class Answer extends Component<AnswerProps, AnswerState> {
  constructor(props: AnswerProps) {
    super(props);
    this.state = {
      isEdited: false,
      adescription: props.answer.description || '',
      aname: props.answer.name || '',
    };

    this.saveAnswer = this.saveAnswer.bind(this);
    this.removeAnswer = this.removeAnswer.bind(this);
    this.handleClickEdit = this.handleClickEdit.bind(this);

    this.onChangeDescription = this.onChangeDescription.bind(this);
    this.onChangeAnswerName = this.onChangeAnswerName.bind(this);
  }
  state: AnswerState;

  componentWillReceiveProps(props) {
    if (props.answer.image !== this.props.answer.image) {
      this.setState({ image: this.props.answer.image });
    }
    if (props.isEdited !== this.props.isEdited && typeof props.isEdited !== 'undefined') {
      this.setState({ isEdited: props.isEdited });
    }
  }

  onChangeDescription(adescription: Object) {
    this.setState({ adescription });
  }
  onChangeDescription: Object => void;

  onChangeAnswerName(e: SyntheticInputEvent) {
    this.setState({ aname: e.target.value });
  }
  onChangeAnswerName: () => void;


  getValues() {
    const refs = this.refs;
    const { adescription, aname } = this.state;
    return {
      name: aname,
      description: adescription.toString('html').replace(/<a /g, '<a target="_blank" '),
      points: +refs.apoints.value,
    };
  }
  getValues: () => Object;

  /* eslint-disable no-alert */
  removeAnswer() {
    if (confirm('Вы уверены, что хотите удалить этот ответ?')) {
      this.props.removeHandler(this.props.answer.id);
    }
  }
  /* eslint-enable no-alert */
  removeAnswer: () => void;

  sortAnswer(direction: number) {
    this.props.sortHandler(this.props.answer.id, direction);
  }
  sortAnswer: number => void;

  saveAnswer() {
    this.setState({ isEdited: false });
    this.props.updateHandler({
      ...this.props.answer,
      ...this.getValues(),
    });
  }
  saveAnswer: () => void;

  handleImage(image: string) {
    this.props.updateHandler({
      ...this.props.answer,
      image,
    });
  }
  handleImage: string => void;

  handleClickEdit() {
    this.setState({ isEdited: true });
  }
  handleClickEdit: () => void;

  render() {
    const { answer: { qid, id, name, image, description, points, order }, count, i } = this.props;
    return (
      <div className="ui form segment answer">
        <div style={{ textAlign: 'right' }}>
          {count === order + 1 ? null : (
            <div
              role="button"
              className="moveDown ui icon small button right floated"
              onClick={() => this.sortAnswer(1)}
            >
              <i className="angle down icon" />
            </div>
          )}
          {order === 0 ? null : (
            <div
              role="button"
              className="moveUp ui icon small button right floated"
              onClick={() => this.sortAnswer(-1)}
            >
              <i className="angle up icon" />
            </div>
          )}
          <span
            className="red ui icon small button right floated answer__remove"
            onClick={this.removeAnswer}
          >
            <i className="remove icon" />
          </span>
          {this.state.isEdited ? (
            <span
              role="button"
              className="red ui icon small button right floated answer__save"
              onClick={this.saveAnswer}
            >
              <i className="save down icon" />
            </span>
          ) : (
            <span
              role="button"
              className="green ui icon small button right floated answer__edit"
              onClick={this.handleClickEdit}
            >
              <i className="edit down icon" />
            </span>
          )}
        </div>
        <div className="field answer__field">
          <label>
            Ответ <b>{i + 1}</b>
          </label>
          {
            this.state.isEdited
            ? (<Textarea
                minRows={3}
                value={this.state.aname}
                onChange={this.onChangeAnswerName}
              />)
            : displayHtml(name)
          }
        </div>
        <div className="field answer__field">
          <label>Пояснение</label>
          {this.state.isEdited
            ? (<ReactQuill
              theme={'snow'}
              className={QuillClass}
              style={QuillContainerStyles}
              onChange={this.onChangeDescription}
              defaultValue={description || ''}
              formats={quillFormats}
              modules={quillModules}
              bounds={'.quill'}
            />)
            : displayHtml(description)}
        </div>
        <div className="field required answer__field">
          <label>Баллы за ответ (0 - неправильный) </label>
          {this.state.isEdited ? (
            <input
              type="number"
              required
              className="test__textarea test__textarea_expand"
              ref="apoints"
              defaultValue={points || 0}
            />
          ) : displayHtml(points || 0)}
        </div>
        <div className="field answer__field">
          <label htmlFor="">Картинка:</label>
          <SingleFileUploader
            handler={img => this.handleImage(img)}
            file={this.props.answer.image}
            accepted='image/*'
          />
        </div>
      </div>
    );
  }
}


export default AnswerContainer;
