// @flow
import React from 'react';
import {
  Container,
} from './styles';
import {
  Paragraph,
  Counter,
} from '/ui/atoms';

type Props = {|
  count: number,
|}

const ContextWidgetHeader = ({ count = 0 }: Props) => (
  <Container>
    <Paragraph>
      <b>Контекст</b>
    </Paragraph>
    <Counter
      count={count}
    />
  </Container>
);

export default ContextWidgetHeader;