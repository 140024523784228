import { change, initialize, reset } from 'redux-form';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';

import io from '../io';
import { store } from './store';
import * as actions from '../actions/actions';
import syncInfo from '../../common/sync-channel';

const throttleSend = {};

io.on(
  syncInfo.model_update_event,
  ({
    entity,
    id,
    field,
    value,
    clientId,
    transactionId,
  }) => {
    const changeEvent = change(syncInfo.formFunc(entity, id), field, value);
    changeEvent.meta.share = true;
    changeEvent.meta.clientId = clientId;
    changeEvent.meta.transactionId = transactionId;
    console.log('dispatching change: ', changeEvent);
    store.dispatch(changeEvent);
  },
);

io.on(
  syncInfo.model_get_event,
  ({
    entity,
    id,
    data,
  }) => {
    store.dispatch(initialize(syncInfo.formFunc(entity, id), data));
    // store.dispatch(reset(syncInfo.formFunc(entity, id)));
  },
);

io.on(
  syncInfo.set_users_in_room,
  ({
    users,
    entity,
    id,
  }) => {
    if (entity == 'post') {
      const state = store.getState();
      const me = users.filter((user) => user.id == state.user.userId);
      if (!me.length) {
        io.emit(syncInfo.me_in_room_event, { entity, id });
      }
      store.dispatch(actions.setUsersInRoom(users));
    }
  },
);

io.on(
  syncInfo.collection_sync_event,
  ({
    data,
    entity,
    id,
  }) => {
    console.log('sync collection');
    store.dispatch(actions.setItemsInSyncCollection(syncInfo.formFunc(entity, id), data));
  },
);

io.on(
  syncInfo.collection_add_event,
  ({
    entity,
    id,
    itemId,
    value,
    clientId,
    transactionId,
  }) => {
    console.log('sync collection');
    if (clientId == window.windowId) {
      store.dispatch(
        actions.updateTransactionId(
          transactionId,
          true, // action from server
        ),
      );
      if (entity == 'events') {
        store.dispatch(
          actions.setEventEditMode(
            itemId,
            true,
          ),
        );
      }
    }

    if (entity == 'events' && itemId) {
      store.dispatch(actions.addEventOnClient(itemId));
    }
    store.dispatch(actions.addItemToSyncCollection(syncInfo.formFunc(entity, id), value));
  },
);

io.on(
  syncInfo.collection_operation_event,
  ({ transactionId, clientId }) => {
    if (transactionId && clientId == window.windowId) {
      store.dispatch(
        actions.updateTransactionId(
          transactionId,
          true, // action from server
        ),
      );
    }
  },
);

io.on(
  syncInfo.collection_remove_event,
  ({ entity, id, itemId }) => {
    console.log('sync collection');
    store.dispatch(actions.removeItemFromSyncCollection(syncInfo.formFunc(entity, id), itemId));
  },
);

io.on(
  syncInfo.collection_map_event,
  ({
    entity,
    id,
    itemId,
    value,
    clientId,
    transactionId,
    type,
  }) => {
    console.log('sync collection');
    if (transactionId && clientId == window.windowId) {
      store.dispatch(
        actions.updateTransactionId(
          transactionId,
          true, // action from server
        ),
      );
    }

    if (entity != 'events'
    || clientId != window.windowId
    || type == actions.PUBLISH_EVENT
    || type == 'is_major'
    ) {
      store.dispatch(actions.addItemToSyncCollection(syncInfo.formFunc(entity, id), value));
    }
  },
);

const fieldsFocuses = {};

const routerReduxFormChange = (store) => (next) => (action) => {
  const { dispatch } = store;

  if (action.type === actions.FIELD_FOCUSED) {
    const FOCUS_TIMEOUT = 30 * 1000;
    const fieldKey = `${action.entity}_${action.id}_${action.field}`;

    if (fieldsFocuses[fieldKey]) clearTimeout(fieldsFocuses[fieldKey]);

    fieldsFocuses[fieldKey] = setTimeout(() => {
      store.dispatch(actions.fieldBlured(action, false));
      delete fieldsFocuses[fieldKey];
    }, FOCUS_TIMEOUT);
  }
  // TODO: refactor to "unsync_" prefix
  if (action.type === '@@redux-form/CHANGE' && action.meta.form != 'auth-form' && action.meta.form != 'tranformPostTypeForm') {
    const state = store.getState();

    const itsMyLocalChange = !action.meta.share && !action.meta.clientId;
    const itsMyReturnedChange = action.meta.clientId == window.windowId;
    const itsActionFromServer = action.meta.share && itsMyReturnedChange;

    if (!action.meta.transactionId) {
      action.meta.transactionId = syncInfo.generateTransactionId(window.windowId);
    }

    if (itsMyReturnedChange || itsMyLocalChange) {
      dispatch(
        actions.updateTransactionId(
          action.meta.transactionId,
          itsActionFromServer,
        ),
      );
    }

    if (itsMyLocalChange) {
      next(action);
      const { entity, id } = syncInfo.formDestructor(action.meta.form);

      const dataToSend = {
        entity,
        id,
        field: action.meta.field,
        value: action.payload,
        clientId: window.windowId,
        transactionId: action.meta.transactionId,
      };

      if (entity && id) {
        if (!throttleSend[action.meta.field]) {
          if (action.meta.field == 'lead' || action.meta.field == 'body') {
            throttleSend[action.meta.field] = debounce(
              io.emit.bind(io),
              400,
              { maxWait: 30000 },
            );
          } else {
            throttleSend[action.meta.field] = throttle(
              io.emit.bind(io),
              400,
            );
          }
        }

        if (entity == 'event') {
          const [_id, _itemId] = id.split('#');

          const value = {};
          value[action.meta.field] = action.payload;

          // send collection#set event
          throttleSend[action.meta.field](syncInfo.collection_operation_event, {
            operation: 'set',
            entity: 'events',
            id: _id,
            itemId: _itemId,
            value,
            clientId: window.windowId,
            transactionId: action.meta.transactionId,
          });

          dispatch(
            actions.updateItemInSyncCollection(
              syncInfo.formFunc('events', _id),
              _itemId,
              value,
            ),
          );
        } else {
          throttleSend[action.meta.field](syncInfo.update_event, dataToSend);
        }
      } else {
        console.error('no entity or id for sending data', JSON.stringify(dataToSend));
      }
    }

    if (itsMyReturnedChange) {
      return;
    }
  }

  next(action);
};

export default routerReduxFormChange;
