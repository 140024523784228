import * as actions from '../../actions/actions';

const addUser = (users, userToAdd) => {
    const alreadyIn = users.filter((user) => user == userToAdd).length > 0;
    if (alreadyIn) {
        return users;
    }
    else {
        return [userToAdd].concat(users);
    }
};

const removeUser = (users, userToRemove) => {
    return users.filter((user) => user != userToRemove);
}

export const focusReducer = (state = {}, action = {}) => {

    let entityPath, usersPath, usersIn;

    switch (action.type) {
        case actions.FIELD_FOCUSED:

            if (action.meta.remote) {
                return state;
            }

            entityPath = `${action.entity}#${action.id}`;
            usersPath = `${action.field}`;
            usersIn = (state[entityPath] || {})[usersPath] || [];

            return {
                ...state,
                [entityPath]: {
                    ...state[entityPath],
                    [action.field]: addUser(usersIn, action.user)
                }
            };
        case actions.FIELD_BLURED:

            if (action.meta.remote) {
                return state;
            }

            entityPath = `${action.entity}#${action.id}`;
            usersPath = `${action.field}`;
            usersIn = (state[entityPath] || {})[usersPath] || [];

            return {
                ...state,
                [`${action.entity}#${action.id}`]: {
                    ...state[`${action.entity}#${action.id}`],
                    [action.field]: removeUser(usersIn, action.user)
                }
            };
        default:
            return state;
    }

};

export default focusReducer;
