export const types = {
	'articles': 'Статьи'
}

export const maxTimeDisconnect = 7000;
export const keyNotification = 'admin_mz_disconnect';
export const REGION_RU = 'ru';
export const REGION_CA = 'ca';
export const REGION_BY = 'by';
export const REGIONS = {
  ru: REGION_RU,
  ca: REGION_CA,
  by: REGION_BY,
};

export const settings = {
	"public": {
		"environment": "dev",
			"publicSite": "http://95.213.204.192:4000",
			"previewUrl": "/preview",
			"imageSource": "https://s3-eu-central-1.amazonaws.com/mz.images/",
			"imageTypes": {
			"entry": [
				{"w": 240, "h": 150},
				{"w": 360, "h": 220},
				{"w": 470, "h": 290},
				{"w": 630, "h": 385},
				{"w": 810, "h": 500},
				{"w": 970, "h": 590},
				{"w": 1400, "h": 470},
				{"w": 1400, "h": 850}
			]
		},
		"searchQueryLimit": 20,
			"allowedRelationOptions": {
			"indexPage": [
				{
					"key": "feature",
					"title": "Фичеры"
				}
			]
		},
		"iframelyApiKey": "fba778db35e06c16e60e4c"
	},
	"postQueryLimit": 100,
	"AWS": {
		"region": "eu-central-1",
			"accessKeyId": "AKIAJXUKNCXC6FXCARAA",
			"secretAccessKey": "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",
			"bucket": "mz.images",
			"ACL": "public-read"
	},
	"GoogleAPI": {
		"updateTimeoutInMinutes": 15,
			"type": "service_account",
			"project_id": "mediazona-1270",
			"private_key_id": "6681b98629e2cd53b74bb84923bc8a036a447940",
			"private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDxsHPCFrdSotX8\nDd+V0K4lZsXNbQ8wg+HG1HwVxTdAgCIw2KBwoAiYrD+o2z32wsVsQ8WHg5+kMwN+\njGOCi/riyjeUj+1nzWYo9K/NAWcKbFEWybtsofOGvggR8/opsm3jcT09yDsNgH5g\n20LbCw707cb1a3+W1J/qwFwtr1JQ0qDaywWhqNk6hrvOa14r4+H2rieSxs3l5Blr\nQu3CC9u24DnwnjgKM7JBM/Xm+WkiQdvzngJF43Jv13+mVT6U7ItmtdKzjtodjPJr\nNe4/cWs/Ly7VB/OUYSi7rGZtLMmNpHxcE5ihJNJgmJ2syOBMMekbnbFM0iZFifhw\nWa6ciwOvAgMBAAECggEAHpr0nbBYuJssM8Xh+Uu6sbvI8pGL6d3Zel8v82XM8FsJ\neVwFPR3W2NZ/HTfeKeBSNbqDSfzN+3IGUEEg3MgzFgL7dNGhA5MMC8WoeXJjBPvg\nbN1Q4ZnEh9yKkxNtK1iP98xAMBGqALSzzDoXxjspQrTVjAB34La+JXEEeziHWPqE\nKqSmHe4v+3IWojZfu6SRTIybonrac/qSJBNbZStrN8IOtmk2G5VfGfwigLdBIRyZ\nUPweMTF9PWQNIH/gZzkD7u/PNZmU4GoMdQrOzNDkL8NY9VYQP7V3smgSRRn7JJT5\n8tdt94eelF4PwPP1QJSYd1dwySgi1EzETt5mD+cQqQKBgQD5HXohiSJ7Wfzl64Xo\nFIm86rGvd62GRN820WZAW/G7+y+zDty8tNUR46u/v0oOumGbS0vjFM6FyMf5pIuB\nzM2ZCsWHPoC1A6juy5O6iU15FPb5HpXJuia9eh5RDaOfm4DvkVQjXn27hgvODU7t\nYCNFnaVeKNyveqKy/kjktdE3qwKBgQD4Xm+fQhEG7jOVp0THzf0MzCK2wOrrL3hf\nex96RNrIbjF0BRP7UwqT7Ej9pdOCvhI2Lq1klowyXuZ7GdlBqv0ClhAARS/gf1F2\nri9swmVvKwFPgrb3HbMUjH1f1S3JiAVtRZmlOpVhMhMF4e4NEFaKsWUeV7kBEQ/o\nsxmsvdGQDQKBgQCxSfIuRbt9kBh0xP6CoYM5KMoV95VOFlvJVpNvwD+0qdpTk4My\n5XsyBRapGwkonCp068xxKtCj07XGC/2g/dFgVpMnYisVKCqk78sOGUNCpdHyj3rI\nmyuQ84T56DwHjhLf7Ey18zyGJarnFP+T3jScCcqzzV7pn9yEiIX2BxODiwKBgAhE\n7KZ6xqJab/E9nnagjxpL+aPoDGpELFfRe5zQihA+Svs36BBoFWQTa+K2ELK7rVOM\nfk1pDcXnjvjuRWOAU+gwItw/cXaiBo5TAlU2q7Xl0qdZDMpxfLLkcpAsQtgA5V1c\nAd+UccDIsAV0Hj7lGlyHx3wZI5m7fbIbtHEh6MZJAoGARQlJ42uMNrkzfMFUkB8W\nMTR/NOJ2uMHY9aIfljYpY8oI4AvP12W/vDyQAT3gkw2YZn21Om60eDP/EwmxAmHW\nBYFHviCGKXWRlMqUiyJwfGfdMNP7BXz9eCJSv0t9GLajat3vfimQox6XMw08Ckt5\ntQJKM1L+RMZzlf8/fAc2tEA=\n-----END PRIVATE KEY-----\n",
			"client_email": "mediazona-server@mediazona-1270.iam.gserviceaccount.com",
			"client_id": "105097630619305307551",
			"auth_uri": "https://accounts.google.com/o/oauth2/auth",
			"token_uri": "https://accounts.google.com/o/oauth2/token",
			"auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
			"client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/mediazona-server%40mediazona-1270.iam.gserviceaccount.com"
	},
	"resizeShPath": "/Users/neoromantic/Projects/mediazona/resize.sh",
	"socialShPath": "/Users/neoromantic/Projects/mediazona/socialpic.sh",
	"socialShareAssetsPath": "/Users/neoromantic/Projects/mediazona/server/socialShare/",
	"tmpPath": "/Users/neoromantic/Projects/mediazona/tmp"
}
