import React, { Component } from "react";
import {
  SelectItem,
  ListItems,
  ListBox
} from '../index';
import {
  DownTriangle
} from '../icons';

export default class Select extends Component {
  state = {
    isOpen: false,
    currentOption: null
  };

  selectRef = null;

  initCurrentOption = () => {
    let currentOption;
    const options = this.props.options;
    if(options.length > 0) {
      if(this.props.currentOption) {
        currentOption = this.props.currentOption
      } else {
        currentOption = options[0];
      }
    }
    return currentOption;
  };

  componentWillReceiveProps(nextProps) {
    if(nextProps.currentOption !== this.state.currentOption) {
      this.setState(() => ({
        currentOption: nextProps.currentOption
      }));
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleOutsideClick, false);
    const currentOption = this.initCurrentOption();
    if(!currentOption) throw new Error("options is required params");
    this.setState(() => ({
      currentOption: currentOption
    }));
    this.props.onChange(currentOption);
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleOutsideClick, false);
  }

  handleOutsideClick = (e) => {
    if (this.selectRef && !this.selectRef.contains(e.target)) {
      this.setState(() => ({
        isOpen: false
      }));
    }
  };

  handleSelectClick = (event) => {
    event.preventDefault();
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  handleItemClick = (option) => (event) => {
    event.preventDefault();
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
      currentOption: option
    }));
    this.props.onChange(option);
  };

  render() {
    const { options, className } = this.props;

    if(!this.state.currentOption) return null;

    const items = options.map((option) => (
      <li
        key={option.value}
        id={option.value}
        onMouseDown={this.handleItemClick(option)}
      >
        {option.label}
      </li>
    ));

    return (
      <ListBox
        innerRef={ref => this.selectRef = ref}
        className={className}
      >
        <SelectItem
          onMouseDown={this.handleSelectClick}
        >
          <li>{this.state.currentOption.label}</li>
          <DownTriangle />
        </SelectItem>
        <ListItems isOpen={this.state.isOpen}>
          {items}
        </ListItems>
      </ListBox>
    );
  }
}