/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import deepEqueal from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';
import { sanitizeFromSpaces } from '../../utils/postForm';

const blackList = {
  createdAt: 1,
  updatedAt: 1,
  current_version: 1,
  published_version: 1,
  snapshots: 1,
  urls: 1,
  is_feature: 1,
  is_slate_rich_text: 1,
  is_old_lead: 1,
  is_old_editor: 1,
  context_title: 1,
  partner_id: 1,
  imageShare: 1,
  imageEntry: 1,
  view_count: 1,
  requireEditorRole: 1,
  requireAdminRole: 1,
  language: 1,
  uuid: 1,
};

const isEqualVersions = (currentVersion = {}, publishedVersion = {}) => {
  for (const prop in currentVersion) {
    // eslint-disable-next-line no-param-reassign
    currentVersion[prop] = sanitizeFromSpaces(currentVersion[prop]);
  }

  for (const prop in publishedVersion) {
    // eslint-disable-next-line no-param-reassign
    publishedVersion[prop] = sanitizeFromSpaces(publishedVersion[prop]);
  }

  if (publishedVersion) {
    const diff = omitBy(currentVersion, (v, k) => {
      if (k in blackList) return true;

      return deepEqueal(publishedVersion[k], v);
    });
    return Object.keys(diff).length === 0;
  }

  return false;
};

module.exports = {
  isEqualVersions,
};
