import React from 'react';
import {
  nodeTypes,
  objectTypes,
} from '../../consts';

const Summary = {
  type: nodeTypes.SUMMARY,
  object: objectTypes.BLOCK,
  isTag: true,

  create() {
    return {
      type: this.type,
      object: this.object,
      nodes: [
        {
          object: objectTypes.TEXT,
        },
      ],
    };
  },

  htmlRender({ children }) {
    return <summary>{children}</summary>;
  },
};

export default Summary;
