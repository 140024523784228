import React from 'react';
import { TopToolBar } from '../features/TopToolBar';

const renderEditor = (toolbarOptions) => (props, editor, next) => {
  const children = next();
  return (
    <>
      <TopToolBar
        editor={editor}
        options={toolbarOptions}
      />
      {children}
    </>
  );
};

export default function topToolBar({ toolbarOptions }) {
  return {
    renderEditor: renderEditor(toolbarOptions),
  };
}
