import styled from 'react-emotion';

export default styled('div')`
  display: inline-block;
  font-weight: bold;
  margin-right: 1rem;

  .ResetFilter__filter-text{
    font-weight: bold;
  }

  a {
    .resetDateFilter {
      font-size: 14px;
    }
  }
`;

export const ResetDateFilter = styled('i')`
  font-size: 14px;
`;

export const FilteredText = styled('span')`
  font-weight: bold;
`;
