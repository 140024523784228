import React, { useState } from 'react';
import {
  SimpleRichText,
} from '/ui/organism';

const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video',
];

const modules = {
  toolbar: [
    [{ header: '2' }],
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' },
    ]],
};

const SimpleRichTextSummary = ({ value, form, onSubmit }) => {
  const [summary, handleSummary] = useState('');

  return (
    <div className="ui segment">
      <SimpleRichText
        key="summary"
        value={summary || value}
        onChange={(v) => handleSummary(v)}
        formats={formats}
        modules={modules}
        style={{
				  height: 'auto',
          backgroundColor: 'white',
          overflow: 'scroll',
          border: '1px solid rgba(34, 36, 38, 0.15)',
          minHeight: '220px',
          height: '220px',
        }}
      />
      <button
        className="ui submit button"
        style={{ marginTop: '16px' }}
        type="submit"
        onClick={() => onSubmit(form, summary)}
      >
        Применить
      </button>
    </div>
  );
};

export default SimpleRichTextSummary;
