import React from 'react';
import {
  FormatBarButton,
} from '../../atoms';
import {
  Icons,
  ModuleContainer
} from '../../../../atoms';

const {
  ClearFormat
} = Icons;

const onClearMark = editor => (event) => {
  event.preventDefault();
  const { value } = editor;

  for (const mark of value.marks.values()) {
    editor.removeMark(mark);
  }
};

const ClearFormatToolModule = ({ editor, enabled }) => (
  <ModuleContainer>
    <FormatBarButton
      onMouseDown={onClearMark(editor)}
      enabled={enabled}
    >
      <ClearFormat />
    </FormatBarButton>
  </ModuleContainer>
);

export default ClearFormatToolModule;
