import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
// import RichText from '../RichText/RichText.js';
import RichText from "/components/SlateRichText/SlateRichText";
import {reduxForm, getFormValues, formValueSelector, Field} from "redux-form";
import {editCard} from '../../actions/actions';

@connect(( state, props ) => {
    const formValues = getFormValues(props.form)(state);
    if (state.cards[props.card.id]){
        console.log(state.cards && state.cards[props.card.id] && state.cards[props.card.id].isEdited);
    }
    return {
        formValues,
        isEdited: state.cards && state.cards[props.card.id] && state.cards[props.card.id].isEdited,
        initialValues: {
            ...props.card
        }
    }
})
@reduxForm({
})

export default class Card extends PureComponent {
    constructor(props){
        super(props);
        this.state = {
            'isEdited': false
        };
    }

    componentWillReceiveProps(props){
        console.log('Props = ',props);
        if (props.isEdited && typeof(props.isEdited)!=='undefined'){
            this.setState({isEdited: props.isEdited});
        }
    }

    remove(id){
        if (confirm("Вы уверены, что хотите удалить данную карточку?"))
        {
            this.props.remove(id);
        }
    }

    edit(){
        this.setState({isEdited: true});
        this.props.dispatch(editCard({cardId: this.props.card.id, isEdited: true}));
    }

    save(){
        this.setState({isEdited: false});
        this.props.dispatch(editCard({cardId: this.props.card.id, isEdited: false}));
        this.props.updateHandler({
             ...this.props.card,
             ...this.props.formValues,
             order: this.props.card.order
        });
    }

    move(dir){
        this.props.sortHandler(this.props.card.id, dir);
    }

    render(){
        const {card, form, count, formValues, pristine} = this.props;
        if (!formValues || !formValues.id) return null;
        const {title, slug, body} = formValues;
        const { order } = card;
        return (
            <form className="ui form segment">
                <div className="field card__field">
                    <div className="ui blue ribbon label">
                        Карточка <b>{order + 1}</b>
                    </div>
                    { (count == order + 1) ?
                        null
                        :
                        <div className="moveDown ui icon small button right floated" onClick={()=>{this.move(1);}}>
                            <i className="angle down icon"></i>
                        </div>
                    }
                    {(order == 0) ?
                        null
                        :
                        <div className="moveUp ui icon small button right floated" onClick={()=>{this.move(-1);}}>
                            <i className="angle up icon"></i>
                        </div>
                    }
                    <span className="ui icon right floated red small button"
                          onClick={() => {this.remove(card.id);}}>
                        <i className="remove icon"></i>
                    </span>
                    { (this.state.isEdited) ?
                        <span className="ui icon right floated red small button question__save" onClick={()=> {this.save();}}>
                        <i className="save icon"></i>
                    </span>
                        :
                        <span className="ui icon right floated green small button question__edit" onClick={()=> {this.edit();}}>
                        <i className="edit icon"></i>
                    </span>
                    }
                    <div className="field">
                        <label>
                            Название
                        </label>
                        {this.state.isEdited ?
                            <Field name="title"
                                   component="input"
                                   type="text"
                                   label="Название карточки"
                                   ref = {(input) => { this.title = input; }}
                            />
                            :
                            <div className="ui basic segment">{title ? title : 'Отсутствует'}</div>
                        }
                    </div>
                    <div className="field">
                        <label>
                            url для карточки
                        </label>
                        {this.state.isEdited ?
                            <Field name="slug"
                                   component="input"
                                   type="text"
                                   label="часть урла,которая ссылается на текущую карточку"
                                   ref = {(input) => { this.slug = input; }}
                            />
                            :
                            <div className="ui basic segment">{slug ? slug : 'Отсутствует'}</div>
                        }

                    </div>
                    <div className="field">
                        <label>
                            Содержание
                        </label>
                        {(this.state.isEdited)?
                            <div className="ckeditor" name="body">
                                <Field name="body"
                                       component={RichText}
                                       key={"card-body-"+card.id}
                                       formKey={"card-body-"+card.id}
                                       ref = {(input) => { this.body = input; }}/>
                            </div>
                            :
                            <div className="ui basic segment"  dangerouslySetInnerHTML={{__html: (body ? body : 'Пусто')}}>
                            </div>
                        }
                    </div>
                    {this.state.isEdited && (
                        <div className="field">
                            <span className={"ui button addQuestion "+(pristine?"":"blue")}
                                  onClick={(e) => {this.save();}}>Сохранить карточку
                            </span>
                        </div>
                    )}
                </div>
            </form>
        );
    }
}
