import React from 'react';
import {
  Quote,
} from './components';
import PullQuoteController from '../PullQuote/components/PullQuoteController';
import {
  nodeTypes,
  objectTypes,
} from '../../consts';
import {
  BlockPlaceholder,
} from '../../molecules';

export const QuoteSourceNode = {
  type: nodeTypes.QUOTE_SOURCE,
  object: objectTypes.BLOCK,

  create({ editor }) {
    return {
      type: this.type,
      object: this.object,
      nodes: [
        editor.node.Paragraph(),
      ],
    };
  },

  schema: {
    nodes: [
      {
        type: nodeTypes.PARAGRAPH,
        min: 1,
      },
    ],
  },

  htmlRender(attrs) {
    const { children, attributes } = attrs;

    return (
      <div className="mz-publish-quote__source" {...attributes}>
        {children}
      </div>
    );
  },

  editorRender(attrs) {
    const { node, attributes } = attrs;

    return (
      <h3 {...attributes}>
        <BlockPlaceholder
          text={node.text}
          placeholder={'Заголовок...'}
          {...attrs}
        />
      </h3>
    );
  },
};

export const QuoteDescriptionNode = {
  type: nodeTypes.QUOTE_DESCRIPTION,
  object: objectTypes.BLOCK,

  create({ editor }) {
    return {
      type: this.type,
      object: this.object,
      nodes: [
        editor.node.Paragraph(),
      ],
    };
  },

  schema: {
    nodes: [
      {
        type: nodeTypes.PARAGRAPH,
        min: 1,
      },
    ],
  },

  htmlRender(attrs) {
    const { children, attributes } = attrs;

    return (
      <div className="mz-publish-quote__description" {...attributes}>
        {children}
      </div>
    );
  },

  editorRender(attrs) {
    const { node, attributes } = attrs;

    return (
      <p className="mz-publish-quote__description" {...attributes}>
        <BlockPlaceholder
          text={node.text}
          placeholder={'Текст...'}
          {...attrs}
        />
      </p>
    );
  },
};

export const QuoteNode = {
  type: nodeTypes.QUOTE,
  object: objectTypes.BLOCK,

  create({ imgSrc, alt, nodes, source }) {
    return {
      type: this.type,
      object: this.object,
      data: {
        imgSrc,
        alt,
        source,
      },
      nodes,
    };
  },

  htmlRender(attrs) {
    return (
      <Quote
        {...attrs}
      />
    );
  },

  editorRender(attrs) {
    const { node, attributes, children } = attrs;
    return (
      <PullQuoteController
        {...attrs}
      >
        <Quote
          attributes={attributes}
          node={node}
        >
          {children}
        </Quote>
      </PullQuoteController>
    );
  },
};
