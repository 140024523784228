import React from 'react';
import PostsFeed from '/features/Feed';
import DateFilter from '/features/DateFilter';
import Search from '/features/Search';

export default function App({ params }) {
  return (<div className="ui container">
    <div className="ui vertically padded grid">
      <div className="column">
        <Search type={params.type} />
        <DateFilter params={params} />
        <PostsFeed
          key={`posts-feed-${params.type}`}
          type={params.type}
          year={params.year}
          month={params.month}
          day={params.day}
        />
      </div>
    </div>
  </div>);
}
