export default {
  ru: {
    faviconUrl: 'https://zona.media/s/favicon/mz/apple-icon-120x120.png',
    timezone: 'Europe/Moscow',
    timzoneShort: 'MSK',
  },
  ca: {
    faviconUrl: 'https://mediazona.ca/s/favicon/mz_ca/apple-icon-120x120.png',
    timezone: 'Asia/Almaty',
    timzoneShort: 'ALMT',
  },
  by: {
    faviconUrl: 'https://mediazonaby.com/s/favicon/mz_by/apple-icon-120x120.png',
    timezone: 'Europe/Minsk',
    timzoneShort: 'Minsk',
  },
};
