/* @flow */
import React from 'react';
import { Range } from 'slate';
import type { Editor as SlateEditor } from 'slate-react';
import {
  ToolTip,
} from '/components/SlateRichText/molecules';
import {
  FormatBarButton
} from '../../atoms';
import {
  Icons,
  ModuleContainer,
} from '../../../../atoms';
import Editor from '../../../../Editor';

const {
  AddLink
} = Icons;

type LinksToolModuleProps = {
  editor: SlateEditor,
  enabled: Boolean,
}

class LinksToolModule extends React.Component<LinksToolModuleProps> {
  addLink = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const { editor } = this.props;
    const { value } = editor;
    const { selection, fragment } = value;

    const isEmptySelection = selection.isBlurred || selection.isCollapsed || fragment.text === '';

    if (isEmptySelection || fragment.nodes.size > 1) return;

    const range = new Range({
      anchor: selection.anchor,
      focus: selection.focus,
    });

    editor.wrapInlineAtRange(range, Editor.node.Link({ isCreated: true }));
  };

  render() {
    const type = Editor.NODE.LINK;
    const { enabled } = this.props;

    return (
      <ModuleContainer>
        <FormatBarButton
          onMouseDown={enabled ? this.addLink : null}
          data-for={type}
          data-tip
          enabled={enabled}
        >
          <AddLink />
          <ToolTip
            id={type}
            tooltip={Editor.TOOLTIPS.LINK}
          />
        </FormatBarButton>
      </ModuleContainer>
    );
  }
}

export default LinksToolModule;
