import styled from 'react-emotion';

export const Container = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
`;

export const Item = styled('div')`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: white;
  font-family: 'Graphik LC', sans-serif !important;
  font-size: 14px;
  padding: 20px 0;

  border-bottom: 1px solid #E9EAEC;
`;

export const TitleContainer = styled('div')`
  display: flex;
  margin-left: 20px;
  margin-right: 25px;
  width: 70%;
`;

export const TypeContainer = styled('div')`
  width: 20%;
  margin-left: 30px;
`;