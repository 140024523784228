import regionConfig from './regionConfig';

module.exports = {
  publicUrl: window.SERVER_SIDE_VARS.public_url || PUBLIC_URL || 'http://localhost:5300',
  adminUrl: window.SERVER_SIDE_VARS.admin_url || ADMIN_URL || 'http://localhost:5000/',
  REGION: window.SERVER_SIDE_VARS.REGION || 'ru',
  regionData: regionConfig[window.SERVER_SIDE_VARS.REGION || 'ru'],
  sentryUrl: window.SERVER_SIDE_VARS.SENTRY_URL,
  sentryClientUrl: window.SERVER_SIDE_VARS.SENTRY_CLIENT_URL,
  appSearchEngine: {
    engineName: window.SERVER_SIDE_VARS.ES_APP_SEARCH_ENGINE_NAME,
    endpointBase: window.SERVER_SIDE_VARS.ES_APP_SEARCH_URL,
    searchKey: window.SERVER_SIDE_VARS.ES_APP_SEARCH_PUBLIC_KEY,
  },
};
