import React from 'react';
import {
  MultiContextPullQuote,
  PullQuoteController,
} from './components';
import {
  nodeTypes,
  objectTypes,
} from '../../consts';

const MultiContextPullQuoteNode = {
  type: nodeTypes.MULTICONTEXT_PULL_QUOTE,
  object: objectTypes.BLOCK,

  schema: {
    isVoid: true,
  },

  create(attrs) {
    return {
      type: this.type,
      object: this.object,
      data: {
        multiContext: attrs.multiContext,
        contextTitle: attrs.contextTitle,
      },
    };
  },

  htmlRender(attrs) {
    return (
      <MultiContextPullQuote
        {...attrs}
      />
    );
  },

  editorRender(attrs) {
    return (
      <PullQuoteController
        {...attrs}
      >
        <MultiContextPullQuote
          {...attrs}
        />
      </PullQuoteController>
    );
  },
};

export default MultiContextPullQuoteNode;

