import React from 'react';
import styled from 'react-emotion';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`;

const Header = styled('div')`
  display: flex;
  padding-bottom: 0.21428571rem;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  margin-bottom: 14px;
`;

const Title = styled('span')`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.28em;
  border: none;
  padding: 0em 0em;
  font-family: Arial;
  font-weight: bold;
  line-height: 1.2857em;
  text-transform: none;
`;

const Subtitle = styled(Title)`
  color: gray;
`;

export default (props) => {
  const {
    title,
    subTitle,
    children,
  } = props;

  return (
    <Container>
      <Header>
        <Title>{ title }</Title>
        &nbsp;
        <Subtitle>{ subTitle }</Subtitle>
      </Header>
      { children }
    </Container>
  );
};
