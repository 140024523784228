import styled from 'react-emotion';

export const LinkFormContainer = styled('form')`
  width: 400px;
  padding: 20px;
  flex-direction: column;
  box-shadow: 0 0 50px -20px rgba(41, 48, 67, 0.74);
  background-color: #ffffff;
`;

export const LinkFormRow = styled('div')`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
  :last-of-type {
    margin-bottom: 0;
  }
`;

export const LinkFormInputTitle = styled('span')`
  font: 12px "Graphik LC", sans-serif;
  line-height: 1.67;
  color: #293043;
  min-width: 70px;
  align-self: center;
`;

export const LinkFormAccept = styled('button')`
  width: 100%;
  height: 30px;
  background-color: #00c656;
  border: none;
  font: 500 11px "Graphik LC", sans-serif;
  margin-left: auto;
  line-height: 2.73;
  letter-spacing: 1px;
  color: #ffffff;
  text-transform: uppercase;
`;

export const PreviewLinkContainer = styled('div')`
  display: flex;
  width: 400px;
  padding: 13px 20px;
  box-shadow: 0 0 50px -20px rgba(41, 48, 67, 0.74);
  background-color: #ffffff;
`;

export const PreviewLinkController = styled('div')`
  margin-left: auto;
`;

export const PreviewLinkUrl = styled('a')`
  max-width: 220px;
  font: 12px "Graphik LC", sans-serif;
  line-height: 1.67;
  color: #1b73c9;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    text-decoration: underline;
  }
`;

export const PreviewLinkButton = styled('button')`
  font: 500 12px "Graphik LC", sans-serif;
  color: ${({ color }) => color || '#1b73c9'};
  border: none;
  outline: none;
  
  &:hover {
    text-decoration: underline;
  }
`;
