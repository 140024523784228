import styled, {css} from 'react-emotion';

export CustomSelect from './CustomSelect';
export Loading from './Loading';
export * as Icons from './icons';

export const RichTextContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 300px;
  position: relative;
  background-color: #ffffff;
  border: solid 1px #e9eaec;
`;

export const ModalCloseButton = styled('button')`
  display: flex;
  padding: 5px 5px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 2px;
  border: none;
  outline: none;
  :hover {
    background-color: #e9eaec;
  }
`;

export const Toolbar = styled('div')`
  position: relative;
  height: 45px;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #eee;
`;

export const FormContainer = styled('form')`
  width: 400px;
  background-color: #ffffff;
  box-shadow: 0 30px 20px -20px rgba(41, 48, 67, 0.24);
`;

export const EmbedFormContainer = styled('form')`
  width: 589px;
  background-color: #ffffff;
`;

export const ModalContent = styled('div')`
  padding: 20px;
  padding-top: 0px;
`;

export const ModalTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F5F5F5;
  height: 45px;
  padding: 0 20px;
  h2 {
    font: 500 14px "Graphik LC", sans-serif;
    line-height: 20px;
    color: #293043;
    margin: 0;
  }
`;


export const InputTitle = styled('span')`
  font: 12px "Graphik LC", sans-serif;
  line-height: 1.67;
  color: #293043;
  display: block;
  margin-top: 10px;
  margin-bottom: 7px;
`;

export const InputError = styled('div')`
  margin: 5px 0;
  font: 12px "Graphik LC", sans-serif;
  color: #ff4b61;
`;

export const Input = styled('input')`
  height: 30px;
  width: 100%;
  padding-left: 10px;
  border: solid 1px rgb(233, 234, 236);
  background-color: rgb(255, 255, 255);
`;

export const TextArea = styled('textarea')`
  height: 90px;
  width: 100%;
  border: solid 1px rgb(233, 234, 236);
  background-color: rgb(255, 255, 255);
`;

export const ModalController = styled('div')`
  display: flex;
`;

export const WideModalController = styled('div')`
  display: flex;
  justify-content: space-between;
`;

export const ModalSubmit = styled('input')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  font: 400 14px "Graphik LC", sans-serif;
  color: #ffffff;
  line-height: 2.73;
  cursor: pointer;
  background: #2452EC;
  border-radius: 5px;
  width: 170px;
  height: 40px;
  border: none;
  margin-left: auto;
  margin-top: 30px;
`;

export const ModalLoadingSubmit = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  background: #2452EC;
  width: 170px;
  height: 40px;
  border: none;
  margin-left: auto;
`;

export const ModalCancel = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  font: 400 14px "Graphik LC", sans-serif;
  color: #000;
  line-height: 2.73;
  cursor: pointer;
  background-color: transparent;
  width: 170px;
  height: 40px;
  border: 1px solid #f1f1f1;
`;

export const SelectItem = styled('ul')`
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  background-color: white;
  padding: 0 0 0.15em 0;
  width: 100%;
  height: 28px;
  cursor: pointer;
  h2, h3 {
    margin-left: 5px;
    font: 700 11px 'Graphik LC', sans-serif;
  }
  li {
    margin-left: 5px;
    font: 500 11px 'William Text';
    line-height: 0.91;
    color: #293043;
  }
  svg {
    color: black;
    position: absolute;
    right: 8px;
    width: 8px;
    height 8px;
  }
`;

export const ListItems = styled('ul')`
  position: absolute;
  top: 0;
  display: ${props => (props.isOpen ? "block" : "none")};
  width: 100%;
  padding: 0;
  background-color: white;
  border: 1px solid #e9eaec;
  cursor: pointer;
  li {
    display: flex;
    align-items: center;
    padding-left: 9px;
    font: 500 11px 'Graphik LC', sans-serif;
    height: 40px;
    letter-spacing: 1px;
    color: #293043;
  }
  li:hover {
    background-color: #f5f5f5;
  }
`;

export const ListBox = styled('ul')`
  position: relative;
  width: 150px;
  height: 30px;
  z-index: 5;
  padding: 0;
  margin: 0;
  border: 1px solid #e9eaec;
`;

export const BlockController = styled('div')`
  border-top: 20px solid;
  border-bottom: 20px solid;
  border-left: 4px solid;
  border-right: 4px solid;
  ${({ focused, color, focusColor }) => (focused ? `border-color: ${focusColor}` : `border-color: ${color}`)};
  margin: 10px 10px;
	position: relative;
	display: block;
  float: ${({ float }) => float || 'none'};
  width: ${({ width }) => width || 'auto'};
	padding: 4px 10px;
	clear: both;
	:after { 
    content: "."; 
    visibility: hidden; 
    display: block; 
    height: 0; 
    clear: both;
  }
  :before { 
    content: "."; 
    visibility: hidden; 
    display: block; 
    height: 0; 
    clear: both;
  }
`;

export const BlockControllerBar = styled('div')`
  position: absolute;
  top: -30px;
  right: -10px;
  display: flex;
`;

export const BlockControllerButton = styled('button')`
   width: 21px;
   height: 21px;
   border: none;
   outline: none;
   display: flex;
   margin-left: 5px;
   padding: 0;
   background: none;
    svg {
      width: 100%;
      height: 100%;
    }
`;

export const PreviewContainer = styled('div')`
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 20px;
`;

export const PreviewContent = styled('div')`
  width: 100%;
  max-width: 550px;
  max-height: 270px;
  overflow-y: scroll;
  
  iframe {
    min-height: 260px;
  }
`;

export const PreviewError = styled('div')`
  color: #ab0000;
  max-width: 550px;
`;

export const CustomRadioFormContainer = styled('form')`
  display: inherit
`;

export const CustomRadioContainer = styled('label')`
  display: block;
  position: relative;
  padding-left: 21px;
  margin-right: 20px;
  cursor: pointer;
  user-select: none;
  font-family: "Graphik LC", sans-serif;
  font-size: 8px;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  :hover input ~ span {
    background-color: #ccc;
  }
  
  input:checked ~ span:after {
    display: block;
  }
`;

export const CustomRadioCheckMark = styled('span')`
  position: absolute;
  top: 0;
  left: 0;
  border: solid 1px rgba(41, 48, 67, 0.41);
  border-radius: 50%;
  width: 15px;
  height: 15px;
  :after {
    content: "";
    border-radius: 50%;
    width: 7px;
    height: 7px;
    background-color: #fd6073;
    position: absolute;
    left: 3px;
    top: 3px;
    display: none;
  }
`;

export const EmbedFormTypeContainer = styled('div')`
  display: flex;
  margin-left: 30%;
`;

export const SelectedRange = styled('mark')`
  display: inline;
  font-family: 'William Text' !important;
  padding-top: 4px;
  background-color: #d9d9d9;
`;

export const ModuleContainer = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
`;

export const TextPlaceholderContainer = styled('span')`
  position: relative;
  width: 0;
  height: 0;
  padding: 0;
  margin: 0;
`;

export const TextPlaceholderContent = styled('p')`
  font-size: ${({ fontSize }) => fontSize || 'inherit'};
  opacity: 0.4;
  position: absolute;
  padding: 0;
  margin: 0 !important;
`;
