import {
  getEmbedType
} from './index';
import Editor from '../Editor';

export default (url) => {
  if (typeof url !== 'string') return;

  if (getEmbedType(url) === Editor.EMBED.INSTAGRAM && window.instgrm) {
    window.instgrm.Embeds.process();
  }
};
