import { store as translatesStore } from '/features/Translate';

const {
  FETCH_POST_ORIGIN,
  FETCH_POST_ORIGIN_SUCCESS,
  FETCH_POST_ORIGIN_FAILURE,
} = translatesStore;

function related(state = {}, action) {
  switch (action.type) {
    case 'POST_RESET':
      return {};
    case 'RELATED_THEMES':
      return {
        ...state,
        themes: action.themes || [],
      };

    case 'SEARCH_THEMES':
      return {
        ...state,
        search_themes: action.searched || [],
      };
    case 'RELATED_CATEGORIES':
      return {
        ...state,
        categories: action.categories || [],
      };
    case 'SEARCH_CATEGORIES':
      return {
        ...state,
        search_categories: action.searched || [],
        isLoading: false,
      };
    case 'RELATED_CONTEXT':
      return {
        ...state,
        context: action.context || [],
      };
    case 'SEARCH_CONTEXT':
      return {
        ...state,
        search_context: action.searched || [],
        isLoadingRelated: false,
      };
    case 'SEARCH_CONTEXT_FOR_THEME':
      return {
        ...state,
        search_theme_context: action.searched || [],
        isLoadingRelated: false,
      };
    case 'SEARCH_EVENT_CONTEXT':
      return {
        ...state,
        search_event_context: action.searched || [],
        isLoadingRelated: false,
      };
    case 'DELETE_RELATED_THEME':
      return {
        ...state,
        themes: state.themes.filter((theme) => {
          if (theme.id !== action.id) {
            return theme;
          }
        }),
      };
    case 'UPDATE_RELATED_SEARCH_TYPES':
      return {
        ...state,
        searchRelatedTypes: [].concat(action.searchRelatedTypes),
      };
    case 'UPDATE_RELATED_SEARCH_THEMES':
      return {
        ...state,
        searchRelatedThemes: [].concat(action.searchRelatedThemes),
      };
    case 'DELETE_RELATED_TYPE':
      return {
        ...state,
        types: action.types,
      };
    case 'ADD_RELATED_TYPE':
      return {
        ...state,
        types: action.types,
      };
    case 'RECEIVE_REST_THEMES_FOR_POST':
      return {
        ...state,
        initial_themes: action.themes,
      };

    case FETCH_POST_ORIGIN:
      return {
        ...state,
        translateOrigin: null,
        translateOriginPending: true,
        translateOriginFail: '',
      };
    case FETCH_POST_ORIGIN_SUCCESS:
      return {
        ...state,
        translateOrigin: action.payload.post,
        translateOriginPending: false,
        translateOriginFail: '',
      };
    case FETCH_POST_ORIGIN_FAILURE:
      return {
        ...state,
        translateOrigin: null,
        translateOriginPending: false,
        translateOriginFail: action.error,
      };
    default:
      return state;
  }
}

export default related;
