var E = {};

E.sanitizeFromSpaces = (value)=> {
	if (typeof(value) === 'string' || value instanceof  String){
		return value.trim();
	}
	return value;
}

E.sanitizeFromNbsp = (value) => {
	return value.replace(/\&nbsp\;/g, ' ');
}

module.exports =  E;