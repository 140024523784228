import React, { Component } from 'react';
import {
  Input,
} from '/ui/atoms';
import {
  InputError,
  InputTitle,
  Loading,
  ModalCancel,
  ModalController,
  ModalLoadingSubmit,
  ModalSubmit,
} from '../../../../atoms';

class CutPullQuoteForm extends Component {
  constructor(props) {
    super(props);
    const { node, initialState, type } = props;

    const dataJSON = node?.data?.toJSON() || {};

    this.state = {
      title: (initialState && initialState.title) || dataJSON.title || '',
      isLoading: false,
      hasError: false,
      type,
    };
  }

  onChange = (fieldName) => (event) => {
    event.persist();
    event.preventDefault();
    this.setState(() => ({
      [fieldName]: event.target.value,
    }));
  };

  onSubmit = (event) => {
    event.persist();
    event.preventDefault();

    const {
      title,
      type,
    } = this.state;

    if (!title) {
      this.setState({ hasError: true });
    }

    if (title) {
      this.props.onSubmit({
        title, type,
      });
      this.props.onClose();
    }
  };

  handleKeyDown = (event) => {
    const {
      title,
      type,
    } = this.state;

    if (event.key === 'Enter') {
      event.preventDefault();
      if (!title) {
        this.setState({ hasError: true });
      }
      if (title) {
        this.props.onSubmit({
          title, type,
        });
        this.props.onClose();
      }
    }
  };

  render() {
    const {
      isLoading,
      hasError,
      title,
    } = this.state;

    const {
      onClose,
      submitText,
    } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <InputTitle>Заголовок</InputTitle>
        <Input type="text" placeholder="Заголовок вреза" onChange={this.onChange('title')} defaultValue={title} onKeyDown={this.handleKeyDown} />
        {hasError ? <InputError> Поле заголовок должно быть заполнено </InputError> : null}
        <ModalController>
          <ModalCancel onClick={onClose}>Отменить</ModalCancel>
          {isLoading ? (
            <ModalLoadingSubmit>
              <Loading />
            </ModalLoadingSubmit>
          ) : (
            <ModalSubmit
              type="submit"
              value={submitText}
            />
          )}
        </ModalController>
      </form>
    );
  }
}

export default CutPullQuoteForm;
