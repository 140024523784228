// drag & drop(add, remove, change), add, remove
/* @flow */

import React, { Component } from 'react';
import * as R from 'ramda';
import { connect } from 'react-redux';
// import RelatedField from '../RelatedField/RelatedField';
import HTML5Backend from 'react-dnd-html5-backend';
import { DragDropContext } from 'react-dnd';
import { reduxForm, change, formValueSelector } from 'redux-form';
import syncForm from '../../lib/sync-form';
import syncInfo from '../../../common/sync-channel';
import FeatureItem from './Feature';
import RecentFeatureItem from './RecentFeature';
import Context from './Context';
import { refreshFeatureContext } from '../../actions/actions';
import { formatTitlePost } from '../../lib/formatString';
import FeturesSettingsForm from './FeaturesSettingsForm';
import HeaderBannerSettings from './HeaderBannerSettings';
import './Features.styl';

type Props = {
  suncedEntity: string,
  syncedId: string,
  form: string,
  features: Object,
  searched: Object,
  dispatch: Object => void,
}

type State = {
  contextItemDragIndex: string,
  features: Object,
  recentlyUsedFeatures: Array,
}

const moveItemInArray = R.curry((at, to, list) => R.insert(to, R.nth(at, list), R.remove(at, 1, list)));

@connect(
  (state, props) => {
    const selector = formValueSelector(syncInfo.formFunc('features', 'features'));
    return {
      syncedEntity: 'features',
      syncedId: 'features',
      features: selector(state, 'instances'),
      searched: state.features,
    };
  },
  (dispatch, ownProps) => ({}),
)
@syncForm()
@reduxForm()
@DragDropContext(HTML5Backend)
export default class Features extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.moveFeature = this.moveFeature.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onDragEndFeature = this.onDragEndFeature.bind(this);
    this.onDragEndContext = this.onDragEndContext.bind(this);
    this.onFeatureRemove = this.onFeatureRemove.bind(this);
    const features = this.props.features;
    this.state = {
      features: features || [],
      recentlyUsedFeatures: [],
    };
  }
  state: State;

  transformPropsToState(features: Object) {
    if (!features) return null;
    return Object.keys(features).map((key: string, i: number) => features[key]);
  }
  transformPropsToState: Object => Array<string>;

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.features && nextProps.features !== this.props.features) {
      this.setState({ features: nextProps.features });
    }
  }

  onDragEndFeature() {
    console.log('dragend feature', this.state.features);

    // todo send request for update
    this.props.dispatch(change(this.props.form, 'instances', this.state.features));
  }
  onDragEndFeature: () => void;

  onDragEndContext(contextItem: { post_id: string, type: string }, dropIndex: string) {
    console.log('dragend context', contextItem, dropIndex);
    const newFeatureFromContext = {
      id: Date.now(),
      sort_id: 0,
      post_id: contextItem.post_id,
      type: contextItem.type,
      title: formatTitlePost(contextItem),
    };

    const newFeatures = [...this.state.features];

    newFeatures.splice(dropIndex, 0, newFeatureFromContext);
    let removedFeature = {};
    if (newFeatures.length > 4) {
      removedFeature = newFeatures.pop();
      // todo send request for update context `removedFeature`
    }

    // todo send request for update features `newFeatures`
    this.setState((prevState) => ({
      features: newFeatures,
      recentlyUsedFeatures: [...prevState.recentlyUsedFeatures, removedFeature ]
    }));

    this.props.dispatch(change(this.props.form, 'instances', newFeatures));
    this.props.dispatch(refreshFeatureContext(contextItem.post_id));
    // this.setState({excludeContextIds: this.state.excludeContextIds.concat(contextItem.post_id)});
  }
  onDragEndContext: (Object, string) => void;

  onFeatureRemove(featureId: string) {
    const newFeatures = this.state.features.filter(feature => feature.id !== featureId);
    const diff = this.state.features.filter(f => !newFeatures.includes(f))

    this.setState((prevState) => ({ 
      features: newFeatures, 
      recentlyUsedFeatures: [...prevState.recentlyUsedFeatures, ...diff ]
    }));
    this.props.dispatch(change(this.props.form, 'instances', newFeatures));
    // this.props.dispatch(refreshFeatureContext());
    // this.setState({excludeContextIds: this.state.excludeContextIds.filter(function(el){
    // 	return el !== featureId;
    // })});
    // todo send request remove `featureId`
  }
  onFeatureRemove: string => void;

  moveFeature(dragIndex: string, hoverIndex: string) {
    const features = R.move(dragIndex, hoverIndex, this.state.features);

    this.setState(
      {
        features,
      }
    );
  }
  moveFeature: (string, string) => void;

  onSearch(e: Event) {}
  onSearch: Event => void;

  render() {
    const { features, contextItemDragIndex } = this.state;
    if (!features) {
      return null;
    }

    const FeaturesList = features.map((feature, index) => (
      <FeatureItem
        key={feature.id}
        index={index}
        id={feature.id}
        sort_id={feature.sort_id}
        post_id={feature.post_id}
        moveFeature={this.moveFeature}
        onDragEndFeature={this.onDragEndFeature}
        onDragEndContext={this.onDragEndContext}
        onRemove={this.onFeatureRemove.bind(null, feature.id)}
        feature={feature}
      />
    ));

    const filteredRecentFeatures = this.state.recentlyUsedFeatures
      .filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.post_id === value.post_id
      )))
      .filter(item => !features.find(el => el.post_id === item.post_id))

    return (
      <div className="ui container basic segment">
        <div className="ui dividing header">Фичеры</div>
        <div className="ui vertically padded grid">
          <div className="nine wide column">
            <div className="ui bottom attached">
              <div className="ui basic">
                <div className="ui small dividing header">
                  Активные
                  <div className="ui horizontal tiny right floated label">
                    {FeaturesList.length}
                  </div>
                </div>
                <div className="relatedContainer">
                  <div className="field relatedPosts featurePosts">{FeaturesList}</div>
                </div>

                <div className="relatedPosts ui bottom attached segment">
                  <div className="ui small dividing header">
                    Недавно использованные:
                  </div>
                  <div className="relatedContainer">
                    {filteredRecentFeatures.length > 0 && filteredRecentFeatures
                      .map((f, index) => 
                        f.id && <RecentFeatureItem
                          key={f.id}
                          index={index}
                          id={f.id}
                          onDragEndContext={this.onDragEndContext}
                          field={f} 
                          />
                      )
                       || 'Пока ничего нет'}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="seven wide column">
            <div className="ui container basic">
              <div className="ui small dividing header">Поиск по контексту</div>
              <div className="field">
                <Context
                  entity="features_context"
                  searched={this.props.searched}
                  onDragEndContext={this.onDragEndContext}
                />
                {/* <RelatedField entity="features_context" /> */}
              </div>
            </div>
          <FeturesSettingsForm />
          <HeaderBannerSettings />
          </div>
        </div>
      </div>
    );
  }
}
